import { useDispatch, useSelector } from 'react-redux'
import { saveFinancingToStorage } from '../../../../redux/actions/Financing'
import { updateOneDynamicFlow } from '../../../../redux/actions/OneDynamicFlow'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { ApplicationState } from '../../../../redux/store'
import { useCallback } from 'react'

export type UseOneDynamicFlowReturn = {
  oneDynamicFlow: Partial<OneDynamicFlowState>,
  setOneDynamicFlow: OneDynamicFlowOnChange
}

export type OneDynamicFlowOnChange = (oneDynamicFlow: Partial<OneDynamicFlowState>) => void

export const useOneDynamicFlow = (): UseOneDynamicFlowReturn => {
  const dispatch = useDispatch()
  const oneDynamicFlow = useSelector<ApplicationState, OneDynamicFlowState>(state => state.oneDynamicFlow)

  const setOneDynamicFlow: OneDynamicFlowOnChange = useCallback((oneDynamicFlow) => {
    dispatch(updateOneDynamicFlow(oneDynamicFlow))
    dispatch(saveFinancingToStorage())
  }, [ dispatch ])

  return { oneDynamicFlow, setOneDynamicFlow }
}
