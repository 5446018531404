import React, { useEffect, useState } from 'react'
import './MonthlyRateSlider.less'
import { toCurrency } from '../../../../services/NumberUtils'
import { Trans, useTranslation } from 'react-i18next'
import { GenericSliderWithInput } from '../genericSliderWithInput/GenericSliderWithInput'

export const getDTI = (percent: number) => percent > 40 ? 'Riskant' : percent > 30 ? 'Anspruchsvoll' : 'Machbar'
export const getPercent = (a: number, b: number) => Math.floor(a * 100 / b)

type MonthlyRateSliderType = {
  monthlyIncome: number
  futureMonthlyBudget: number
  minMonthlyRate: number
  maxMonthlyRate: number
  monthlyRate: number
  onMonthlyRateChange: (val: number) => void
}

export const MonthlyRateSlider: React.FC<MonthlyRateSliderType> = ({
  monthlyIncome,
  futureMonthlyBudget,
  minMonthlyRate,
  maxMonthlyRate,
  monthlyRate,
  onMonthlyRateChange
}) => {
  const { t } = useTranslation('odf')
  const [ monthlyRateVal, setMonthlyRateVal ] = useState(monthlyRate)
  const [ sliderMonthlyRate, setSliderMonthlyRate ] = useState(monthlyRate)
  const percentOfMonthlyIncome = monthlyIncome ? getPercent(sliderMonthlyRate, monthlyIncome) : 0

  useEffect(() => {
    setMonthlyRateVal(monthlyRate)
    setSliderMonthlyRate(monthlyRate)
  }, [ monthlyRate ])

  const onSliderWithInputChange = (val: number) => {
    onMonthlyRateChange(val)
    setMonthlyRateVal(val)
  }

  return (
    <div className='MonthlyRateSlider padding-vertical-xl'>
      <GenericSliderWithInput
        topLabel={t('resultPage.monthlyRateSlider.title')}
        id='monthly-rate'
        min={minMonthlyRate}
        max={maxMonthlyRate}
        value={monthlyRateVal}
        onChange={setSliderMonthlyRate}
        onRelease={onSliderWithInputChange}/>
      <div className='grid-flex grid-justify-space-between slider-bottom'>
        <div role='status' aria-label='dti' className='font-xs'>
          {getDTI(percentOfMonthlyIncome)}
        </div>
        <div role='status' aria-label='max-monthly-rate' className='padding-right-l'>
          {toCurrency(maxMonthlyRate)}
        </div>
      </div>
      <div>
        <b><span role='status' aria-label='percent-of-monthly-income' className='percent-of-monthly-income'>
            {percentOfMonthlyIncome}
        </span> % </b>
        <Trans t={t} i18nKey='resultPage.monthlyRateSlider.description'>
          {toCurrency(monthlyIncome)}
          <br/>
        </Trans>
      </div>
      <div className='grid-flex grid-justify-space-between padding-top-xxl'>
        <div className='font-m'>
          {t('resultPage.monthlyRateSlider.whatIsLeft')}
        </div>
        <div className='align-right'>
          <span role='status' aria-label='available-funds-after-buying' className='font-xl'>
            {toCurrency(futureMonthlyBudget)}
          </span>
          <br/>
          {t('resultPage.monthlyRateSlider.monthly')}
        </div>
      </div>
    </div>
  )
}
