import DataTiles from '../../../common/oneDynamicFlow/dataTiles/DataTiles'
import DataTile from '../../../common/oneDynamicFlow/dataTiles/DataTile'
import { toCurrency } from '../../../../services/NumberUtils'
import { getIncomeTotal } from '../../../common/incomeForm/IncomeForm'
import React from 'react'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { useTranslation } from 'react-i18next'
import { LoanCalculations } from '../../hook/useLoanCalculations'
import { getTotal } from '../../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'

const Summary: React.FC<{ oneDynamicFlow: OneDynamicFlowState, loanCalculations: LoanCalculations }> = (
  {
    oneDynamicFlow,
    loanCalculations
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.summary' })

  return (
    <DataTiles minTwoColumns={true}>
      <DataTile value={toCurrency(oneDynamicFlow.propertyCost ?? 0)} label={t('purchasePrice')}/>
      <DataTile value={toCurrency(getIncomeTotal([ oneDynamicFlow.income ?? {} ]))} label={t('monthlyIncome')}/>
      <DataTile value={toCurrency(getTotal(oneDynamicFlow.equityCapital))} label={t('ownCapital')}/>
      <DataTile value={toCurrency(loanCalculations.totalCost ?? 0)} label={t('totalCost')}/>
    </DataTiles>
  )
}

export default Summary
