import React, { useCallback, useEffect, useState } from 'react'
import Headline from './headline/Headline'
import ProfileTabs, { TabId } from './tabs/ProfileTabs'
import { HeaderSection } from './headerSection/HeaderSection'
import { useBuyersProfileData } from '../../hooks/useBuyersProfileData'
import useSelectedObject from './hook/useSelectedObject'
import { withToastMessages } from '../common/toastMessage/ToastMessage'
import { onAffordabilityInit } from '../../redux/thunks/Affordability'
import { usePreApprovalResults } from '../preApproval/hook/usePreApprovalResults'
import { useDispatch } from 'react-redux'

export const ProfilePage: React.FC = () => {
  const dispatch = useDispatch()
  const { profile: { firstName } } = useBuyersProfileData()
  const [ selectedTabId, setSelectedTabId ] = useState(TabId.FEASIBILITY_TAB)
  const [ selectedTabIndex, setSelectedTabIndex ] = useState(0)
  const { selectedObject, selectNewObject } = useSelectedObject()
  const preApprovalResults = usePreApprovalResults()

  useEffect(() => {
    dispatch(onAffordabilityInit())
  }, [ dispatch ])

  const onTabSelected = useCallback((tabId: TabId, tabIndex: number) => {
    setSelectedTabId(tabId)
    setSelectedTabIndex(tabIndex)
  }, [ setSelectedTabId, setSelectedTabIndex ])

  return (
    <div data-testid='profilePage'>
      <Headline />
      <HeaderSection firstName={firstName} stepIndex={selectedTabIndex} stepId={selectedTabId}/>
      <ProfileTabs onTabSelected={onTabSelected}
                   selectedObject={selectedObject}
                   selectNewObject={selectNewObject}
                   preApprovalResults={preApprovalResults}/>
    </div>
  )
}

export default withToastMessages(ProfilePage)
