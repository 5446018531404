import { useEffect, useState } from 'react'
import { ShortlistEntryAO } from '../../../../../../api/api/types/ShortlistEntryAO'
import { getShortlistEntries } from '../../../../../common/shortlistDropdown/ShortlistService'

export type UseShortlistReturn = {
  totalCount: number
  visibleEntries: ShortlistEntryAO[]
  loading: boolean
  showAll: boolean
  setShowAll: (value: boolean) => void
}

const useShortlist = (loadShortlist = getShortlistEntries, limitShownEntriesCount = 4): UseShortlistReturn => {
  const [ shortlistEntries, setShortlistEntries ] = useState([] as ShortlistEntryAO[])
  const [ visibleEntries, setVisibleEntries ] = useState([] as ShortlistEntryAO[])
  const [ loading, setLoading ] = useState(false)
  const [ showAll, setShowAll ] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadShortlist().then(entries => {
      setShortlistEntries(entries.filter(entry => entry.publicationState === 'active'))
    }).catch(error => {
      console.error(error)
      setShortlistEntries([])
    }).finally(() => setLoading(false))
  }, [ loadShortlist ])

  useEffect(() =>
      setVisibleEntries(showAll ? shortlistEntries : shortlistEntries.slice(0, limitShownEntriesCount)),
    [ showAll, shortlistEntries, limitShownEntriesCount ])

  return {
    totalCount: shortlistEntries.length,
    visibleEntries,
    loading,
    showAll,
    setShowAll
  }
}

export default useShortlist
