import React, { useMemo } from 'react'
import Officer from '../../../../../common/appointmentOfficer/Officer'
import andreas from '../../../../../../assets/images/officer-photos/andreas.png'
import stefan from '../../../../../../assets/images/officer-photos/stefan.png'
import simone from '../../../../../../assets/images/officer-photos/simone.png'
import dirk from '../../../../../../assets/images/officer-photos/dirk.png'
import christian from '../../../../../../assets/images/officer-photos/christian.png'
import jacqueline from '../../../../../../assets/images/officer-photos/jacqueline.png'
import klaus from '../../../../../../assets/images/officer-photos/klaus.png'
import natalie from '../../../../../../assets/images/officer-photos/natalie.png'
import veit from '../../../../../../assets/images/officer-photos/veit.png'
import { useTranslation } from 'react-i18next'
import './AdvisorsModal.less'

type AdvisorsModalProps = {
  numberOfAdvisors: number;
  showAddress?: boolean
}

type AdvisorData = {
  image: string,
  name: string,
  jobTitle: string,
  streetAndNumber: string,
  postcodeAndLocation: string
}

const advisorImages = [ andreas, stefan, simone, dirk, christian, jacqueline, klaus, natalie, veit ];

export const Advisors: React.FC<AdvisorsModalProps> = ({ numberOfAdvisors, showAddress = true }) => {
  const { t } = useTranslation('userFlow')

  const allOfficers: AdvisorData[] = useMemo(() => advisorImages.map((image, index) => ({
    image: image,
    name: t(`personalDetails.advisorsInfo.person${index+1}.name`),
    jobTitle: t(`personalDetails.advisorsInfo.person${index+1}.title`),
    streetAndNumber: t(`personalDetails.advisorsInfo.person${index+1}.streetAndNumber`),
    postcodeAndLocation: t(`personalDetails.advisorsInfo.person${index+1}.postcodeAndLocation`),
  })), [t])

  let randomOfficers = [ ...allOfficers ]
  while (randomOfficers.length > numberOfAdvisors) {
    const randomIndex = Math.round(Math.random() * randomOfficers.length)
    randomOfficers = randomOfficers.filter((_, index) => index !== randomIndex).sort(() => Math.random() - 0.5);
  }

  return (
    <>
    {randomOfficers.map((officer: AdvisorData) => (
      <div key={officer.name} className="officer-wrapper grid-item palm-one-whole desk-one-fourth lap-one-fourth">
        <Officer
          officerImg={officer.image}
          name={officer.name}
          imgWidth={130}
          jobTitle={officer.jobTitle}
          streetAndNumber={showAddress ? officer.streetAndNumber : undefined}
          postcodeAndLocation={showAddress ? officer.postcodeAndLocation : undefined}
        />
      </div>
    ))}
    </>
  )
}
