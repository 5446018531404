import React from 'react'
import { useAffordabilityResults } from '../../../../../affordability/hook/useAffordabilityResults'
import AffordabilityResults from './AffordabilityResults'

export const AffordabilityResultsWrapper: React.FC = () => {
  const { result } = useAffordabilityResults()

  return (
    <AffordabilityResults result={result}/>
  )
}

export default AffordabilityResultsWrapper
