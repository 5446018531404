import React from 'react'
import { Heading5 } from 'is24-corecss'
import { toCurrency } from '../../../../services/NumberUtils'
import './financingCostItem.less'

interface FinancingCostItemProps {
  name: string
  value: number
}

const FinancingCostItem: React.FC<FinancingCostItemProps> = ({ name, value }) => {
  return (
    <div className='financing-cost-item'>
      <Heading5 tag='div'>{name}</Heading5>
      <Heading5 tag='div'>{toCurrency(value)}</Heading5>
    </div>
  )
}

export default FinancingCostItem
