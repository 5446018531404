import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AFFORDABILITY_USER_FLOW,
  RESTART_FLOW_PATH,
  RESULTS_AFFORDABILITY
} from '../../../config/contextPaths'
import { AffordabilityState } from '../../../redux/reducers/Affordability'
import { ApplicationState } from '../../../redux/store'
import { isDefined, isNumber } from '../../../utils/utils'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { Expense, isValidExpense } from '../../common/oneDynamicFlow/pages/expense/Expense'
import { Income, isValidIncome } from '../../common/oneDynamicFlow/pages/income/Income'
import {
  isValidLocationWithGeoCode,
  Location,
  LocationQueryType
} from '../../common/oneDynamicFlow/pages/location/Location'
import { NumberOfChildren } from '../../common/oneDynamicFlow/pages/numberOfChildren/NumberOfChildren'
import { NumberOfPeople } from '../../common/oneDynamicFlow/pages/numberOfPeople/NumberOfPeople'
import { ObjectUsage } from '../../common/oneDynamicFlow/pages/objectUsage/ObjectUsage'
import { OwnCapital } from '../../common/oneDynamicFlow/pages/ownCapital/OwnCapital'
import { isValidFloorArea, Size } from '../../common/oneDynamicFlow/pages/size/Size'
import { oneDynamicFlowRedirectAndExit } from '../../../redux/actions/OneDynamicFlow'
import FlowContainer, { PageType } from '../../common/oneDynamicFlow/wizard/FlowContainer'
import { useFlowNavigation } from '../../common/oneDynamicFlow/hook/useFlowNavigation'
import { onAffordabilityInit } from '../../../redux/thunks/Affordability'
import { logInCheck } from '../../../redux/actions/Login'
import {
  calculateLivingExpenses,
  shouldPrefillLivingExpenses
} from '../../common/oneDynamicFlow/prefillCalculations/PrefillCalculations'
import useResetLocation from '../../common/oneDynamicFlow/pages/location/hook/useResetLocation'
import { isEquityCapitalValid } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'
import { LogInState } from '../../../redux/reducers/Login'
import useAffordabilityResultPageUrl from '../hook/useAffordabilityResultPageUrl'

const PagePaths = {
  numberOfBorrowers: 'anzahl-darlehensnehmer',
  numberOfChildren: 'anzahl-kinder',
  floorArea: 'objekt-groesse',
  ownUse: 'benutzungsart',
  location: 'finanzierungsort',
  equityCapital: 'eigenkapital',
  income: 'einnahmen',
  expense: 'ausgaben'
}

const AffordabilityFlowContainer: React.FC = () => {
  const dispatch = useDispatch()
  const { initialized } = useSelector<ApplicationState, AffordabilityState>(state => state.affordability)
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)
  const resultPageUrl = useAffordabilityResultPageUrl()
  const {
    getCurrentProgress,
    goToNextPage,
    goToPreviousPage,
    goToResultPage
  } = useFlowNavigation(AFFORDABILITY_USER_FLOW, resultPageUrl, Object.values(PagePaths))

  useEffect(() => {
    dispatch(logInCheck())
  }, [ dispatch ])

  useEffect(() => {
    if (loggedIn !== undefined) {
      dispatch(onAffordabilityInit())
    }
  }, [ dispatch, loggedIn ])

  const onCloseButtonClicked = useCallback(() => {
    dispatch(oneDynamicFlowRedirectAndExit())
  }, [ dispatch ])

  const commonProps = useMemo(() => ({
    progress: getCurrentProgress,
    numberOfBorrowers: oneDynamicFlow.numberOfBorrowers,
    onClose: onCloseButtonClicked,
    onBack: goToPreviousPage,
    onNext: goToNextPage
  }), [onCloseButtonClicked, oneDynamicFlow.numberOfBorrowers, getCurrentProgress, goToNextPage, goToPreviousPage])

  useResetLocation(LocationQueryType.geocode, (location) => {
    setOneDynamicFlow({ location, exposeId: undefined, exposeFromShortlist: undefined })
  }, oneDynamicFlow.location)

  const updateCalculatedLivingCost = useCallback((): Promise<void> => {
    if (shouldPrefillLivingExpenses(oneDynamicFlow.expense)) {
      return calculateLivingExpenses(oneDynamicFlow.numberOfBorrowers, oneDynamicFlow.numberOfChildren, oneDynamicFlow.income?.salary)
        .then(calculatedLivingCost => setOneDynamicFlow({ expense: { ...oneDynamicFlow.expense, livingCost: calculatedLivingCost }}))
    } else {
      return Promise.resolve()
    }
  }, [setOneDynamicFlow, oneDynamicFlow.expense, oneDynamicFlow.numberOfBorrowers, oneDynamicFlow.numberOfChildren, oneDynamicFlow.income?.salary])

  const pages: PageType[] = useMemo(() => [
    {
      path: PagePaths.numberOfBorrowers,
      canProceedToNextPage: isNumber(oneDynamicFlow.numberOfBorrowers),
      pageViewTracking: 'affordability_funnel_borrowers',
      component:
        <NumberOfPeople { ...commonProps }
          onClick={(numberOfBorrowers) => {
            setOneDynamicFlow({ numberOfBorrowers })
            goToNextPage()
          }}
          onBack={undefined}
        />
    },
    {
      path: PagePaths.numberOfChildren,
      canProceedToNextPage: isNumber(oneDynamicFlow.numberOfChildren),
      pageViewTracking: 'affordability_funnel_children',
      component:
        <NumberOfChildren { ...commonProps }
          numberOfChildren={oneDynamicFlow.numberOfChildren}
          onChange={(numberOfChildren) => {
            setOneDynamicFlow({ numberOfChildren })
          }}
        />
    },
    {
      path: PagePaths.floorArea,
      canProceedToNextPage: isValidFloorArea(oneDynamicFlow.floorArea),
      pageViewTracking: 'affordability_funnel_squaremeters',
      component:
        <Size { ...commonProps }
          floorArea={oneDynamicFlow.floorArea}
          onChange={(floorArea) => {
            setOneDynamicFlow({ floorArea })
          }}
        />
    },
    {
      path: PagePaths.ownUse,
      canProceedToNextPage: isDefined(oneDynamicFlow.ownUse),
      pageViewTracking: 'affordability_funnel_usetype',
      component:
        <ObjectUsage { ...commonProps }
          ownUse={oneDynamicFlow.ownUse}
          onClick={(ownUse) => {
            setOneDynamicFlow({ ownUse })
            goToNextPage()
          }}
        />
    },
    {
      path: PagePaths.location,
      canProceedToNextPage: isValidLocationWithGeoCode(oneDynamicFlow.location),
      pageViewTracking: 'affordability_funnel_region',
      component:
        <Location { ...commonProps }
          location={oneDynamicFlow.location}
          queryType={LocationQueryType.geocode}
          onLocationChange={(location) => {
            setOneDynamicFlow({ location })
          }}
        />
    },
    {
      path: PagePaths.equityCapital,
      canProceedToNextPage: isEquityCapitalValid(oneDynamicFlow.equityCapital),
      pageViewTracking: 'affordability_funnel_capital',
      component:
        <OwnCapital { ...commonProps }
          equityCapital={oneDynamicFlow.equityCapital}
          onChange={(equityCapital) => {
            setOneDynamicFlow({ equityCapital })
          }}
        />
    },
    {
      path: PagePaths.income,
      canProceedToNextPage: isValidIncome(oneDynamicFlow.income),
      pageViewTracking: 'affordability_funnel_income',
      component:
        <Income { ...commonProps }
          income={oneDynamicFlow.income}
          numberOfChildren={oneDynamicFlow.numberOfChildren}
          onChange={(income) => {
            setOneDynamicFlow({ income })
          }}
          onNext={() => updateCalculatedLivingCost().then(goToNextPage)}
        />
    },
    {
      path: PagePaths.expense,
      pageViewTracking: 'affordability_funnel_spending',
      canProceedToNextPage: isValidExpense(oneDynamicFlow.expense),
      component:
        <Expense { ...commonProps }
          expense={oneDynamicFlow.expense}
          onChange={(expense) => {
            setOneDynamicFlow({ expense })
          }}
          onNext={goToResultPage}
        />
    }
  ], [ oneDynamicFlow, goToNextPage, setOneDynamicFlow, goToResultPage, commonProps, updateCalculatedLivingCost ])

  return (
    <FlowContainer pages={pages} flowUrl={AFFORDABILITY_USER_FLOW} resultPageUrl={RESULTS_AFFORDABILITY} restartFlowPath={RESTART_FLOW_PATH} dataInitialized={initialized}/>
  )
}

export default AffordabilityFlowContainer
