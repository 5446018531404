import { AFFORDABILITY_CALCULATION_API, IS24_HOST_URL_LOCAL } from '../../../api/contextPaths'
import { postRequest } from '../../../api/request'
import { AdditionalCostAO } from '../../../api/api/types/UserAffordabilityResultAO'
import { BorrowerExpensesAO } from '../../../api/api/types/BorrowerExpensesAO.ds'
import { BorrowerIncomeAO } from '../../../api/api/types/BorrowerIncomeAO.ds'
import { MORTGAGE_PROFILE } from '../../../config/contextPaths'
import { redirectToSso } from '../../../services/SsoService'
import { mapFromCalculationResponse, mapToAffordabilityCalculationRequest } from './AffordabilityResultMapper'
import {
  AffordabilityCalculationRequest,
  AffordabilityCalculationResponse
} from '../../../api/api/types/UserAffordabilityCalculation'
import { FinancingUseType } from '../../../api/api/types/FinancingUseType.ds'


export const DEFAULT_VALUE_AMORTIZATION_RATE = 1
export const DEFAULT_VALUE_INTEREST_RATE = 2

export type AffordabilityResult = {
  totalCost: number
  currentMonthlyBudget: number
  futureMonthlyBudget: number
  minMonthlyRate: number
  maxMonthlyRate: number
  monthlyRate: number,
  pricePerSquareMeter: number,
  savedMonthlyRent: number,
  squareMeters: number,
  firstBorrowerIncome: BorrowerIncomeAO,
  firstBorrowerSpending: BorrowerExpensesAO,
  amortizationRate?: number,
  interestRate?: number,
  totalOwnFunds: number,
  additionalCosts: AdditionalCostAO,
  maxPurchasePrice: number,
  maxLoanAmount: number,
  ownFundPurchasePriceRatio: number,
  includeBrokerCommission: boolean,
  fixedNominalInterestRate: string,
  geoCode: string,
  searchLabel: string,
  financingUseType: FinancingUseType,
  error?: string,
  warning?: string
}

const isLowIncomeValidationError = (error: any): boolean =>
  error &&
  error.httpStatus === 400 &&
  error.errors &&
  error.errors.messages?.includes('incomeLowerThanCostOfLiving')

export const calculateUserAffordability = async (result: AffordabilityResult): Promise<AffordabilityResult> => {
  const body: AffordabilityCalculationRequest = mapToAffordabilityCalculationRequest(result);

  try {
    const response: AffordabilityCalculationResponse  = await postRequest({
      url: AFFORDABILITY_CALCULATION_API,
      body
    })
    return mapFromCalculationResponse(response, result);
  } catch (error: any) {
    if (isLowIncomeValidationError(error)) {
      const errorMessage = error.errors.messages[0]
      return { ...result, error: errorMessage }
    }
    throw error
  }
}

export const redirectToSsoToOpenProfile = (): void => {
  const ssoRedirectionSource = 'affordabilityFlow'
  const returnUrl = `${IS24_HOST_URL_LOCAL}${MORTGAGE_PROFILE}`
  redirectToSso(ssoRedirectionSource, returnUrl)
}
