import React from 'react'
import  '../CertificateModal.less'

export type DetailRowProps = {
  icon: string
  caption: string
  description: string
}

export const DetailRow: React.FC<DetailRowProps> = ({ icon, caption, description }) => {
  return (
    <div className='details-row'>
      <div>
        <img aria-hidden='true' src={icon} alt='icon' className='icon'/>
      </div>
      <div>
        <div className='details-caption'>{caption}</div>
        <div className='details-description'>{description}</div>
      </div>
    </div>
  )
}

export default DetailRow
