import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PRE_APPROVAL_RESULT_PAGE, PRE_APPROVAL_USER_FLOW, RESTART_FLOW_PATH } from '../../../config/contextPaths'
import { useFlowNavigation } from '../../common/oneDynamicFlow/hook/useFlowNavigation'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import FlowContainer, { PageType } from '../../common/oneDynamicFlow/wizard/FlowContainer'
import {
  isValidLocationWithPostcode,
  Location,
  LocationQueryType
} from '../../common/oneDynamicFlow/pages/location/Location'
import { loadFinancingFromStorage } from '../../../redux/actions/Financing'
import { logInCheck } from '../../../redux/actions/Login'
import { isDefined } from '../../../utils/utils'
import { extractFlowDataFrom } from '../../financing/services/FinancingService'
import { useExposeFromQueryParam } from '../../financing/hook/useExposeFromQueryParam'
import { DateOfBirth } from '../../common/oneDynamicFlow/pages/dateOfBirth/DateOfBirth'
import { ApplicationState } from '../../../redux/store'
import { LogInState } from '../../../redux/reducers/Login'
import LoginPrompt from './loginPrompt/LoginPrompt'
import ProposalsLoadingPage from './loading/ProposalsLoadingPage'
import useResetLocation from '../../common/oneDynamicFlow/pages/location/hook/useResetLocation'
import { EmploymentTypePage } from '../../common/oneDynamicFlow/pages/employmentTypePage/EmploymentTypePage'
import { EmployedSince } from '../../common/oneDynamicFlow/pages/employedSince/EmployedSince'
import { EmploymentType } from '../../../api/api/types/Employment'
import {
  redirectToSsoWithLoadingPageAsReturnUrl,
  shouldShowEmployedSinceQuestionFor
} from '../services/PreApprovalService'
import useResetDataDependentOnCurrentEmploymentType from '../hook/useResetDataDependentOnCurrentEmploymentType'
import { OtherRemainingDebts } from '../../common/oneDynamicFlow/pages/otherRemainingDebts/OtherRemainingDebts'
import PreApprovalDisclaimer from '../disclaimer/PreApprovalDisclaimer'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../config/optimizely'
import { useContactRequestBlockedReset } from '../hook/useContactRequestBlockedReset'
import { isValidPropertyCost } from '../../financing/propertyCost/PropertyCost'
import { IncomeSimplified, isValidIncome } from '../../common/oneDynamicFlow/pages/incomeSimplified/IncomeSimplified'
import { OwnCapitalSimplified } from '../../common/oneDynamicFlow/pages/ownCapitalSimplified/OwnCapitalSimplified'
import { isEquityCapitalValid } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'
import usePreApprovalResultPageUrl from '../hook/usePreApprovalResultPageUrl'
import LoadingPage from '../../common/loadingPage/LoadingPage'
import ContactDataForm from './contactDataForm/ContactDataForm'
import { BuyersProfileDataState, useBuyersProfileData } from '../../../hooks/useBuyersProfileData'
import { BuyersProfileUser } from '../../../services/BuyersProfileService'
import { showToastMessage, withToastMessages } from '../../common/toastMessage/ToastMessage'
import { logError } from '../../../api/api/Logger'

const PagePaths = {
  income: 'einnahmen',
  equityCapital: 'eigenkapital',
  dateOfBirth: 'geburtsdatum',
  employmentType: 'beschaeftigungsart',
  employedSince: 'beschaeftigungszeit',
  otherRemainingDebts: 'kredite',
  location: 'finanzierungsort',
  loginPrompt: 'login',
  loading: 'berechnung',
  contactForm: 'kontaktangaben'
}

const PreApprovalFlowContainer: React.FC = () => {
  useContactRequestBlockedReset()
  const dispatch = useDispatch()
  const [ dataLoadedFromStorage, setDataLoadedFromStorage ] = useState(false)
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()
  const resultPageUrl = usePreApprovalResultPageUrl()
  const { getCurrentProgress, goToNextPage, goToPreviousPage, goToResultPage, goToFlowPage } = useFlowNavigation(PRE_APPROVAL_USER_FLOW, resultPageUrl ?? PRE_APPROVAL_RESULT_PAGE, Object.values(PagePaths))
  const { done: dataInitialized } = useExposeFromQueryParam(dataLoadedFromStorage)
  const { loggedIn, email } = useSelector<ApplicationState, LogInState>(state => state.logIn)
  const [ showProposals ] = useFeature(Feature.SHOW_EUROPACE_PROPOSALS_ON_PREAPPROVAL_RESULT_PAGE)
  const [ showContactInFlow, /*unused*/, /*optimizelyReady, optimizelyTimedOut*/ ] = useFeature(Feature.BAUFI_READY_SHOW_CONTACT_IN_FLOW)
  const { profile, state : buyersProfileState, updateProfile: updateBuyersProfile } = useBuyersProfileData()

  const showContactForm = showContactInFlow && buyersProfileState !== BuyersProfileDataState.VALID

  useEffect(() => {
    dispatch(loadFinancingFromStorage())
    dispatch(logInCheck())
    setDataLoadedFromStorage(true)
  }, [ dispatch ])

  const commonProps = useMemo(() => ({
    progress: getCurrentProgress,
    onBack: goToPreviousPage,
    onNext: goToNextPage
  }), [getCurrentProgress, goToNextPage, goToPreviousPage])

  useResetLocation(LocationQueryType.postcode, (location) => {
    setOneDynamicFlow({ location, exposeId: undefined, exposeFromShortlist: undefined })
  }, oneDynamicFlow.location)

  const updateDateOfBirth = useCallback((dateOfBirth: string | undefined) => {
    setOneDynamicFlow({ dateOfBirth })
  }, [ setOneDynamicFlow ])

  const updateEmployedSince = useCallback((employedSince: string | undefined) => {
    setOneDynamicFlow({ employedSince })
  }, [ setOneDynamicFlow ])

  const goToNextPageAfterSelectingEmploymentType = useCallback((employmentType: EmploymentType | undefined) =>
    shouldShowEmployedSinceQuestionFor(employmentType) ? goToNextPage() : goToFlowPage(PagePaths.otherRemainingDebts),
    [ goToFlowPage, goToNextPage ])

  const goToLoadingPageOrResultPage = useCallback(() => {
    if (showContactInFlow) {
      goToFlowPage(PagePaths.loading)
    } else {
      goToResultPage()
    }
  }, [showContactInFlow, goToFlowPage, goToResultPage])

  useResetDataDependentOnCurrentEmploymentType()

  const isDataFromExpose = isDefined(oneDynamicFlow.exposeId) && (oneDynamicFlow.exposeFromShortlist == undefined || !oneDynamicFlow.exposeFromShortlist)

  const pages: PageType[] = useMemo(() => [
    {
      path: PagePaths.income,
      canProceedToNextPage: isValidIncome(oneDynamicFlow.income),
      pageViewTracking: 'preapprovalfunnel.income',
      component:
        <IncomeSimplified
          {...commonProps}
          income={oneDynamicFlow.income}
          onChange={(income) => setOneDynamicFlow({ income })}
          onBack={undefined}
        />
    },
    {
      path: PagePaths.equityCapital,
      canProceedToNextPage: isEquityCapitalValid(oneDynamicFlow.equityCapital),
      pageViewTracking: 'preapprovalfunnel.ownfunds',
      component:
        <OwnCapitalSimplified
          {...commonProps}
          equityCapital={oneDynamicFlow.equityCapital}
          onChange={(equityCapital) => setOneDynamicFlow({ equityCapital })}
        />
    },
    {
      path: PagePaths.dateOfBirth,
      canProceedToNextPage: isDefined(oneDynamicFlow.dateOfBirth),
      pageViewTracking: 'preapprovalfunnel.age',
      component:
        <DateOfBirth
          {...commonProps}
          dateOfBirth={oneDynamicFlow.dateOfBirth}
          onChange={updateDateOfBirth}
        />
    },
    {
      path: PagePaths.employmentType,
      canProceedToNextPage: isDefined(oneDynamicFlow.employmentType),
      pageViewTracking: 'preapprovalfunnel.employment',
      component:
        <EmploymentTypePage
          {...commonProps}
          employmentType={oneDynamicFlow.employmentType}
          onClick={(employmentType) => {
            setOneDynamicFlow({ employmentType })
            goToNextPageAfterSelectingEmploymentType(employmentType)
          }}
          onNext={() => goToNextPageAfterSelectingEmploymentType(oneDynamicFlow.employmentType)}
        />
    },
    {
      path: PagePaths.employedSince,
      canProceedToNextPage: isDefined(oneDynamicFlow.employedSince),
      shouldBeSkipped: !shouldShowEmployedSinceQuestionFor(oneDynamicFlow.employmentType),
      pageViewTracking: 'preapprovalfunnel.employedSince',
      component:
        <EmployedSince
          {...commonProps}
          employedSince={oneDynamicFlow.employedSince}
          fixedTermEmployment={oneDynamicFlow.fixedTermEmployment}
          onEmployedSinceChanged={updateEmployedSince}
          onFixedTermEmploymentChanged={(fixedTermEmployment) => setOneDynamicFlow({ fixedTermEmployment })}
          dateOfBirth={oneDynamicFlow.dateOfBirth}
        />
    },
    {
      path: PagePaths.otherRemainingDebts,
      canProceedToNextPage: isDefined(oneDynamicFlow.otherRemainingDebts),
      pageViewTracking: 'preapprovalfunnel.other_remaining_debts',
      component:
        <OtherRemainingDebts
          {...commonProps}
          otherRemainingDebts={oneDynamicFlow.otherRemainingDebts}
          onChange={(otherRemainingDebts) => {
            setOneDynamicFlow({ otherRemainingDebts: otherRemainingDebts })
          }}
          onNext={() => isDataFromExpose ? (loggedIn ? goToLoadingPageOrResultPage() : goToFlowPage(PagePaths.loginPrompt)) : goToNextPage()}
          onBack={() => shouldShowEmployedSinceQuestionFor(oneDynamicFlow.employmentType) ? goToPreviousPage() : goToFlowPage(PagePaths.employmentType)}
        >
          {showProposals && isDataFromExpose && <PreApprovalDisclaimer/>}
        </OtherRemainingDebts>
    },
    {
      path: PagePaths.location,
      canProceedToNextPage: isValidLocationWithPostcode(oneDynamicFlow.location) && isValidPropertyCost(oneDynamicFlow.propertyCost) && isDefined(oneDynamicFlow.exposeId),
      shouldBeSkipped: isDataFromExpose,
      pageViewTracking: 'preapprovalfunnel.region',
      component:
        <Location
          {...commonProps}
          location={oneDynamicFlow.location}
          exposeId={oneDynamicFlow.exposeId}
          exposeFromShortlist={oneDynamicFlow.exposeFromShortlist}
          exposeOnlyMode={true}
          queryType={LocationQueryType.postcode}
          onLocationChange={(location) => {
            setOneDynamicFlow({ location })
          }}
          onExposeChange={(expose, exposeFromShortlist) => {
            setOneDynamicFlow(extractFlowDataFrom(expose, exposeFromShortlist))
          }}
          onNext={() => loggedIn ? goToLoadingPageOrResultPage() : goToNextPage()}
        >
          {showProposals && !isDataFromExpose && <PreApprovalDisclaimer/>}
        </Location>
    },
    {
      path: PagePaths.loginPrompt,
      canProceedToNextPage: true,
      pageViewTracking: 'preapprovalfunnel.loginprompt',
      component:
        <LoginPrompt onClick={() => showContactInFlow ? redirectToSsoWithLoadingPageAsReturnUrl() : goToResultPage()}/>
    },
    {
      path: PagePaths.loading,
      canProceedToNextPage: !showContactForm,
      pageViewTracking: 'preapprovalfunnel.loading',
      component:
        <ProposalsLoadingPage onNext={goToNextPage}/>
    },
    {
      path: PagePaths.contactForm,
      canProceedToNextPage: !showContactForm,
      pageViewTracking: 'preapprovalfunnel.contactdata', // TODO: tracking needs to be confirmed
      component:
        <ContactDataForm email={email ?? ''} profile={profile} updateProfile={(p: BuyersProfileUser) =>
          updateBuyersProfile(p)
            .then(goToResultPage)
            .catch(error => {
              showToastMessage('odf:resultPage.toastMessages.savingFailed.title',
                'odf:resultPage.toastMessages.savingFailed.message',
                'error')
              console.error("Saving data to Buyer's Profile failed.", error)
              logError("Saving data to Buyer's Profile failed.", error)
            })
        }/>
    },
  ], [
    oneDynamicFlow,
    loggedIn,
    setOneDynamicFlow,
    goToResultPage,
    goToNextPage,
    commonProps,
    updateDateOfBirth,
    updateEmployedSince,
    goToNextPageAfterSelectingEmploymentType,
    goToLoadingPageOrResultPage,
    goToFlowPage,
    goToPreviousPage,
    showProposals,
    isDataFromExpose,
    showContactInFlow,
    showContactForm,
    profile,
    updateBuyersProfile,
    email,
  ])

  return <>
    {resultPageUrl ?
      <FlowContainer
        pages={pages}
        flowUrl={PRE_APPROVAL_USER_FLOW}
        resultPageUrl={resultPageUrl}
        restartFlowPath={RESTART_FLOW_PATH}
        dataInitialized={dataInitialized}/>
    : <LoadingPage/>}
  </>
}

export default withToastMessages(PreApprovalFlowContainer)
