import trackingService from '../../../services/TrackingService/TrackingService'

const reportPreApprovalEvent = (label: string, eventName: string, eventParameter1: string, eventParameter2?: string) => {
  trackingService.reportEvent(
    'preapprovalfunnel.resultpage.positive',
    'preapproval',
    label,
    'finance', {
      event_name: eventName,
      event_product: 'finance',
      event_parameter_1: eventParameter1,
      event_parameter_2: eventParameter2
    }
  )
}

export const reportProposalRequestEvent = (idx: number) => {
  reportPreApprovalEvent(`proposal_${idx}`, 'proposal_shown', 'preapproval', `${idx}`)
}

export const reportProposalRequestCTAEvent = () => {
  reportPreApprovalEvent('proposal_cta', 'proposal_cta', 'preapproval')
}

export const reportContactRequestCreatedEvent = (contactRequestId: number) => {
  trackingService.reportEvent(
    'preapprovalfunnel.resultpage.positive',
    'finance',
    'preapproval_lead',
    'lead',
    {
      evt_lafid: contactRequestId,
      event_name: 'lead_submit',
      event_product: 'finance',
      event_parameter_1: 'preapproval_lead'
    }
  )
}
