import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorrowerEquityCapital } from '../../../../../api/api/types/BorrowerEquityCapital'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import NumberInput from '../../../input/numericInput/NumberInput'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { getTotal, isEquityCapitalValid } from '../ownCapital/ownCapital.utils'
import './OwnCapitalSimplified.less'
import { FormLabel } from '../../../input/FormLabel'

export type OwnCapitalSimplifiedProps = Pick<OneDynamicFlowState, 'equityCapital' | 'numberOfBorrowers'> & {
  onChange: (equityCapital: Partial<BorrowerEquityCapital>) => void
} & FooterProps & CloseButtonProps

export const OwnCapitalSimplified: React.FC<OwnCapitalSimplifiedProps> = ({
     equityCapital = {},
     numberOfBorrowers = 1,
     onChange, onBack,
     onNext,
     progress,
     onClose
}) => {
  const { t } = useTranslation('odf')
  const total = getTotal(equityCapital)
  const handleChange = (value: number) => onChange({ otherAssets: value })

  return (
    <OneDynamicFlowQuestion
      title={t('userFunnel.ownCapital.title', { count: numberOfBorrowers })}
      description={t('userFunnel.ownCapital.description')}
      arrow='long-arrow-bottom-right'
      disabled={!isEquityCapitalValid(equityCapital)}
      touched={total > 0}
      onBack={onBack}
      onNext={onNext}
      progress={progress}
      onClose={onClose}
    >
      <div className='OwnCapitalSimplifiedContent grid-item one-whole palm-margin-horizontal-l palm-padding-bottom-xxl lap-padding-bottom-xxl'>
        <FormLabel label={t('userFunnel.ownCapital.total.label')} tooltip={t('userFunnel.ownCapital.total.tooltip')}/>
        <div className='one-whole grid-item'>
          <NumberInput
            id='otherAssets'
            placeholder=''
            valid={isEquityCapitalValid(equityCapital)}
            errorMessage={t('userFunnel.ownCapital.errorMessage')}
            value={total}
            onChange={handleChange}
            forceSelect={false}
            unitRight='eur'
            ariaLabel={t(`userFunnel.ownCapital.otherAssets`)}
          />
        </div>
      </div>
    </OneDynamicFlowQuestion>
  )
}


