import { Helmet } from 'react-helmet-async'
import React from 'react'

const DefaultMetaAttributes = () => (
  <Helmet>
    <title>Finanzierungsberatung von ImmoScout24</title>
    <meta
      name="description"
      content="Die ImmoScout24 Expert:innen begleiten dich in persönlichen Gesprächen durch den gesamten Finanzierungsprozess. Wir beantworten deine Fragen und finden für dich passende Angebote."
    />
    <meta name="robots" content="index, follow"/>
    <link rel="canonical" href="https://www.immobilienscout24.de/baufinanzierung/finanzierungsberatung/"/>
  </Helmet>
)

export default DefaultMetaAttributes
