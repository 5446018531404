import React, { useEffect, useState } from 'react'

export type UseDelayedHidingToggleReturn = {
  visible: boolean
  delayedVisible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const useDelayedHidingToggle = (delay = 200, initialState = false): UseDelayedHidingToggleReturn => {
  const [ visible, setVisible ] = useState(initialState)
  const [ delayedVisible, setDelayedVisible ] = useState(visible)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (visible) {
      setDelayedVisible(true)
    } else {
      timer = setTimeout(() => setDelayedVisible(false), delay)
    }
    return () => timer && clearTimeout(timer)
  }, [ visible, delay ])

  return { visible, delayedVisible, setVisible }
}

export default useDelayedHidingToggle
