import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import DataTiles from '../../../common/oneDynamicFlow/dataTiles/DataTiles'
import DataTile from '../../../common/oneDynamicFlow/dataTiles/DataTile'

const SampleOffer: React.FC<{
  monthlyInstalment: number,
  fixedInterestRate: number
  effectiveInterestRate: number,
  fixedRateEndYear: number,
  valueClass?: string
  labelClass?: string
}> = ({ monthlyInstalment, fixedInterestRate, effectiveInterestRate, fixedRateEndYear, valueClass, labelClass }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanOverviewSection.sampleOffer'})
  const commonProps = { valueClass, labelClass }
  return (
    <DataTiles minTwoColumns={true}>
      <DataTile { ...commonProps } value={toCurrency(monthlyInstalment)} label={t('monthlyInstalment')} highlight={true}/>
      <DataTile { ...commonProps } value={`${formatNumber(fixedInterestRate, 2)} %`} label={t('fixedInterestRate')}/>
      <DataTile { ...commonProps } value={`${formatNumber(effectiveInterestRate, 2)} %`} label={t('effectiveInterestRate')}/>
      <DataTile { ...commonProps } value={`${fixedRateEndYear} ${t('years')}`} label={t('fixedRateEndYear')}/>
    </DataTiles>
  )
}

export default SampleOffer
