import React from 'react'
import { useTranslation } from 'react-i18next'
import img from '../../../../../assets/images/beratung.jpg'
import { PersonalDetailsType } from '../../../state/reducer'
import { DialogPage } from '../dialogPage/DialogPage'
import { Calendly } from './Calendly'

const PageNames = {
  START: 'appointmenttype',
  DATE: 'datetime',
  CONTACT_DATA: 'contactdata',
  SCHEDULED: 'scheduled',
} as const
type PageName = typeof PageNames[keyof typeof PageNames]

type CalenderProps = {
  personalDetails: PersonalDetailsType
  onSubmit?: () => void
  onPageView?: (pageName: PageName) => void,
  calendlyURL?: string,
  height?: string,
  title?: string,
  subtitle?: string
}

export const Calender: React.FC<CalenderProps> = ({
  personalDetails,
  onPageView,
  onSubmit,
  calendlyURL,
  height,
  title,
  subtitle
}) => {
  const { t } = useTranslation('userFlow')

  const { firstName, lastName, email, phoneNumber } = personalDetails

  return (
    <DialogPage
      avatarUrl={img}
      title={title ? title : t(`calender.title`)}
      subTitle={subtitle}
      dataTestId='calendar'
    >
      <Calendly
           firstName={firstName}
           lastName={lastName}
           email={email}
           phoneNumber={phoneNumber}
           onProfilePageViewed={() => onPageView && onPageView(PageNames.START)}
           onEventTypeViewed={() => onPageView && onPageView(PageNames.DATE)}
           onDateAndTimeSelected={() => onPageView && onPageView(PageNames.CONTACT_DATA)}
           onEventScheduled={() => {
             onPageView && onPageView(PageNames.SCHEDULED)
             onSubmit && onSubmit()
           }}
           calendlyURL={calendlyURL}
           height={height}
         />
    </DialogPage>
  )
}
