import React, { useEffect, useState } from 'react'
import './OwnCapitalSlider.less'
import { Trans, useTranslation } from 'react-i18next'
import { GenericSliderWithInput } from '../genericSliderWithInput/GenericSliderWithInput'

const OWN_CAPITAL_MAX_RANGE = 99_999_999

export const getOwnCapitalMaxRange = (n: number) => {
  if (n <= 10_000) { return 100_000 }
  if (n <= 50_000) { return 150_000 }
  if (n <= 100_000) { return 1_000_000 }
  if (n <= 250_000) { return 2_000_000 }
  return Math.min(n * 10, OWN_CAPITAL_MAX_RANGE)
}

type OwnCapitalSliderType = {
  ownCapital: number
  onOwnCapitalChange: (val: number) => void
}

export const OwnCapitalSlider: React.FC<OwnCapitalSliderType> = ({
  ownCapital,
  onOwnCapitalChange
}) => {
  const { t } = useTranslation('odf')
  const [ sliderValue, setSliderValue ] = useState(ownCapital)
  const [ maxRange, setMaxRange ] = useState(getOwnCapitalMaxRange(ownCapital))
  const [ lockMaxRange, setLockMaxRange ] = useState(false)

  useEffect(() => {
    setSliderValue(ownCapital)
  }, [ ownCapital ])

  useEffect(() => {
    if (!lockMaxRange) {
      setMaxRange(getOwnCapitalMaxRange(ownCapital))
    }
  }, [ ownCapital, lockMaxRange ])

  useEffect(() => {
    if (lockMaxRange && (ownCapital > maxRange)) {
      setMaxRange(ownCapital);
    }
  }, [ maxRange, ownCapital, lockMaxRange ]);

  const onChange = (val: number) => {
    setSliderValue(val)
    setLockMaxRange(true)
    onOwnCapitalChange(val)
  }

  return (
    <div className='OwnCapitalSlider padding-vertical-xl'>
      <GenericSliderWithInput
        topLabel={t('resultPage.ownCapital.title')}
        id='own-capital'
        min={0}
        max={maxRange}
        value={sliderValue}
        onRelease={onChange}
        finalMaxRange={OWN_CAPITAL_MAX_RANGE}
      />
      <div className='description1'>
        {t('resultPage.ownCapital.description1')}
      </div>
      <div className='description2'>
        <Trans t={t} i18nKey='resultPage.ownCapital.description2'>
          <br/>
        </Trans>
      </div>
    </div>
  )
}
