import React, { useCallback } from 'react'
import { FinancingResultError } from '../model/FinancingResults'
import { useTranslation } from 'react-i18next'
import { Button, Heading1, Heading3 } from 'is24-corecss'
import defaultAvatar from '../../../../assets/images/avatar.png'
import { useNavigate } from 'react-router-dom'
import { FINANCING_USER_FLOW, RESTART_FLOW_PATH } from '../../../../config/contextPaths'
import { LoanAmountCalculation } from './loanAmountCalculation/LoanAmountCalculation'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { LeadEngineButton } from '../leadEngineButton/LeadEngineButton'
import './FinancingErrorPage.less'
import {getTotal} from "../../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils";

export type FinancingErrorPageProps = {
  error: FinancingResultError
  oneDynamicFlow: OneDynamicFlowState,
  loanAmount: number
  flowUrl?: string
}

const FinancingErrorPage: React.FC<FinancingErrorPageProps> = ({ error, oneDynamicFlow, loanAmount, flowUrl = FINANCING_USER_FLOW }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.errorPage' })
  const navigate = useNavigate()
  const restartFlowHandler = useCallback(() => navigate(`${flowUrl}/${RESTART_FLOW_PATH}`),
    [ navigate, flowUrl ])
  return (
    <div className='FinancingErrorPage financing-guide-section lap-padding-bottom-l palm-padding-bottom-xxl grid'>
      <div className='grid-item one-whole padding-bottom'>
        <Heading1>{t('title')}</Heading1>
      </div>
      <div className='grid-item one-whole'>
        <div className='grid-item one-whole grid-flex avatarAndProbability'>
          <div className='grid-item avatar padding-right-l desk-padding-right-xl float-left'>
            <img src={defaultAvatar} width={80} alt='avatar'/>
          </div>
          <div className='grid-item probabilityHeading'>
            <Heading3>{t('genericMessage')}</Heading3>
          </div>
        </div>
        <div className='grid-item one-whole margin-top palm-margin-top-xl'>
          <p>{t(`error.${error}`)}</p>
        </div>
      </div>
      <div className='grid-item one-whole margin-top-xl'>
        <div className='grid float-right palm-margin-top-l palm-one-whole button-container'>
          <Button className='grid-item palm-one-whole palm-margin-top-l palm-order-one-down'
                  onClick={restartFlowHandler}>
            {t('restartFlow')}
          </Button>
          <div className='grid-item palm-one-whole margin-left palm-margin-left-none'>
            <LeadEngineButton
              geoCode={oneDynamicFlow.location?.geoCode}
              purchasePrice={oneDynamicFlow.propertyCost}
              additionalCosts={oneDynamicFlow.brokerCommission ?? 0}
              ownFunds={getTotal(oneDynamicFlow.equityCapital)}
              remainingDebt={0}
            />
          </div>
        </div>

      </div>

      {
        error === FinancingResultError.LOAN_AMOUNT_TO_LOW &&
        <div className='grid-item one-whole margin-top palm-margin-top-xl'>
          <LoanAmountCalculation purchasePrice={oneDynamicFlow.propertyCost ?? 0}
                                 additionalCosts={oneDynamicFlow.brokerCommission ?? 0}
                                 ownCapital={getTotal(oneDynamicFlow.equityCapital)} loanAmount={loanAmount}/>
        </div>
      }
    </div>
  )
}

export default FinancingErrorPage
