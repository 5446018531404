import { useEffect } from 'react'
import { useURLSearchParams } from '../../../hooks/useURLSearchParams'
import { resetContactRequestSentTimestamp } from '../services/PreApprovalService'
import { useNavigate } from 'react-router-dom'

const SEARCH_PARAM_NAME = 'reset'

export const useContactRequestBlockedReset = (): void => {
  const searchParams = useURLSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAM_NAME)) {
      searchParams.delete(SEARCH_PARAM_NAME)
      navigate({ search: searchParams.toString() }, { replace: true })
      resetContactRequestSentTimestamp()
    }
  }, [searchParams, navigate])
}
