import React from 'react'
import { StepDescription } from '../../stepDescription/StepDescription'
import { TabId } from '../ProfileTabs'
import { Expose } from '../../../../api/api/Expose'
import SelectedObjectSection from '../../common/selectedObjectSection/SelectedObjectSection'
import FinancingCalculatorSection from './financingCalculatorSection/FinancingCalculatorSection'
import ProvidersComparisonSection from './providersComparisonSection/ProvidersComparisonSection'

export type ConditionsTabProps = {
  selectedObject?: Expose
  selectNewObject: () => void
}

const ConditionsTab: React.FC<ConditionsTabProps> = ({ selectedObject, selectNewObject }) =>
  <div>
    <StepDescription className='padding-vertical-l lap-hide desk-hide'
                     stepIndex={1}
                     stepId={TabId.CONDITIONS_TAB}/>
    {selectedObject && <SelectedObjectSection selectedObject={selectedObject} selectNewObject={selectNewObject}/>}
    <FinancingCalculatorSection/>
    <ProvidersComparisonSection/>
  </div>

export default ConditionsTab
