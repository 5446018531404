import React, { useEffect, useState } from 'react'
import './LoanSettings.less'
import { Trans, useTranslation } from 'react-i18next'
import { Accordion, AccordionItem } from 'is24-corecss'
import { DEFAULT_VALUE_AMORTIZATION_RATE, DEFAULT_VALUE_INTEREST_RATE } from '../../services/AffordabilityResultService'
import {
  MAXIMUM_PERCENTAGE,
  MIN_AMORTIZATION_RATE_PERCENTAGE,
  MIN_INTEREST_RATE_PERCENTAGE,
  sanitizeAmortizationRateInput,
  sanitizeInterestRateInput,
  validateAmortizationRate,
  validateInterestRate
} from './LoanSettingsService'
import { PercentageStepper } from './PercentageStepper'

type LoanSettingsProps = {
  amortizationRate?: number,
  interestRate?: number,
  onAmortizationRateChange: (val: number) => void
  onInterestRateChange: (val: number) => void
}
export const LoanSettings: React.FC<LoanSettingsProps> = ({
  amortizationRate,
  interestRate,
  onAmortizationRateChange,
  onInterestRateChange
}) => {
  const { t } = useTranslation('odf')
  const [ amortizationRateVal, setAmortizationRateVal ] = useState(amortizationRate)
  const [ interestRateVal, setInterestRateVal ] = useState(interestRate)

  useEffect(() => {
    if (amortizationRate) {
      setAmortizationRateVal(amortizationRate)
    }
  }, [ amortizationRate ])

  useEffect(() => {
    if (interestRate) {
      setInterestRateVal(interestRate)
    }
  }, [ interestRate ])

  const onAmortizationRateStepperChange = (value: number) => {
    const validValue = validateAmortizationRate(value)
    onAmortizationRateChange(validValue)
    setAmortizationRateVal(validValue)
  }

  const onInterestRateStepperChange = (value: number) => {
    const validValue = validateInterestRate(value)
    onInterestRateChange(validValue)
    setInterestRateVal(validValue)
  }

  return (
    <div className='settings'>
      <Accordion>
        <AccordionItem title={t('resultPage.settings.title')}>
          <div>
            <div className='padding-top-m padding-bottom-xl font-s'>
              <Trans t={t} i18nKey='resultPage.settings.description1'>
                {(DEFAULT_VALUE_AMORTIZATION_RATE + DEFAULT_VALUE_INTEREST_RATE).toString()}
                {DEFAULT_VALUE_AMORTIZATION_RATE.toString()}
                {DEFAULT_VALUE_INTEREST_RATE.toString()}
              </Trans>
              <div>{t('resultPage.settings.description2')}</div>
            </div>
            <div className='grid grid-flex gutter-horizontal-xxl'>
              <div className='grid-item desk-one-half lap-one-half palm-padding-bottom-xl'>
                <div className='stepper-container'>
                  <PercentageStepper
                    id='amortizationRate'
                    label={t('resultPage.settings.amortizationRate')}
                    min={MIN_AMORTIZATION_RATE_PERCENTAGE}
                    max={MAXIMUM_PERCENTAGE}
                    defaultValue={amortizationRateVal?.toString()}
                    onValueChange={onAmortizationRateStepperChange}
                    inputSanitization={sanitizeAmortizationRateInput}
                  />
                  <span className='percent-value font-s'>%</span>
                </div>
                <div className='font-s padding-top-l'>
                  {t('resultPage.settings.amortizationRateDescription')}
                </div>
              </div>
              <div className='grid-item desk-one-half lap-one-half'>
                <div className='stepper-container'>
                  <PercentageStepper
                    id={'interestRate'}
                    label={t('resultPage.settings.interestRate')}
                    min={MIN_INTEREST_RATE_PERCENTAGE}
                    max={MAXIMUM_PERCENTAGE}
                    defaultValue={interestRateVal?.toString()}
                    onValueChange={onInterestRateStepperChange}
                    inputSanitization={sanitizeInterestRateInput}
                  />
                  <span className='percent-value font-s'>%</span>
                </div>
                <div className='font-s padding-top-l'>
                  {t('resultPage.settings.interestRateDescription')}
                </div>
              </div>
            </div>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
