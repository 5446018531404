import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadFinancingFromStorage } from '../../../redux/actions/Financing'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { useNavigate } from 'react-router-dom'
import { FINANCING_USER_FLOW } from '../../../config/contextPaths'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'


const isEmpty = (oneDynamicFlow: Partial<OneDynamicFlowState>) =>
  !oneDynamicFlow || Object.keys(oneDynamicFlow).length === 0

export const isFilledEnoughToShowResults = (oneDynamicFlow: Partial<OneDynamicFlowState>) =>
  (!!oneDynamicFlow?.location?.geoCode || !!oneDynamicFlow?.location?.postalCode) &&
  !!oneDynamicFlow?.propertyCost &&
  oneDynamicFlow?.ownUse != undefined &&
  !!oneDynamicFlow?.employmentType &&
  getIncomeTotal([ oneDynamicFlow?.income ?? {} ]) > 0 &&
  !!oneDynamicFlow?.ageGroupType

const useOneDynamicFlowDataCheck = () => {

  const { oneDynamicFlow } = useOneDynamicFlow()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isEmpty(oneDynamicFlow)) {
      dispatch(loadFinancingFromStorage())
    } else if (!isFilledEnoughToShowResults(oneDynamicFlow)) {
      navigate(FINANCING_USER_FLOW, { replace: true })
    }
  }, [ dispatch, oneDynamicFlow, navigate ])
}

export default useOneDynamicFlowDataCheck
