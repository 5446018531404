import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { ModalBackdrop, ModalContainer, TextInput } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { getBuyersProfileUser } from '../../../../../services/BuyersProfileService'
import { isValidName } from './validations'
import ValidatedPhoneNumberInput from '../../../../common/phoneNumberInput/ValidatedPhoneNumberInput'
import { ContactInformation } from '../../../services/ContactRequestService'
import ContactRequestDisclaimer from '../../../disclaimer/ContactRequestDisclaimer'
import './ContactRequestModal.less'
import { DescriptionPanel } from './descriptionPanel/DescriptionPanel'
import { isValidEmail } from '../../../../../validations/validations'

enum ValidationState {
  VALID, INVALID, PENDING
}

export interface ContactRequestModalProps {
  onClose: () => void;
  onSubmit: (contactInformation: ContactInformation) => void;
}

const ContactRequestModal: React.FC<ContactRequestModalProps> = ({ onClose, onSubmit }) => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.contactRequestModal' })

  const [ firstName, setFirstName ] = useState('')
  const [ firstNameTouched, setFirstNameTouched ] = useState(false)

  const [ lastName, setLastName ] = useState('')
  const [ lastNameTouched, setLastNameTouched ] = useState(false)

  const [ email, setEmail ] = useState('')
  const [ emailTouched, setEmailTouched ] = useState(false)

  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [ phoneNumberTouched, setPhoneNumberTouched ] = useState(false)
  const [ phoneNumberValid, setPhoneNumberValid ] = useState(ValidationState.INVALID)
  const [ submitted, setSubmitted ] = useState(false)
  const [ isDisclaimerOpen, setDisclaimerOpen ] = useState(false)

  const handlePhoneChange = useCallback((value: string, valid: boolean) => {
    setPhoneNumber(value)
    setPhoneNumberValid(valid ? ValidationState.VALID : ValidationState.INVALID)
  }, [ setPhoneNumber, setPhoneNumberValid ])

  useEffect(() => {
    getBuyersProfileUser()
      .then(user => {
        if (user.firstName) {
          setFirstName(prevState => prevState || user.firstName || '')
        }
        if (user.lastName) {
          setLastName(prevState => prevState || user.lastName || '')
        }
        if (user.phoneNumber) {
          handlePhoneChange(user.phoneNumber, true)
        }
      })
      .catch(error => console.warn(`Could not fetch user's profile: ${error?.message}`))
  }, [ setFirstName, setLastName, handlePhoneChange ])

  useEffect(() => {
    if (submitted) {
      if (isValidName(firstName) && isValidName(lastName) && isValidEmail(email)
        && phoneNumberValid === ValidationState.VALID) {

        onSubmit({ firstName, lastName, email, phoneNumber })
        return
      }
      if (phoneNumberValid !== ValidationState.PENDING) {
        setSubmitted(false)
      }
    }
  }, [ submitted, email, firstName, lastName, onSubmit, phoneNumber, phoneNumberValid ])

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    setFirstNameTouched(true)
    setLastNameTouched(true)
    setEmailTouched(true)
    setPhoneNumberTouched(true)
    setSubmitted(true)
  }

  const handleClose = () => !isDisclaimerOpen && onClose()

  return (
    <ModalBackdrop visible={true} onClose={handleClose}>
      <ModalContainer
        visible={true}
        modalTitle={t('title')}
        onClose={handleClose}
        ariaLabelCloseButton='close'
        containerClassName='contact-request-modal'>

        <form onSubmit={onSubmitHandler}>
          <div className='contact-request-modal'>
            <div className='grid grid-flex'>
              <div className='grid-item one-whole desk-one-half'>
                <DescriptionPanel/>
              </div>
              <div className='grid-item one-whole desk-one-half'>
                <div className='grid grid-flex gutter-horizontal-s padding-horizontal-l palm-padding-bottom-l padding-bottom-xxl'>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <TextInput id='firstName' name='firstName'
                               label={t('firstName')}
                               disabled={submitted}
                               placeholder=''
                               defaultValue={firstName}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                 setFirstNameTouched(true)
                                 setFirstName(e.target.value)
                               }
                               }
                               valid={!firstNameTouched || isValidName(firstName)}
                               data-testid='firstNameInput'
                    />
                  </div>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <TextInput id='lastName' name='lastName'
                               label={t('lastName')}
                               disabled={submitted}
                               placeholder=''
                               defaultValue={lastName}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                 setLastNameTouched(true)
                                 setLastName(e.target.value)
                               }
                               }
                               valid={!lastNameTouched || isValidName(lastName)}
                               data-testid='lastNameInput'
                    />
                  </div>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <TextInput id='email' name='email'
                               label={t('email')}
                               disabled={submitted}
                               placeholder=''
                               defaultValue={email}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                 setEmailTouched(true)
                                 setEmail(e.target.value)
                               }
                               }
                               valid={!emailTouched || isValidEmail(email)}
                               data-testid='emailInput'
                    />
                  </div>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <ValidatedPhoneNumberInput
                      id='phoneNumber'
                      label={t('phoneNumber')}
                      value={phoneNumber}
                      valid={!phoneNumberTouched || phoneNumberValid !== ValidationState.INVALID}
                      onChange={handlePhoneChange}
                      allowAutoComplete={false}
                      onBlur={() => {
                        setPhoneNumberTouched(true)
                        setPhoneNumberValid(ValidationState.PENDING)
                      }}
                    />
                  </div>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <ContactRequestDisclaimer onOpen={() => setDisclaimerOpen(true)}
                                              onClose={() => setDisclaimerOpen(false)} />
                  </div>
                  <div className='grid-item one-whole palm-padding-top-l padding-top-m'>
                    <button className='button-secondary' type='submit'>{t('submit')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalContainer>
    </ModalBackdrop>
  )
}

export default ContactRequestModal
