import React from 'react'
import './Settings.less'

type ChipProps = {
  active: boolean
  onClick: () => void
  label: string
}

export const Chip: React.FC<ChipProps> = ({
  active,
  onClick,
  label
}) =>
  <div className={'chip chip-component' + (active ? ' chip--active' : '')} onClick={onClick} role='presentation'>
    <span role='button' className='font-s'>{label}</span>
  </div>


