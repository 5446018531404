import { useMemo } from 'react'
import { AdditionalCostAO } from '../../../api/api/types/UserAffordabilityResultAO'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'

export type LoanCalculations = {
  brokerCommission: number
  landTransfer: number
  landRegister: number
  notaryCosts: number
  additionalCosts: number
  totalOwnCapital: number
  totalCost: number
}

export const calculatePropertyCostTaxByPercentage = (propertyCost?: number, taxPercentage?: number) => {
  return (propertyCost ?? 0) * (taxPercentage ?? 0) / 100
}

export const useLoanCalculations = (oneDynamicFlow: OneDynamicFlowState, additionalCost?: AdditionalCostAO): LoanCalculations => {

  return useMemo(() => {
    const brokerCommission = oneDynamicFlow.brokerCommission
      ? calculatePropertyCostTaxByPercentage(oneDynamicFlow.propertyCost, additionalCost?.brokerCommissionPercentage)
      : 0
    const landTransfer = calculatePropertyCostTaxByPercentage(oneDynamicFlow.propertyCost, additionalCost?.landTransferPercentage)
    const notaryCosts = calculatePropertyCostTaxByPercentage(oneDynamicFlow.propertyCost, additionalCost?.notaryCostsPercentage)
    const landRegister = calculatePropertyCostTaxByPercentage(oneDynamicFlow.propertyCost, additionalCost?.entryLandRegisterPercentage)
    const additionalCosts = brokerCommission + landTransfer + notaryCosts + landRegister
    const totalOwnCapital = getTotal(oneDynamicFlow.equityCapital)
    const totalCost = (oneDynamicFlow.propertyCost ?? 0) - totalOwnCapital + additionalCosts

    return {
      brokerCommission,
      landTransfer,
      landRegister,
      notaryCosts,
      additionalCosts,
      totalOwnCapital,
      totalCost
    }
  }, [ additionalCost, oneDynamicFlow ])
}
