import React, { useLayoutEffect, useRef, useState } from 'react'

export interface Page {
  page: string,
  component: React.ReactNode
}

type Props = {
  page: string,
  pages: Page[],
}

const Pager = ({ page, pages }: Props) => {
  const pagerRef = useRef<HTMLDivElement>(null)
  const [ width, setWidth ] = useState(0)

  const index = pages.findIndex(e => e.page === page)

  useLayoutEffect(() => {
    const handleResize = () => {
      const width = pagerRef.current?.getBoundingClientRect().width
      width && setWidth(width)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [ pagerRef ])

  const getPages = () =>
    pages.map((obj, i) => {
      const isActivePage = obj.page === page
      const visibility = isActivePage ? 'visible' : 'hidden'
      const opacity = isActivePage ? 1 : 0

      return (
        <div
          key={i}
          data-page={obj.page}
          style={{ width, float: 'left', transition: 'opacity .2s ease-in-out', opacity, visibility, minHeight: 400 }}
        >
          {isActivePage && obj.component}
        </div>
      )
    })

  const getContainer = () =>
    <div style={{
      transition: 'transform 300ms ease',
      transform: `translate(-${width * index}px, 0)`,
      width: pages.length * width
    }}>
      {getPages()}
    </div>

  return (
    <div ref={pagerRef} style={{ overflow: 'hidden' }}>
      {width && getContainer()}
    </div>
  )
}

export default Pager
