import React from 'react'
import { toCurrency } from '../../../../../services/NumberUtils'
import { useTranslation } from 'react-i18next'
import { TableRow } from '../../../../affordability/resultPage/maxCostsSelector/TableRow'
import './LoanAmountCalculation.less'

export type LoanAmountCalculationProps = {
  purchasePrice: number
  additionalCosts: number
  ownCapital: number
  loanAmount: number
}

const createLabel = (operatorSign: string, labelText: string) => (
  <span className='rowLabel'>
    <span className='operator'>{operatorSign}</span>
    {labelText}
  </span>
)

export const LoanAmountCalculation: React.FC<LoanAmountCalculationProps> = ({
                                                                     purchasePrice,
                                                                     additionalCosts,
                                                                     ownCapital,
                                                                     loanAmount
                                                                   }) => {

  const { t } = useTranslation('odf')
  return (
    <div className='loan-amount-calculation'>
      <div className='loan-content'>
        <div className='rows padding-top-xl'>
          <div className='row font-l palm-font-m font-bold border-bottom-teal'>
            <span role='cell' className='float-left'>{t('financingFunnel.errorPage.purchasePrice')}</span>
            <span className='float-right'>{toCurrency(purchasePrice)}</span>
          </div>
          <TableRow
            label={createLabel('+', t('resultPage.maxCostsSelector.additionalCosts'))}
            percent={purchasePrice ? additionalCosts / purchasePrice * 100 : 0}
            value={additionalCosts}
            bold={true}
          />
          <TableRow
            label={createLabel('-', t('resultPage.maxCostsSelector.ownCapital'))}
            percent={purchasePrice ? ownCapital / purchasePrice * 100 : 0}
            value={-ownCapital}
            bold={true}
          />
          <div role='cell' className='font-l palm-font-m align-right padding-top-l'>
            {t('financingFunnel.resultPage.loanDevelopmentSection.summary.netLoan')}
          </div>
          <div className='align-right padding-top-s'>
            <div className='font-xl palm-font-l font-bold'>
              {toCurrency(loanAmount)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
