import highlightCircle from './../../../../../../assets/images/highlight-circle.svg'

import './FinancingCard.less'

interface FinancingCardProps {
  stepNumber: number,
  title: string,
  content: string,
  className?: string
}

const FinancingCard = ({ stepNumber, title, content, className = '' }: FinancingCardProps) => (
  <div className={`FinancingCard ${className}`}>
    <div className='stepCircle heading'>
      <img src={highlightCircle} aria-hidden alt=''/>
      <div className='stepCenter'>{stepNumber}</div>
    </div>
    <h2 className='heading'>{title}</h2>
    <div className='content'>
      {content}
    </div>
  </div>
)

export default FinancingCard
