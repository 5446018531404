import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { MORTGAGE_PROFILE, } from '../../../config/contextPaths'
import './ProfileButtonWrapper.less'

interface ProfileButtonWrapperProps {
  button?: ReactChild
}

const ProfileButtonWrapper: React.FC<ProfileButtonWrapperProps> = ({ button }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanDevelopmentSection.profileButtonWrapper' })

  return (
    <div className='bottom-action-buttons margin-top-xxl'>
      <div className='grid grid-flex grid-justify-end'>
        <a className={`palm-one-whole ${button ? 'profile-button link-text align-center' : 'button-primary'} padding-horizontal-m`}
           href={MORTGAGE_PROFILE}>
          {t('profileButton')}
        </a>
        {button &&
          <div className='grid-item palm-one-whole palm-order-one-up'>
            {button}
          </div>
        }
      </div>
    </div>
  )
}

export default ProfileButtonWrapper
