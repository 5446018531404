import React, { ReactChild } from 'react'
import TotalCostDonutChart from './totalCostDonutChart/TotalCostDonutChart';
import GuideSection from './guideSection/GuideSection'
import { MonthlyRateSlider } from './monthlyRateSlider/MonthlyRateSlider'
import ActionButtons from './actionButtons/ActionButtons'
import { HouseholdBudget } from './householdBudget/HouseholdBudget'
import { OwnCapitalSlider } from './ownCapitalSlider/OwnCapitalSlider'
import { LoanSettings } from './loanSettings/LoanSettings'
import { MaxCostsSelector } from './maxCostsSelector/MaxCostsSelector'
import { StatusMessage } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'
import { toCurrency } from '../../../services/NumberUtils'
import RestartButton from './actionButtons/RestartButton'
import './AffordabilityResultPage.less'
import { AffordabilityResult } from '../services/AffordabilityResultService'
import ProfileButtonWrapper from '../../common/profileButtonWrapper/ProfileButtonWrapper'
import { withToastMessages } from '../../common/toastMessage/ToastMessage'
import { usePreApproval } from '../../preApproval/hook/usePreApproval'
import PreApprovalTouchPoint from '../../preApproval/touchpoint/PreApprovalTouchPoint'

export type AffordabilityResultPageType = {
  result: AffordabilityResult
  onOpenProfilePageButtonClick: () => void
  onMonthlyRateChange: (val: number) => void
  onAmortizationRateChange: (val: number) => void
  onInterestRateChange: (val: number) => void
  onOwnCapitalChange: (val: number) => void
  onRenovationCostPercentageChange: (val: number) => void
  onBrokerCommissionChange: (val: boolean) => void
}

const AffordabilityResultPage: React.FC<AffordabilityResultPageType> = (
  {
    result,
    onOpenProfilePageButtonClick,
    onMonthlyRateChange,
    onAmortizationRateChange,
    onInterestRateChange,
    onOwnCapitalChange,
    onRenovationCostPercentageChange,
    onBrokerCommissionChange,
  }) => {
  const { t } = useTranslation('odf')
  const displayPreApproval = usePreApproval();
  const {
    totalCost, futureMonthlyBudget, minMonthlyRate, maxMonthlyRate, totalOwnFunds: ownCapital, monthlyRate,
    pricePerSquareMeter, savedMonthlyRent, squareMeters, firstBorrowerIncome, firstBorrowerSpending, amortizationRate,
    interestRate, additionalCosts, maxPurchasePrice, maxLoanAmount, ownFundPurchasePriceRatio, includeBrokerCommission,
    error: calculationError, warning: calculationWarning
  } = result

  const upperSections = (
    <>
      <div className='grid-item one-whole desk-one-half'>
        <GuideSection totalCost={totalCost}/>
      </div>
      <div className='grid-item one-whole desk-one-half desk-padding-bottom-xl'>
        <TotalCostDonutChart
          maxLoan={maxLoanAmount}
          ownCapital={ownCapital}
          totalCost={totalCost}/>
        <ActionButtons onClick={onOpenProfilePageButtonClick}/>
      </div>
      <div className='grid-item one-whole desk-one-half'>
        <MonthlyRateSlider monthlyIncome={firstBorrowerIncome.total ?? 0}
                           futureMonthlyBudget={futureMonthlyBudget}
                           minMonthlyRate={minMonthlyRate}
                           maxMonthlyRate={maxMonthlyRate}
                           monthlyRate={monthlyRate}
                           onMonthlyRateChange={onMonthlyRateChange}/>
      </div>
      <div className='grid-item one-whole desk-one-half'>
        <OwnCapitalSlider ownCapital={ownCapital} onOwnCapitalChange={onOwnCapitalChange}/>
      </div>
    </>
  )

  const lowerSections = (
    <>
      <div className='grid-item one-whole'>
        <MaxCostsSelector additionalCosts={additionalCosts} ownCapital={ownCapital} maxPurchasePrice={maxPurchasePrice}
                          maxLoanAmount={maxLoanAmount} ownFundPurchasePriceRatio={ownFundPurchasePriceRatio}
                          onRenovationCostPercentageChange={onRenovationCostPercentageChange}
                          onBrokerCommissionChange={onBrokerCommissionChange}
                          includeBrokerCommission={includeBrokerCommission}/>
      </div>
      <div className='grid-item one-whole'>
        <LoanSettings
          amortizationRate={amortizationRate}
          interestRate={interestRate}
          onAmortizationRateChange={onAmortizationRateChange}
          onInterestRateChange={onInterestRateChange}
        />
      </div>
      {!displayPreApproval ?
        <div className='grid-item one-whole'>
          <ProfileButtonWrapper/>
        </div>
        :
        <div className='grid-item one-whole padding-top-xxl'>
          <PreApprovalTouchPoint/>
        </div>}
    </>
  )

  const showMessage = (title: ReactChild, message: ReactChild) => (
    <div className='grid-item one-whole margin-bottom'>
      <StatusMessage
        headline={title}
        message={message}
        statusType='info'
      />
    </div>
  )

  const showErrorMessage = () => showMessage(t('resultPage.calculationError.title'), t(`resultPage.calculationError.${calculationError}`))

  const showLoanAmountTooSmallWarning = () => showMessage(t('resultPage.loanAmountTooSmallWarning.title'),
    <Trans t={t} i18nKey='resultPage.loanAmountTooSmallWarning.message'>
      {toCurrency(maxLoanAmount)}
      {toCurrency(maxPurchasePrice)}
      {toCurrency(additionalCosts.total || 0)}
      {toCurrency(ownCapital)}
    </Trans>)

  const showFutureMonthlyBudgetBelowZeroWarning = () => showMessage(t('resultPage.futureMonthlyBudgetBelowZeroWarning.title'), t('resultPage.futureMonthlyBudgetBelowZeroWarning.message'))

  return (
    <div className='grid grid-flex gutter-horizontal-xxl'>
      {calculationWarning === 'loanAmountTooSmall' && !calculationError && showLoanAmountTooSmallWarning()}
      {calculationWarning === 'futureMonthlyBudgetBelowZero' && !calculationError && showFutureMonthlyBudgetBelowZeroWarning()}
      {calculationError ? showErrorMessage() : upperSections}
      {calculationError &&
        <div className='grid-item one-whole align-right standaloneRestartButton'>
          <RestartButton/>
        </div>
      }
      <div className='grid-item one-whole'>
        <HouseholdBudget
          monthlyRate={monthlyRate}
          pricePerSquareMeter={pricePerSquareMeter}
          savedMonthlyRent={savedMonthlyRent}
          squareMeters={squareMeters}
          firstBorrowerIncome={firstBorrowerIncome}
          firstBorrowerSpending={firstBorrowerSpending}
          forcedRollDown={!!calculationError}
        />
      </div>
      {!calculationError && lowerSections}
    </div>
  );
}

export default withToastMessages(AffordabilityResultPage)
