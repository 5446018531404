import React, { ReactChild } from 'react';
import { FinancingProduct } from '../../../../../model/PreApprovalResults';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next'

export type RenderCellValueFn = (product: FinancingProduct, t: TFunction) => ReactChild

export type RowProps = {
    labelKey: string,
    valueFn: RenderCellValueFn,
    financingProducts: FinancingProduct[]
  }

const Row: React.FC<RowProps> = ({ labelKey, valueFn, financingProducts }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.details' })
  return <tr>
    <td>{t(labelKey)}</td>
    {financingProducts.map((product, index) =>
      <td key={product.providerName} data-testid={`${labelKey}-${index}`}>{valueFn(product, t)}</td>)}
  </tr>
}

export default Row
