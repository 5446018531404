import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Section from '../../../../common/section/Section'
import ProposalsSection from '../../../../../preApproval/resultPage/proposalsSection/ProposalsSection'
import { useOneDynamicFlow } from '../../../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import CustomTooltip from '../../../../../common/customTooltip/CustomTooltip'
import './ProposalsResult.less'
import { CalculationSummary } from '../../../../../preApproval/resultPage/calculationSummary/CalculationSummary'
import { useAdditionalCostDefaults } from '../../../../../financing/hook/useAdditionalCostDefaults'
import { useLoanCalculations } from '../../../../../preApproval/hook/useLoanCalculations'
import { PreApprovalResultState } from '../../../../../preApproval/hook/usePreApprovalResults'
import { FinancingResultError } from '../../../../../preApproval/resultPage/model/PreApprovalResults'

const ProposalsResult: React.FC<{ preApprovalResults: PreApprovalResultState }> = ({ preApprovalResults }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'offersTab.proposalSection.result' })
  const { oneDynamicFlow } = useOneDynamicFlow()
  const additionalCost = useAdditionalCostDefaults(oneDynamicFlow.location?.postalCode, oneDynamicFlow.location?.geoCode)
  const loanCalculations = useLoanCalculations(oneDynamicFlow, additionalCost)

  return (
    <Section borderBottom={false} title={
      <span className='absolute-reference'>
        {t('title')}
        <span className='ProposalsResultTooltip'>
          <CustomTooltip
            text={t('tooltip')}
            bodyPosition='top'
            arrowPosition='right'
            minWidth={200}
          />
        </span>
      </span>}>
      <ProposalsSection oneDynamicFlow={oneDynamicFlow}
                        proposals={preApprovalResults.proposals}
                        additionalCosts={preApprovalResults.additionalCosts}
                        isTechnicalError={preApprovalResults.error === FinancingResultError.COULD_NOT_CALCULATE}
                        customHeader={
                          <div className='font-s'>
                            <Trans t={t} i18nKey='description'
                                   values={{ location: oneDynamicFlow.location?.locationLabel ?? '' }}>
                            </Trans>
                          </div>}/>
      <div className='font-l padding-top-xxl margin-top-s padding-bottom-s'>
        {t('costsSubsectionTitle')}
      </div>
      <CalculationSummary oneDynamicFlow={oneDynamicFlow} loanCalculations={loanCalculations} simplifiedMode/>
    </Section>
  )
}

export default ProposalsResult
