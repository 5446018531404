import React from 'react'
import { useTranslation } from 'react-i18next'

type MortgageProviderUnsubscribeSucccessType = {
  email: string;
  emailType: string;
}


const ProviderUnsubscribeSuccess: React.FC<MortgageProviderUnsubscribeSucccessType> = ({ email, emailType }) => {
  const { t } = useTranslation('unsubscribe')

  return (
    <div className='status-message status-message--short status-confirm'>
      <h4>{t('success.title')}</h4>
      <p>{t('success.message', { email, emailType: t(`emailType.${emailType}`) })}</p>
    </div>
  )
}

export default ProviderUnsubscribeSuccess
