import React from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/calendar.jpg'
import { DialogPage } from '../dialogPage/DialogPage'
import { FinancingStartType } from '../../../../../api/api/types/FinancingStart'
import { FinancingStartSelect } from '../../../common/FinancingStartSelect'
import { StatusMessage } from 'is24-corecss'
import {
  ContactRequestError,
  ContactRequestErrorType
} from '../../../../../api/api/ContactRequestExceptions'
import { FinancingType as TypeOfFinancingType } from '../../../../../api/api/types/FinancingType';

interface Props {
  financingStart?: FinancingStartType,
  financingType?: TypeOfFinancingType,
  onChange: (financingStart: FinancingStartType) => void,
  errors?: ContactRequestErrorType[]
}

export const FinancingStart: React.FC<Props> = ({ financingStart, errors = [], onChange, financingType }) => {
  const { t } = useTranslation('userFlow')
  const title = t('financingStart.title')

  return (
    <DialogPage avatarUrl={img} title={title}>
      {errors.includes(ContactRequestError.UNKNOWN) &&
      <div className='margin-top-m margin-bottom-l'>
        <StatusMessage
          headline={t('errors.headline')}
          message={t('errors.UNKNOWN')}
          statusType='error'
        />
      </div>
      }
      <div className='align-center grid grid-flex grid-justify-center' style={{ whiteSpace: 'nowrap' }}>
        <FinancingStartSelect value={financingStart} onSelect={onChange} financingType={financingType}/>
      </div>
    </DialogPage>
  )
}
