import React from 'react'
import { Heading3 } from 'is24-corecss'
import { toCurrency } from '../../../../services/NumberUtils'
import defaultAvatar from '../../../../assets/images/avatar.png'
import './GuideSection.less'
import { useTranslation } from 'react-i18next'

interface GuideSectionProps {
  totalCost: number
}

const GuideSection = ({ totalCost }: GuideSectionProps) => {

  const { t } = useTranslation('odf')

  return (
    <div className='guide-section padding-top-l padding-bottom-xxl'>
      <div className='img-container padding-right-l desk-padding-right-xl float-left'>
        <img src={defaultAvatar} width={80} alt="avatar"/>
      </div>
      <Heading3 className='guide-title font-black'>
          {t('resultPage.guideSection.titleFirstPart')} <span className='tealHighlighting font-bold'>{toCurrency(totalCost)}</span>
          {t('resultPage.guideSection.titleSecondPart')}
      </Heading3>
      <div className='description-container padding-top-l palm-padding-top-s float-left desk-padding-right-l padding-bottom-xxl'>
        <p>
          {t('resultPage.guideSection.description')}
        </p>
      </div>
    </div>
  )
}

export default GuideSection
