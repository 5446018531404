import React from 'react';
import { ShortlistEntryAO } from '../../../../../../../api/api/types/ShortlistEntryAO';

const ShortlistHeader: React.FC<{ shortlistEntry: ShortlistEntryAO }> = ({ shortlistEntry }) => <>
  <h5 className='padding-top font-ellipsis margin-none font-line-s shortlist-preview-heading'>
    <a href={`/expose/${shortlistEntry.exposeId}`}>{shortlistEntry.title}</a>
  </h5>
  <div className='padding-bottom shortlist-preview-header-padding-bottom font-ellipsis'>
    {shortlistEntry.address}&nbsp;
  </div>
</>

export default ShortlistHeader
