import React from 'react'
import { useOneDynamicFlow } from '../../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { isFilledEnoughToShowResults } from '../../../../preApproval/hook/usePreApprovalFlowDataCheck'
import ProposalsResultWrapper from './proposalsResult/ProposalsResultWrapper'
import ProposalsTeaser from './proposalsTeaser/ProposalsTeaser'
import { BuyersProfileDataState, useBuyersProfileData } from '../../../../../hooks/useBuyersProfileData'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../../../config/optimizely'
import { PreApprovalResultState } from '../../../../preApproval/hook/usePreApprovalResults'

const ProposalsSection: React.FC<{ preApprovalResults: PreApprovalResultState }> = ({ preApprovalResults }) => {
  const { oneDynamicFlow } = useOneDynamicFlow()
  const isFilledEnoughToShowProposals = isFilledEnoughToShowResults(oneDynamicFlow)
  const [ showContactInFlow, /*unused*/, /*optimizelyReady, optimizelyTimedOut*/ ] = useFeature(Feature.BAUFI_READY_SHOW_CONTACT_IN_FLOW)
  const { state: buyersProfileState } = useBuyersProfileData(showContactInFlow && isFilledEnoughToShowProposals)
  const profileIsFilled = buyersProfileState === BuyersProfileDataState.VALID

  return isFilledEnoughToShowProposals && (profileIsFilled || !showContactInFlow) ?
    <ProposalsResultWrapper preApprovalResults={preApprovalResults}/> : <ProposalsTeaser/>
}

export default ProposalsSection
