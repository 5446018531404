import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorrowerEquityCapital } from '../../../../../api/api/types/BorrowerEquityCapital'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { formatNumber } from '../../../../../services/NumberUtils'
import NumberInput from '../../../input/numericInput/NumberInput'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { EquityCapitalField, ownCapitalFields, getTotal, isEquityCapitalValid, isFieldValid, MAX_VALUE } from './ownCapital.utils'

export type OwnCapitalProps = Pick<OneDynamicFlowState, 'equityCapital' | 'numberOfBorrowers'> & {
  onChange: (equityCapital: Partial<BorrowerEquityCapital>) => void
} & FooterProps & CloseButtonProps

export const OwnCapital: React.FC<OwnCapitalProps> =
  ({
     equityCapital = {},
     numberOfBorrowers = 1,
     onChange, onBack,
     onNext,
     progress,
     onClose
   }) => {
    const { t } = useTranslation('odf')
    const total = getTotal(equityCapital)
    const isTotalValid = total <= MAX_VALUE

    const handleChange = (prop: EquityCapitalField) => (value: number) =>
      onChange({ ...equityCapital, [ prop ]: value })

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.ownCapital.title', { count: numberOfBorrowers })}
        description={t('userFunnel.ownCapital.description')}
        arrow='long-arrow-bottom-right'
        disabled={!isEquityCapitalValid(equityCapital)}
        touched={total > 0}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <div style={{ 'maxWidth': '428px' }} className='palm-margin-horizontal-l palm-padding-bottom-xxl lap-padding-bottom-xxl'>
          {ownCapitalFields.map((id) => (
            <InputRow
              id={id}
              key={id}
              valid={isFieldValid(equityCapital[ id ])}
              value={equityCapital[ id ]}
              onChange={handleChange(id)}
            />
          ))}
          <div>
            <div className='grid-flex grid-justify-space-between padding-top-xl padding-horizontal-s'>
              <div className='font-bold'>
                {t('userFunnel.ownCapital.total.label')}
              </div>
              <div role='status' aria-label='own-capital-sum'>
                {formatNumber(total)} €
              </div>
            </div>
          </div>
          <div className='font-error absolute-content margin-top-xs padding-horizontal-s'>
            {!isTotalValid && t('userFunnel.ownCapital.totalErrorMessage')}
          </div>
        </div>
      </OneDynamicFlowQuestion>
    )
  }

type InputRowProps = {
  id: EquityCapitalField,
  value?: number,
  valid: boolean,
  onChange: (value: number) => void
}

const InputRow: React.FC<InputRowProps> = ({ id, value = 0, valid, onChange }) => {
  const { t } = useTranslation('odf')

  return (
    <div className='grid-item one-whole padding-top-m'>
      <NumberInput
        id={id}
        placeholder=''
        label={t(`userFunnel.ownCapital.${id}`)}
        valid={valid}
        errorMessage={t('userFunnel.ownCapital.errorMessage')}
        value={value}
        onChange={onChange}
        forceSelect={false}
        unitRight='eur'
        className='align-left'
        ariaLabel={t(`userFunnel.ownCapital.${id}`)}
      />
    </div>
  )
}

