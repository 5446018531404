import React, { ReactNode } from 'react'
import { Heading4 } from 'is24-corecss'
import './Section.less'

interface SectionProps {
  title: React.ReactChild
  className?: string
  children: React.ReactNode
  borderBottom?: boolean
  topRightCornerComponent?: ReactNode
}

const Section: React.FC<SectionProps> = ({ title, className, children, borderBottom = true, topRightCornerComponent }) => (
  <section
    className={`ProfilePageSection grid-item one-whole padding-top-l margin-bottom-m ${borderBottom ? 'padding-bottom-xxl border-bottom' : 'padding-bottom-s'} ${className ?? ''}`}>
    {topRightCornerComponent &&
      <div className='topRightCornerComponent padding-top-s palm-padding-top-m'>
        {topRightCornerComponent}
      </div>}
    <Heading4>{title}</Heading4>
    {children}
  </section>
)

export default Section
