import React from 'react'
import './ResultPageSection.less'
import CustomTooltip from '../../../common/customTooltip/CustomTooltip'

interface ResultPageSectionProps {
  title?: string
  children: React.ReactNode,
  tooltipBodyPosition?: 'left' | 'right' | 'top' | 'bottom'
  tooltipTitle?: string
  tooltipText?: string
  id?: string
  className?: string
}

const Section: React.FC<ResultPageSectionProps> = (
  {
    title,
    children,
    tooltipTitle,
    tooltipText,
    tooltipBodyPosition = 'top',
    id,
    className
  }) => (
  <div className={`result-page-section ${className}`} id={id}>
    <div className='title-with-tooltip'>
        <span className='font-h2 font-normal'>
          {title}
        </span>
      {tooltipText &&
        <span className='tooltip'>
            <CustomTooltip
              title={tooltipTitle}
              text={tooltipText}
              bodyPosition={tooltipBodyPosition}
              arrowPosition='right'
              minWidth={200}
            />
          </span>
      }
    </div>
    <div className='children'>
      {children}
    </div>
  </div>
)

export default Section
