import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IS24_HOST_URL_LOCAL } from '../../../../../../api/contextPaths'
import logo from '../../../../../../assets/images/logo/is24-logo-white.svg'
import './BackgroundWithLogo.less'

const BackgroundWithLogo: React.FC<{logoAlwaysOn?: boolean, children?: ReactNode }> = ({children, logoAlwaysOn = false}) => {
  const { t } = useTranslation('odf')

  return (
    <div className='charcoal-background'>
      <div className={`margin-left-l margin-top-m palm-margin-top-l absolute-content ${!logoAlwaysOn ? 'palm-hide' : ''}`}>
        <a href={IS24_HOST_URL_LOCAL}>
          <img alt={t('userFunnel.common.logoAlt')} src={logo} width={116} />
        </a>
      </div>
      {children}
    </div>
  );
}

export default BackgroundWithLogo
