import React from 'react'
import { useTranslation } from 'react-i18next'


const ProviderUnsubscribeError: React.FC = () => {
  const { t } = useTranslation('unsubscribe')

  return (
    <div className='status-message status-message--short status-error'>
      <h4>{t('error.title')}</h4>
      <p>{t('error.message')}</p>
    </div>
  )
}

export default ProviderUnsubscribeError
