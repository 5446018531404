import { useTranslation } from 'react-i18next';
import { Heading3 } from 'is24-corecss';
import React from 'react';

const ProposalTilesHeader = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals' })
  return <>
    <Heading3 className='palm-hide'>{t('caption')}</Heading3>
    <Heading3 className='desk-hide lap-hide'>{t('captionShort')}</Heading3>
    <p className='margin-bottom-l font-standard palm-hide'>{t('subCaption')}</p>
    <p className='margin-bottom-l font-standard desk-hide lap-hide'>{t('subCaptionShort')}</p>
  </>
}

export default ProposalTilesHeader
