import { Dispatch } from 'redux'
import { logError } from '../../../../api/api/Logger'
import { BuildingFinancingAO, PurchaseFinancingAO } from '../../../../api/api/types/InitialFinancingAOType.ds'
import TrackingService from '../../../../services/TrackingService/TrackingService'

import { MortgageOfficerDialogPages } from '../../userFlow/pages/mortgageOfficerDialog/MortgageOfficerDialog'
import { postFinancingRequest, REQUEST_SOURCE, validateContactRequest } from '../../../../api/api/ContactRequest'
import { MortgageOfficer } from '../reducer'
import { ContactRequestAO } from '../../../../api/api/types/ContactRequestAO.ds'
import { ApplicationState } from '../../../../redux/store'
import { errorContactRequest, pendingContactRequest, successContactRequest } from '../actions'
import { ContactRequestError, convertToContactRequestErrors } from '../../../../api/api/ContactRequestExceptions'
import { CALENDLY_URL } from '../../../../api/contextPaths'
import { FollowupFinancingAO } from '../../../../api/api/types/FollowupFinancingAO.ds';
import { goToFlowPage } from '../../routing'
import { NavigateFunction } from 'react-router/dist/lib/hooks'


export const preCheckContactRequest = (navigate: NavigateFunction) => async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
  try {
    const { mortgageOfficer } = getState().mortgageOfficerDialog
    const contactRequest = mapToContactRequest(mortgageOfficer)

    try {
      dispatch(pendingContactRequest())

      await validateContactRequest(contactRequest)
      goToFlowPage(MortgageOfficerDialogPages.calender, navigate)
      dispatch(successContactRequest())
    } catch (e: any) {
      const errors = convertToContactRequestErrors(e)
      dispatch(errorContactRequest(errors))
    }
  } catch (e) {
    dispatch(errorContactRequest([ ContactRequestError.UNKNOWN ]))
    goToFlowPage(MortgageOfficerDialogPages.financingType, navigate)
  }
}

export const createContactRequest = () => async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
  try {
    const { mortgageOfficer } = getState().mortgageOfficerDialog
    const contactRequest = mapToContactRequest(mortgageOfficer)

    try {
      const { id : contactRequestId } = await postFinancingRequest(contactRequest, REQUEST_SOURCE.MORTGAGEOFFICER)
      dispatch(successContactRequest())

      TrackingService.reportEvent('mortgageofficer.personaldata', 'finance', 'mortgageofficer_appointment', 'lead', {
        evt_lafid: contactRequestId,
        event_name: 'lead',
        event_product: 'finance',
        event_parameter_1: 'mortgage_officer'
      })
    } catch (e: any) {
      logError('MortgageOfficer postFinancingRequest failed:', e)
    }
  } catch (e: any) {
    logError('MortgageOfficer createContactRequest failed:', e)
  }
}

export const createAndValidateContactRequest = (navigate: NavigateFunction) => async (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
  try {
    const { mortgageOfficer } = getState().mortgageOfficerDialog
    const contactRequest = mapToContactRequest(mortgageOfficer)

    try {
      dispatch(pendingContactRequest())
      const { advisoryLink, id : contactRequestId } = await postFinancingRequest(contactRequest, REQUEST_SOURCE.LEADENGINE, undefined, { maAppointmentLink: true, meetingLink: CALENDLY_URL })

      TrackingService.reportEvent('mortgageofficer.personaldata', 'finance', 'mortgage_officer', 'lead', {
        evt_lafid: contactRequestId,
        event_name: 'lead_submit',
        event_product: 'finance',
        event_parameter_1: 'mortgage_officer'
      })

      dispatch(successContactRequest(advisoryLink.link))
      goToFlowPage(MortgageOfficerDialogPages.calender, navigate)
    } catch (e: any) {
      const errors = convertToContactRequestErrors(e)
      dispatch(errorContactRequest(errors))
    }
  } catch (e) {
    dispatch(errorContactRequest([ ContactRequestError.UNKNOWN ]))
    goToFlowPage(MortgageOfficerDialogPages.financingType, navigate)
  }
}


const mapToContactRequest = (mortgageOfficer: MortgageOfficer): ContactRequestAO => {
  const { financingStart, financingType, projectState = 'NA', followupFinancingData, netLoan, location, personalDetails, employment, netIncomeRange } = mortgageOfficer
  const { lastName, firstName, email, phoneNumber, streetName, streetNumber, postalCode, city } = personalDetails

  let financing: PurchaseFinancingAO | BuildingFinancingAO | FollowupFinancingAO

  // ContactRequestAO needs to be more generic
  // @todo implement TS type guard for financing object
  //
  if(financingType === 'PurchaseFinancing') {
    financing = {
      type: 'PurchaseFinancing',
      financingStart,
      projectState,
      purchasePrice: netLoan?.purchasePrice,
      ownFunds: netLoan?.ownFunds,
    } as PurchaseFinancingAO
  } else if(financingType === 'BuildingFinancing') {
    financing = {
      type: 'BuildingFinancing',
      financingStart,
      projectState,
      purchasePrice: netLoan?.purchasePrice,
      ownFunds: netLoan?.ownFunds,
    } as BuildingFinancingAO
  } else {
    financing = {
      type: 'FollowupFinancing',
      financingStart,
      remainingDebt: followupFinancingData?.remainingDebt,
      propertyValue: followupFinancingData?.propertyValue,
    } as FollowupFinancingAO
  }

  //@todo Validation error could be thrown before request is send, same for leadEngine
  // like geocode, firstName must not be empty
  return {
    financingTerms: {
      geoCode:  location.geoCode,
      postalCode: location.postalCode,
      employment,
      financing
    },
    surname: lastName,
    forename: firstName,
    email,
    phoneNumber: phoneNumber,
    contactChannel: 'TELEPHONE',
    contactAddress: (streetName && streetNumber && postalCode && city) ? {
      street: streetName,
      streetNumber,
      postalCode,
      location: city
    } : undefined,
     netIncomeRange
  }
}
