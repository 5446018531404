import React from 'react'
import './FinancingCalculatorResults.less'
import { Trans, useTranslation } from 'react-i18next'
import Section from '../../../../common/section/Section'
import {
  FINANCING_RESULT_PAGE,
  FINANCING_USER_FLOW_RESTART,
} from '../../../../../../config/contextPaths'
import { FinancingResultState } from '../../../../../financing/hook/useFinancingResults'
import ProbabilityGauge from '../../../../../common/oneDynamicFlow/probabilityGauge/ProbabilityGauge'
import FinancingCalculatorTeaser from '../teaser/FinancingCalculatorTeaser'
import { Heading5 } from 'is24-corecss'
import SampleOffer from '../../../../../financing/resultPage/sampleOffer/SampleOffer'
import useLeadEngineFlowUrlWithParams from '../../../../hook/useLeadEngineFlowUrlWithParams'

export type FinancingCalculatorResultsProps = FinancingResultState

const FinancingCalculatorResults: React.FC<FinancingCalculatorResultsProps> = ({ probability, result, error }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'conditionsTab.financingCalculatorSection.results' })
  const leadEngineFlowUrl = useLeadEngineFlowUrlWithParams()

  const probabilityLabel = probability ? t(probability) : ''

  const sectionTitle =
    <Trans t={t} i18nKey='title' values={{ probability: probabilityLabel }}>
      <span className='Highlight'/>
    </Trans>

  const resultPageLink = <a className='palm-hide' href={FINANCING_RESULT_PAGE}>{t('goToResultPage')}</a>

  return !probability || !result || error ?
    <FinancingCalculatorTeaser/>
    :
    <Section title={sectionTitle} topRightCornerComponent={resultPageLink}
             className='FinancingCalculatorResultsSection'>
      <p className='palm-padding-top palm-padding-bottom-l padding-vertical-s'>
        {t('description')}
      </p>
      <Heading5 weight='bold' className='desk-hide'>{t('summary')}</Heading5>
      <div className='SampleOfferContainer'>
        <SampleOffer
          monthlyInstalment={result.monthlyInstalment}
          fixedInterestRate={result.averageInterestRate}
          effectiveInterestRate={result.effectiveInterestRate}
          fixedRateEndYear={result.fixedRateEndYear}
          valueClass='SampleOfferValue'
          labelClass='SampleOfferLabel'
        />
      </div>
      <div className='ProbabilityGaugeContainer padding-vertical-xl'>
        <ProbabilityGauge probability={probability}/>
      </div>
      <div className='ButtonContainer'>
        <a href={FINANCING_USER_FLOW_RESTART} className='button palm-one-whole' role='button'>
          {t('goToFinancingCalculatorFlowButton')}
        </a>
        <a href={leadEngineFlowUrl} className='button button-primary palm-one-whole' role='button'>
          {t('goToLeadEngineFlow')}
        </a>
      </div>
    </Section>
}

export default FinancingCalculatorResults
