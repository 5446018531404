import React, { useMemo } from 'react'
import './GuideSection.less'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { ProbabilityResult } from '../PreApprovalResultPage'
import GuideSectionPositive from './GuideSectionPositive'
import { toCurrency } from '../../../../services/NumberUtils'
import GuideSectionNegative from './GuideSectionNegative'

export type GuideSectionProps = {
  probabilityResult: ProbabilityResult,
  oneDynamicFlow: OneDynamicFlowState
}

export type SubtitleParams = {
  purchasePrice: string,
  locationLabel?: string
}

const GuideSection: React.FC<GuideSectionProps> = ({ oneDynamicFlow, probabilityResult }) => {
  const subtitleParams = useMemo(() => ({
      purchasePrice: toCurrency(oneDynamicFlow.propertyCost || 0),
      locationLabel: oneDynamicFlow.location?.locationLabel
    }),
    [ oneDynamicFlow.propertyCost, oneDynamicFlow.location?.locationLabel ])

  return (
    <div className='guide-section '>
      {probabilityResult === ProbabilityResult.POSITIVE ?
        <GuideSectionPositive subtitleParams={subtitleParams}/>
        :
        <GuideSectionNegative subtitleParams={subtitleParams}/>
      }
    </div>
  )
}

export default GuideSection
