import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BuyersProfileUser, Salutation } from '../../../../services/BuyersProfileService'
import { isValidName } from '../../resultPage/proposalsSection/contactRequestModal/validations'
import { Loader, RadioButton, TextInput } from 'is24-corecss'
import ValidatedPhoneNumberInput from '../../../common/phoneNumberInput/ValidatedPhoneNumberInput'
import './ContactDataForm.less'
import PageWithHeader from '../../../common/oneDynamicFlow/wizard/common/pageWithHeader/PageWithHeader'
import BlurredProposals from './blurredProposals/BlurredProposals'
import { isValidEmail } from '../../../../validations/validations'

enum ValidationState {
  VALID, INVALID, PENDING
}

export type ContactDataFormProps = {
  email: string,
  profile: BuyersProfileUser,
  updateProfile: (p: BuyersProfileUser) => Promise<void>
}

//Cosma set "defaultChecked" prop in defaultProps of RadioButton, which makes using "checked" prop impossible
//(exception is printed on console). This is hack to avoid this problem.
RadioButton.defaultProps = undefined

const ContactDataForm: React.FC<ContactDataFormProps> = ({ email, profile, updateProfile }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.userFunnel.contactDataForm' })

  const [ salutation, setSalutation ] = useState(profile.salutation)

  const [ firstName, setFirstName ] = useState(profile.firstName ?? '')
  const [ firstNameTouched, setFirstNameTouched ] = useState(false)

  const [ lastName, setLastName ] = useState(profile.lastName ?? '')
  const [ lastNameTouched, setLastNameTouched ] = useState(false)

  const [ phoneNumber, setPhoneNumber ] = useState(profile.phoneNumber ?? '')
  const [ phoneNumberTouched, setPhoneNumberTouched ] = useState(false)
  const [ phoneNumberValid, setPhoneNumberValid ] = useState(profile.phoneNumber ? ValidationState.VALID : ValidationState.INVALID)
  const [ submitted, setSubmitted ] = useState(false)

  const handlePhoneChange = useCallback((value: string, valid: boolean) => {
    setPhoneNumber(value)
    setPhoneNumberValid(valid ? ValidationState.VALID : ValidationState.INVALID)
  }, [ setPhoneNumber, setPhoneNumberValid ])

  useEffect(() => {
    if (profile.salutation) {
      setSalutation(prevState => prevState || profile.salutation)
    }
    if (profile.firstName) {
      setFirstName(prevState => prevState || profile.firstName || '')
    }
    if (profile.lastName) {
      setLastName(prevState => prevState || profile.lastName || '')
    }
    if (profile.phoneNumber) {
      handlePhoneChange(profile.phoneNumber, true)
    }
  }, [ profile, setSalutation, setFirstName, setLastName, handlePhoneChange ])

  useEffect(() => {
    if (submitted) {
      if (isValidName(firstName) && isValidName(lastName) && isValidEmail(email)
        && phoneNumberValid === ValidationState.VALID) {

        updateProfile({ firstName, lastName, phoneNumber })
          .finally(() =>  setSubmitted(false))
        return
      }
      if (phoneNumberValid !== ValidationState.PENDING) {
        setSubmitted(false)
      }
    }
  }, [ submitted, email, firstName, lastName, updateProfile, phoneNumber, phoneNumberValid ])

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    setFirstNameTouched(true)
    setLastNameTouched(true)
    setPhoneNumberTouched(true)
    setSubmitted(true)
  }

  return (
    <PageWithHeader withBenefitBar>
      <div className='ContactDataForm'>
        <h2 className='ContactDataFormBlurredTitle align-center'>{t('title')}</h2>
        <div className='ContactDataFormBlurredProposals'>
          <BlurredProposals/>
        </div>
        <div className='ContactDataFormDescription align-center'>{t('description')}</div>
        <form onSubmit={onSubmitHandler}>
          <div className='grid grid-flex gutter-horizontal-xl gutter-vertical-m'>
            <div className='grid-item one-whole'>
              <div className='inline-block'>
                <RadioButton
                  id='salutation-male'
                  name='salutation'
                  disabled={submitted}
                  label={t('salutation.male')}
                  checked={salutation === Salutation.MR}
                  onChange={() => setSalutation(Salutation.MR)}
                />
              </div>
              <div className='inline-block'>
                <RadioButton
                  id='salutation-female'
                  name='salutation'
                  disabled={submitted}
                  label={t('salutation.female')}
                  checked={salutation === Salutation.MS}
                  onChange={() => setSalutation(Salutation.MS)}
                />
              </div>
            </div>
            <div className='grid-item one-whole desk-one-half desk-margin-top-s'>
              <TextInput id='firstName' name='firstName'
                         label={t('firstName')}
                         disabled={submitted}
                         placeholder=''
                         defaultValue={firstName}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           setFirstNameTouched(true)
                           setFirstName(e.target.value)
                         }
                         }
                         valid={!firstNameTouched || isValidName(firstName)}
                         data-testid='firstNameInput'
              />
            </div>
            <div className='grid-item one-whole desk-one-half desk-margin-top-s'>
              <TextInput id='lastName' name='lastName'
                         label={t('lastName')}
                         disabled={submitted}
                         placeholder=''
                         defaultValue={lastName}
                         onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           setLastNameTouched(true)
                           setLastName(e.target.value)
                         }
                         }
                         valid={!lastNameTouched || isValidName(lastName)}
                         data-testid='lastNameInput'
              />
            </div>
            <div className='grid-item one-whole desk-one-half'>
              <TextInput id='email' name='email'
                         label={t('email')}
                         disabled={true}
                         placeholder=''
                         defaultValue={email}
                         data-testid='emailInput'
              />
            </div>
            <div className='grid-item one-whole desk-one-half'>
              <ValidatedPhoneNumberInput
                id='phoneNumber'
                label={t('phoneNumber')}
                disabled={submitted}
                allowAutoComplete={false}
                value={phoneNumber}
                valid={!phoneNumberTouched || phoneNumberValid !== ValidationState.INVALID}
                onChange={handlePhoneChange}
                onBlur={() => {
                  setPhoneNumberTouched(true)
                  setPhoneNumberValid(ValidationState.PENDING)
                }}
              />
            </div>
            <div className='grid-item one-whole ContactDataFormLowerDescription align-center'>{t('lowerDescription')}</div>
            <div className='grid-item one-whole padding-top-m align-center margin-none'>
              <button disabled={submitted} className='button-secondary palm-one-whole' type='submit' style={{ minWidth: '122px', maxHeight: '40px' }}>
                {submitted ?
                  <div className='absolute-reference center'><Loader className='absolute-content' size='small'/></div>
                  : t('submit')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </PageWithHeader>
  )
}

export default ContactDataForm
