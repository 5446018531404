import React from 'react'
import { Heading5 } from 'is24-corecss'

interface SectionProps {
  title: string
  className?: string
  children: React.ReactNode
}

const Section: React.FC<SectionProps> = ({ title, className, children }) => (
  <section className={`grid-item one-whole ${className}`}>
    <Heading5 weight='semibold'>{title}</Heading5>
    {children}
  </section>
)

export default Section
