import React from 'react'
import { Button, ModalBackdrop, ModalContainer, ModalContent } from 'is24-corecss'
import { useTranslation, Trans } from 'react-i18next'
import './AdditionalInfoModal.less'

const LINK_DATA_PROTECTION = 'https://www.immobilienscout24.de/agb/datenschutz.html'

interface AdditionalInfoModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const AdditionalInfoModal: React.FC<AdditionalInfoModalProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.disclaimer.additionalInfo' })

  return (
    <ModalBackdrop visible={isVisible} onClose={onClose}>
      <ModalContainer
        visible={isVisible}
        modalTitle={t('title')}
        onClose={onClose}
        ariaLabelCloseButton='close'
      >
        <ModalContent>
          <div>
            <Trans t={t} i18nKey='text'>
              <a className='font-regular link-underline' href={LINK_DATA_PROTECTION} target='_blank' rel='noreferrer'>
                link
              </a>
            </Trans>
          </div>

          <div className='padding-top-m'>
            <span data-testid='partner-info'>{t('partner.text')}</span>
            <ul className='list-bullet partner-link padding-top-m'>
              <li>
                <a className='font-regular link-underline' href='https://meinedatenschutzhinweise.de/PPW15.pdf' target='_blank' rel='noreferrer'>
                  {t('partner.baufimanufaktur')}
                </a>
              </li>
              <li>
                <a className='font-regular link-underline' href='https://meinedatenschutzhinweise.de/HLN21.pdf' target='_blank' rel='noreferrer'>
                  {t('partner.hac')}
                </a>
              </li>
            </ul>
          </div>

          <Button className='one-whole margin-top-xl' appearance='secondary' onClick={onClose}>
            {t('close')}
          </Button>
        </ModalContent>
      </ModalContainer>
    </ModalBackdrop>
  )
}

export default AdditionalInfoModal
