import React from 'react'
import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import './ButtonGroup.less'

type ButtonGroupProps<T> = {
  values: T[],
  onClick: (v: T) => void,
  translationRoute: string,
  value?: T
}

export const ButtonGroup = <T,>({ values, onClick, translationRoute, value }: ButtonGroupProps<T>): React.ReactElement => {
  const {t} = useTranslation()

  return (
    <div className='odf-selection-group'>
      {values.map((v, i) =>
        <Button key={i}
                className={`button ${v === value ? 'selected' : ''} ${values.length < 5 ? 'one-column': ''}`}
                onClick={() => onClick(v)}
        >{t(`${translationRoute}.${v}`)}</Button>
      )}
    </div>
  )
}

