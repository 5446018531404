import React from 'react'
import { Button, Heading3, Modal } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import './AdvisorsModal.less'
import { Advisors } from './Advisors';

const MODAL_WIDTH = 974
const DESIRED_DISPLAYED_OFFICERS_COUNT = 4

type AdvisorsModalProps = {
  onClose: () => void;
}

export const AdvisorsModal: React.FC<AdvisorsModalProps> = ({ onClose }) => {
  const { t } = useTranslation('userFlow')

  return (
    <Modal visible={true} width={MODAL_WIDTH} onClose={onClose}>
      <Heading3 alignment='center'>{t('personalDetails.advisorsInfo.header')}</Heading3>
      <div className='grid-flex grid-justify-center gutter-xl margin-top-s'>
        <Advisors numberOfAdvisors={DESIRED_DISPLAYED_OFFICERS_COUNT}/>
      </div>
      <Button
        appearance='secondary'
        style={{ margin: '30px auto 10px', display: 'block' }}
        onClick={onClose}
      >
        {t('common:button.back')}
      </Button>
    </Modal>
  )
}
