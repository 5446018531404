import React from 'react'
import './CallculationSummary.less'
import { useTranslation } from 'react-i18next'
import { toCurrency } from '../../../../services/NumberUtils'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { Row } from './Row'
import { LoanCalculations } from '../../hook/useLoanCalculations'

export type CalculationSummaryProps = {
  oneDynamicFlow: OneDynamicFlowState,
  loanCalculations: LoanCalculations,
  simplifiedMode?: boolean
}

export const CalculationSummary: React.FC<CalculationSummaryProps> = (
  {
    oneDynamicFlow,
    loanCalculations,
    simplifiedMode = false
  }) => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.calculationSummary' })

  return (
    <div className='calculation-summary'>
      {!simplifiedMode && <div className='font-h4 palm-font-m font-bold border-bottom-teal row'>
        <p className='content left'>{t('title')}</p>
      </div>}
      {!simplifiedMode && <Row firstColumn={t('propertyCost')}
                               secondColumn={toCurrency(oneDynamicFlow.propertyCost ?? 0)}/>}
      {!simplifiedMode && <Row firstColumn={t('ownCapital')}
                               secondColumn={toCurrency(loanCalculations.totalOwnCapital ? loanCalculations.totalOwnCapital * -1 : 0)}/>}
      <Row firstColumn={t('additionalCosts')} secondColumn={toCurrency(loanCalculations.additionalCosts)}/>
      <div>
        <Row subRow={true} firstColumn={t('brokerCommission')}
             secondColumn={toCurrency(loanCalculations.brokerCommission)}/>
        <Row subRow={true} firstColumn={t('landTransfer')}
             secondColumn={toCurrency(loanCalculations.landTransfer)}/>
        <Row subRow={true} firstColumn={t('notaryAndLandRegisterCosts')}
             secondColumn={toCurrency(loanCalculations.notaryCosts + loanCalculations.landRegister)}/>
      </div>
      {!simplifiedMode && <div className='font-h5 font-bold row border-bottom'>
        <p>{t('totalCost')}</p>
        <p className='right'>{toCurrency(loanCalculations.totalCost)}</p>
      </div>}
    </div>
  )
}
