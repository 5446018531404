import React from 'react'
import { useFinancingResults } from '../../../../../financing/hook/useFinancingResults'
import FinancingCalculatorResults from './FinancingCalculatorResults'

const FinancingCalculatorResultsWrapper: React.FC = () => {
  const financingResultState = useFinancingResults()
  return (
    <FinancingCalculatorResults { ...financingResultState } />
  )
}

export default FinancingCalculatorResultsWrapper
