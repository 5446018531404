import { Button } from 'is24-corecss'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const NextButton: React.FC<{ disabled?: boolean, onClick: () => void }> = ({ disabled = false, onClick }) => {
  const { t } = useTranslation('odf')
  return (
    <Button appearance='secondary' disabled={disabled} onClick={onClick}>
      {t('userFunnel.common.nextButton')}
    </Button>
  )
}

