import React from 'react'
import CustomTooltip from '../../../common/customTooltip/CustomTooltip'
import './LabelWithTooltip.less'

interface LabelWithTooltipProps {
  label: string
  tooltipTitle?: string
  tooltipText: string
  minWidth?: number
  bodyPosition?: 'top' | 'bottom' | 'left' | 'right'
}

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({ label, tooltipTitle = '', tooltipText, minWidth = 150, bodyPosition = 'top'}) => {
  return (
    <div className='label-with-tooltip'>
      <span className='margin-right-xs'>{label}</span>
      <CustomTooltip
        title={tooltipTitle}
        text={tooltipText}
        bodyPosition={bodyPosition}
        minWidth={minWidth}
      />
    </div>
  )
}

export default LabelWithTooltip
