import { AdditionalCostAO } from '../../../../api/api/types/UserAffordabilityResultAO'
import React from 'react'
import { TableRow } from './TableRow'
import { useTranslation } from 'react-i18next'

export type AdditionalCostsType = {
  additionalCosts: AdditionalCostAO
  onRenovationCostPercentageChange: (val: number) => void
  onBrokerCommissionChange: (val: boolean) => void
  includeBrokerCommission: boolean
}

const MAX_RENOVATION_COST_PERCENTAGE = 50

const sanitizeRenovationCostPercentage = (val: number) => Math.min(val, MAX_RENOVATION_COST_PERCENTAGE)

export const AdditionalCosts: React.FC<AdditionalCostsType> = ({
  additionalCosts,
  onRenovationCostPercentageChange,
  onBrokerCommissionChange,
  includeBrokerCommission
}) => {
  const { t } = useTranslation('odf')

  return (
    <div>
      <TableRow
        label={t('resultPage.maxCostsSelector.landTransfer')}
        percent={additionalCosts.landTransferPercentage ?? 0}
        value={additionalCosts.landTransfer ?? 0}
        bold={false}
      />
      <TableRow
        label={t('resultPage.maxCostsSelector.notaryCosts')}
        percent={additionalCosts.notaryCostsPercentage ?? 0}
        value={additionalCosts.notaryCosts ?? 0}
        bold={false}
      />
      <TableRow
        label={t('resultPage.maxCostsSelector.entryLandRegister')}
        percent={additionalCosts.entryLandRegisterPercentage ?? 0}
        value={additionalCosts.entryLandRegister ?? 0}
        bold={false}
      />
      <TableRow
        label={t('resultPage.maxCostsSelector.brokerCommission')}
        percent={additionalCosts.brokerCommissionPercentage ?? 0}
        value={additionalCosts.brokerCommission ?? 0}
        bold={false}
        checkbox={true}
        onBrokerCommissionChange={onBrokerCommissionChange}
        includeBrokerCommission={includeBrokerCommission}
      />
      <TableRow
        label={t('resultPage.maxCostsSelector.renovationCost')}
        percent={additionalCosts.renovationCostPercentage ?? 0}
        value={additionalCosts.renovationCost ?? 0}
        bold={false}
        inputPercentValue={true}
        sanitizePercentValue={sanitizeRenovationCostPercentage}
        onRenovationCostPercentageChange={onRenovationCostPercentageChange}
      />
    </div>
  )
}
