import React from 'react'
import './LoginPrompt.less'
import BackgroundWithLogo from '../../../common/oneDynamicFlow/wizard/common/backgroundWithLogo/BackgroundWithLogo'
import { Button, Heading2 } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import CloseButton from '../../../common/oneDynamicFlow/wizard/common/closeButton/CloseButton'

export type LoginPromptProps = {
  onClick: () => void,
  onClose?: () => void
}

const LoginPrompt: React.FC<LoginPromptProps> = ({ onClick, onClose }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.userFunnel.loginPrompt' })
  return (
    <div className='LoginPrompt'>
      {onClose && <CloseButton onClose={onClose} translationKey='preApproval.userFunnel.loginPrompt.close' darkMode={true}/>}
      <BackgroundWithLogo logoAlwaysOn={true}>
        <div className='CenteredContent'>
          <div className='InnerContent'>
            <Heading2>{t('title')}</Heading2>
            <p>{t('explanation')}</p>
            <Button appearance='tertiary' onClick={onClick}>{t('login')}</Button>
          </div>
        </div>
      </BackgroundWithLogo>
    </div>
  )
}

export default LoginPrompt
