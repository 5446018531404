import React, { ChangeEvent, RefObject, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, StatusMessage, TextInput } from 'is24-corecss'

import img from '../../../../../assets/images/beratung.jpg'
import ValidatedPhoneNumberInput from '../../../../common/phoneNumberInput/ValidatedPhoneNumberInput'
import { DialogPage } from '../dialogPage/DialogPage'
import { PersonalDetailsType } from '../../../state/reducer'
import { createRefs, isFormValid, isRefValid, TextInputParams } from '../../../../common/input/refs'
import ScrollToTop from '../../../../common/scrollToTopOn/ScrollToTop'
import { ContactRequestError, ContactRequestErrorType } from '../../../../../api/api/ContactRequestExceptions'
import { AdvisorsModal } from './adivisorsModal/AdvisorsModal'

type Props = {
  personalDetails?: Partial<PersonalDetailsType>,
  onChange: (personalDetails: any) => void
  onClick: () => void,
  errors?: ContactRequestErrorType[]
}

const normalizeValue = (value: string) => value.trim()

export const PersonalDetails = ({ personalDetails = {}, errors = [], onChange, onClick }: Props) => {
  const { t } = useTranslation('userFlow')
  const [ showErrors, setShowErrors ] = useState(false)
  const [ isModalOpen, setModalOpen ] = useState(false)

  useEffect(() => {
    errors.length > 0 && setShowErrors(true)
  }, [ errors ])

  const inputRefs = createRefs(8)
  const [ firstNameRef, lastNameRef, streetRef, streetNumberRef, postalCodeRef, cityRef, emailRef, phoneNumberRef ] = inputRefs

  const { firstName, lastName, streetName, streetNumber, phoneNumber, city, email, postalCode } = personalDetails

  const handleChange = (key: keyof PersonalDetailsType) => (e: ChangeEvent<HTMLInputElement>): void =>
    onChange({ ...personalDetails, [ key ]: e.target.value })

  const handleChangePhoneNumber = (phoneNumber?: string) =>
    onChange({ ...personalDetails, phoneNumber })

  const isFieldValid = (ref: RefObject<HTMLInputElement>): boolean =>
    !showErrors || (showErrors && isRefValid(ref))

  const handleClick = () => {
    setShowErrors(true)

    if (isFormValid(inputRefs)) {
      onClick()
    }
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    event.target.value = normalizeValue(event.target.value)
    onChange({ ...personalDetails, [ event.target.id ]: event.target.value })
  }

  const inputParams = (id: keyof PersonalDetailsType, ref: RefObject<HTMLInputElement>, errorType?: ContactRequestErrorType): TextInputParams<keyof PersonalDetailsType> => (
    {
      id,
      name: id,
      ref,
      placeholder: '',
      label: `${t(`personalDetails.${id}`)}`,
      errorMessage: `${t(`personalDetails.${id}ErrorMessage`)}`,
      onChange: handleChange(id),
      valid: isFieldValid(ref) && (errorType ? !errors?.includes(errorType) : true),
      required: true
    }
  )

  return (
    <>
      <ScrollToTop/>
      <DialogPage
        avatarUrl={img}
        title={t('personalDetails.title')}
        subTitle={t('personalDetails.subtitle')}
      >
        {showErrors && errors.includes(ContactRequestError.RECENTLY_CREATED_REQUEST) &&
        <div className="margin-top-xl">
          <StatusMessage
            headline={t('errors.headline')}
            onClose={() => setShowErrors(false)}
            message={t('errors.RECENTLY_CREATED_REQUEST')}
            statusType="error" statusStyle="toast"
          />
        </div>}
        <div className="align-center grid grid-flex grid-justify-center margin-top-xl">
          <div className="grid-flex gutter-horizontal-l" style={{ width: '100%' }}>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <TextInput
                {...inputParams('firstName', firstNameRef, ContactRequestError.FORENAME_BLACKLISTED)}
                defaultValue={firstName}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <TextInput
                {...inputParams('lastName', lastNameRef, ContactRequestError.SURNAME_BLACKLISTED)}
                defaultValue={lastName}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-three-fourth dialog-input">
              <TextInput
                {...inputParams('streetName', streetRef)}
                defaultValue={streetName}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-one-fourth dialog-input">
              <TextInput
                {...inputParams('streetNumber', streetNumberRef)}
                defaultValue={streetNumber}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <TextInput
                {...inputParams('postalCode', postalCodeRef, ContactRequestError.INVALID_POSTALCODE)}
                defaultValue={postalCode}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <TextInput
                {...inputParams('city', cityRef)}
                defaultValue={city}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <ValidatedPhoneNumberInput
                {...inputParams('phoneNumber', phoneNumberRef, ContactRequestError.TELEPHONE_IS_NO_GERMAN_NUMBER)}
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
              />
            </div>
            <div className="grid-item one-half palm-one-whole dialog-input">
              <TextInput
                {...inputParams('email', emailRef, ContactRequestError.EMAIL_BLACKLISTED)}
                type="email"
                defaultValue={email}
                onBlur={handleBlur}
              />
            </div>
            <div className="grid-item one-whole">
              {t('personalDetails.mandatoryFieldExplanation')}
            </div>

            <div className="grid-item grid grid-flex grid-fill-rows grid-justify-center one-whole palm-margin-left-l palm-padding-none padding-left-m margin-top-xl">
              <div className="grid-item align-center palm-padding-right-s is24-icon-info1 font-h4" style={{ color: '#2268E8' }}/>
              <div className="grid-item eleven-twelfths align-left" style={{ paddingTop: 4 }}>
                <Trans t={t} i18nKey='personalDetails.submitDataDescription'>
                  <Button textStyle onClick={() => setModalOpen(true)} />
                </Trans>
              </div>
            </div>

            <div className="grid-item one-whole align-center margin-top-xl margin-bottom-xxl">
              <Button appearance="primary" size="medium" className="two-fourth palm-one-whole nextBtn" onClick={handleClick}>
                {t('common:button.forward')}
              </Button>
            </div>
          </div>
        </div>
        {isModalOpen && <AdvisorsModal onClose={() => setModalOpen(false)}/>}
      </DialogPage>
    </>
  )
}
