import './Officer.less'
import { useTranslation } from 'react-i18next'

import defaultOfficerImg from '../../../assets/images/officer-photos/default-officer-photo.png'

interface Props {
  officerImg?: string,
  imgWidth?: number,
  name?: string,
  jobTitle?: string,
  streetAndNumber?: string
  postcodeAndLocation?: string
  fontSize?: string
}

const Officer = ({ officerImg, imgWidth, name, jobTitle, streetAndNumber, postcodeAndLocation, fontSize }: Props) => {
  const {t} = useTranslation()
  return (
    <div className='Officer'>
      <div className="imgContainer">
        <img src={officerImg ?? defaultOfficerImg} width={imgWidth ?? 189} alt={t('appointmentOfficer.jobTitle')} />
        <div className="name" style={fontSize ? { fontSize } : undefined}>
          {name ?? t('appointmentOfficer.name')}
        </div>
      </div>
      <div className="jobTitle palm-margin-bottom-m" style={fontSize ? { fontSize } : undefined}>
        {jobTitle ?? t('appointmentOfficer.jobTitle')}
      </div>
      {(streetAndNumber && postcodeAndLocation) && <div className="jobTitle">
        <div style={{ fontSize: fontSize ?? '1.2rem' }}>{streetAndNumber}</div>
        <div style={{ fontSize: fontSize ?? '1.2rem' }}>{postcodeAndLocation}</div>
      </div>}
    </div>
  )
}

export default Officer
