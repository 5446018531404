import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

const SECOND = 1000

export const usePreApprovalLoadingPageMessage = (): string => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.loadingPageMessages' })
  const [ message, setMessage ] = useState(1)

  useEffect(() => {
    if (message >= 3) {
      return
    }
    const timeoutID = setTimeout(() => {
      setMessage(message + 1)
    }, 3 * SECOND);
    return () => clearTimeout(timeoutID)
  }, [ message ]);

  return t(`${message}`)
}
