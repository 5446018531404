export const FollowupFinancingStart = {
  IN_LESS_THAN_ONE_YEAR: 'IN_LESS_THAN_ONE_YEAR',
  IN_ONE_YEAR: 'IN_ONE_YEAR',
  IN_TWO_YEARS: 'IN_TWO_YEARS',
  IN_THREE_YEARS: 'IN_THREE_YEARS',
  IN_FOUR_YEARS: 'IN_FOUR_YEARS',
  IN_FIVE_YEARS: 'IN_FIVE_YEARS',
} as const
export type FollowupFinancingStartType = keyof typeof FollowupFinancingStart

/**
 * The translation of this enum is not correct, but has been like this for a long time
 * These values come from the backend and there are millions of records using these values
 * At the moment, the benefit of fixing the translation is too low for the work involved
 */
export const InitialFinancingStart = {
  IN_LESS_THAN_THREE_MONTH: 'IN_LESS_THAN_THREE_MONTH',
  IN_BETWEEN_THREE_AND_SIX_MONTHS: 'IN_BETWEEN_THREE_AND_SIX_MONTHS',
  IN_MORE_THAN_SIX_MONTH: 'IN_MORE_THAN_SIX_MONTH',
} as const
export type InitialFinancingStartValueType = keyof typeof InitialFinancingStart

export type FinancingStartType = FollowupFinancingStartType | InitialFinancingStartValueType
