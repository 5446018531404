import React, { ReactElement } from 'react'

import './Chips.less'

export interface ChipItem<T> {
  key: T,
  label: string
}

interface ChipsType<T> {
  value?: T,
  chips: ChipItem<T>[],
  onClick: (value: T) => void,
}

export const Chips = <T, >({ value, chips, onClick }: ChipsType<T>):
  ReactElement => <>
  {React.Children.toArray(chips.map(({ key, label }) =>
    <Chip
      key={`${key}`}
      active={key === value} label={label}
      onClick={() => {
        window.scrollTo(0,0);
        onClick(key);
      }}
    />
  ))}
</>

export const Chip: React.FC<{ active?: boolean, label: string } & Pick<JSX.IntrinsicElements['button'], 'onClick'>> =
  ({ onClick, label, active }) =>
    <button
      className={`chip ${active ? 'active ' : '' }palm-one-whole palm-margin-left-xxl palm-margin-right-xxl`}
      onClick={onClick}
    >
      {label}
    </button>
