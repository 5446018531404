import { useEffect, useState } from 'react'
import {
  FinancialProposalResult,
  FinancingResultError
} from '../resultPage/model/PreApprovalResults'
import { MINIMAL_LOAN_AMOUNT, useLoanAmount } from './useLoanAmount'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'
import { isDefined } from '../../../utils/utils'
import { DateAsISOString } from '../../../api/api/types/DateAsISOString'
import { calculateDifferenceInYears, currentDateAsISOString } from '../../../utils/dateUtils'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'
import { getFinancialProposals, shouldShowEmployedSinceQuestionFor } from '../services/PreApprovalService'

const birthdateToAge = (birthdate: DateAsISOString): number => {
  const age: number = calculateDifferenceInYears(currentDateAsISOString(), birthdate)
  return Math.floor(age)
}


export type PreApprovalResultState = Partial<FinancialProposalResult> & {
  loanAmount: number
  error?: FinancingResultError
}

export const usePreApprovalResults = (): PreApprovalResultState => {
  const [ error, setError ] = useState<FinancingResultError | undefined>(undefined)
  const loanAmount = useLoanAmount(error, setError)
  const [ preApprovalResult, setPreApprovalResult ] = useState<FinancialProposalResult | undefined>(undefined)
  const { oneDynamicFlow } = useOneDynamicFlow()
  const incomeTotal = getIncomeTotal([ oneDynamicFlow.income ?? {} ])
  const ownCapital: number = getTotal(oneDynamicFlow.equityCapital)

  useEffect(() => {
    if (loanAmount > MINIMAL_LOAN_AMOUNT &&
      !!oneDynamicFlow.propertyCost &&
      incomeTotal > 0 &&
      !!oneDynamicFlow.dateOfBirth &&
      !!oneDynamicFlow.location?.postalCode &&
      !!oneDynamicFlow.employmentType &&
      (!!oneDynamicFlow.employedSince || !shouldShowEmployedSinceQuestionFor(oneDynamicFlow.employmentType)) &&
      isDefined(oneDynamicFlow.otherRemainingDebts)) {
      setError(prevState => prevState === FinancingResultError.LOAN_AMOUNT_TO_LOW ? prevState : undefined)
      setPreApprovalResult(undefined)
      const borrowerAge: number = birthdateToAge(oneDynamicFlow.dateOfBirth)
      if (borrowerAge > 66) {
        setError(FinancingResultError.AGE_GROUP_NOT_SUPPORTED)
      } else {
        const withBrokerCommission: boolean = (oneDynamicFlow.brokerCommission || 0) > 0
        getFinancialProposals(oneDynamicFlow.propertyCost, incomeTotal, oneDynamicFlow.dateOfBirth,
          ownCapital, withBrokerCommission, oneDynamicFlow.location?.postalCode, oneDynamicFlow.employmentType,
          oneDynamicFlow.employedSince, oneDynamicFlow.fixedTermEmployment, oneDynamicFlow.otherRemainingDebts)
          .then(setPreApprovalResult)
          .catch(error => {
            console.error(error)
            setError(FinancingResultError.COULD_NOT_CALCULATE)
          })
      }
    }
  }, [
    loanAmount,
    incomeTotal,
    oneDynamicFlow.propertyCost,
    oneDynamicFlow.dateOfBirth,
    ownCapital,
    oneDynamicFlow.brokerCommission,
    oneDynamicFlow.location?.postalCode,
    oneDynamicFlow.employmentType,
    oneDynamicFlow.employedSince,
    oneDynamicFlow.fixedTermEmployment,
    oneDynamicFlow.otherRemainingDebts,
    setError
  ])

  return {
    loanAmount,
    proposals: preApprovalResult?.proposals,
    additionalCosts: preApprovalResult?.additionalCosts,
    overallFeasibility: preApprovalResult?.overallFeasibility,
    optimizationMessages: preApprovalResult?.optimizationMessages,
    error
  }
}
