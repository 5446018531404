import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading4 } from 'is24-corecss'

const NoDataState: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.shortlistSection.shortlist.noResults' })
  return <>
    <Heading4>{t('message')}</Heading4>
    <div className='center margin-top-xl margin-bottom'>
      <a className='button button-primary' role='button' href='/'>{t('goToSearchButton')}</a>
    </div>
  </>
}

export default NoDataState
