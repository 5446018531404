import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TabId } from '../tabs/ProfileTabs'
import './StepDescription.less'

export type StepDescriptionType = {
  stepIndex: number
  stepId: TabId
  className?: string
}

export const StepDescription: React.FC<StepDescriptionType> = ({
  stepIndex,
  stepId,
  className
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'stepDescription' })

  return (
    <div className={`StepDescription ${className}`}>
      <div className='font-bold padding-top-s'>
        <Trans t={t} i18nKey='heading'>
          {(stepIndex + 1).toString()}
        </Trans>
      </div>
      <div className='description padding-top-xs'>
        {t(`description.${stepId}`)}
      </div>
    </div>
  )
}
