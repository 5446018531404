import React, { useEffect, useState } from 'react'
import { useOneDynamicFlow } from '../../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { isFilledEnoughToShowResults } from '../../../../affordability/hook/useAffordabilityFlowDataCheck'
import AffordabilityTeaser from './teaser/AffordabilityTeaser'
import AffordabilityResultsWrapper from './results/AffordabilityResultsWrapper'

const AffordabilitySection: React.FC = () => {
  const [ canShowResults, setCanShowResults ] = useState(false)
  const { oneDynamicFlow } = useOneDynamicFlow()

  useEffect(() => {
    setCanShowResults(oneDynamicFlow && isFilledEnoughToShowResults(oneDynamicFlow))
  }, [ oneDynamicFlow ])

  return (
    canShowResults ? <AffordabilityResultsWrapper/> : <AffordabilityTeaser/>
  )
}

export default AffordabilitySection
