import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'

import { MortgageOfficerState } from '../state/reducer'
import { MortgageOfficerDialog } from './pages/mortgageOfficerDialog/MortgageOfficerDialog'

import './UserFlowDialogPage.less'
import { withHtmlMeta } from '../withHtmlMeta'

const UserFlowDialogPage = () => {
  const { mortgageOfficer, errors, isFetching, meetingLinkWithEncryptedContactRequest } =
    useSelector<ApplicationState, MortgageOfficerState>(state => state.mortgageOfficerDialog)

  return <MortgageOfficerDialog mortgageOfficer={mortgageOfficer} errors={errors} isFetching={isFetching} meetingLinkWithEncryptedContactRequest={meetingLinkWithEncryptedContactRequest}/>
}

export default withHtmlMeta(UserFlowDialogPage)
