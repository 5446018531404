import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { ButtonGroup } from '../../wizard/common/buttonGroup/ButtonGroup'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'

type Props = Pick<OneDynamicFlowState, 'numberOfBorrowers'> & {
  onClick: (numberOfBorrowers: number) => void
}

export const NumberOfPeople: React.FC<Props & FooterProps & CloseButtonProps> = ({ numberOfBorrowers, onClick, onBack, onNext, progress, onClose }) => {
  const { t } = useTranslation('odf')
  return (
    <OneDynamicFlowQuestion
      title={t('userFunnel.numberOfBorrowers.title')}
      description={t('userFunnel.numberOfBorrowers.description')}
      arrow='long-arrow-bottom-left'
      disabled={!numberOfBorrowers}
      onBack={onBack}
      onNext={onNext}
      progress={progress}
      onClose={onClose}
    >
      <ButtonGroup<number>
        values={[ 1, 2 ]}
        onClick={onClick}
        translationRoute={'odf:userFunnel.numberOfBorrowers'}
        value={numberOfBorrowers}
      />
    </OneDynamicFlowQuestion>
  )
}

