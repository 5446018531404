import React from 'react'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import { CALENDLY_URL } from '../../../../../api/contextPaths'
import { CalendlyEventHandlers } from 'react-calendly/typings/components/hooks/useCalendlyEventListener'

type CalendlyProps = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  calendlyURL?: string,
  height?: string
} & CalendlyEventHandlers

export const Calendly: React.FC<CalendlyProps> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  calendlyURL = CALENDLY_URL,
  height = 1100,
  onDateAndTimeSelected,
  onEventScheduled,
  onEventTypeViewed,
  onProfilePageViewed,
}) => {
  useCalendlyEventListener({
    onDateAndTimeSelected,
    onEventScheduled,
    onEventTypeViewed,
    onProfilePageViewed,
  })
  return <>
    <InlineWidget
      styles={{ height: height }}
      url={calendlyURL}
      prefill={{
        email,
        firstName,
        lastName,
        customAnswers: {
          a1: phoneNumber
        }
      }}
    />
  </>
}
