import React, { ChangeEvent, ReactChild } from 'react'
import { SelectField } from 'is24-corecss'
import './Dropdown.less'

type DropdownProps<T> = {
  listItems?: T[],
  label: ReactChild,
  id: string,
  onChange: (value: string) => void,
  labelFormatter: (value: T) => string,
  value: T,
  tooltipArrowPosition?: 'left' | 'center' | 'right',
  tooltipBodyPosition?: 'left' | 'right' | 'top' | 'bottom'
}

const Dropdown = <T extends number | string>
({
   listItems,
   label,
   id,
   onChange,
   labelFormatter = (value) => value.toString(),
   value
 }: DropdownProps<T>): React.ReactElement => {

  return (
    <div className='dropdown'>
      <div>
        <SelectField
          id={id}
          label={label}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
          value={value}
        >
          {listItems && listItems.map((item) =>
            <option key={item} value={item}>{labelFormatter(item)}</option>
          )}
        </SelectField>
      </div>
    </div>
  )
}
export default Dropdown
