import React from 'react'
import './DataTiles.less'

const DataTiles: React.FC<{ minTwoColumns?: boolean, children?: React.ReactNode}> = ({children, minTwoColumns= false}) => (
  <div className={`DataTiles ${minTwoColumns ? 'minTwoColumns' : ''}`}>
    {children}
  </div>
)

export default DataTiles
