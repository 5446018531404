import React, { Suspense } from 'react';
import { Provider } from 'react-redux'

import { ErrorBoundary } from './components/common/errorBoundary/ErrorBoundary'
import { withOptimizely } from './components/hoc/withOptimizely'
import { configureStore } from './redux/store'
import AppRoutes from './config/AppRoutes'
import './config/i18n'
import { Loading } from './components/common/snippets/Loading'
import './styles/common.less'
import { HelmetProvider } from 'react-helmet-async';
import DefaultMetaAttributes from './DefaultMetaAttributes'
import { BrowserRouter } from 'react-router-dom'

const store = configureStore()

export const App: React.FC = () => (
  <ErrorBoundary>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Loading/>}>
            <DefaultMetaAttributes/>
            <AppRoutes/>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </ErrorBoundary>
)

export default withOptimizely(App);
