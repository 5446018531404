import React from 'react'
import { Button, Loader, ModalContent } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import './CertificateModalResultState.less'
import tickIcon from '../../../../../assets/images/icons/s24_tick.svg'
import bookIcon from '../../../../../assets/images/icons/s24_book.svg'
import userIcon from '../../../../../assets/images/icons/s24_user_tie_24.svg'
import DetailRow from './DetailRow'

export type CertificateModalResultStateProps = {
  isLoading: boolean,
  onCloseHandler: () => void
}

const CertificateModalResultState: React.FC<CertificateModalResultStateProps> = (
  {
    isLoading,
    onCloseHandler
  }) => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.certificateModal' })

  const handleClick = () => {
    const resultPageSection = document.getElementById('proposals-section-id');
    onCloseHandler()
    resultPageSection?.scrollIntoView({ behavior: 'smooth' })
  }

  return (<>
    <ModalContent className='CertificateModalResult'>
      {isLoading ?
        <div>
          <div className='SpinnerContainer' data-testid='loading-spinner'>
            <Loader/>
          </div>
          <div className='align-center'>
            {t('certificateLoading')}
          </div>
        </div>
        :
        <div>
          <div className='check-container font-normal'>
            <img aria-hidden='true' src={tickIcon} alt='icon' className='check-icon'/>
            <span className='text'>{t('successCertificateCreated')}</span>
          </div>
          <div className='font-h4 font-bold padding-top-m align-center'>
            {t('successHeader')}
          </div>
          <div className='details padding-top-s'>
            <DetailRow icon={bookIcon} caption={t('point1Caption')} description={t('point1Description')}/>
            <DetailRow icon={userIcon} caption={t('point2Caption')} description={t('point2Description')}/>
          </div>
          <Button className='lets-go-button one-whole'
                  onClick={handleClick}
                  appearance='primary'
                  data-testid='close-and-scroll-button'>
            {t('letsGo')}
          </Button>
        </div>
      }
    </ModalContent>
  </>)
}
export default CertificateModalResultState
