import React, { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Redistribution from './Redistribution'
import { sendContactRequestRedistribution } from '../../api/api/ContactRequest'

const RedistributionLandingPage: FC = () => {
  const [ status, setStatus ] = useState<number>()
  const { id } = useParams<{ id: string }>()
  const token = new URLSearchParams(useLocation().search).get('token') || '';

  useEffect(() => {
    const sendRequest = async (id: string | undefined, token: string) => {
      if (!isNaN(Number(id))) {
        const response = await sendContactRequestRedistribution(Number(id), token)
        setStatus(response.status)
      } else {
        setStatus(400)
      }
    }

    sendRequest(id, token)
  }, [ id, token ])

  return <Redistribution status={status}/>
}

export default RedistributionLandingPage
