import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../../services/NumberUtils'
import { Button, Tooltip, TooltipContainer, TooltipTarget } from 'is24-corecss'
import { Proposal } from '../../model/PreApprovalResults'
import './ProposalTile.less'
import { getRemainingDaysToSendAnotherContactRequest } from '../../../services/PreApprovalService'
import ProposalTileDetails from './proposalTileDetails/ProposalTileDetails'
import ProposalLogos from './proposalLogos/ProposalLogos'
import useDelayedHidingToggle from '../../../../../hooks/useDelayedHidingToggle'

export type ProposalTileProps = {
  proposal: Proposal,
  onProposalSelected: () => void
  showSelection: boolean
  selectionLocked: boolean
  showDetailsButton?: boolean
}

const ProposalTile: React.FC<ProposalTileProps> = (
  {
    proposal,
    onProposalSelected,
    showSelection,
    selectionLocked,
    showDetailsButton = true,
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals' })
  const [ remainingDaysToSendAnotherContactRequest, setRemainingDaysToSendAnotherContactRequest ] = useState(getRemainingDaysToSendAnotherContactRequest())
  const { visible: detailView, delayedVisible: detailViewShown, setVisible: setDetailView } = useDelayedHidingToggle()

  useEffect(() => {
    if (selectionLocked) {
      setRemainingDaysToSendAnotherContactRequest(getRemainingDaysToSendAnotherContactRequest());
    }
  }, [ selectionLocked, setRemainingDaysToSendAnotherContactRequest ])

  return (
    <div className={`ProposalTile ${detailView ? 'DetailsExpanded' : 'DetailsCollapsed'}`} data-testid='proposal-tile'>
      <div className='ProposalTileContainer'>
        <ProposalLogos proposal={proposal}/>
        <div className='MonthlyInstallment'>
          <span>{t('monthly')} </span>
          <span>{toCurrency(proposal.monthlyInstallment)}</span>
        </div>
        <div className='DataField'>
          <div>{proposal.fixedInterestRateInYears}&nbsp;{t('years')}</div>
          <div>{t('fixedRate')}</div>
        </div>
        <div className='DataField'>
          <div>{t('startingFrom')}&nbsp;{formatNumber(proposal.interestRate, 2)}&nbsp;%</div>
          <div>{t('interestRate')}</div>
        </div>
        <div className='DataField DataFieldLoanAmount'>
          <div>{toCurrency(proposal.loanAmount)}</div>
          <div>{t('netLoanAmount')}</div>
        </div>

        <div className='OfferButtonContainer'>
          <TooltipContainer>
            <TooltipTarget>
              <Button appearance='primary'
                      disabled={selectionLocked}
                      className={`OfferButton ${showSelection && selectionLocked ? 'OfferSelected' : ''}`}
                      onClick={onProposalSelected}>
                {t(selectionLocked ? 'offerRequested' : 'getOffer')}
              </Button>
            </TooltipTarget>
            <Tooltip className={`SelectionLockedTooltip ${!selectionLocked ? 'Hidden' : ''}`} bodyPosition={'top'}>
              <Trans t={t} i18nKey='selectionLockedTooltip'
                     values={{ days: remainingDaysToSendAnotherContactRequest }}/>
            </Tooltip>
          </TooltipContainer>
        </div>
      </div>
      {detailViewShown && <ProposalTileDetails proposal={proposal}/>}
      {showDetailsButton && <div className='DetailsButtonContainer'>
        <Button className='horizontal-center' textStyle
                onClick={() => setDetailView(!detailView)}>{detailView ? t('lessDetails') : t('moreDetails')}</Button>
      </div>}
    </div>
  )
}

export default ProposalTile
