import { useEffect, useState } from 'react'
import { FinancingResultError } from '../resultPage/model/FinancingResults'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { AgeGroup, AgeGroupType } from '../../../api/api/types/AgeGroup'
import { calculateProbability } from '../services/FinancingService'
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'
import { MINIMAL_LOAN_AMOUNT } from './useLoanAmount'
import { FinancingProbability } from '../../../api/preApproval/FinancingProbability'

const ageGroupToDefaultAge = (ageGroup: AgeGroupType): number => {
  if (ageGroup === AgeGroup.UNDER_45) {
    return 40
  }
  if (ageGroup === AgeGroup.BETWEEN_45_AND_54) {
    return 50
  }
  if (ageGroup === AgeGroup.BETWEEN_55_AND_66) {
    return 60
  }
  throw new Error(`Unsupported age group "${ageGroup}"`)
}

export type OnErrorCallback = (e: FinancingResultError | undefined) => void

export const useFinancingProbability = (loanAmount: number, onError: OnErrorCallback): FinancingProbability | undefined => {

  const [ probability, setProbability ] = useState<FinancingProbability | undefined>(undefined)
  const { oneDynamicFlow } = useOneDynamicFlow()

  useEffect(() => {
    const incomeTotal = getIncomeTotal([ oneDynamicFlow.income ?? {} ])
    if (loanAmount > MINIMAL_LOAN_AMOUNT && !!oneDynamicFlow.propertyCost && incomeTotal > 0 && !!oneDynamicFlow.ageGroupType) {
      if (oneDynamicFlow.ageGroupType === AgeGroup.OVER_66) {
        onError(FinancingResultError.AGE_GROUP_NOT_SUPPORTED)
      } else {
        const borrowerAge: number = ageGroupToDefaultAge(oneDynamicFlow.ageGroupType)
        calculateProbability(loanAmount, oneDynamicFlow.propertyCost || 0, incomeTotal, borrowerAge)
          .then(setProbability)
          .catch(error => {
            console.error(error)
            onError(FinancingResultError.COULD_NOT_CALCULATE)
          })
      }
    }
  }, [ loanAmount, oneDynamicFlow.income, oneDynamicFlow.propertyCost, oneDynamicFlow.ageGroupType, onError ])

  return probability
}
