import React, { useEffect } from 'react'
import { Heading2 } from 'is24-corecss'
import PageWithHeader from '../../../common/oneDynamicFlow/wizard/common/pageWithHeader/PageWithHeader'
import ProposalSkeleton from './ProposalSkeleton'
import { useTranslation } from 'react-i18next'
import './ProposalsLoadingPage.less'

export interface ProposalsLoadingPageProps {
  onNext: () => void
}

const ProposalsLoadingPage: React.FC<ProposalsLoadingPageProps> = ({onNext}) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.userFunnel.loading' })

  useEffect(() => {
    const timer = setTimeout(() => {
      onNext()
    }, 4_000)

    return () => clearTimeout(timer)
  }, [onNext])

  return (
    <PageWithHeader withBenefitBar>
      <div className='loading-page-container'>
        <Heading2 weight='semibold' className='heading'>{t('heading')}</Heading2>
        <Heading2 className='subheading'>{t('subheading')}</Heading2>

        <div className='loading-component-container'>
          <ProposalSkeleton />
          <ProposalSkeleton />
          <ProposalSkeleton />
        </div>
      </div>
    </PageWithHeader>
  )
}

export default ProposalsLoadingPage
