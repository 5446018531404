import React from 'react'
import { useTranslation } from 'react-i18next'
import './NoOffer.less'
import img from '../../../../../assets/images/mortgageOfficer/warning.svg'

const url = 'https://www.immobilienscout24.de/baufinanzierung/wie-viel-haus-kann-ich-mir-leisten.html'

const NoOffer = () => {
  const { t } = useTranslation('userFlow')

  return (
    <div
      className='noOffer horizontal-center palm-margin-top-s lap-margin-top-xxl palm-margin-left-xxl palm-margin-right-xxl'>
      <div className='align-center padding-top-s'>
        <div className='img-wrapper vertical-center-container'>
          <img src={img} aria-hidden alt='' className='dialogImg vertical-center'/>
        </div>
        <h2 className='title horizontal-center'>{t('noOffer.title')}</h2>
        <div className='description horizontal-center margin-top-xl'>{t('noOffer.description')}</div>
        <div className='reasons horizontal-center margin-top-l'>
          {[ 1, 2, 3, 4 ].map(i =>
            <div className='align-left' key={i}>
              <div className='float-left'><span className='is24-icon-eye-check reason-icon'/></div>
              <div className='padding-left-xl'> {t(`noOffer.reasons.${i}`)}</div>
            </div>
          )}
        </div>
        <div className='feedback horizontal-center margin-top-xxl align-left'>
          <span className='float-left is24-icon-info info-icon'/>
          <span className='font-bold'>{t('noOffer.feedback.title')}</span>
          <div className='padding-top-xs'>{t('noOffer.feedback.content')}</div>
        </div>
        <a href={url} className='button button-primary no-offer-button'>{t('noOffer.button')}</a>
      </div>
    </div>
  )
}

export default NoOffer
