import { useLocation, useParams } from 'react-router-dom'
import ContactRequestObjectionComponent from './ContactRequestObjectionComponent'
import { useDispatch, useSelector } from 'react-redux'
import { sendGdprContactRequestObjectionThunk } from './state/thunk'
import { ApplicationState } from '../../redux/store'
import { GdprState } from './state/reducer'
import React from 'react'


const ContactRequestObjectionPage: React.FC = () => {
  const dispatch = useDispatch()
  const gdprState = useSelector<ApplicationState, GdprState>(state => state.gdpr)

  const { id } = useParams<{ id: string }>()
  const token = new URLSearchParams(useLocation().search).get('token') || '';
  const { objectionSuccess, error } = gdprState

  return <ContactRequestObjectionComponent onSend={() => dispatch(sendGdprContactRequestObjectionThunk(Number(id), token))}
                                           objectionSuccess={objectionSuccess} error={error}/>
}

export default ContactRequestObjectionPage
