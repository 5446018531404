import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import './ActionButtons.less'
import CertificateModal from '../certificateModal/CertificateModal'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'

export type ActionButtonsPositiveProps = {
  restartFlowHandler: () => void
  trackingEvent: (pageName: string, label: string, eventName: string, eventParameter1: string, eventParameter2?: string) => void
  oneDynamicFlow: OneDynamicFlowState
}

const ActionButtonsPositive: React.FC<ActionButtonsPositiveProps> = (
  {
    restartFlowHandler,
    trackingEvent,
    oneDynamicFlow
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.actionButtons' })
  const [ isModalVisible, setModalVisible ] = useState(false)

  const createCertificateHandler = () => {
    trackingEvent('preapprovalfunnel.resultpage.positive', 'certificate_cta', 'certificate_cta', 'preapproval')
    setModalVisible(true)
  }

  return (
    <>
      {isModalVisible && <CertificateModal oneDynamicFlow={oneDynamicFlow} onClose={() => setModalVisible(false)}/>}
      <Button className='palm-one-whole lap-one-whole palm-margin-top-l lap-margin-top-m'
              onClick={createCertificateHandler}
              appearance='secondary'>
        {t('positive.createCertificate')}
      </Button>
      <Button className='palm-one-whole lap-one-whole desk-margin-left palm-margin-top-l lap-margin-top-m'
              onClick={restartFlowHandler}>
        {t('restartFlow')}
      </Button>
    </>
  )
}

export default ActionButtonsPositive
