import React from 'react'
import NegativeStateSection from '../../../../common/negativeStateSection/NegativeStateSection'
import { PreApprovalResultState } from '../../../../../preApproval/hook/usePreApprovalResults'
import LoadingPage from '../../../../../common/loadingPage/LoadingPage'
import Section from '../../../../common/section/Section'
import ProposalsResult from './ProposalsResult'
import { FinancingFeasibility } from '../../../../../preApproval/resultPage/model/PreApprovalResults'

const ProposalsResultWrapper: React.FC<{ preApprovalResults: PreApprovalResultState }> = ({ preApprovalResults }) => {
  const { overallFeasibility, optimizationMessages, error } = preApprovalResults
  const isLoading = overallFeasibility == undefined && error == undefined
  const showResult = overallFeasibility == FinancingFeasibility.medium || overallFeasibility == FinancingFeasibility.high
  const showNegativeState = overallFeasibility == FinancingFeasibility.low || error

  return <>
    {isLoading && <Section title='' borderBottom={false}><LoadingPage/></Section>}
    {showResult && <ProposalsResult preApprovalResults={preApprovalResults}/>}
    {showNegativeState && <NegativeStateSection optimizationMessages={optimizationMessages} onOffersTab/>}
  </>

}

export default ProposalsResultWrapper
