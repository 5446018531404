import React from 'react'
import { Font } from 'is24-corecss'
import { useTranslation } from 'react-i18next'

const PreApprovalDisclaimer: React.FC = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.disclaimer.preapproval' })

  return (
    <Font hint={true}>
      {t('text')}
    </Font>
  )
}

export default PreApprovalDisclaimer
