import React, { useMemo } from 'react'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import ProbabilityGauge from '../../common/oneDynamicFlow/probabilityGauge/ProbabilityGauge'
import Section from '../../common/oneDynamicFlow/section/Section'
import Summary from './summary/Summary'
import { useTranslation } from 'react-i18next'
import ProposalsSection from './proposalsSection/ProposalsSection'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../config/optimizely'
import { CalculationSummary } from './calculationSummary/CalculationSummary'
import { useAdditionalCostDefaults } from '../../financing/hook/useAdditionalCostDefaults'
import NegativeStateSummary from './negativeStateSummary/NegativeStateSummary'
import { useLoanCalculations } from '../hook/useLoanCalculations'
import ResultPageSection from './resultPageSection/ResultPageSection'
import ActionButtons from './actionButtons/ActionButtons'
import PreApprovalBreadcrumb from './bradcrumb/PreApprovalBreadcrumb'
import GuideSection from './guideSection/GuideSection'
import CertificateSection from './certificateSection/CertificateSection'
import {
  FinancialProposalResult,
  FinancingFeasibility, FinancingResultError,
} from './model/PreApprovalResults'

export enum ProbabilityResult {
  'POSITIVE' = 'positive',
  'NEGATIVE' = 'negative'
}

export type PreApprovalResultPageProps = Partial<FinancialProposalResult> & {
  overallFeasibility: FinancingFeasibility,
  oneDynamicFlow: OneDynamicFlowState,
  error?: FinancingResultError,
}

const PreApprovalResultPage: React.FC<PreApprovalResultPageProps> = ({ overallFeasibility, oneDynamicFlow, proposals, additionalCosts, error }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage' })
  const [ showProposals ] = useFeature(Feature.SHOW_EUROPACE_PROPOSALS_ON_PREAPPROVAL_RESULT_PAGE)
  const additionalCost = useAdditionalCostDefaults(oneDynamicFlow.location?.postalCode, oneDynamicFlow.location?.geoCode)
  const loanCalculations = useLoanCalculations(oneDynamicFlow, additionalCost)
  const [ leftHandNavi ] = useFeature(Feature.LEFT_HAND_NAVIGATION_RESULT_PAGES)

  const probabilityResult = useMemo(() =>
    overallFeasibility !== FinancingFeasibility.low ?
      ProbabilityResult.POSITIVE
      :
      ProbabilityResult.NEGATIVE, [ overallFeasibility ])

  const summarySection = <Section title={t('summary.title')} className='margin-top-xxl'>
    <Summary oneDynamicFlow={oneDynamicFlow} loanCalculations={loanCalculations}/>
  </Section>

  return (
    <div className='grid grid-flex'>
      {leftHandNavi && <PreApprovalBreadcrumb/>}
      <ResultPageSection title={t(`guideSection.${probabilityResult}.title`)}
                         tooltipTitle={t(`guideSection.${probabilityResult}.tooltipTitle`)}
                         tooltipText={t(`guideSection.${probabilityResult}.tooltipMessage`)}
                         tooltipBodyPosition='bottom'
                         id='proposals-section-id'>
        <GuideSection probabilityResult={probabilityResult} oneDynamicFlow={oneDynamicFlow}/>
      </ResultPageSection>
      {probabilityResult === ProbabilityResult.POSITIVE ?
        <>
          <ResultPageSection className='padding-top-xs'>
            {showProposals ?
              <ProposalsSection oneDynamicFlow={oneDynamicFlow}
                                proposals={proposals}
                                additionalCosts={additionalCosts}
                                isTechnicalError={error === FinancingResultError.COULD_NOT_CALCULATE}/>
              : summarySection
            }
          </ResultPageSection>
          <ResultPageSection>
            <CertificateSection oneDynamicFlow={oneDynamicFlow}/>
          </ResultPageSection>
          <ResultPageSection>
            <CalculationSummary oneDynamicFlow={oneDynamicFlow} loanCalculations={loanCalculations}/>
            <Section title={t('probability')} className='margin-top-xxl'>
              <ProbabilityGauge probability={overallFeasibility}/>
            </Section>
          </ResultPageSection>
        </>
        :
        <>
          {summarySection}
          <CalculationSummary oneDynamicFlow={oneDynamicFlow} loanCalculations={loanCalculations}/>
          <NegativeStateSummary/>
          <ActionButtons probabilityResult={probabilityResult} oneDynamicFlow={oneDynamicFlow}/>
        </>
      }
    </div>
  )
}

export default PreApprovalResultPage
