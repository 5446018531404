import { useEffect, useMemo } from 'react'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { FinancingResultError } from '../resultPage/model/PreApprovalResults'
import {getTotal} from "../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils";

export const MINIMAL_LOAN_AMOUNT = 100_000

export type OnErrorCallback = (e: FinancingResultError | undefined) => void

export const useLoanAmount = (currentError: FinancingResultError | undefined, onErrorChanged: OnErrorCallback): number => {
  const { oneDynamicFlow } = useOneDynamicFlow()

  const loanAmount = useMemo(() =>
      (oneDynamicFlow.propertyCost ?? 0) + (oneDynamicFlow.brokerCommission ?? 0) - getTotal(oneDynamicFlow.equityCapital),
    [ oneDynamicFlow.propertyCost, oneDynamicFlow.brokerCommission, oneDynamicFlow.equityCapital ])

  useEffect(() => {
    if (loanAmount <= MINIMAL_LOAN_AMOUNT) {
      onErrorChanged(FinancingResultError.LOAN_AMOUNT_TO_LOW)
    } else if (currentError === FinancingResultError.LOAN_AMOUNT_TO_LOW) {
      onErrorChanged(undefined)
    }
  }, [ loanAmount, currentError, onErrorChanged ])


  return loanAmount
}
