import { useCallback, useEffect, useState } from 'react'
import { AffordabilityResult, calculateUserAffordability } from '../services/AffordabilityResultService'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { createInitialResult } from '../services/AffordabilityResultMapper'
import { isEmptyObject } from '../../../utils/utils'
import { isFilledEnoughToShowResults } from './useAffordabilityFlowDataCheck'

export type AffordabilityResultPageState = {
  result?: AffordabilityResult
  onBrokerCommissionChange: (val: boolean) => void
  onOwnCapitalChange: (val: number) => void
  onInterestRateChange: (val: number) => void
  onRenovationCostPercentageChange: (val: number) => void
  onMonthlyRateChange: (val: number) => void
  onAmortizationRateChange: (val: number) => void
}

export const useAffordabilityResults = (onCalculationError?: () => void): AffordabilityResultPageState => {
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()

  const [ result, setResult ] = useState<AffordabilityResult | undefined>(undefined)

  const getInitializedResult = useCallback(() => result || createInitialResult(oneDynamicFlow), [ result, oneDynamicFlow ])

  const calculateAndUpdate = useCallback((value: Partial<AffordabilityResult>) => {
    const newResult: AffordabilityResult = { ...getInitializedResult(), ...value}
    calculateUserAffordability(newResult)
      .then(setResult)
      .catch(error => { console.warn('Couldn\'t calculate affordability!', error) })
  },[ getInitializedResult ])

  const onAmortizationRateChange = useCallback((amortizationRate: number) => {
    setOneDynamicFlow({ amortizationRate })
    calculateAndUpdate({ amortizationRate })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  const onInterestRateChange = useCallback((interestRate: number) => {
    setOneDynamicFlow({ interestRate })
    calculateAndUpdate({ interestRate })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  const onMonthlyRateChange = useCallback((monthlyRate: number) => {
    setOneDynamicFlow({ monthlyRate })
    calculateAndUpdate({ monthlyRate })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  const onOwnCapitalChange = useCallback((totalOwnFunds: number) => {
    setOneDynamicFlow({ equityCapital: { otherAssets: totalOwnFunds } })
    calculateAndUpdate({ totalOwnFunds })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  const onBrokerCommissionChange = useCallback((includeBrokerCommission: boolean) => {
    setOneDynamicFlow({ includeBrokerCommission })
    calculateAndUpdate({ includeBrokerCommission })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  const onRenovationCostPercentageChange = useCallback((renovationCostPercentage: number) => {
    setOneDynamicFlow({ renovationCostPercentage })
    calculateAndUpdate({ additionalCosts: { renovationCostPercentage } })
  }, [ setOneDynamicFlow, calculateAndUpdate ])

  useEffect(() => {
    if (!isEmptyObject(oneDynamicFlow) && isFilledEnoughToShowResults(oneDynamicFlow)) {
      const initializedResult: AffordabilityResult = createInitialResult(oneDynamicFlow)
      calculateUserAffordability(initializedResult)
        .then(setResult)
        .catch(error => {
          console.warn('Couldn\'t calculate affordability!', error)
          onCalculationError?.()
        })
    }
  }, [oneDynamicFlow, onCalculationError ])

  return {
    result,
    onAmortizationRateChange,
    onInterestRateChange,
    onMonthlyRateChange,
    onOwnCapitalChange,
    onBrokerCommissionChange,
    onRenovationCostPercentageChange
  }
}
