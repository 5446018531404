import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ContactRequestModal from '../contactRequestModal/ContactRequestModal'
import SuccessModal from '../successModal/SuccessModal'
import { AdditionalCosts, Proposal } from '../../model/PreApprovalResults'
import { ContactInformation, sendContactRequest } from '../../../services/ContactRequestService'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { showToastMessage, withToastMessages } from '../../../../common/toastMessage/ToastMessage'
import {
  reportContactRequestCreatedEvent,
  reportProposalRequestCTAEvent
} from '../../../services/PreApprovalTrackingService'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../../../config/optimizely'
import { BuyersProfileDataState, useBuyersProfileData } from '../../../../../hooks/useBuyersProfileData'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../redux/store'
import { LogInState } from '../../../../../redux/reducers/Login'

type ProposalModalsProps = {
  selectedProposal: Proposal,
  oneDynamicFlow: OneDynamicFlowState,
  additionalCosts: AdditionalCosts,
  resetSelection: () => void
  onContactRequestSent: () => void
}

const showGenericErrorToastMessage = () => showToastMessage('odf:preApproval.resultPage.proposals.contactRequestModal.errorToast.title',
  'odf:preApproval.resultPage.proposals.contactRequestModal.errorToast.message', 'error')

const ProposalModals: React.FC<ProposalModalsProps> = ({
  selectedProposal,
  oneDynamicFlow,
  additionalCosts,
  resetSelection,
  onContactRequestSent
}) => {
  const { email } = useSelector<ApplicationState, LogInState>(state => state.logIn)
  const [ showContactInFlow ] = useFeature(Feature.BAUFI_READY_SHOW_CONTACT_IN_FLOW)
  const { profile: buyersProfile, state : buyersProfileState } = useBuyersProfileData(showContactInFlow)

  const useProfileDataInsteadModal : boolean | undefined = useMemo(() => {
    if (!showContactInFlow) {
      return false
    }
    return buyersProfileState === BuyersProfileDataState.LOADING || buyersProfileState === BuyersProfileDataState.DISABLED ?
      undefined :
      buyersProfileState === BuyersProfileDataState.VALID

  }, [ showContactInFlow, buyersProfileState ])

  const [ isContactModalVisible, setContactModalVisible ] = useState(!!selectedProposal)
  const [ isSuccessModalVisible, setSuccessModalVisible ] = useState(false)

  useEffect(() => setContactModalVisible(!!selectedProposal), [ selectedProposal ])

  const submitContactInformation = useCallback((contactInformation: ContactInformation) => {
    reportProposalRequestCTAEvent()
    sendContactRequest(oneDynamicFlow, contactInformation, selectedProposal, additionalCosts)
      .then(({ id: contactRequestId }) => {
        setContactModalVisible(false)
        setSuccessModalVisible(true)
        onContactRequestSent()
        reportContactRequestCreatedEvent(contactRequestId)
      })
      .catch(() => {
        setContactModalVisible(false)
        resetSelection()
        showGenericErrorToastMessage()
      })
  }, [additionalCosts, onContactRequestSent, oneDynamicFlow, resetSelection, selectedProposal])

  useEffect(() => {
    if (useProfileDataInsteadModal && isContactModalVisible) {
      submitContactInformation({
        firstName: buyersProfile.firstName ?? '',
        lastName: buyersProfile.lastName ?? '',
        phoneNumber: buyersProfile.phoneNumber ?? '',
        email: email ?? ''
      })
    }

  }, [ useProfileDataInsteadModal, isContactModalVisible, submitContactInformation, buyersProfile, email ])

  return (
    <>
      {(useProfileDataInsteadModal === false && isContactModalVisible) && <ContactRequestModal
        onClose={() => {
          setContactModalVisible(false)
          resetSelection()
        }}
        onSubmit={submitContactInformation}

      />}
      {isSuccessModalVisible && <SuccessModal onClose={() => setSuccessModalVisible(false)}/>}
    </>
  )
}

export default withToastMessages(ProposalModals)
