import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { USER_FLOW_PATH, USER_FLOW_RESULT_PAGE_PATH } from '../../config/contextPaths'

import AffordabilityFlowContainer
  from './userFlow/AffordabilityFlowContainer'
import EntryPointTracker from './EntryPointTracker'
import AffordabilityMetaAttributes from './AffordabilityMetaAttributes'
import AffordabilityResultWrapper from './resultPage/AffordabilityResultWrapper'

const AffordabilityFlowContainerWithEntryPointTracker = () =>
  <EntryPointTracker>
    <AffordabilityFlowContainer/>
  </EntryPointTracker>

const AffordabilityRoutes: React.FC = () =>
  <>
    <AffordabilityMetaAttributes/>
    <Routes>
      <Route path={USER_FLOW_PATH + '/:page?'} element={<AffordabilityFlowContainerWithEntryPointTracker/>}/>
      <Route path={USER_FLOW_RESULT_PAGE_PATH} element={<AffordabilityResultWrapper/>}/>
    </Routes>
  </>

export default AffordabilityRoutes;
