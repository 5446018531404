import React, { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/kaufpreis.jpg'
import { NumberInput } from '../../../../common/input/numericInput'
import { DialogPage } from '../dialogPage/DialogPage'
import { FollowupFinancingDataType } from '../../../state/reducer'
import { Button } from 'is24-corecss'
import { createRefs, isFormValid, isRefValid } from '../../../../common/input/refs'
import { formatNumber } from '../../../../../services/NumberUtils'
import { MIN_LOAN_AMOUNT } from '../netLoan/NetLoan';

type Props = {
  followupFinancingData?: Partial<FollowupFinancingDataType>,
  onChange: (followupFinancingData: any) => void
  onClick: () => void
}

export const FollowupFinancingData = ({ followupFinancingData = {}, onChange, onClick }: Props) => {
  const { t } = useTranslation('userFlow')
  const [ showErrors, setShowErrors ] = useState<boolean>(false)
  const inputRefs = createRefs(2)
  const [ remainingDebtRef, propertyValueRef ] = inputRefs
  const { remainingDebt = 0, propertyValue = 0 } = followupFinancingData

  const handleChange = (key: string) => (value: number) => {
    onChange({ ...followupFinancingData, [ key ]: value })
  }

  const isRemainingDebtValid = (ref: RefObject<HTMLInputElement>): boolean => {
    return !showErrors || (isRefValid(ref) && remainingDebt > 0)
  }

  const isPropertyValueValid = (ref: RefObject<HTMLInputElement>): boolean => {
    return !showErrors || (isRefValid(ref) && propertyValue > 0)
  }

  const isAboveMinLoanAmount = remainingDebt >= MIN_LOAN_AMOUNT

  const handleClick = () => {
    setShowErrors(true)
    if (isFormValid(inputRefs) && isAboveMinLoanAmount && propertyValue > 0) {
      onClick()
    }
  }

  return (
    <DialogPage avatarUrl={img} title={t('followupFinancingData.title')} subTitle={t('followupFinancingData.subtitle')}>
      <div className="align-center grid grid-flex margin-top-xl dialog-single-input-wrapper">
        <div className="grid-item one-whole dialog-input">
          <NumberInput
            id='remainingDebt'
            ref={remainingDebtRef}
            label={t('followupFinancingData.remainingDebt')}
            onChange={handleChange('remainingDebt')}
            valid={isRemainingDebtValid(remainingDebtRef)}
            errorMessage={t('followupFinancingData.remainingDebtErrorMessage')}
            value={remainingDebt}
          />
        </div>
        <div className="grid-item one-whole dialog-input">
          <NumberInput
              id='propertyValue'
              ref={propertyValueRef}
              label={t('followupFinancingData.propertyValue')}
              onChange={handleChange('propertyValue')}
              valid={isPropertyValueValid(propertyValueRef)}
              errorMessage={t('followupFinancingData.propertyValueErrorMessage')}
              value={propertyValue}
          />
        </div>
        <span id="loanAmount"
              className={`font-m font-bold align-left one-whole 'font-normal'}`}>
          {formatNumber(remainingDebt)} &euro;
        </span>
        <span className="align-left one-whole">{t('followupFinancingData.loanAmount')}</span>
        <Button
          appearance="primary"
          size="medium"
          className="one-whole palm-one-whole margin-top-xl"
          onClick={handleClick}
        >
          {t('followupFinancingData.button')}
        </Button>
      </div>
      {showErrors && !isAboveMinLoanAmount &&
      <p id="minLoanAmountError" className="margin-top-m align-center font-error">
        {t('followupFinancingData.loanAmountTooLowErrorMessage', { minLoanAmount: formatNumber(MIN_LOAN_AMOUNT) })}
      </p>}
      <p className="margin-top-xl align-center palm-padding-bottom-l">{t('followupFinancingData.description')}</p>
    </DialogPage>
  )
}
