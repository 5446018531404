import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorrowerIncomeAO } from '../../../../../api/api/types/BorrowerIncomeAO.ds'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { getIncomeTotal, IncomeFields } from '../../../incomeForm/IncomeForm'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import IncomeInputRow, { checkIfValid } from '../../../incomeForm/IncomeInputRow'
import { formatNumber } from '../../../../../services/NumberUtils'

export type IncomeSimplifiedProps = Pick<OneDynamicFlowState, 'income' | 'numberOfBorrowers'> & {
  onChange: (incomes: IncomeFields) => void
} & FooterProps & CloseButtonProps

const MAX_VALUE = 100_000

export const isValidIncome = (income: BorrowerIncomeAO = {}) => {
  for ( const key in income ) {
    if (key !== 'numberOfSalaries' && key !== 'total'
      && !checkIfValid(income[ key as keyof BorrowerIncomeAO ], MAX_VALUE)) {
      return false
    }
  }
  const total = getIncomeTotal([ income ])
  return total > 0 && total <= MAX_VALUE
}

export const IncomeSimplified: React.FC<IncomeSimplifiedProps> =
  ({
     income = {},
     numberOfBorrowers = 1,
     onChange,
     onBack,
     onNext,
     progress,
     onClose
   }) => {
    const { t } = useTranslation('odf')

    const total = getIncomeTotal([ income ])
    const salary = income.salary ?? 0
    const other = total - salary
    const isTotalValid = total <= MAX_VALUE

    const handleRegularIncomeChanged = () => (value: number) => {
      onChange({ ...income, salary: value })
    }

    const handleOtherIncomesChanged = () => (value: number) => {
      onChange({ ...income, other: value, childBenefits: 0 , alimony: 0, rental: 0, pension: 0 })
    }

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.income.title', { count: numberOfBorrowers })}
        description={t('userFunnel.income.description')}
        arrow='long-arrow-top-right'
        disabled={!isValidIncome(income)}
        touched={total > 0}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <div style={{ 'maxWidth': '428px' }}
             className='grid-item one-whole padding-vertical-m palm-margin-horizontal-l palm-padding-bottom-xxl lap-padding-bottom-xxl'>
          <IncomeInputRow
            id='salary'
            customLabel={t('userFunnel.income.simplifiedRegularIncome.label')}
            customTooltip={t('userFunnel.income.simplifiedRegularIncome.tooltip')}
            primaryValue={salary}
            primaryOnChange={handleRegularIncomeChanged}
            numberOfBorrowers={1}
            showTooltip={false}
            maxValue={MAX_VALUE}
          />
          <IncomeInputRow
            id='other'
            customLabel={t('userFunnel.income.simplifiedOtherIncomes.label')}
            customTooltip={t('userFunnel.income.simplifiedOtherIncomes.tooltip')}
            primaryValue={other}
            primaryOnChange={handleOtherIncomesChanged}
            numberOfBorrowers={1}
            showTooltip={false}
            maxValue={MAX_VALUE}
          />
          <div>
            <div className='grid-flex grid-justify-space-between padding-top-xl padding-horizontal-s'>
              <strong>{t('userFunnel.income.total')}</strong> <br/>
              <span>{formatNumber(total)}&nbsp;€</span>
            </div>
            <div className='font-error absolute-content margin-top-xs padding-horizontal-s'>
              {!isTotalValid && t('userFunnel.income.totalErrorMessage')}
            </div>
          </div>
        </div>
      </OneDynamicFlowQuestion>
    )
  }

