import React from 'react'
import Section from '../../../common/section/Section'
import Shortlist from './shortlist/Shortlist'
import { ShortlistEntryAO } from '../../../../../api/api/types/ShortlistEntryAO'
import { getShortlistEntries } from '../../../../common/shortlistDropdown/ShortlistService'
import useShortlist from './hook/useShortlist'
import LoadingPage from '../../../../common/loadingPage/LoadingPage'
import NoDataState from './shortlist/NoDataState'
import { useTranslation } from 'react-i18next'

export type ShortlistSectionProps = {
  loadShortlist?: () => Promise<ShortlistEntryAO[]>
}

const ShortlistSection: React.FC<ShortlistSectionProps> = ({ loadShortlist = getShortlistEntries }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.shortlistSection' })
  const { totalCount, visibleEntries, loading, showAll, setShowAll } = useShortlist(loadShortlist)

  return <>
    {loading && <Section title=''><LoadingPage/></Section>}
    {!loading && !!totalCount &&
      <Section title={t('title')}>
        <Shortlist totalCount={totalCount} visibleEntries={visibleEntries} showAll={showAll} setShowAll={setShowAll}/>
      </Section>
    }
    {!loading && !totalCount && <Section title=''><NoDataState/></Section>}
  </>
}

export default ShortlistSection
