import React, { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { Checkbox } from 'is24-corecss'
import './EmployedSince.less'

import {
  currentDateAsISOString,
  formatDateString,
  GERMAN_DATE_FORMAT,
  isDateStringBetween,
  ISO_DATE_FORMAT,
  isValidDateString
} from '../../../../../utils/dateUtils'
import { DateAsISOString } from '../../../../../api/api/types/DateAsISOString'
import DateInputWithPlaceholder, {
  DateValidationResult
} from '../../../input/dateInputWithPlaceholder/DateInputWithPlaceholder'

export type EmployedSinceProps = {
  employedSince: DateAsISOString | undefined,
  fixedTermEmployment: boolean | undefined
  onEmployedSinceChanged: (employedSince: DateAsISOString | undefined) => void
  onFixedTermEmploymentChanged: (fixedTermEmployment: boolean) => void
  dateOfBirth: DateAsISOString | undefined,
} & FooterProps & CloseButtonProps

export const EmployedSince: React.FC<EmployedSinceProps> = (
  {
    employedSince,
    fixedTermEmployment,
    onEmployedSinceChanged,
    onFixedTermEmploymentChanged,
    dateOfBirth,
    onBack,
    onNext,
    progress,
    onClose
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'userFunnel.employedSince' })
  const [ touched, setTouched ] = useState(false)
  const [ valid, setValid ] = useState(true)

  const validateDateString = (dateStringInGermanFormat: string | undefined): DateValidationResult => {
    if (!isValidDateString(dateStringInGermanFormat, GERMAN_DATE_FORMAT)) {
      return {
        isValid: false,
        message: t('errorMessageInvalidFormat')
      }
    }
    const dateString = formatDateString(dateStringInGermanFormat as string, GERMAN_DATE_FORMAT, ISO_DATE_FORMAT)
    if (!isDateStringBetween(dateString, dateOfBirth ?? '1900-01-01', currentDateAsISOString())) {
      return {
        isValid: false,
        message: t('errorMessageDateOutOfRange')
      }
    }
    return { isValid: true }
  }
  const validate = useCallback(validateDateString, [ t, dateOfBirth ])

  return (
    <OneDynamicFlowQuestion
      title={t('title')}
      description={t('description')}
      arrow='long-arrow-bottom-middle-left'
      disabled={!valid}
      onBack={onBack}
      onNext={onNext}
      touched={touched && !!employedSince}
      progress={progress}
      onClose={onClose}
    >
      <div className='EmployedSince'>
        <DateInputWithPlaceholder
          id='employedSince'
          label={t('dateInputLabel')}
          placeholder={t('placeholder')}
          initialValue={employedSince}
          onChange={onEmployedSinceChanged}
          validateDateString={validate}
          onTouched={() => setTouched(true)}
          onValidationStatusChanged={(isValid => setValid(isValid))}
          validationErrorClass='ValidationErrorMessage'
        />
        <div>
          <Checkbox
            id='fixed-term-employment'
            label={t('fixedTermEmploymentLabel')}
            checked={fixedTermEmployment}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onFixedTermEmploymentChanged(event.target.checked)}
          />
        </div>
      </div>
    </OneDynamicFlowQuestion>
  )
}

