export enum FinancingResultError {
  LOAN_AMOUNT_TO_LOW = 'LOAN_AMOUNT_TO_LOW',
  COULD_NOT_CALCULATE = 'COULD_NOT_CALCULATE',
  AGE_GROUP_NOT_SUPPORTED = 'AGE_GROUP_NOT_SUPPORTED'
}

export enum FinancingFeasibility {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

export interface Proposal {
  loanAmount: number
  interestRate: number
  monthlyInstallment: number
  fixedInterestRateInYears: string
  /** @deprecated use financingProducts[n].providerName instead */
  providerName?: string
  amortizationRate: number
  feasibility: FinancingFeasibility
  financingProducts: FinancingProduct[]
}

export enum LoanType {
  ANNUITY_LOAN = 'ANNUITY_LOAN',
  PRIVATE_LOAN = 'PRIVATE_LOAN',
  FORWARD_LOAN = 'FORWARD_LOAN',
  VARIABLE_LOAN = 'VARIABLE_LOAN',
  SUBSIDIZED_LOAN = 'SUBSIDIZED_LOAN',
  UNKNOWN = 'UNKNOWN',
}

export type FinancingProduct = {
  loanType: LoanType
  providerName: string
  loanAmount: number
  monthlyInstallment: number
  nominalInterestRate: number
  effectiveInterestRate: number
  fixedInterestRateInYears: number
  amortizationRate?: number
  totalCosts: number
  remainingDebt: number
  numberOfInstallments: number
  lastInstallmentAmount: number
  lastInstallmentDate: string
}


export interface AdditionalCosts {
  brokerCommission: number;
  notaryCosts: number;
  landTransferTax: number;
}

export enum OptimizationMessageCode {
  MONTHLY_INCOME = 'MONTHLY_INCOME',
  OWN_CAPITAL = 'OWN_CAPITAL',
  UNKNOWN = 'UNKNOWN',
}

export type OptimizationMessage = {
  code: OptimizationMessageCode;
  text: string;
};

export interface FinancialProposalResult {
  proposals: Proposal[];
  additionalCosts: AdditionalCosts;
  overallFeasibility: FinancingFeasibility;
  optimizationMessages: OptimizationMessage[];
}
