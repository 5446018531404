import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CertificateExample from '../../../../preApproval/resultPage/certificateExample/CertificateExample'
import { Heading5 } from 'is24-corecss'
import { toCurrency } from '../../../../../services/NumberUtils'
import { useOneDynamicFlow } from '../../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import LoadingPage from '../../../../common/loadingPage/LoadingPage'
import Section from '../../../common/section/Section'
import './CertificateSection.less'
import CertificateButtons from './certificateButtons/CertificateButtons'
import { isFilledEnoughToShowResults } from '../../../../preApproval/hook/usePreApprovalFlowDataCheck'
import { PreApprovalResultState } from '../../../../preApproval/hook/usePreApprovalResults'
import NegativeStateSection from '../../../common/negativeStateSection/NegativeStateSection'
import { FinancingFeasibility } from '../../../../preApproval/resultPage/model/PreApprovalResults'

const CertificateSection: React.FC<{ preApprovalResults: PreApprovalResultState }> = ({ preApprovalResults }) => {

  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.certificateSection' })
  const { oneDynamicFlow } = useOneDynamicFlow()
  const { overallFeasibility, optimizationMessages, error } = preApprovalResults
  const isFilledEnoughToShowCertificate = isFilledEnoughToShowResults(oneDynamicFlow)

  const subtitleParams = useMemo(() => ({
      purchasePrice: toCurrency(oneDynamicFlow.propertyCost || 0),
      locationLabel: oneDynamicFlow.location?.locationLabel
    }),
    [ oneDynamicFlow.propertyCost, oneDynamicFlow.location?.locationLabel ])

  return (
    <>
      {isFilledEnoughToShowCertificate &&
        <>
          {overallFeasibility == undefined && error == undefined ? <Section title=''><LoadingPage/></Section>
            :
            <>
              {overallFeasibility === FinancingFeasibility.high || overallFeasibility === FinancingFeasibility.medium ?
                <Section title={t('title')}>
                  <div className='CertificateSection' data-testid='certificateSection'>
                    <div className='Subtitle padding-top-l'>
                      <Heading5>
                        <Trans t={t} i18nKey='subtitle' values={subtitleParams}><span className='Highlight'/></Trans>
                      </Heading5>
                    </div>
                    <div className='Description lap-padding-top-l palm-padding-top-l'>
                      <ul className='DescriptionDesktop'>
                        <li className='padding-top-l'><Trans t={t} i18nKey='description1Desktop'
                                                             components={{ 1: <br/> }}/>
                        </li>
                        <li className='padding-top-xl'>{t('description2Desktop')}</li>
                      </ul>
                      <div className='DescriptionMobile'>
                        {t('descriptionMobile')}
                      </div>
                    </div>
                    <div className='Buttons desk-padding-top-s'>
                      <CertificateButtons oneDynamicFlow={oneDynamicFlow}/>
                    </div>
                    <div className='Certificate lap-padding-top-l palm-padding-top-l'>
                      <CertificateExample/>
                    </div>
                  </div>
                </Section>
                :
                <NegativeStateSection optimizationMessages={optimizationMessages}/>
              }
            </>
          }
        </>
      }
    </>
  )
}

export default CertificateSection
