import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import './CertificateButtons.less'
import { OneDynamicFlowState } from '../../../../../../redux/reducers/OneDynamicFlow'
import CertificateModal from '../../../../../preApproval/resultPage/certificateModal/CertificateModal'
import { PRE_APPROVAL_USER_FLOW, RESTART_FLOW_PATH } from '../../../../../../config/contextPaths'
import { CERTIFICATE_MODE_URL_PARAM } from '../../../../../preApproval/hook/usePreApprovalResultPageUrl'
import { useNavigate } from 'react-router-dom'

export type ActionButtonsPositiveProps = {
  oneDynamicFlow: OneDynamicFlowState
}

const CertificateButtons: React.FC<ActionButtonsPositiveProps> = (
  {
    oneDynamicFlow
  }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.certificateSection.certificateButtons' })
  const [ isModalVisible, setModalVisible ] = useState(false)
  const navigate = useNavigate()

  const createCertificateHandler = () => {
    setModalVisible(true)
  }

  const restartFlowHandler = () =>
    navigate(`${PRE_APPROVAL_USER_FLOW}/${RESTART_FLOW_PATH}?${CERTIFICATE_MODE_URL_PARAM}`)

  return (
    <div className='CertificateButtons'>
      {isModalVisible && <CertificateModal oneDynamicFlow={oneDynamicFlow} onClose={() => setModalVisible(false)}/>}
      <Button className='palm-one-whole lap-one-whole palm-margin-top-l lap-margin-top-m'
              onClick={restartFlowHandler}
              appearance='ghost'>
        {t('restartFlow')}
      </Button>
      <Button className='palm-one-whole lap-one-whole desk-margin-left palm-margin-top-l lap-margin-top-m'
              onClick={createCertificateHandler}
              appearance='primary'>
        {t('createCertificate')}
      </Button>

    </div>
  )
}

export default CertificateButtons
