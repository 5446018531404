import React, { useEffect } from 'react'
import { storeCurrentEntryPointUrl } from './services/EntryPointService'

const EntryPointTracker: React.FC<{ children?: React.ReactNode}> = ({ children }) => {
  useEffect(() => {
    storeCurrentEntryPointUrl()
  }, [])
  return <>{ children }</>
}

export default EntryPointTracker;
