import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { isUndefined } from '../../../../../utils/utils'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { ButtonGroup } from '../../wizard/common/buttonGroup/ButtonGroup'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'


type Props = Pick<OneDynamicFlowState, 'ownUse' | 'numberOfBorrowers'> & {
  onClick: (ownUse: boolean) => void
}

export const ObjectUsage: React.FC<Props & FooterProps & CloseButtonProps> =
  ({
     ownUse,
     numberOfBorrowers = 1,
     onClick,
     onBack,
     onNext,
     onClose,
     progress
   }) => {
    const { t } = useTranslation('odf')
    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.objectUsage.title', { count: numberOfBorrowers })}
        description={t('userFunnel.objectUsage.description', { count: numberOfBorrowers })}
        arrow='short-arrow-bottom-right'
        disabled={isUndefined(ownUse)}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <ButtonGroup<boolean>
          value={ownUse}
          onClick={onClick}
          values={[ true, false ]}
          translationRoute={'odf:userFunnel.objectUsage'}
        />
      </OneDynamicFlowQuestion>
    )
  }


