import { Tab, TabList, TabPanel, TabsContainer } from 'is24-corecss'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export type TabDefinition<T extends string | number> = {
  id: T
  title: string
  path: string
  component: React.ReactElement
  renderAlsoWhenInactive?: boolean
}

export type TabNavigationProps<T extends string | number> = {
  tabs: TabDefinition<T>[]
  baseUrl: string
  onTabSelected: (tabId: T, tabIndex: number) => void
}

const TabNavigation = <T extends string | number,>({ tabs, baseUrl, onTabSelected }: TabNavigationProps<T>) => {
  const location = useLocation()
  const navigate = useNavigate()

  const navigateToTab = useCallback((tabIndex: number) =>
    navigate({
        pathname: `${baseUrl}${tabs[tabIndex].path}`,
        search: location.search
      }), [ navigate, tabs, baseUrl, location.search ])

  const getTabIndexFromUrl = useCallback((): number => {
    const index = tabs.findIndex(tab => location.pathname.endsWith(tab.path))
    return index >= 0 ? index : 0
  }, [ tabs, location.pathname ])

  const tabIndexFormUrl = getTabIndexFromUrl()
  const [ selectedTabIndex, setSelectedTabIndex ] = useState(tabIndexFormUrl)
  const [ initTabSelection, setInitTabSelection ] = useState(false)

  useEffect(() => {
    if (!initTabSelection) {
      setInitTabSelection(true)
      onTabSelected(tabs[ selectedTabIndex ].id, selectedTabIndex)
    }
  }, [ initTabSelection, onTabSelected, tabs, selectedTabIndex ])

  const handleSelection = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex)
    navigateToTab(tabIndex)
    onTabSelected(tabs[tabIndex].id, tabIndex)
  }, [ navigateToTab, onTabSelected, tabs ])

  return <TabsContainer onClick={handleSelection} defaultSelected={tabIndexFormUrl}>
    <TabList aria-label='profile tabs' withBorderBottom>
      {tabs.map(tab => <Tab key={tab.id}>{tab.title}</Tab>)}
    </TabList>
    <div className='one-whole'>
      {tabs.map((tab, index) => <TabPanel key={tab.id}>
        {index === selectedTabIndex || tab.renderAlsoWhenInactive ? tab.component : null}
      </TabPanel>)}
    </div>
  </TabsContainer>
}

export default TabNavigation
