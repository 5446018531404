import { useEffect, useState } from 'react'
import { getUser, UserResponse } from '../services/UserService'

export const useUserData = (): UserResponse | undefined => {

  const [ user, setUser ] = useState<UserResponse | undefined>(undefined)

  useEffect(() => {
    getUser()
      .then((userData) => setUser(userData))
      .catch(error => {
        console.warn(`Could not fetch user data: ${error?.message}`)
      })

  }, [])

  return user;
}
