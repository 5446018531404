import React from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionItem } from 'is24-corecss'
import FinancingCostItem from './FinancingCostItem'

interface FinancingCostProps {
  totalPaid: number
  totalInterestPaid: number
  totalAmortizationPaid: number
  remainingLoanAmount: number
}

const FinancingCost: React.FC<FinancingCostProps> = (
  { totalPaid, totalInterestPaid, totalAmortizationPaid, remainingLoanAmount }
) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanDevelopmentSection' })

  return (
    <div className='grid-item one-whole margin-top-xxl palm-margin-top-l float-none'>
      <Accordion>
        <AccordionItem defaultExpanded={true} title={t('financingCost.title')}>
          <FinancingCostItem name={t('financingCost.totalPaid')} value={totalPaid}/>
          <FinancingCostItem name={t('financingCost.totalInterestPaid')} value={totalInterestPaid}/>
          <FinancingCostItem name={t('financingCost.totalAmortizationPaid')} value={totalAmortizationPaid}/>
          <FinancingCostItem name={t('financingCost.remainingLoanAmount')} value={remainingLoanAmount}/>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default FinancingCost
