import React from 'react'
import './NegativeStateItem.less'
import { useTranslation } from 'react-i18next'

export type NegativeStateItemProps = {
  image: string
  imageMobile: string
  itemName: 'selectObject' | 'income' | 'ownCapital' | 'affordability'
  url: string
  optimizationMessage?: string
}

const NegativeStateItem: React.FC<NegativeStateItemProps> = ({
  image,
  imageMobile,
  itemName,
  url,
  optimizationMessage
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'common.negativeStateSection.item' })

  return (
    <div className='NegativeStateItem'>
      <div className='grid grid-flex palm-padding-top-s'>
        <div className='grid-item one-whole palm-one-eighth'>
          <div className='negative-state-icon-container'>
            <img className='palm-hide' src={image} alt='item-icon'/>
            <img className='lap-hide desk-hide' src={imageMobile} alt='item-icon'/>
          </div>
        </div>
        <div className='grid-item one-whole palm-seven-eighth'>
          <div className='negative-state-title' data-testid='negative-state-title'>{t(`${itemName}.title`)}</div>
          <div className='negative-state-description' data-testid='negative-state-description'>
            {optimizationMessage ?? t(`${itemName}.description`)}
          </div>
          <a className='palm-hide lap-hide button palm-one-whole' role='button' href={url}>{t(`${itemName}.cta`)}</a>
          <a className='desk-hide' href={url}>{t(`${itemName}.cta`)}</a>
        </div>
      </div>
    </div>
  )
}

export default NegativeStateItem
