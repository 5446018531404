import React from 'react'

export const withHtmlMeta = <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const canonical = document.querySelector('link[rel="canonical"]')
    const robots = document.querySelector('meta[name="robots"]')
    const local = window.location.href
    canonical && !canonical.outerHTML.includes(local) && canonical.setAttribute('href', local)
    robots && robots.setAttribute('content', 'noindex, follow')

    return (
      <Component {...props} />
    )
  }

