import React from 'react'
import { FinancingProduct } from '../../../../model/PreApprovalResults'
import { useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../../../../services/NumberUtils'
import { formatDateString, GERMAN_DATE_FORMAT, ISO_DATE_FORMAT } from '../../../../../../../utils/dateUtils'
import './FinancingProductsTable.less'
import Row, { RenderCellValueFn } from './row/Row'

export type FinancingProductsTableProps = {
  financingProducts: FinancingProduct[]
}

const nbsp = '\u00A0'
const formatPercentage = (value?: number) => `${value ? formatNumber(value, 0, 2) : '-'}${nbsp}%`

const ROWS: { [ labelKey: string ]: RenderCellValueFn } = {
  loanType: (product, t) => t(`loanTypeEnum.${product.loanType}`) as string,
  loanAmount: (product) => toCurrency(product.loanAmount),
  monthlyInstallment: (product) => toCurrency(product.monthlyInstallment),
  nominalInterestRate: (product) => formatPercentage(product.nominalInterestRate),
  effectiveInterestRate: (product) => formatPercentage(product.effectiveInterestRate),
  fixedInterestRateInYears: (product, t) => product.fixedInterestRateInYears ? `${product.fixedInterestRateInYears} ${t('years')}` : '-',
  amortizationRate: (product) => formatPercentage(product.amortizationRate),
  totalCosts: (product) => toCurrency(product.totalCosts),
  remainingDebt: (product) => toCurrency(product.remainingDebt),
  numberOfInstallments: (product, t) => `${product.numberOfInstallments} ${t('installments')}`,
  lastInstallmentAmount: (product) => toCurrency(product.lastInstallmentAmount),
  lastInstallmentDate: (product) => formatDateString(product.lastInstallmentDate, ISO_DATE_FORMAT, GERMAN_DATE_FORMAT),
}

const FinancingProductsTable: React.FC<FinancingProductsTableProps> = ({ financingProducts }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.details' })

  if (!financingProducts || financingProducts.length === 0) {
    return null
  }

  return (
    <table className={`FinancingProductsTable ${financingProducts.length === 1 ? 'FixedTableLayout' : ''}`}>
      <thead>
      <tr>
        <th>{t('conditions')}</th>
        {financingProducts.map(product => <th key={product.providerName}>{product.providerName}</th>)}
      </tr>
      </thead>
      <tbody>
      {Object.keys(ROWS).map(labelKey =>
        <Row key={labelKey} labelKey={labelKey} valueFn={ROWS[ labelKey ]} financingProducts={financingProducts}/>)}
      </tbody>
    </table>
  )
}

export default FinancingProductsTable
