import { useCallback, useMemo, useState } from 'react'
import {
  RepaymentType,
  UserInput,
  userInputDefaults,
} from '../resultPage/model/FinancingResults'

export type UseUserInputReturnType = {
  userInput: UserInput
  changeHandlers: {
    onAmortizationRateChange: (val: number) => void
    onFixedRateEndYearChange: (val: number) => void
    onRepaymentAmountChange: (repaymentAmount: number) => void
    onRepaymentTypeChange: (repaymentType: RepaymentType, repaymentYearOrMonth: number) => void
  }
}

export const useUserInput = (): UseUserInputReturnType => {
  const [ userInput, setUserInput ] = useState<UserInput>(userInputDefaults)

  const onAmortizationRateChange = useCallback((amortizationRate: number) => {
    setUserInput(prevState => ({ ...prevState, amortizationRate }))
  }, [ setUserInput ])

  const onFixedRateEndYearChange = useCallback((fixedRateEndYear: number) => {
    setUserInput(prevState => ({ ...prevState, fixedRateEndYear }))
  }, [ setUserInput ])

  const onRepaymentAmountChange = useCallback((repaymentAmount: number) => {
    setUserInput(prevState => ({ ...prevState, repaymentAmount }))
  }, [ setUserInput ])

  const onRepaymentTypeChange = useCallback((repaymentType: RepaymentType, repaymentYearOrMonth: number) => {
    setUserInput(prevState => ({ ...prevState, repaymentType, repaymentYearOrMonth }))
  }, [ setUserInput ])

  const changeHandlers = useMemo(() => ({
    onAmortizationRateChange,
    onFixedRateEndYearChange,
    onRepaymentAmountChange,
    onRepaymentTypeChange
  }), [ onAmortizationRateChange, onFixedRateEndYearChange, onRepaymentAmountChange, onRepaymentTypeChange ])

  return {
    userInput,
    changeHandlers
  }
}
