import { useLoadScript } from '@react-google-maps/api'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GOOGLE_MAPS_API_KEY } from '../../../../../api/contextPaths'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { isDefined } from '../../../../../utils/utils'
import { LocationType } from '../../../../mortgageOfficer/state/reducer'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import './Location.less'
import MapContainer from './map/MapContainer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { Expose } from '../../../../../api/api/Expose'

export enum LocationQueryType {
  geocode = 'geocode',
  postcode = 'postcode'
}

type Props = Pick<OneDynamicFlowState, 'location' | 'numberOfBorrowers' | 'exposeId' | 'exposeFromShortlist'> & {
  exposeOnlyMode?: boolean
  onLocationChange: (location?: LocationType) => void
  onExposeChange?: (expose: Expose | undefined, exposeFromShortlist: boolean | undefined) => void
  queryType: LocationQueryType.geocode | LocationQueryType.postcode,
  children?: React.ReactNode
}
export const isValidLocation = (queryType: LocationQueryType.geocode | LocationQueryType.postcode, location?: LocationType) => {
  return queryType === LocationQueryType.postcode ? isValidLocationWithPostcode(location) : isValidLocationWithGeoCode(location)
}

export const isValidLocationWithPostcode = (location?: LocationType) => {
  return isDefined(location?.locationLabel) && isDefined(location?.postalCode)
}

export const isValidLocationWithGeoCode = (location?: LocationType) => {
  return isDefined(location?.locationLabel) && (isDefined(location?.geoCode))
}

export const Location: React.FC<Props & FooterProps & CloseButtonProps> =
  ({
     location,
     exposeId,
     exposeFromShortlist,
     exposeOnlyMode = false,
     numberOfBorrowers = 1,
     onLocationChange,
     onExposeChange,
     onBack,
     onNext,
     progress,
     onClose,
     queryType,
     children
   }) => {
    const { t } = useTranslation('odf', { keyPrefix: 'userFunnel.location' })

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY
    })
    const canGoToNextPage = isValidLocation(queryType, location) && (isDefined(exposeId) || !exposeOnlyMode)
    return (
      <OneDynamicFlowQuestion
        title={t(exposeOnlyMode ? 'titleExposeMode' : 'title', { count: numberOfBorrowers })}
        description={t(exposeOnlyMode ? 'descriptionExposeMode' : 'description')}
        arrow='short-arrow-bottom-left'
        additionalClassName='wider-container'
        disabled={!canGoToNextPage}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <div className='grid-item one-whole map-rounded-corners'>
          {isLoaded &&
            <MapContainer
              location={location}
              exposeId={exposeId}
              exposeFromShortlist={exposeFromShortlist}
              exposeOnlyMode={exposeOnlyMode}
              setLocation={onLocationChange}
              setExpose={onExposeChange}
              queryType={queryType}
              hasDisclaimer={isDefined(children)}
            />
          }
          <div className='disclaimer-container'>
            {children}
          </div>
        </div>
      </OneDynamicFlowQuestion>
    )
  }


