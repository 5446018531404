import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BorrowerIncomeAO } from '../../../../../api/api/types/BorrowerIncomeAO.ds'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { formatNumber } from '../../../../../services/NumberUtils'
import IncomeForm, { getIncomeTotal, IncomeFields } from '../../../incomeForm/IncomeForm'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { checkIfValid } from '../../../incomeForm/IncomeInputRow'
import {
  calculateChildBenefit,
  shouldPrefillChildBenefit
} from '../../prefillCalculations/PrefillCalculations'


export type IncomeProps = Pick<OneDynamicFlowState, 'income' | 'numberOfBorrowers' | 'numberOfChildren'> & {
  onChange: (incomes: IncomeFields) => void
} & FooterProps & CloseButtonProps

const MAX_VALUE = 100_000

export const isValidIncome = (income: BorrowerIncomeAO = {}) => {
  for ( const key in income ) {
    if (key !== 'numberOfSalaries' && key !== 'total'
      && !checkIfValid(income[ key as keyof BorrowerIncomeAO ], MAX_VALUE)) {
      return false
    }
  }
  const total = getIncomeTotal([ income ])
  return total > 0 && total <= MAX_VALUE
}

export const Income: React.FC<IncomeProps> =
  ({
     income = {},
     numberOfBorrowers = 1,
     onChange,
     onBack,
     onNext,
     progress,
     onClose,
     numberOfChildren
   }) => {
    const { t } = useTranslation('odf')

    const total = getIncomeTotal([ income ])
    const isTotalValid = total <= MAX_VALUE

    useEffect(() => {
      if (shouldPrefillChildBenefit(income, numberOfChildren)) {
        numberOfChildren && onChange({ ...income, childBenefits: calculateChildBenefit(numberOfChildren) })
      }
    }, [ income, numberOfChildren, onChange ]);

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.income.title', { count: numberOfBorrowers })}
        description={t('userFunnel.income.description')}
        arrow='long-arrow-top-right'
        disabled={!isValidIncome(income)}
        touched={total > 0}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <div style={{ 'maxWidth': '428px' }}
             className='grid-item one-whole padding-vertical-m palm-margin-horizontal-l palm-padding-bottom-xxl lap-padding-bottom-xxl'>
          <IncomeForm
            primaryIncome={income}
            showPrimarySalary={false}
            showSecondarySalary={false}
            showTooltips={false}
            numberOfBorrowers={1}
            primaryOnChange={onChange}
            maxValue={MAX_VALUE}
            odfLabel={true}
          />
          <div>
            <div className='grid-flex grid-justify-space-between padding-top-xl padding-horizontal-s'>
              <strong>{t('userFunnel.income.total')}</strong> <br/>
              <span>{formatNumber(total)}&nbsp;€</span>
            </div>
            <div className='font-error absolute-content margin-top-xs padding-horizontal-s'>
              {!isTotalValid && t('userFunnel.income.totalErrorMessage')}
            </div>
          </div>
        </div>
      </OneDynamicFlowQuestion>
    )
  }

