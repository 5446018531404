import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './ValueWithLabel.less'

export interface ValueWithLabelProps {
  label: string
  value?: string
  placeholderWidth?: number
  labelPosition?: 'left' | 'right' | 'top' | 'bottom'
  labelWeight?: 'normal' | 'bold'
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({
  label,
  value,
  placeholderWidth = 100,
  labelPosition = 'bottom',
  labelWeight = 'normal'
}) => {
  return (
    <div className={`value-with-label-container label-position-${labelPosition}`}>
      {value && <span className='value'>{value}</span>}
      {!value && <span className='value'><Skeleton width={placeholderWidth} borderRadius={4} /></span>}
      <span className={`label label-weight-${labelWeight}`}>{label}</span>
    </div>
  )
}

export default ValueWithLabel
