import { ChangeEvent, RefObject, useRef } from 'react'

export type  TextInputParams<T> = {
  id: T,
  name: T,
  placeholder: string,
  label: string,
  errorMessage: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  ref?: RefObject<HTMLInputElement>,
  required: boolean,
  valid: boolean
}

export const createRefs = (length: number): RefObject<HTMLInputElement>[] => {
  return Array.from({ length }, () => useRef<HTMLInputElement>(null))
}

export const isRefValid = (ref?: RefObject<HTMLInputElement | HTMLSelectElement>): boolean => {
  return !ref || (ref && (!ref.current || ref.current.validity.valid))
}

export const isFormValid = (refs: RefObject<HTMLInputElement | HTMLSelectElement>[]) => {
  return refs.length > 0 && refs.filter(ref => ref.current !== null).filter(ref => isRefValid(ref)).length === refs.length
}
