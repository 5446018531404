import React, { useEffect } from 'react'
import TrackingService from '../../../services/TrackingService/TrackingService'

export const withPageTrackingReporting = <P extends object>(WrappedComponent: React.ComponentType<P>, pageTitle: string): React.FC<P> => {
  return (props) => {
    useEffect(
      () => TrackingService.reportPageView(pageTitle),
      []
    )
    return <WrappedComponent {...props as P} />
  }
}
