import React, { useCallback } from 'react'
import { PRE_APPROVAL_USER_FLOW, RESTART_FLOW_PATH } from '../../../../config/contextPaths'
import './ActionButtons.less'
import TrackingService from '../../../../services/TrackingService/TrackingService'
import { ProbabilityResult } from '../PreApprovalResultPage'
import ActionButtonsPositive from './ActionButtonsPositive'
import ActionButtonsNegative from './ActionButtonsNegative'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { useNavigate } from 'react-router-dom'

export type ActionButtonsProps = {
  probabilityResult: ProbabilityResult
  oneDynamicFlow: OneDynamicFlowState
}

const trackingEvent = (pageName: string, label: string, eventName: string, eventParameter1: string, eventParameter2?: string) =>
  TrackingService.reportEvent(
    pageName,
    'preapproval',
    label,
    'finance',
    {
      event_name: eventName,
      event_product: 'finance',
      event_parameter_1: eventParameter1,
      event_parameter_2: eventParameter2
    }
  )

const ActionButtons: React.FC<ActionButtonsProps> = ({ probabilityResult, oneDynamicFlow }) => {
  const navigate = useNavigate()

  const restartFlowHandler = useCallback(() => {
    const pageName = `preapprovalfunnel.resultpage.${probabilityResult}`
    trackingEvent(pageName, 'certificate_reboot', 'certificate_reboot', 'preapproval')
    navigate(`${PRE_APPROVAL_USER_FLOW}/${RESTART_FLOW_PATH}`)
  }, [ navigate, probabilityResult ])

  return (
    <div className='actionButtons grid one-whole desk-margin-top-l'>
      <div
        className={`buttonGroup grid-item padding-bottom-xl desk-padding-bottom-none ${probabilityResult === 'positive' ? 'grid-justify-start' : 'grid-justify-end'}`}>
        {probabilityResult === ProbabilityResult.POSITIVE ?
          <ActionButtonsPositive restartFlowHandler={restartFlowHandler}
                                 trackingEvent={trackingEvent}
                                 oneDynamicFlow={oneDynamicFlow}/>
          :
          <ActionButtonsNegative restartFlowHandler={restartFlowHandler}/>
        }
      </div>
    </div>
  )
}

export default ActionButtons
