import ShortlistTile from '../shortlistTile/ShortlistTile'
import React from 'react'
import { ShortlistEntryAO } from '../../../../../../api/api/types/ShortlistEntryAO'
import './ShortlistTiles.less'

const NUMBER_OF_TILES_BY_DEFAULT = 4

export type ShortlistTilesProps = {
  shortlistEntries: ShortlistEntryAO[],
}

export const shouldHideTileOnMobile = (index: number, shortlistEntriesLength: number) =>
  (index === NUMBER_OF_TILES_BY_DEFAULT - 1) && shortlistEntriesLength === NUMBER_OF_TILES_BY_DEFAULT

const ShortlistTiles: React.FC<ShortlistTilesProps> = ({ shortlistEntries }) => {

  return <div className='ShortlistTiles flex grid flex--wrap gutter'>

    {shortlistEntries.map((entry, index) =>
      <div key={entry.exposeId}
           className={`flex-item grid-item palm-one-whole absolute-reference desk-one-fourth lap-one-half ${shouldHideTileOnMobile(index, shortlistEntries.length) ? 'HideOnMobile' : ''}`}>
        <ShortlistTile shortlistEntry={entry}/>
      </div>
    )}
  </div>
}

export default ShortlistTiles
