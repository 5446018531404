import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { useCallback, useEffect, useState } from 'react'
import { Expose, fetchExposeEntity } from '../../../api/api/Expose'

export type UseSelectedObjectReturn = {
  selectedObject?: Expose
  selectNewObject: () => void
  loading: boolean
}

const useSelectedObject = (): UseSelectedObjectReturn => {
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()
  const [ loading, setLoading ] = useState(false)
  const [ selectedObject, setSelectedObject ] = useState<Expose | undefined>(undefined)

  const selectNewObject = useCallback(() => {
    setOneDynamicFlow({
      ...oneDynamicFlow,
      exposeId: undefined,
      exposeFromShortlist: undefined,
      propertyCost: undefined,
      floorArea: undefined,
      brokerCommission: undefined,
      brokerCommissionPercentage: undefined
    })
    setSelectedObject(undefined)
  }, [ oneDynamicFlow, setOneDynamicFlow ])

  useEffect(() => {
    if (oneDynamicFlow.exposeId) {
      setLoading(true)
      fetchExposeEntity(oneDynamicFlow.exposeId)
        .then((expose: Expose | undefined) => {
          if (expose) {
            setSelectedObject(expose)
          } else {
            // Object not found, clean it from local storage!
            selectNewObject()
          }
        }).catch(error => {
          console.error(error)
        }).finally(() => setLoading(false))
    }
  }, [ oneDynamicFlow, selectNewObject ])

  return {
    selectedObject,
    selectNewObject,
    loading
  }
}

export default useSelectedObject
