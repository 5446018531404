import React from 'react'
import ProposalTile from '../../../resultPage/proposalsSection/proposalTiles/ProposalTile'
import './BlurredProposals.less'
import { FinancingFeasibility, FinancingProduct, Proposal } from '../../../resultPage/model/PreApprovalResults'

const proposals: Proposal[] = [
  {
    fixedInterestRateInYears: '15',
    interestRate: 3.72,
    loanAmount: 257000,
    monthlyInstallment: 1117.95,
    amortizationRate: 1.5,
    feasibility: FinancingFeasibility.medium,
    financingProducts: [ {
      providerName: 'DEVK',
    } as FinancingProduct
    ],
  },
  {
    fixedInterestRateInYears: '15',
    interestRate: 4.19,
    loanAmount: 257000,
    monthlyInstallment: 1111.53,
    amortizationRate: 1,
    feasibility: FinancingFeasibility.high,
    financingProducts: [ {
      providerName: 'Hannoversche',
    } as FinancingProduct
    ],
  },
  {
    fixedInterestRateInYears: '15',
    interestRate: 3.92,
    loanAmount: 257000,
    monthlyInstallment: 1053.7,
    amortizationRate: 1,
    feasibility: FinancingFeasibility.high,
    financingProducts: [ {
      providerName: 'ING',
    } as FinancingProduct
    ],
  }
]

const BlurredProposals: React.FC = () =>
  <div className='BlurredProposals'>
    <div className='BlurredProposalsList' data-testid='blurred-proposals-list'>
      {proposals.map((proposal, index) =>
        <ProposalTile key={index}
                      proposal={proposal}
                      onProposalSelected={() => undefined}
                      showSelection={false}
                      selectionLocked={false}
                      showDetailsButton={false}
        />)}
    </div>
  </div>

export default BlurredProposals
