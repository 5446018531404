import React from 'react'
import { StatusMessage } from 'is24-corecss'
import { toast, ToastContainer, ToastContent, ToastContentProps, ToastOptions } from 'react-toastify'
import './ToastMessage.less'
import 'react-toastify/dist/ReactToastify.min.css';
import './ToastMessage.less'
// we can't use useTranslation() hook here, because this function is triggered outside React's rendering cycle, hence using the t-function directly
import { t } from 'i18next';

type ToastMessageProps = {
  titleKey: string
  messageKey: string
  type: 'confirm' | 'info' | 'error'
}

export const ToastMessage: React.FC<ToastContentProps<ToastMessageProps>> = ({ data }) => (
  <StatusMessage
    headline={data?.titleKey && t(data?.titleKey) || ''}
    message={data?.messageKey && t(data?.messageKey)}
    statusType={data?.type ?? 'confirm'}
    statusStyle='toast'
  />
)

const defaultToastOptions: ToastOptions = {
  closeButton: false,
  position: 'top-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  hideProgressBar: true
}

export const showToastMessage = (titleKey: string, messageKey?: string, type?: ToastMessageProps['type']) =>
  toast(ToastMessage as ToastContent, {
    ...defaultToastOptions, data: {
      titleKey, messageKey, type
    }
  })

export const withToastMessages = <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props) =>
    <>
      <ToastContainer className='ToastContainer'/>
      <WrappedComponent {...props as P} />
    </>


