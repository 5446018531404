import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PercentageStepper } from '../../../affordability/resultPage/loanSettings/PercentageStepper'
import {
  sanitizeInput,
  validateAmortizationRate
} from '../../../affordability/resultPage/loanSettings/LoanSettingsService'
import './Settings.less'
import { fixedRateEndYearValues, RepaymentType } from '../model/FinancingResults'
import Dropdown from '../../../common/dropdown/Dropdown'
import CustomTooltip from '../../../common/customTooltip/CustomTooltip'
import SpecialRepayment from './SpecialRepayment'

export const MIN_AMORTIZATION_RATE_PERCENTAGE = 1
export const MAXIMUM_PERCENTAGE = 7.5
export const DEFAULT_AMORTIZATION_RATE_PERCENTAGE = 2

const Settings: React.FC<{
  amortizationRate?: number
  onAmortizationRateChange: (val: number) => void
  fixedRateEndYear: number
  onFixedRateEndYearChange: (val: number) => void
  loanAmount: number
  repaymentAmount: number
  onRepaymentAmountChange: (repaymentAmount: number) => void
  repaymentType: RepaymentType
  repaymentYearOrMonth: number
  onRepaymentTypeChange: (repaymentType: RepaymentType, repaymentYearOrMonth: number) => void
}> = ({
        amortizationRate = DEFAULT_AMORTIZATION_RATE_PERCENTAGE,
        onAmortizationRateChange,
        fixedRateEndYear,
        onFixedRateEndYearChange,
        loanAmount,
        repaymentAmount,
        onRepaymentAmountChange,
        repaymentType,
        repaymentYearOrMonth,
        onRepaymentTypeChange
      }) => {

  const [ amortizationRateVal, setAmortizationRateVal ] = useState(amortizationRate)
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanOverviewSection.settings' })

  const onAmortizationRateStepperChange = (value: number) => {
    const validValue = validateAmortizationRate(value)
    onAmortizationRateChange(validValue)
    setAmortizationRateVal(validValue)
  }

  const onFixedRateEndYEarDropdownChange = (value: string) => {
    onFixedRateEndYearChange(Number(value))
  }

  const labelWithTooltip = (labelText: string, tooltipText: string) => {
    return (
      <div className='label-with-tooltip'>
        <span>
          {labelText}
        </span>
        <span className='tooltip'>
          <CustomTooltip
            text={tooltipText}
            bodyPosition='top'
            arrowPosition='left'
            minWidth={215}
          />
        </span>
      </div>)
  }

  return (
    <div className='settings-container padding-top-l'>
      <div className='grid grid-flex gutter'>
        <div className='grid-item stepper-container one-whole lap-one-half desk-one-fourth'>
          <PercentageStepper
            id='amortizationRate'
            label={labelWithTooltip(t('amortizationRate'), t('amortizationRateTooltip'))}
            min={MIN_AMORTIZATION_RATE_PERCENTAGE}
            max={MAXIMUM_PERCENTAGE}
            defaultValue={amortizationRateVal?.toString()}
            onValueChange={onAmortizationRateStepperChange}
            inputSanitization={sanitizeInput(MIN_AMORTIZATION_RATE_PERCENTAGE, MAXIMUM_PERCENTAGE)}
          />
          <span className='percent-value font-s'>%</span>
        </div>
        <div className='grid-item one-whole lap-one-half desk-one-fourth'>
          <Dropdown<number>
            id={'fixedRateEndYear'}
            label={labelWithTooltip(t('fixedRateEndYear'), t('fixedRateEndYearTooltip'))}
            value={fixedRateEndYear}
            listItems={fixedRateEndYearValues()}
            labelFormatter={(value: number) => t('fixedRateEndYearOption', { years: value })}
            onChange={onFixedRateEndYEarDropdownChange}
          />
        </div>
        <div className='grid-item one-whole desk-two-fourth'>
          <SpecialRepayment loanAmount={loanAmount}
                            repaymentAmount={repaymentAmount}
                            onRepaymentAmountChange={onRepaymentAmountChange}
                            repaymentType={repaymentType}
                            repaymentYearOrMonth={repaymentYearOrMonth}
                            onRepaymentTypeChange={onRepaymentTypeChange}/>
        </div>
      </div>
    </div>
  )
}

export default Settings
