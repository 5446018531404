import React, { ReactChild, useState } from 'react'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import './ProposalsSection.less'
import ProposalTiles from './proposalTiles/ProposalTiles'
import ProposalModals from './proposalModals/ProposalModals'
import { canSendAnotherContactRequest, storeContactRequestSentTimestamp } from '../../services/PreApprovalService'
import { AdditionalCosts, Proposal } from '../model/PreApprovalResults'
import TechnicalError from './technicalError/TechnicalError'

export type ProposalsSectionProps = {
  oneDynamicFlow: OneDynamicFlowState
  proposals?: Proposal[]
  additionalCosts?: AdditionalCosts
  isTechnicalError: boolean
  customHeader?: ReactChild
}

const ProposalsSection: React.FC<ProposalsSectionProps> = ({ oneDynamicFlow, proposals, additionalCosts, isTechnicalError, customHeader }) => {
  const [ selectedProposal, setSelectedProposal ] = useState<Proposal | undefined>(undefined)
  const [ isSelectionLocked, setSelectionLocked ] = useState(!canSendAnotherContactRequest())

  const onContactRequestSent = () => {
    storeContactRequestSentTimestamp()
    setSelectionLocked(true)
  }
  return (
    <section className='proposals-section one-whole'>
      {proposals && <ProposalTiles proposals={proposals}
                                   onProposalSelected={setSelectedProposal}
                                   selectedProposal={selectedProposal} selectionLocked={isSelectionLocked}
                                   customHeader={customHeader}/>}
      {isTechnicalError && <TechnicalError/>}
      {selectedProposal && additionalCosts && <ProposalModals selectedProposal={selectedProposal}
                                                              oneDynamicFlow={oneDynamicFlow}
                                                              additionalCosts={additionalCosts}
                                                              resetSelection={() => setSelectedProposal(undefined)}
                                                              onContactRequestSent={onContactRequestSent}/>
      }
    </section>
  )
}

export default ProposalsSection
