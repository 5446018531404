import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ModalBackdrop, ModalContainer } from 'is24-corecss'
import './SuccessModal.less'
import Tick from '../../tick/Tick'
import Details from './details/Details'

export type SuccessModalProps = {
  onClose: () => void
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.successModal' })
  return (
    <div className='SuccessModal'>
      <ModalBackdrop visible={true}>
        <ModalContainer visible={true} containerClassName='SuccessModalContainer'>
          <div className='ModalContent'>
            <Tick/>
            <div className='font-h2 font-normal padding-top-m palm-padding-top-none'>{t('offerRequested')}</div>
            <div className='font-h4 font-normal palm-font-standard font-line-standard palm-padding-bottom-l'>{t('advisoryWillContactYouSoon')}</div>
            <div className='font-h3 font-bold padding-top-m palm-hide'>{t('whatCanYouExpect')}</div>
            <Details/>
            <div className='ButtonContainer'>
              <Button appearance='secondary' onClick={onClose}>{t('understoodButton')}</Button>
            </div>
          </div>
        </ModalContainer>
      </ModalBackdrop>
    </div>
  )
}

export default SuccessModal
