import DetailsRow from './DetailsRow'
import pdfIconSmall from '../../../../../../assets/images/icons/s24_document_pdf_24.svg'
import pdfIconBig from '../../../../../../assets/images/icons/s24_document_pdf_48.svg'
import userIconSmall from '../../../../../../assets/images/icons/s24_user_tie_24.svg'
import userIconBig from '../../../../../../assets/images/icons/s24_user_tie_48.svg'
import percentIconSmall from '../../../../../../assets/images/icons/s24_percent_24.svg'
import percentIconBig from '../../../../../../assets/images/icons/s24_percent_48.svg'
import React from 'react'
import './Details.less'

const Details: React.FC = () => (
  <div className='Details'>
    <DetailsRow iconSmall={pdfIconSmall} iconBig={pdfIconBig} translationKey='personalOffer'/>
    <DetailsRow iconSmall={userIconSmall} iconBig={userIconBig} translationKey='individualAdvising'/>
    <DetailsRow iconSmall={percentIconSmall} iconBig={percentIconBig} translationKey='matchingConditions'/>
  </div>
)

export default Details
