import React from 'react'
import './NegativeStateSummary.less'
import bulb from '../../../../assets/images/bulb.svg'
import { Trans, useTranslation } from 'react-i18next'

const NegativeStateSummary: React.FC = () => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.negativeStateSummary' })

  return (
    <div className='negative-state-summary padding-top-xl'>
      <div className='background-section'>
        <div className='container'>
          <div>
            <img src={bulb} aria-hidden alt='' className='image'/>
          </div>
          <div className='text-section'>
            <div className='title'>
              {t('title')}
            </div>
            <ul className='list-bullet'>
              <li><Trans t={t} i18nKey='point1'><span className='font-bold'/></Trans></li>
              <li><Trans t={t} i18nKey='point3'><span className='font-bold'/></Trans></li>
              <li><Trans t={t} i18nKey='point2'><span className='font-bold'/></Trans></li>
            </ul>
          </div>
        </div>
      </div>
      <div className='no-background-section'>
        <div className='title tealHighlighting'>
          {t('whatICanDo')}
        </div>
        <ul className='list-bullet'>
          <li>{t('whatICanDoPoint1')}</li>
          <li>{t('whatICanDoPoint2')}</li>
        </ul>
      </div>
    </div>
  )
}

export default NegativeStateSummary
