import React from 'react'
import { logError } from '../../../api/api/Logger'

/**
 * Known unsupported features.
 * - Intl support (https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl)
 * has been introduced quite some time ago and the polyfill is quite large (16k gzipped)
 */
const knownUnsupportedFeatureMessages = [ 'Can\'t find variable: Intl', 'Intl is not defined' ]

export class ErrorBoundary extends React.Component<{ children?: React.ReactNode }> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!knownUnsupportedFeatureMessages.includes(error.message)) {
      logError(`ErrorBoundary: ${error.message}`, errorInfo)
    }
  }

  render() {
    return <>
      {this.props.children}
    </>
  }
}

