import React from 'react'
import CloseButton, { CloseButtonProps } from './wizard/common/closeButton/CloseButton'
import Footer, { FooterProps } from './wizard/common/footer/Footer'
import Question, { QuestionProps } from './wizard/common/question/Question'

import './wizard/Page.less'

export const OneDynamicFlowQuestion: React.FC<QuestionProps & FooterProps & CloseButtonProps & { touched?: boolean, children?: React.ReactNode }> =
  ({
     title,
     description,
     arrow = 'long-arrow-bottom-left',
     additionalClassName,
     disabled,
     touched = false,
     children,
     onNext,
     onBack,
     onClose,
     progress
   }) => (
    <div className='Page grid grid-flex'>
      {onClose ? <CloseButton disabled={touched && disabled} onClose={onClose}/> : <div/>}
      <div className='grid-item desk-one-half one-whole'>
        <Question
          title={title}
          description={description}
          arrow={arrow}
          additionalClassName={additionalClassName}
        />
      </div>
      <div className='container grid-item one-whole desk-one-half'>
        <div className='input rounded-corners grid grid-flex grid-justify-center grid-align-center'>
          {children}
        </div>
        <Footer disabledForward={disabled} disabledBack={touched && disabled} onNext={onNext} onBack={onBack} progress={progress}/>
      </div>
    </div>
  )

