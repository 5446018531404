import { GoogleMap, GoogleMapProps } from '@react-google-maps/api'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import MapStyles from './MapStyles'
import Search from './Search'
import { createPositionWithOffset } from './MapUtils'
import { LocationType } from '../../../../../mortgageOfficer/state/reducer'
import { getGeocode, getLatLng, LatLng, GeocodeResult } from 'use-places-autocomplete'
import MapMarker from './MapMarker'
import { Expose } from '../../../../../../api/api/Expose'

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

type SearchProps = {
  setLocation: (location?: LocationType) => void;
  location?: LocationType;
  exposeId?: number;
  exposeFromShortlist?: boolean
  exposeOnlyMode?: boolean
  setExpose?: (expose: Expose | undefined, exposeFromShortlist: boolean | undefined) => void;
  queryType?: 'street' | 'geocode' | 'cityAndStreet' | 'postcode',
  hasDisclaimer?: boolean
}

export default function MapContainer(
  {
    location,
    exposeId,
    exposeFromShortlist,
    exposeOnlyMode = false,
    setLocation,
    setExpose,
    queryType,
    hasDisclaimer
  }: SearchProps) {

  const INITIAL_MAP_ZOOM = 8;
  const LOCATION_MAP_ZOOM = 12;

  const [ propertyLocation, setPropertyLocation ] = useState<LatLngLiteral>();
  const mapRef = useRef<GoogleMap>();
  const center = useMemo<LatLngLiteral>(() => ({ lat: 51.1657, lng: 10.4515 }), []);
  const [ zoom, setZoom ] = useState(INITIAL_MAP_ZOOM);
  const options = useMemo<MapOptions>(() => ({
    styles: MapStyles,
    disableDefaultUI: true,
    zoomControl: false,
    gestureHandling: 'none',
    keyboardShortcuts: false
  }), []);
  const onLoad = useCallback((map: GoogleMap) => { mapRef.current = map }, []);

  function changeLocationWithAnimation(position: LatLng) {
    setPropertyLocation(undefined)
    setPropertyLocation(position)
  }

  const updateLocationOnMap = useCallback(async (location: LocationType) => {
    const results: GeocodeResult[] = await getGeocode({ address: location.locationLabel });
    const position: LatLng = getLatLng(results[ 0 ]);
    const mapPosition = createPositionWithOffset(position)

    mapRef.current?.panTo(mapPosition)

    if (zoom == INITIAL_MAP_ZOOM) {
      setTimeout(() => {
        setZoom(LOCATION_MAP_ZOOM)
      }, 1000);
      setTimeout(() => {
        changeLocationWithAnimation(position)
      }, 2000);
    } else {
      setTimeout(() => {
        changeLocationWithAnimation(position)
      }, 700);
    }
  }, [ zoom ])

  const handleLocation = async (location?: LocationType) => {
    setLocation(location);
    if (location && location.locationLabel) {
      await updateLocationOnMap(location)
    }
  };

  useEffect(() => {
    if (exposeId && !exposeFromShortlist && location?.locationLabel) {
      updateLocationOnMap(location) // just to trigger google-map zoom
    }
  }, [ exposeId, exposeFromShortlist, location, updateLocationOnMap ])

  return (
    <div className='map-container'>
      <Search
        location={location}
        exposeId={exposeId}
        exposeFromShortlist={exposeFromShortlist}
        exposeOnlyMode={exposeOnlyMode}
        setLocation={handleLocation}
        setExpose={setExpose}
        queryType={queryType}
      />
      <GoogleMap
        zoom={zoom}
        center={center}
        mapContainerClassName={`map ${hasDisclaimer ? 'disclaimer-message' : ''}`}
        options={options}
        onLoad={onLoad as unknown as GoogleMapProps['onLoad']}
      >
        {propertyLocation && <MapMarker location={propertyLocation}/>}
      </GoogleMap>
    </div>
  )
}
