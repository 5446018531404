import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../section/Section'
import { Heading4 } from 'is24-corecss'
import NegativeStateItem from './NegativeStateItem'
import bookIcon from '../../../../assets/images/profile/negative-state/book.svg'
import calculatorIcon from '../../../../assets/images/profile/negative-state/calculator.svg'
import moneyboxIcon from '../../../../assets/images/profile/negative-state/moneybox.svg'
import bookIconMobile from '../../../../assets/images/profile/negative-state/book-mobile.svg'
import calculatorIconMobile from '../../../../assets/images/profile/negative-state/calculator-mobile.svg'
import moneyboxIconMobile from '../../../../assets/images/profile/negative-state/moneybox-mobile.svg'
import { AFFORDABILITY_USER_FLOW, PRE_APPROVAL_USER_FLOW } from '../../../../config/contextPaths'
import { OptimizationMessage, OptimizationMessageCode } from '../../../preApproval/resultPage/model/PreApprovalResults'

export type NegativeStateSectionProps = {
  onOffersTab?: boolean
  optimizationMessages?: OptimizationMessage[]
}

const NegativeStateSection: React.FC<NegativeStateSectionProps> = ({
  onOffersTab = false,
  optimizationMessages
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'common.negativeStateSection' })

  const { incomeMesssage, ownCapitalMessage } = useMemo(() => ({
    incomeMesssage: optimizationMessages?.find(m => m.code === OptimizationMessageCode.MONTHLY_INCOME)?.text,
    ownCapitalMessage: optimizationMessages?.find(m => m.code === OptimizationMessageCode.OWN_CAPITAL)?.text
  }), [ optimizationMessages ])

  return (
    <Section title={t('title')}>
      <div className='padding-top-xl palm-padding-top-m'>
        {!onOffersTab && t('description.feasibilityTab')}
        {onOffersTab && <>
          <span className='palm-hide'>{t('description.offersTab')}</span>
          <span className='lap-hide desk-hide'>{t('description.offersTabMobile')}</span>
        </>}
      </div>
      <Heading4 className='padding-top-l palm-padding-top-xl padding-bottom-s'>{t('subtitle')}</Heading4>
      <div className='grid grid-flex gutter'>
        {!onOffersTab && <div className='grid-item one-whole lap-one-third desk-one-third'>
          <NegativeStateItem
            image={calculatorIcon}
            imageMobile={calculatorIconMobile}
            itemName='selectObject'
            url='/'
          />
        </div>}
        {incomeMesssage && <div className='grid-item one-whole lap-one-third desk-one-third'>
          <NegativeStateItem
            image={moneyboxIcon}
            imageMobile={moneyboxIconMobile}
            itemName='income'
            url={PRE_APPROVAL_USER_FLOW + '/einnahmen'}
            optimizationMessage={incomeMesssage}
          />
        </div>}
        {ownCapitalMessage && <div className={`grid-item one-whole lap-one-third desk-one-third ${onOffersTab ? 'order-one-up' : ''}`}>
          <NegativeStateItem
            image={bookIcon}
            imageMobile={bookIconMobile}
            itemName='ownCapital'
            url={PRE_APPROVAL_USER_FLOW + '/eigenkapital'}
            optimizationMessage={ownCapitalMessage}
          />
        </div>}
        {onOffersTab && <div className='grid-item one-whole lap-one-third desk-one-third'>
          <NegativeStateItem
            image={calculatorIcon}
            imageMobile={calculatorIconMobile}
            itemName='affordability'
            url={AFFORDABILITY_USER_FLOW}
          />
        </div>}
      </div>
    </Section>
  )
}

export default NegativeStateSection
