import React from 'react'
import ProvidersComparisonTeaser from './teaser/ProvidersComparisonTeaser'

export const ProvidersComparisonSection: React.FC = () => {

  return (
    <ProvidersComparisonTeaser/>
  )
}

export default ProvidersComparisonSection
