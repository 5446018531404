import { useEffect, useState } from 'react'
import { FinancingResult, FinancingResultError, UserInput, } from '../resultPage/model/FinancingResults'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { calculateAmortization } from '../services/FinancingService'
import { mapToMonthlyRemainingDebtAmounts, mapToYearlyRepayments } from '../resultPage/model/mappings'
import { OnErrorCallback } from './useFinancingProbability'
import { MINIMAL_LOAN_AMOUNT } from './useLoanAmount'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'
import { mapToFixedInterestValue } from '../../../api/utils'

let latestResultVersion = 0

export const useAmortizationCalculation = (userInput: UserInput, loanAmount: number, onError: OnErrorCallback): FinancingResult | undefined => {

  const [ result, setResult ] = useState<FinancingResult | undefined>(undefined)
  const { oneDynamicFlow } = useOneDynamicFlow()

  useEffect(() => {
    if (!!oneDynamicFlow.propertyCost && (!!oneDynamicFlow.location?.geoCode || !!oneDynamicFlow.location?.postalCode) && oneDynamicFlow.employmentType && loanAmount > MINIMAL_LOAN_AMOUNT) {
      const currentResultVersion = ++latestResultVersion
      calculateAmortization({
        purchasePrice: oneDynamicFlow.propertyCost,
        additionalCosts: oneDynamicFlow.brokerCommission ?? 0,
        ownFunds: getTotal(oneDynamicFlow.equityCapital),
        amortizationRate: userInput.amortizationRate,
        geoCode: oneDynamicFlow.location?.geoCode,
        postalCode: oneDynamicFlow.location?.postalCode,
        employmentType: oneDynamicFlow.employmentType,
        fixedRatePeriod: mapToFixedInterestValue(userInput.fixedRateEndYear),
        repaymentAmount: userInput.repaymentAmount,
        repaymentType: userInput.repaymentType,
        repaymentYearOrMonth: userInput.repaymentYearOrMonth,
        financingType: 'PROPERTY_PURCHASE'
      }).then(calculated => {
          if (currentResultVersion === latestResultVersion) {
            setResult({
              totalTimeMonths: calculated.summaryOnDebtEnd?.numberOfInstalments,
              monthlyInstalment: calculated.repaymentSchedule?.length && calculated.repaymentSchedule[ 0 ].instalment,
              averageInterestRate: calculated.averageInterestRate,
              effectiveInterestRate: calculated.effectiveInterestRate,
              loanAmount,
              yearlyRepayments: mapToYearlyRepayments(calculated.repaymentSchedule),
              monthlyRemainingDebtAmounts: mapToMonthlyRemainingDebtAmounts(calculated.repaymentSchedule),
              monthlyInstalmentSum: calculated.summaryOnFixedRateEnd?.totalPaid,
              interestSum: calculated.summaryOnFixedRateEnd?.totalInterestPaid,
              amortizationSum: calculated.summaryOnFixedRateEnd?.totalAmortizationPaid,
              overpaymentSum: calculated.summaryOnDebtEnd.totalOverpayment,
              remainingDebtSum: calculated.summaryOnFixedRateEnd?.remainingLoanAmount,
              fixedRateEndYear: userInput.fixedRateEndYear // to avoid re-rendering of the whole page on input change we copy this to result object
            })
          }
        }
      ).catch(error => {
        console.error(error)
        onError(FinancingResultError.COULD_NOT_CALCULATE)
      })
    }
  }, [ setResult, loanAmount, oneDynamicFlow.propertyCost, oneDynamicFlow.brokerCommission, oneDynamicFlow.equityCapital, userInput.amortizationRate, oneDynamicFlow.location?.geoCode, oneDynamicFlow.location?.postalCode, oneDynamicFlow.employmentType, userInput.fixedRateEndYear, userInput.repaymentAmount, userInput.repaymentType, userInput.repaymentYearOrMonth, onError ])

  return result
}
