import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'

import {
  currentDateAsISOString,
  dateStringBeforeYears,
  formatDateString,
  GERMAN_DATE_FORMAT,
  isDateStringBetween,
  ISO_DATE_FORMAT,
  isValidDateString
} from '../../../../../utils/dateUtils'
import DateInputWithPlaceholder, {
  DateValidationResult
} from '../../../input/dateInputWithPlaceholder/DateInputWithPlaceholder'

export type DateOfBirthProps = Pick<OneDynamicFlowState, 'dateOfBirth'> & {
  onChange: (birthDate: OneDynamicFlowState['dateOfBirth']) => void
} & FooterProps & CloseButtonProps

const isValidDateRange = (dateString: string): boolean =>
  isDateStringBetween(dateString, '1900-01-01', dateStringBeforeYears(currentDateAsISOString(), 18));



export const DateOfBirth: React.FC<DateOfBirthProps> = (
  {
    dateOfBirth,
    onChange,
    onBack,
    onNext,
    progress,
    onClose
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'userFunnel.dateOfBirth' })
  const [ touched, setTouched ] = useState(false)
  const [ valid, setValid ] = useState(true)

  const validateDateString = (dateStringInGermanFormat: string | undefined): DateValidationResult => {
    if (!isValidDateString(dateStringInGermanFormat, GERMAN_DATE_FORMAT)) {
      return {
        isValid: false,
        message: t('errorMessageInvalidFormat')
      }
    }

    const dateString = formatDateString(dateStringInGermanFormat as string, GERMAN_DATE_FORMAT, ISO_DATE_FORMAT)
    if (!isValidDateRange(dateString)) {
      return {
        isValid: false,
        message: t('errorMessageInvalidAge')
      }
    }

    return { isValid: true }
  }
  const validate = useCallback(validateDateString, [ t ])

  return (
    <OneDynamicFlowQuestion
      title={t('title')}
      description={t('description')}
      arrow='long-arrow-bottom-middle-left'
      disabled={!valid}
      onBack={onBack}
      onNext={onNext}
      touched={touched && !!dateOfBirth}
      progress={progress}
      onClose={onClose}
    >
      <div>
        <DateInputWithPlaceholder
          id='dateOfBirth'
          label={t('inputLabel')}
          placeholder={t('placeholder')}
          initialValue={dateOfBirth}
          onChange={onChange}
          validateDateString={validate}
          onTouched={() => setTouched(true)}
          onValidationStatusChanged={isValid => setValid(isValid)}
        />
      </div>
    </OneDynamicFlowQuestion>
  )
}

