import React from 'react'
import ShortlistSection from './shortlistSection/ShortlistSection'
import { StepDescription } from '../../stepDescription/StepDescription'
import { TabId } from '../ProfileTabs'
import CertificateSection from './certificateSection/CertificateSection'
import AdvisorySection from './advisorySection/AdvisorySection'
import AffordabilitySection from './affordabilitySection/AffordabilitySection'
import { Expose } from '../../../../api/api/Expose'
import SelectedObjectSection from '../../common/selectedObjectSection/SelectedObjectSection'
import { PreApprovalResultState } from '../../../preApproval/hook/usePreApprovalResults'

export type FeasibilityTabProps = {
  selectedObject?: Expose
  selectNewObject: () => void
  preApprovalResults: PreApprovalResultState,
}

const FeasibilityTab: React.FC<FeasibilityTabProps> = ({ selectedObject, selectNewObject, preApprovalResults }) =>
  <div>
    <StepDescription className='padding-vertical-l lap-hide desk-hide'
                     stepIndex={0}
                     stepId={TabId.FEASIBILITY_TAB}/>
    {selectedObject && <SelectedObjectSection selectedObject={selectedObject} selectNewObject={selectNewObject}/>}
    {!selectedObject && <ShortlistSection/>}
    {selectedObject && <CertificateSection preApprovalResults={preApprovalResults}/>}
    <AffordabilitySection/>
    <AdvisorySection/>
  </div>

export default FeasibilityTab
