import React, { useEffect } from 'react'
import { Is24Template } from '../../common/snippets/Is24Template'
import { logInCheck } from '../../../redux/actions/Login'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LogInState } from '../../../redux/reducers/Login'

import { withPageTrackingReporting } from '../../mortgageOfficer/hoc/withPageTrackingReporting'
import LoadingPage from '../../common/loadingPage/LoadingPage'
import FinancingResultPage from './FinancingResultPage'
import { useFinancingResults } from '../hook/useFinancingResults'
import { useUserInput } from '../hook/useUserInput'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import FinancingErrorPage from './errorPage/FinancingErrorPage'
import useOneDynamicFlowDataCheck from '../hook/useOneDynamicFlowDataCheck'

const FinancingResultWrapper: React.FC = () => {
  useOneDynamicFlowDataCheck()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logInCheck())
  }, [ dispatch ])

  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)

  const { userInput, changeHandlers } = useUserInput()
  const { loanAmount, result, probability, error } = useFinancingResults(userInput)
  const { oneDynamicFlow } = useOneDynamicFlow()

  return (
    (loggedIn == undefined) ? <LoadingPage/> :
      <Is24Template
        headerType={loggedIn ? 'default' : 'light'}
        backToOverview={true}>

        {((!probability || !result) && !error) && <LoadingPage/>}

        {probability && result && !error &&
          <FinancingResultPage
            oneDynamicFlow={oneDynamicFlow}
            userInput={userInput}
            result={result}
            probability={probability}
            changeHandlers={changeHandlers}/>}

        {error && <FinancingErrorPage error={error} oneDynamicFlow={oneDynamicFlow} loanAmount={loanAmount}/>}
      </Is24Template>
  )
}

export default withPageTrackingReporting(FinancingResultWrapper, 'financingfunnel.resultpage')
