import React from 'react'
import { useTranslation } from 'react-i18next'

type RedistributionComponentType = {
  status?: number;
}

const SUCCESS = 200
const BAD_REQUEST = 400
const NO_CONTACT_REQUEST = 404
const MULTIPLE_CLICKS = 409
const REQUEST_FALLS_OUTSIDE_ALLOWED_PERIOD = 410

const renderMessages = (t: any, status: number): string => {
  switch (status) {
    case SUCCESS: return t('success')
    case BAD_REQUEST:
    case NO_CONTACT_REQUEST: return t('noContactRequest')
    case MULTIPLE_CLICKS: return t('multipleClicks')
    case REQUEST_FALLS_OUTSIDE_ALLOWED_PERIOD: return t('requestOutsideAllowedPeriod')
    default: return ''
  }
}

const Redistribution: React.FC<RedistributionComponentType> =
  ({ status }) => {
    const error = status !== SUCCESS
    const { t } = useTranslation('redistribution')

    return (
      <div className="background-wrapper content-wrapper margin-vertical-l">
        {status && <div className={`status-message status-message--short ${error ? 'status-error' : 'status-confirm'}`}>
          <h4>{t("thankYou")}</h4>
          <p role="status">{renderMessages(t, status)}</p>
        </div>}
      </div>
    )
  }

export default Redistribution
