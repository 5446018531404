import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { AFFORDABILITY_USER_FLOW_RESTART } from '../../../config/contextPaths'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { isDefined, isNumber } from '../../../utils/utils'
import { isValidFloorArea } from '../../common/oneDynamicFlow/pages/size/Size'
import { isValidLocationWithGeoCode } from '../../common/oneDynamicFlow/pages/location/Location'
import { isEquityCapitalValid } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'
import { isValidIncome } from '../../common/oneDynamicFlow/pages/income/Income'
import { isValidExpense } from '../../common/oneDynamicFlow/pages/expense/Expense'

export const isFilledEnoughToShowResults = (oneDynamicFlow: Partial<OneDynamicFlowState>) =>
  isNumber(oneDynamicFlow.numberOfBorrowers) &&
  isNumber(oneDynamicFlow.numberOfChildren) &&
  isValidFloorArea(oneDynamicFlow.floorArea) &&
  isDefined(oneDynamicFlow.ownUse) &&
  isValidLocationWithGeoCode(oneDynamicFlow.location) &&
  isEquityCapitalValid(oneDynamicFlow.equityCapital) &&
  isValidIncome(oneDynamicFlow.income) &&
  isValidExpense(oneDynamicFlow.expense)

const useAffordabilityFlowDataCheck = (initialized: boolean) => {
  const { oneDynamicFlow } = useOneDynamicFlow()
  const navigate = useNavigate()

  useEffect(() => {
    if (initialized  && !isFilledEnoughToShowResults(oneDynamicFlow)) {
      navigate(AFFORDABILITY_USER_FLOW_RESTART, { replace: true })
    }
  }, [ oneDynamicFlow, initialized, navigate ])
}

export default useAffordabilityFlowDataCheck
