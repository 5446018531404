import React from 'react'
import { useTranslation } from 'react-i18next'
import { FINANCING_USER_FLOW } from '../../../../../../config/contextPaths'
import financingCalcImage from '../../../../../../assets/images/profile/financing-calculator.png'
import './FinancingCalculatorTeaser.less'
import Section from '../../../../common/section/Section'

const FinancingCalculatorTeaser: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'conditionsTab.financingCalculatorSection.teaser' })
  return (
    <Section title={t('title')}>
      <div className='FinancingCalculatorTeaser'>
        <img className='FinancingCalcImage' src={financingCalcImage} alt='screenshot'/>
        <div className='TeaserContent'>
          <p className='TeaserText'>{t('text')}</p>
          <a className='TeaserButton button button-primary palm-one-whole' role='button'
             href={FINANCING_USER_FLOW}>{t('goToFlowButton')}</a>
        </div>
      </div>
    </Section>
  )
}

export default FinancingCalculatorTeaser
