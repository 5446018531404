import { useCallback, useEffect } from 'react'
import { LocationQueryType } from '../Location'
import { LocationType } from '../../../../../mortgageOfficer/state/reducer'

export const useResetLocation = (
  queryType: LocationQueryType, onLocationChange: (location?: LocationType) => void, location?: LocationType) => {

  const resetLocation = useCallback(() => {
    if (location?.geoCode || location?.postalCode) {
      if (queryType === LocationQueryType.geocode && location.geoCode == undefined) {
        onLocationChange(undefined)
      } else if (queryType === LocationQueryType.postcode && location.postalCode == undefined) {
        onLocationChange(undefined)
      }
    }
  }, [ location, onLocationChange, queryType ])

  useEffect(() => {
    resetLocation()
  }, [ resetLocation ]);

}
export default useResetLocation
