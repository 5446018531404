import React from 'react'
import './ProviderLogo.less'

export interface ProviderLogoProps {
  providerName: string
}

const ProviderLogo: React.FC<ProviderLogoProps> = ({ providerName }) => {
  return (
    <div className='ProviderLogo'>
      <img src={`https://www.europace2.de/produktanbieter-logos/logo/${providerName}.svg`} alt={providerName}/>
    </div>
  )
}

export default ProviderLogo
