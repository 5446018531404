import { useEffect, useState } from 'react'
import { getAdditionalCostByGeocode, getAdditionalCostByPostalCode } from '../services/FinancingService'
import { AdditionalCostAO } from '../../../api/api/types/UserAffordabilityResultAO'

const getAdditionalCost = (postalCode: string | undefined, geoCode: string | undefined): Promise<AdditionalCostAO> =>
  postalCode ? getAdditionalCostByPostalCode(postalCode) : getAdditionalCostByGeocode(geoCode!)

export const useAdditionalCostDefaults = (postalCode: string | undefined, geoCode: string | undefined): AdditionalCostAO | undefined => {

  const [ additionalCost, setAdditionalCost ] = useState<AdditionalCostAO>()

  useEffect(() => {
    (postalCode || geoCode) && getAdditionalCost(postalCode, geoCode)
      .then(setAdditionalCost)
      .catch(error => {
        console.warn('Couldn\'t fetch additional cost!', error)
      });
  }, [ setAdditionalCost, postalCode, geoCode ])

  return additionalCost
}
