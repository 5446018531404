import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TrackingService from '../../../../../services/TrackingService/TrackingService'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../../../config/optimizely'

import { MortgageOfficer } from '../../../state/reducer'
import { Calender } from '../calender/Calender'
import { FinancingStart } from '../financingStart/FinancingStart'
import Pager, { Page } from '../Pager'
import BackButton from './BackButton'
import { updateDialog } from '../../../state/actions'
import {
  createAndValidateContactRequest,
  createContactRequest,
  preCheckContactRequest
} from '../../../state/thunks/thunks'

import { FinancingType } from '../financingType/FinancingType'
import Location from '../location/Location'
import { PersonalDetails } from '../personalDetails/PersonalDetails'
import { ProgressBar } from 'is24-corecss'
import { FinancingProjectStatePage } from '../financingProjectStatePage/FinancingProjectStatePage'
import { NetLoan } from '../netLoan/NetLoan'
import { EmploymentDialog } from '../employment/EmploymentDialog'
import { ContactRequestErrorType } from '../../../../../api/api/ContactRequestExceptions'
import { Loading } from '../../../../common/loading/Loading'

import './mortgageOfficerDialog.less'
import { IncomeDialog } from '../income/IncomeDialog'
import { FinancingTypeValue } from '../../../../../api/api/types/FinancingType';
import { FollowupFinancingData } from '../followupFinancingData/FollowupFinancingData';
import { goToFlowPage, navigateOnIncomePageChange } from '../../../routing'
import { usePreconfiguredFlowFromSearchParams } from '../../../hook/usePreconfiguredFlowFromSearchParams'
import { useUserData } from '../../../../../hooks/useUserData'

const TRACKING_ROOT = 'mortgageofficer.'
const CALENDLY_FIRST_PAGE = 'appointmenttype'

export const generateTrackingKey = (pageIndex: MortgageOfficerDialogPage): string =>
  TRACKING_ROOT + pageTrackingParameters[ pageIndex ]

export const MortgageOfficerDialogPages = {
  financingStart: 'finanzierungsstart',
  financingType: 'finanzierungsart',
  locationDialog: 'finanzierungsort',
  followupFinancingData: 'anschlussfinanzierung',
  netLoan: 'nettodarlehen',
  employment: 'beschaeftigungsart',
  income: 'einkommen',
  personalDetails: 'kontaktdetails',
  projectState: 'projektstatus',
  calender: 'kalender'
} as const
export type MortgageOfficerDialogPage = typeof MortgageOfficerDialogPages[keyof typeof MortgageOfficerDialogPages]

const pageTrackingParameters: Record<MortgageOfficerDialogPage, string> = {
  finanzierungsstart: 'financingstart',
  finanzierungsart: 'financingtype',
  finanzierungsort: 'financingobjectlocation',
  anschlussfinanzierung: 'anschlussfinanzierung',
  nettodarlehen: 'financingterms',
  beschaeftigungsart: 'employment',
  einkommen: 'income',
  kontaktdetails: 'personaldata',
  projektstatus: 'projectstate',
  kalender: 'calender'
} as const

export type Props = {
  mortgageOfficer: MortgageOfficer,
  errors?: ContactRequestErrorType[],
  isFetching: boolean,
  meetingLinkWithEncryptedContactRequest?: string
}

export const MortgageOfficerDialog: React.FC<Props> = ({ mortgageOfficer, errors, isFetching, meetingLinkWithEncryptedContactRequest }) => {
  const [ calendlyPage, setCalendlyPage ] = useState('')
  const [ calendlyCounter, setCalendlyCounter ] = useState(0)
  const { page } = useParams<{ page: MortgageOfficerDialogPage }>()
  const [ netIncomeFilterRemoved ] = useFeature(Feature.REMOVE_NET_INCOME_FILTER)
  const [ addAdvisorEmailToContactRequest ] = useFeature(Feature.ADD_ADVISOR_EMAIL_TO_CONTACT_REQUEST)
  const isExcludedFromPageView = (page: MortgageOfficerDialogPage | undefined) => MortgageOfficerDialogPages.calender === page

  useEffect(() => {
    if (isExcludedFromPageView(page)) return
    page && TrackingService.reportPageView(generateTrackingKey(page))
  }, [ page ])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userData = useUserData()

  useEffect(() => {
    if (userData != undefined) {
      dispatch(updateDialog({
        personalDetails: {
          firstName: userData.userContactDetails?.firstname ?? '',
          lastName: userData.userContactDetails?.lastname ?? '',
          streetName: userData.userContactDetails?.address?.street ?? '',
          streetNumber: userData.userContactDetails?.address?.houseNumber ?? '',
          postalCode: userData.userContactDetails?.address?.postcode ?? '',
          city: userData.userContactDetails?.address?.city ?? '',
          email: userData.userContactDetails?.email ?? '',
          phoneNumber: userData.userContactDetails?.cellPhoneNumber ?? userData.userContactDetails?.phoneNumber ?? ''
        }
      }))
    }
  }, [ userData, dispatch ]);

  const onCalenderPageView = (pageName: string) => {
    setCalendlyPage(pageName)
    setCalendlyCounter(calendlyCounter + 1)
    const trackingPath = generateTrackingKey(MortgageOfficerDialogPages.calender)
    TrackingService.reportPageView(`${trackingPath}.${pageName}`)
  }

  const { financingTypeProvided, postalCodeProvided } = usePreconfiguredFlowFromSearchParams()

  const pageAfterFinancingStart =
    mortgageOfficer.financingType === FinancingTypeValue.FollowupFinancing
      ? (!postalCodeProvided ? MortgageOfficerDialogPages.locationDialog : MortgageOfficerDialogPages.followupFinancingData)
      : MortgageOfficerDialogPages.projectState

  const pageAfterProjectState = !postalCodeProvided ? MortgageOfficerDialogPages.locationDialog : MortgageOfficerDialogPages.followupFinancingData

  const pages: Page[] = [
    !financingTypeProvided && {
      page: MortgageOfficerDialogPages.financingType,
      component:
          <FinancingType
              financingType={mortgageOfficer.financingType}
              onChange={financingType => {
                dispatch(updateDialog({ financingType }))
                goToFlowPage(MortgageOfficerDialogPages.financingStart, navigate)
              }}
          />
    },
    {
      page: MortgageOfficerDialogPages.financingStart,
      component:
        <FinancingStart
          financingStart={mortgageOfficer.financingStart}
          financingType={mortgageOfficer.financingType}
          errors={errors}
          onChange={financingStart => {
            dispatch(updateDialog({ financingStart }))
            goToFlowPage(pageAfterFinancingStart, navigate)
          }}
        />
    },
    {
      page: MortgageOfficerDialogPages.projectState,
      component:
        <FinancingProjectStatePage
          initialValue={mortgageOfficer.projectState}
          financingType={mortgageOfficer.financingType}
          onChange={projectState => {
            dispatch(updateDialog({ projectState }))
            goToFlowPage(pageAfterProjectState, navigate)
          }}
        />
    },
    !postalCodeProvided && {
      page: MortgageOfficerDialogPages.locationDialog,
      component:
        <Location
          location={mortgageOfficer.location}
          onChange={location => dispatch(updateDialog({ location }))}
          onClick={() => goToFlowPage(mortgageOfficer.financingType === FinancingTypeValue.FollowupFinancing ? MortgageOfficerDialogPages.followupFinancingData : MortgageOfficerDialogPages.netLoan, navigate)}
        />
    },
    {
      page: MortgageOfficerDialogPages.followupFinancingData,
      component:
          <FollowupFinancingData
              followupFinancingData={mortgageOfficer.followupFinancingData}
              onChange={followupFinancingData => dispatch(updateDialog({ followupFinancingData }))}
              onClick={() => goToFlowPage(MortgageOfficerDialogPages.employment, navigate)}
          />
    },
    {
      page: MortgageOfficerDialogPages.netLoan,
      component:
        <NetLoan
          netLoan={mortgageOfficer.netLoan}
          onChange={netLoan => dispatch(updateDialog({ netLoan }))}
          onClick={() => goToFlowPage(MortgageOfficerDialogPages.employment, navigate)}
        />
    },
    {
      page: MortgageOfficerDialogPages.employment,
      component:
        <EmploymentDialog
          employment={mortgageOfficer.employment}
          onChange={employment => dispatch(updateDialog({ employment }))}
          onClick={() => goToFlowPage(MortgageOfficerDialogPages.income, navigate)}
        />
    },
    {
      page: MortgageOfficerDialogPages.income,
      component:
        <IncomeDialog
          netIncomeRange={mortgageOfficer.netIncomeRange}
          onClick={netIncomeRange => {
            dispatch(updateDialog({ netIncomeRange }))
            navigateOnIncomePageChange(MortgageOfficerDialogPages.personalDetails, netIncomeRange,
                mortgageOfficer.financingType === FinancingTypeValue.FollowupFinancing ? mortgageOfficer.followupFinancingData!.remainingDebt : mortgageOfficer.netLoan!.purchasePrice - mortgageOfficer.netLoan!.ownFunds,
                netIncomeFilterRemoved, navigate)
          }}
        />
    },
    {
      page: MortgageOfficerDialogPages.personalDetails,
      component:
        <PersonalDetails
          personalDetails={mortgageOfficer.personalDetails}
          errors={errors}
          onChange={personalDetails => dispatch(updateDialog({ personalDetails }))}
          onClick={() => addAdvisorEmailToContactRequest ? dispatch(createAndValidateContactRequest(navigate)) : dispatch(preCheckContactRequest(navigate))}
        />
    },
    {
      page: MortgageOfficerDialogPages.calender,
      component:
        <Calender
          calendlyURL={meetingLinkWithEncryptedContactRequest}
          personalDetails={mortgageOfficer.personalDetails}
          onSubmit={() => dispatch(createContactRequest())}
          onPageView={onCalenderPageView}
        />
    }
  ].filter(Boolean) as Page[]

  const showProgressBackButton = () => {
    const isLastPage = pageIndex === pages.length - 1
    if (pageIndex === 0) return false
    return !isLastPage || calendlyPage === CALENDLY_FIRST_PAGE
  }

  const onClickProgressBackButton = () => {
    if (calendlyPage === CALENDLY_FIRST_PAGE) {
      navigate(-calendlyCounter)
      setCalendlyCounter(0)
    } else {
      navigate(-1)
    }
  }

  const pageIndex = pages.findIndex(e => e.page === page)
  const progress = ((pageIndex + 1) / pages.length) * 100

  return (
    <div className='MortgageOfficerDialog'>
      <div className='ProgressBar'>
        <ProgressBar progress={progress}/>
      </div>
      {isFetching
        ? <Loading size='large' className='one-whole align-center screen-top margin-top-xxl padding-top-xxl'/>
        :
        <>
          {showProgressBackButton() && <BackButton goToPrevious={onClickProgressBackButton}/>}
          <Pager page={page ?? ''} pages={pages}/>
        </>
      }
    </div>
  )
}
