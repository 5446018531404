import React from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../../../../common/section/Section'
import './ProvidersComparisonTeaser.less'
import providersComparisonImage from '../../../../../../assets/images/profile/providers-comparison.png'
import { PROVIDER_COMPARISON_PAGE } from '../../../../../../config/contextPaths'

const ProvidersComparisonTeaser: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'conditionsTab.providersComparisonSection.teaser' })

  return (
    <Section title={t('title')} borderBottom={false}>
      <div className='ProvidersComparisonTeaser'>
        <div className='ImageContainer'>
          <div className='Image'>
            <img src={providersComparisonImage} alt='screenshot'/>
          </div>
        </div>
        <div className='DescriptionContainer'>
          <div>{t('text')}</div>
        </div>
        <div className='ButtonContainer'>
          <a
            className='button-inverted'
            href={PROVIDER_COMPARISON_PAGE}>
            {t('goToProvidersComparison')}
          </a>
        </div>
      </div>
    </Section>
  )
}

export default ProvidersComparisonTeaser
