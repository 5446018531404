import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../../common/oneDynamicFlow/section/Section'
import GuideSection from './guideSection/GuideSection'
import AmortizationChart from './amortizationChart/AmortizationChart'
import ActionButtons from './actionButtons/ActionButtons'
import SampleOffer from './sampleOffer/SampleOffer'
import LoanDevelopmentSummary from './loanDevelopment/LoanDevelopmentSummary'
import FinancingCost from './financingCost'
import Settings from './settings/Settings'
import RepaymentSchedule from './repaymentSchedule/RepaymentSchedule'
import { FinancingResult } from './model/FinancingResults'
import ProfileButtonWrapper from '../../common/profileButtonWrapper/ProfileButtonWrapper'
import { UseUserInputReturnType } from '../hook/useUserInput'
import { LeadEngineButton } from './leadEngineButton/LeadEngineButton'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import ProposalsTouchPoint from '../../preApproval/proposalsTouchpoint/ProposalsTouchPoint'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../config/optimizely'
import { FinancingProbability } from '../../../api/preApproval/FinancingProbability'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'

export type FinancingResultPageProps = {
  oneDynamicFlow: OneDynamicFlowState
  result: FinancingResult
  probability: FinancingProbability
 } & UseUserInputReturnType

export type FinancingODFModel = {
  propertyCost?: number
  additionalCosts: number
  ownCapital: number
  geoCode?: string
}

export const getODFModel = (oneDynamicFlow: OneDynamicFlowState): FinancingODFModel => ({
  propertyCost: oneDynamicFlow.propertyCost,
  additionalCosts: oneDynamicFlow.brokerCommission ?? 0,
  ownCapital: getTotal(oneDynamicFlow.equityCapital),
  geoCode: oneDynamicFlow.location?.geoCode
})

// TODO: BAU-8390 Hidden until Lead Engine flow moved to ODF
const SHOW_LEAD_ENGINE_BUTTON = false;

const FinancingResultPage: React.FC<FinancingResultPageProps> = ({
  oneDynamicFlow,
  userInput,
  result,
  probability,
  changeHandlers
}) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage' })

  const odfModel = useMemo(() => getODFModel(oneDynamicFlow), [ oneDynamicFlow ])
  const [ showProposalsTouchPoint ] = useFeature(Feature.SHOW_EUROPACE_PROPOSALS_ON_PREAPPROVAL_RESULT_PAGE)

  return (
    <div>
      <GuideSection probability={probability}/>
      <ActionButtons/>
      <Section title={t('loanOverviewSection.title')}>
        <SampleOffer
          monthlyInstalment={result.monthlyInstalment}
          fixedInterestRate={result.averageInterestRate}
          effectiveInterestRate={result.effectiveInterestRate}
          fixedRateEndYear={result.fixedRateEndYear}
        />
        <Settings
          amortizationRate={userInput.amortizationRate}
          onAmortizationRateChange={changeHandlers.onAmortizationRateChange}
          fixedRateEndYear={userInput.fixedRateEndYear}
          onFixedRateEndYearChange={changeHandlers.onFixedRateEndYearChange}
          loanAmount={result.loanAmount}
          repaymentAmount={userInput.repaymentAmount}
          onRepaymentAmountChange={changeHandlers.onRepaymentAmountChange}
          repaymentType={userInput.repaymentType}
          repaymentYearOrMonth={userInput.repaymentYearOrMonth}
          onRepaymentTypeChange={changeHandlers.onRepaymentTypeChange}
        />
        <AmortizationChart
          monthlyRemainingDebtAmounts={result.monthlyRemainingDebtAmounts}
          fixedRateEndYear={result.fixedRateEndYear}
        />
      </Section>
      <Section title={t('loanDevelopmentSection.title')}>
        <LoanDevelopmentSummary
          netLoan={result.loanAmount}
          totalTimeMonths={result.totalTimeMonths}
          remainingDebt={result.remainingDebtSum}
        />
        <FinancingCost
          totalPaid={result.monthlyInstalmentSum}
          totalInterestPaid={result.interestSum}
          totalAmortizationPaid={result.amortizationSum}
          remainingLoanAmount={result.remainingDebtSum}
        />
        {useMemo(() => <RepaymentSchedule
          yearlyRepayments={result.yearlyRepayments}
          yearlyInstalmentSum={result.monthlyInstalmentSum}
          yearlyInterestSum={result.interestSum}
          yearlyAmortizationSum={result.amortizationSum}
          yearlyOverpaymentSum={result.overpaymentSum}
          yearlyRemainingDebtSum={result.remainingDebtSum}
          fixedRateEndYear={result.fixedRateEndYear}
        />, [result.yearlyRepayments, result.monthlyInstalmentSum, result.interestSum, result.amortizationSum, result.overpaymentSum, result.remainingDebtSum, result.fixedRateEndYear])}
        {showProposalsTouchPoint ? <ProposalsTouchPoint/> : undefined}
        <ProfileButtonWrapper
          button={SHOW_LEAD_ENGINE_BUTTON ?
            <LeadEngineButton
              geoCode={odfModel.geoCode}
              purchasePrice={odfModel.propertyCost}
              additionalCosts={odfModel.additionalCosts}
              ownFunds={odfModel.ownCapital}
              remainingDebt={result.remainingDebtSum}
            /> : undefined
          }/>
      </Section>
    </div>
  )
}

export default FinancingResultPage
