import React from 'react'
import BreadcrumbNavigation from '../../../common/breadcrumb/BreadcrumbNavigation'
import { MORTGAGE_PROFILE, PRE_APPROVAL_USER_FLOW_RESTART } from '../../../../config/contextPaths'
import { useTranslation } from 'react-i18next'

const PreApprovalBreadcrumb: React.FC = () => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.breadcrumb' })

  const items = [
    {
      text: t('myProfile'),
      href: MORTGAGE_PROFILE
    },
    {
      text: t('applyForCertificate'),
      href: PRE_APPROVAL_USER_FLOW_RESTART
    },
    {
      text: t('myCertificate')
    }
  ]

  return (
    <div className='pre-approval-breadcrumb padding-top-s palm-padding-top-none'>
      <BreadcrumbNavigation items={items}/>
    </div>
  )
}

export default PreApprovalBreadcrumb
