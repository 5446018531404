import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { NumberInput } from '../../../input/numericInput'
import './OtherRemainingDebts.less'

export type OtherRemainingDebtsProps = Pick<OneDynamicFlowState, 'otherRemainingDebts'> & {
  children?: React.ReactNode
  onChange: (otherRemainingDebts: number) => void
} & FooterProps & CloseButtonProps

const MAX_VALUE = 99_999_999

export const validate = (value?: number) => {
  return value !== undefined && value >= 0 && value <= MAX_VALUE
}

export const OtherRemainingDebts: React.FC<OtherRemainingDebtsProps> = (
  {
    children,
    otherRemainingDebts = 0,
    onChange,
    onBack,
    onNext,
    progress,
    onClose
  }) => {

  const { t } = useTranslation('odf', { keyPrefix: 'userFunnel.otherRemainingDebts' })
  const [ validateResult, setValidateResult ] = useState(validate(otherRemainingDebts))

  const onOtherRemainingDebtsChange = (value: number) => {
    onChange(value)
    setValidateResult(validate(value))
  }

  const handleOnNext = () => {
    if (validateResult){
      onChange(otherRemainingDebts)
      onNext?.()
    }
  }

  return (
    <OneDynamicFlowQuestion
      title={t('title')}
      description={t('description')}
      arrow='long-arrow-top-right'
      disabled={!validateResult}
      onBack={onBack}
      onNext={handleOnNext}
      progress={progress}
      onClose={onClose}
    >
      <div className='other-remaining-debts-container'>
        <div className='input-container'>
          <NumberInput
            id='other-remaining-debts'
            label={t('inputLabel')}
            value={otherRemainingDebts}
            onChange={(value) => onOtherRemainingDebtsChange(value)}
            valid={validateResult}
          />
          <div className='font-error absolute-content margin-top-xs'>
            {!validateResult && t('errorMessage')}
          </div>
        </div>
        <div>
          {children}
        </div>
      </div>
    </OneDynamicFlowQuestion>
  )
}
