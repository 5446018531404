import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import { useNavigate } from 'react-router-dom'
import {
  FINANCING_USER_FLOW,
  MORTGAGE_PROFILE,
  MORTGAGE_PROFILE_CONDITIONS_TAB_PATH,
  RESTART_FLOW_PATH
} from '../../../../config/contextPaths'

const ActionButtons: React.FC = () => {
  const { t } = useTranslation('odf')
  const navigate = useNavigate()

  const restartFlowHandler = useCallback(() => navigate(`${FINANCING_USER_FLOW}/${RESTART_FLOW_PATH}`),
    [navigate])

  return (
    <div className='grid one-whole'>
      <div className='grid-item desk-one-half palm-hide lap-hide'>&nbsp;</div>
      <div className='grid-item grid grid-flex desk-flex-nowrap padding-bottom-xl desk-padding-bottom-none desk-one-half desk-padding-left-xl '>
        <Button className='palm-one-whole palm-margin-top-l' onClick={restartFlowHandler}>
          {t('financingFunnel.resultPage.actionButtons.restartFlow')}
        </Button>
        <a role='button'
          className='button-secondary palm-one-whole desk-margin-left-l lap-margin-left-l palm-order-one-up palm-margin-bottom-l'
          href={`${MORTGAGE_PROFILE}${MORTGAGE_PROFILE_CONDITIONS_TAB_PATH}`}>
          {t('financingFunnel.resultPage.actionButtons.goToProfile')}
        </a>
      </div>
    </div>
  )
}

export default ActionButtons
