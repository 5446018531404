import React, { useEffect } from 'react'
import './TotalCostDonutChart.less'
import ReactApexChart from 'react-apexcharts';
import { ChartOptions } from './chartOptions/ChartOptions'
import { useTranslation } from 'react-i18next'
import ApexCharts from 'apexcharts'

interface ChartProps {
  maxLoan: number
  ownCapital: number
  totalCost: number
  profileStyling?: boolean
}

const TotalCostDonutChart = ({ maxLoan, ownCapital, totalCost, profileStyling = false }: ChartProps) => {
  const { t } = useTranslation('odf')
  const initialOptions = ChartOptions(totalCost, t, profileStyling);
  const series = [ ownCapital, maxLoan ];
  const height = '360';
  const width = profileStyling ? '325' : '375';

  useEffect(() => {
    if (totalCost) {
      ApexCharts.getChartByID('total-cost-chart')?.updateOptions(ChartOptions(totalCost, t, profileStyling), true, true);
    }
  }, [ totalCost, t, profileStyling ])

  return (
    <div className='chart-container'>
      <div className='chart'>
        {totalCost ?
          <ReactApexChart
            options={initialOptions}
            series={series}
            type='donut'
            height={height}
            width={width}
          />
          : <div className='plug'/>
        }
      </div>
    </div>
  )
}

export default TotalCostDonutChart;
