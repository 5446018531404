import { useURLSearchParams } from '../../../hooks/useURLSearchParams'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { updateDialog } from '../state/actions'
import { parseFinancingType } from '../../../api/api/types/FinancingType'
import { useLocation } from 'react-router-dom'
import { MortgageOfficerDialogPages } from '../userFlow/pages/mortgageOfficerDialog/MortgageOfficerDialog'
import { isFiveDigitsString } from '../../../validations/validations'

export const usePreconfiguredFlowFromSearchParams = () => {
  const location = useLocation()
  const urlSearchParams = useURLSearchParams();
  const dispatch = useDispatch();

  const isOnFinancingTypePage = location.pathname.includes(MortgageOfficerDialogPages.financingType);
  const financingType = parseFinancingType(urlSearchParams.get('financingType') ?? '');
  const propertyValue = parseInt(urlSearchParams.get('propertyValue') ?? '0');
  const isOnLocationPage = location.pathname.includes(MortgageOfficerDialogPages.locationDialog);
  const postalCode = urlSearchParams.get('postalCode');

  useEffect(() => {
    if (financingType) {
      dispatch(updateDialog({ financingType: financingType }));
    }
    if (propertyValue) {
      dispatch(updateDialog({ followupFinancingData: { remainingDebt: 0, propertyValue: propertyValue } }));
    }
    if (postalCode && isFiveDigitsString(postalCode)) {
      dispatch(updateDialog({ location: { postalCode: postalCode } }));
    }
  }, [ dispatch, financingType, postalCode, propertyValue ]);

  return {
    financingTypeProvided: !!financingType && !isOnFinancingTypePage,
    postalCodeProvided: !!postalCode && !isOnLocationPage && isFiveDigitsString(postalCode),
  };
};
