import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { extractFlowDataWithLocationFrom } from '../services/FinancingService'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { fetchExposeEntity } from '../../../api/api/Expose'

const EXPOSE_ID_QUERY_PARAM = 'exposeId'

export type UseExposeFromQueryParamReturn = {
  done: boolean
}

enum InternalState {
  INITIAL,
  NOTHING_TO_LOAD,
  LOADING,
  LOADED
}

export const useExposeFromQueryParam = (readyToLoadExpose: boolean): UseExposeFromQueryParamReturn => {
  const [ state, setState ] = useState(InternalState.INITIAL)
  const { search } = useLocation()
  const navigate = useNavigate()
  const { setOneDynamicFlow } = useOneDynamicFlow()

  const increaseState = useCallback((newState: InternalState) =>
    setState(prevState => Math.max(newState, prevState)), [ setState ])

  useEffect(() => {
    if (readyToLoadExpose) {
      const queryParams = new URLSearchParams(search)
      if (queryParams.has(EXPOSE_ID_QUERY_PARAM)) {
        const exposeId = queryParams.get(EXPOSE_ID_QUERY_PARAM)
        queryParams.delete(EXPOSE_ID_QUERY_PARAM)
        navigate({ search: queryParams.toString() }, { replace: true })
        if (exposeId && !isNaN(+exposeId)) {
          increaseState(InternalState.LOADING)
          fetchExposeEntity(+exposeId)
            .then(expose => expose && setOneDynamicFlow(extractFlowDataWithLocationFrom(expose, false)))
            .catch(console.error)
            .finally(() => increaseState(InternalState.LOADED))
        }
      }
      increaseState(InternalState.NOTHING_TO_LOAD)
    }
  }, [ readyToLoadExpose, search, navigate, setOneDynamicFlow, increaseState ])

  return useMemo(() => ({
    done: state === InternalState.NOTHING_TO_LOAD || state === InternalState.LOADED
  }), [ state ])
}
