import React, { useCallback } from 'react'
import { ShortlistEntryAO } from '../../../../../../api/api/types/ShortlistEntryAO'
import './ShortlistTile.less'
import { Button } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { PRE_APPROVAL_USER_FLOW } from '../../../../../../config/contextPaths'
import ShortlistImage from './parts/ShortlistImage'
import ShortlistHeader from './parts/ShortlistHeader'
import ShortlistAttributes from './parts/ShortlistAttributes'
import { CERTIFICATE_MODE_URL_PARAM } from '../../../../../preApproval/hook/usePreApprovalResultPageUrl'
import { useNavigate } from 'react-router-dom'

export type ShortlistTileProps = {
  shortlistEntry: ShortlistEntryAO,
  previewMode?: boolean
}

const ShortlistTile: React.FC<ShortlistTileProps> = ({ shortlistEntry, previewMode = false }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.shortlistSection.shortlistTile' })
  const navigate = useNavigate()

  const onEntrySelected = useCallback(() =>
      navigate(`${PRE_APPROVAL_USER_FLOW}?exposeId=${shortlistEntry.exposeId}&${CERTIFICATE_MODE_URL_PARAM}`),
    [ navigate, shortlistEntry.exposeId ])

  return (
    <div className={`ShortlistTile border flex-content absolute-reference ${previewMode ? 'preview-mode' : ''}`}>
      <ShortlistImage shortlistEntry={shortlistEntry}/>
      <div className='criteria margin-horizontal shortlist-preview-margin-horizontal absolute-reference'>
        <div className='content'>
          <ShortlistHeader shortlistEntry={shortlistEntry}/>
          <ShortlistAttributes shortlistEntry={shortlistEntry}/>
        </div>
        {!previewMode &&
          <div className='grid padding-top-xl padding-bottom-xl center'>
            <Button appearance='secondary' onClick={onEntrySelected}>{t('goToPreApprovalButton')}</Button>
          </div>
        }
      </div>
    </div>
  )
}

export default ShortlistTile
