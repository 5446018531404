import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, TabsContainer } from 'is24-corecss'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../../../../config/optimizely'
import { Region, RegionSelection } from '../../../../input/regionSelection/RegionSelection'
import { LocationType } from '../../../../../mortgageOfficer/state/reducer'
import { createLocation, validateRegion } from './MapUtils'
import ShortlistSelection from './ShortlistSelection'
import './search.less'
import { Expose } from '../../../../../../api/api/Expose'
import useShortlistTabPreselection from './useShortlistTabPreselection'
import { isDefined } from '../../../../../../utils/utils'

type SearchProps = {
  location?: LocationType;
  exposeId?: number;
  exposeFromShortlist?: boolean
  exposeOnlyMode?: boolean
  setLocation: (location?: LocationType) => void;
  setExpose?: (expose: Expose | undefined, exposeFromShortlist: boolean | undefined) => void;
  queryType? : 'street' | 'geocode' | 'cityAndStreet' | 'postcode'
};
export default function Search({ setLocation, location, setExpose, exposeId, exposeFromShortlist, exposeOnlyMode = false, queryType }: SearchProps) {
  const [ isShortlistFeatureEnabled ] = useFeature(Feature.ODF_LOCATION_SHORTLIST)
  const [ showErrors, setShowErrors ] = useState(false);
  const { t } = useTranslation('odf', { keyPrefix: 'userFunnel.location'});
  const { initialized, preselectShortlistTab } = useShortlistTabPreselection()

  const handleRegionSelect = async (region: Region) => {
    const regionValid = validateRegion(region);
    setShowErrors(!regionValid);
    if (regionValid) {
      setLocation(createLocation(region));
      setExpose?.(undefined, undefined)
    }
  };

  const handleExposeSelect = (location: LocationType | undefined, expose: Expose | undefined, exposeFromShortlist: boolean | undefined) => {
    setLocation(location)
    setExpose?.(expose, exposeFromShortlist)
  }

  // Don't show the tab panel when feature is disabled
  if (!isShortlistFeatureEnabled) {
    return (
      <div className='region-search-container' data-testid='region-search-container'>
        <RegionSelection
          onRegionSelect={handleRegionSelect}
          value={location?.locationLabel}
          valid={!showErrors}
          errorMessage={t('region_tab.errorMessage')}
        />
      </div>
    )
  }
  const shortlistTabIndex = (exposeOnlyMode ? 0 : 1)
  const defaultSelectedTab = preselectShortlistTab || isDefined(exposeId) ? shortlistTabIndex : 0

  return (
    <div className='search-container' data-testid='search-container'>
      {initialized && <TabsContainer defaultSelected={defaultSelectedTab}>
        <div className='overflow-hidden padding-bottom-s'>
          <div className='search-tab-list-wrapper padding-top-s padding-horizontal-s'>
            <TabList aria-label='Tab selection for region search'>
              {!exposeOnlyMode &&
                <Tab>{t('region_tab.title')}</Tab>}
              <Tab>{t('shortlist_tab.title')}</Tab>
            </TabList>
          </div>
        </div>
        <div className='one-whole padding-top-m padding-horizontal-l padding-bottom-m'>
          {!exposeOnlyMode &&
            <TabPanel>
              <RegionSelection
                onRegionSelect={handleRegionSelect}
                value={location?.locationLabel}
                valid={!showErrors}
                errorMessage={t('region_tab.errorMessage')}
                queryType={queryType === 'geocode' ? 'city' : queryType}
              />
            </TabPanel>
          }
          <TabPanel>
            <ShortlistSelection exposeId={exposeId} exposeFromShortlist={exposeFromShortlist} onExposeSelect={handleExposeSelect}/>
          </TabPanel>
        </div>
      </TabsContainer>
      }
    </div>
  )
}
