import React from 'react'
import Section from '../../../common/section/Section'
import { useTranslation } from 'react-i18next'
import officerImg from '../../../../../assets/images/officer-photos/andreas_circle.png'
import './AdvisorySection.less'
import { Button } from 'is24-corecss'
import { MORTGAGE_OFFICER_USER_FLOW_START } from '../../../../../config/contextPaths'

export const AdvisorySection: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.advisorySection' })

  return (
    <Section title={t('title')} borderBottom={false}>
      <div className='AdvisorySection'>
        <div className='AvatarContainer'>
          <div className='Avatar'>
            <img src={officerImg} alt={t('jobPosition')}/>
          </div>
          <div className='font-center'>
            <div className='font-s font-bold'>{t('officerName')}</div>
            <div className='font-xs'>{t('jobPosition')}</div>
          </div>
        </div>
        <div className='DescriptionContainer'>
          <div>{t('description')}</div>
          <div className='Button'>
            <a href={MORTGAGE_OFFICER_USER_FLOW_START}>
              <Button className='palm-one-whole' appearance='ghost'>{t('advisoryButton')}</Button>
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AdvisorySection
