import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { isUndefined } from '../../../../../utils/utils'
import { ButtonGroup } from '../../wizard/common/buttonGroup/ButtonGroup'
import { AgeGroupType, AgeGroupValues } from '../../../../../api/api/types/AgeGroup'

type Props =
  Pick<OneDynamicFlowState, 'ageGroupType'> & {
  onClick: (ageGroupType: AgeGroupType) => void
}

export const AgeGroup: React.FC<Props & FooterProps & CloseButtonProps> = (
  {
    ageGroupType,
    onClick,
    onBack,
    onNext,
    progress,
    onClose
  }) => {

  const { t } = useTranslation('odf')
  return (
    <OneDynamicFlowQuestion
      title={t('financingFunnel.ageGroup.title')}
      description={t('financingFunnel.ageGroup.description')}
      arrow='long-arrow-bottom-middle-left'
      disabled={isUndefined(ageGroupType)}
      onBack={onBack}
      onNext={onNext}
      progress={progress}
      onClose={onClose}
    >
      <ButtonGroup<AgeGroupType>
        value={ageGroupType}
        onClick={onClick}
        values={AgeGroupValues}
        translationRoute={'odf:financingFunnel.ageGroup'}
      />
    </OneDynamicFlowQuestion>
  )
}

