import React from 'react'
import ProviderLogo from '../../providerLogo/ProviderLogo'
import { Proposal } from '../../../model/PreApprovalResults'
import './ProposalLogos.less'

export type ProposalLogosProps = {
  proposal: Proposal
}

const ProposalLogos: React.FC<ProposalLogosProps> = ({ proposal }) => {
  // TODO should we use "und" instead of "," for the last element?
  const providerNames = proposal.financingProducts?.map(product => product.providerName).join(', ') ?? ''
  return (
    <div className='ProposalLogos'>
      <div className='ProposalLogoGroup'>
        {proposal.financingProducts?.map(product =>
          <ProviderLogo key={product.providerName} providerName={product.providerName}/>)}
      </div>
      <div className='ProviderName'>{providerNames}</div>
    </div>
  )
}

export default ProposalLogos
