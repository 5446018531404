import React, { useState } from 'react'
import { AdditionalCostAO } from '../../../../api/api/types/UserAffordabilityResultAO'
import { MaxBuyingCost } from './MaxBuyingCost'
import './MaxCostsSelector.less'
import { useTranslation } from 'react-i18next'

type MaxCostsSelectorType = {
  additionalCosts: AdditionalCostAO
  ownCapital: number
  maxPurchasePrice: number
  maxLoanAmount: number
  ownFundPurchasePriceRatio: number
  onRenovationCostPercentageChange: (val: number) => void
  onBrokerCommissionChange: (val: boolean) => void
  includeBrokerCommission: boolean
}

export const MaxCostsSelector: React.FC<MaxCostsSelectorType> = ({
  additionalCosts,
  ownCapital,
  maxPurchasePrice,
  maxLoanAmount,
  ownFundPurchasePriceRatio,
  onRenovationCostPercentageChange,
  onBrokerCommissionChange,
  includeBrokerCommission
}) => {
  const { t } = useTranslation('odf')

  const [ maxBuyingCostsSelected, setMaxBuyingCostsSelected ] = useState(true);

  return (
    <div className='max-costs-selector padding-bottom-xxl'>
      <div className='tabs padding-top-l'>
        <div className={'purchase-button chip' + (maxBuyingCostsSelected ? ' chip--active' : '')}
             onClick={() => setMaxBuyingCostsSelected(true)} role='presentation'>
          <span role='button' className='font-s palm-hide'>{t('resultPage.maxCostsSelector.maxBuyingCosts')}</span>
          <span role='button' className='font-xs desk-hide lap-hide'>{t('resultPage.maxCostsSelector.maxBuyingCostsShort')}</span>
        </div>
        <div className={'loan-button chip' + (maxBuyingCostsSelected ? '' : ' chip--active')}
             onClick={() => setMaxBuyingCostsSelected(false)} role='presentation'>
          <span role='button' className='font-s palm-hide'>{t('resultPage.maxCostsSelector.maxLoanAmount')}</span>
          <span role='button' className='font-xs desk-hide lap-hide'>{t('resultPage.maxCostsSelector.maxLoanAmountShort')}</span>
        </div>
      </div>
      <MaxBuyingCost
        additionalCosts={additionalCosts}
        ownCapital={ownCapital}
        maxPurchasePrice={maxPurchasePrice}
        maxLoanAmount={maxLoanAmount}
        ownFundPurchasePriceRatio={ownFundPurchasePriceRatio}
        onRenovationCostPercentageChange={onRenovationCostPercentageChange}
        onBrokerCommissionChange={onBrokerCommissionChange}
        includeBrokerCommission={includeBrokerCommission}
        maxLoanAmountMode={!maxBuyingCostsSelected}
      />
    </div>
  )
}

