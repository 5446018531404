import React from 'react'
import { ProbabilityResult } from '../PreApprovalResultPage'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import CertificateExample from '../certificateExample/CertificateExample'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../actionButtons/ActionButtons'
import './CertificateSection.less'

export type CertificateSectionProps = {
  oneDynamicFlow: OneDynamicFlowState,
}

const CertificateSection: React.FC<CertificateSectionProps> = (
  {
    oneDynamicFlow
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.certificateSection' })

  return (
    <div className='certificate-section'>
      <div className='certificate'>
        <CertificateExample/>
      </div>
      <div className='description desk-padding-top-l'>
        <div className='title'>{t('title')}</div>
        <ul className='description-desktop'>
          <br/>
          <li>{t('description1Desktop')}</li>
          <br/>
          <li>{t('description2Desktop')}</li>
        </ul>
        <div className='description-mobile'>
          {t('descriptionMobile')}
        </div>
      </div>
      <div className='buttons-section'>
        <ActionButtons probabilityResult={ProbabilityResult.POSITIVE} oneDynamicFlow={oneDynamicFlow}/>
      </div>
    </div>
  )
}

export default CertificateSection
