import axios from 'axios'
import { IS24_HOST_URL_LOCAL, PRE_APPROVAL_API } from '../../../api/contextPaths'
import { redirectToSso } from '../../../services/SsoService'
import { FinancialProposalResult } from '../resultPage/model/PreApprovalResults'
import { postRequest } from '../../../api/request'
import { DateAsISOString } from '../../../api/api/types/DateAsISOString'
import { getLocalStorageData, setLocalStorageData, removeLocalStorageData } from '../../../api/api/LocalStorage'
import { Employment, EmploymentType } from '../../../api/api/types/Employment'
import { PRE_APPROVAL_USER_FLOW } from '../../../config/contextPaths'

export type CertificateData = {
  name: string;
  birthdate: string; // YYYY-MM-DD
  purchasePrice: number;
  location: string;
}

const replaceSpaces = (value: string): string => value.replace(/\s/g, '_');

export const downloadCertificate = (data: CertificateData) =>
  axios.post(`${PRE_APPROVAL_API}/create-certificate`, data, {
    responseType: 'blob',
    withCredentials: true,
    headers: { 'Accept': 'application/pdf' }
  }).then((response) => (createLinkAndDownloadFile(response.data, data.name)))
    .catch((e) => Promise.reject(new Error('Get certificate failed: ' + e)))

export const createLinkAndDownloadFile = (responseData: any, name: string) => {
  const url = window.URL.createObjectURL(new Blob([ responseData ]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `IS24-Finanzierungszertifikat_${replaceSpaces(name)}.pdf`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const redirectToSsoForLogin = (returnUrl?: string): void => {
  const ssoRedirectionSource = 'preapprovalFlow'
  redirectToSso(ssoRedirectionSource, returnUrl)
}

export const redirectToSsoWithLoadingPageAsReturnUrl = (): void => {
  const returnUrl = IS24_HOST_URL_LOCAL + PRE_APPROVAL_USER_FLOW + '/berechnung'
  redirectToSsoForLogin(returnUrl)
}

export const getFinancialProposals = (
  purchasePrice: number,
  monthlyIncome: number,
  birthdate: DateAsISOString,
  ownCapital: number,
  withBrokerCommission: boolean,
  postalCode: string,
  employmentType: EmploymentType,
  employedSince: DateAsISOString | undefined,
  fixedTermEmployment: boolean | undefined,
  otherRemainingDebts: number
): Promise<FinancialProposalResult> =>
  postRequest({
    url: `${PRE_APPROVAL_API}/get-financial-proposals`,
    body: { purchasePrice, monthlyIncome, birthdate, ownCapital, withBrokerCommission, postalCode, employmentType, employedSince, fixedTermEmployment, otherRemainingDebts, showAll: true },
    credentials: 'include'
  })

const contactRequestSentTimestampKey = 'PreApprovalContactRequestSentOn'
const contactRequestMinIntervalDays = 7
const daysToMillis = 24 * 60 * 60 * 1000;

export const storeContactRequestSentTimestamp = () => setLocalStorageData(contactRequestSentTimestampKey, new Date().toISOString())

export const canSendAnotherContactRequest = (): boolean => {
  const timestamp = getLocalStorageData<string>(contactRequestSentTimestampKey)
  return !timestamp || (Date.now() - Date.parse(timestamp) >= contactRequestMinIntervalDays * daysToMillis)
}

export const getRemainingDaysToSendAnotherContactRequest = (): number => {
  const timestamp = getLocalStorageData<string>(contactRequestSentTimestampKey)
  if (!timestamp) {
    return 0
  }
  const remainingDays = Math.ceil(contactRequestMinIntervalDays - (Date.now() - Date.parse(timestamp)) / daysToMillis)
  return remainingDays >= 0 ? remainingDays : 0
}

export const resetContactRequestSentTimestamp = () => removeLocalStorageData(contactRequestSentTimestampKey)

const employmentTypesToShowEmployedSinceQuestion: EmploymentType[] = [ Employment.WORKER, Employment.EMPLOYED ]

export const shouldShowEmployedSinceQuestionFor = (employmentType: EmploymentType | undefined): boolean =>
  !!employmentType && employmentTypesToShowEmployedSinceQuestion.includes(employmentType)
