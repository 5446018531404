import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'

import img from '../../../../../assets/images/map.svg'
import { DialogPage } from '../dialogPage/DialogPage'
import { RegionSelection } from '../../../../common/input/regionSelection/RegionSelection'
import { LocationType } from '../../../state/reducer'

interface LocationProps {
  location?: LocationType,
  onChange: (location: LocationType) => void,
  onClick: () => void,
}

const Location = ({ location: defaultLocation, onChange, onClick }: LocationProps) => {
  const {t} = useTranslation('userFlow')
  const [showErrors, setShowErrors] = useState(false)
  const [location, setLocation] = useState(defaultLocation)

  const isRegionValid = location?.locationLabel !== undefined && (location?.geoCode || location?.postalCode) !== ''

  const handleClick = () => {
    setShowErrors(true)
    if (isRegionValid) {
      onChange(location!)
      onClick()
    }
  }

  return (
    <DialogPage avatarUrl={img} title={t('locationDialog.title')} subTitle={t('locationDialog.subTitle')}>
      <div className="LocationDialog grid grid-flex dialog-single-input-wrapper">
        <div className="grid-item one-whole dialog-input">
          <RegionSelection
            onRegionSelect={setLocation}
            value={location?.locationLabel}
            valid={!showErrors || (showErrors && isRegionValid)}
            errorMessage={t('locationDialog.errorMessage')}
          />
        </div>
        <Button appearance="primary" onClick={handleClick} className="one-whole">
          {t('locationDialog.button')}
        </Button>
      </div>
    </DialogPage>
  )
}

export default Location
