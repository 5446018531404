import { Marker } from '@react-google-maps/api'
import { createIcon } from './MapUtils'
import React from 'react'

type Props = {
  location: google.maps.LatLngLiteral
};

export default function MapMarker({ location }: Props) {

  return (
    <Marker
      position={location}
      animation={google.maps.Animation.DROP}
      icon={createIcon()}
      clickable={true}
    />
  )
}
