import React from 'react'
import { useTranslation } from 'react-i18next'
import './DetailsRow.less'

type DetailsRowProps = { iconSmall: string, iconBig: string, translationKey: string }

const DetailsRow: React.FC<DetailsRowProps> = ({ iconSmall, iconBig, translationKey }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.successModal' })
  return (
    <div className='DetailsRow'>
      <div>
        <img aria-hidden='true' src={iconSmall} alt='icon' className='Icon palm-hide'/>
        <img aria-hidden='true' src={iconBig} alt='icon' className='Icon lap-hide desk-hide'/>
      </div>
      <div>
        <div className='DetailCaption'>{t(`${translationKey}.title`)}</div>
        <div className='DetailDescription'>{t(`${translationKey}.description`)}</div>
      </div>
    </div>
  )
}

export default DetailsRow
