import React from 'react'
import './ProposalsTouchPoint.less'
import { useTranslation } from 'react-i18next'
import proposalsThumbnail from '../../../assets/images/pre-approval/proposals.png'
import Allianz from '../../../assets/images/pre-approval/provider-logos/Allianz.svg'
import AXA from '../../../assets/images/pre-approval/provider-logos/AXA.svg'
import Commerzbank from '../../../assets/images/pre-approval/provider-logos/Commerzbank.svg'
import DeutscheBank from '../../../assets/images/pre-approval/provider-logos/Deutsche-Bank.svg'
import DKB from '../../../assets/images/pre-approval/provider-logos/DKB.svg'
import DSL from '../../../assets/images/pre-approval/provider-logos/DSL.svg'
import ERGO from '../../../assets/images/pre-approval/provider-logos/ERGO.svg'
import HanseaticBank from '../../../assets/images/pre-approval/provider-logos/Hanseatic-Bank.svg'
import HypoVereinsbank from '../../../assets/images/pre-approval/provider-logos/HypoVereinsbank.svg'
import ING from '../../../assets/images/pre-approval/provider-logos/ING.svg'
import OLB from '../../../assets/images/pre-approval/provider-logos/OLB.svg'
import Sparda from '../../../assets/images/pre-approval/provider-logos/Sparda-Baden-Wuerttemberg.svg'
import { PRE_APPROVAL_USER_FLOW_RESTART } from '../../../config/contextPaths'

const NUMBER_OF_DISPLAYED_LOGOS = 6
const PROVIDERS_LOGOS = [ Allianz, AXA, Commerzbank, DeutscheBank, DKB, DSL, ERGO, HanseaticBank, HypoVereinsbank, ING, OLB, Sparda ]

export const getNumberOfRandomLogos = (logosArray: string[], numberOfLogos: number) =>
  logosArray.sort(() => .5 - Math.random()).slice(0, numberOfLogos)

export const generateLogos = (logosArray: string[]) =>
  logosArray.map((logo, index) =>
    <li key={index}><img className='logo' alt={index.toString()} src={logo}/></li>)

export type ProposalsTouchPointProps = {
  withBadge?: boolean
}

const ProposalsTouchPoint: React.FC<ProposalsTouchPointProps> = ({ withBadge = true }) => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.proposalsTouchPoint' })

  const randomLogos = getNumberOfRandomLogos(PROVIDERS_LOGOS, NUMBER_OF_DISPLAYED_LOGOS)

  return (
    <div className='proposals-touch-point padding-top-xl'>
      <div className='container padding-bottom-xxl'>
        {withBadge && <div className='corner'>
          <div className='font-h5 padding-left-xl padding-top-l'>
            {t('new')}
          </div>
        </div>}
        <div className='title font-h4 font-center'>
          {t('title')}
        </div>
        <div className='description padding-top-m font-m font-center'>
          {t('description1')}
        </div>
        <div className='proposals padding-top-l'>
          <img src={proposalsThumbnail} alt={t('pictureAltText')} width='100%' height='100%'/>
        </div>
        <div className='button-section padding-top-l padding-horizontal-m'>
          <a
            className='button-primary palm-one-whole'
            data-testid='funnelButton'
            href={PRE_APPROVAL_USER_FLOW_RESTART}>
            {t('funnelButton')}
          </a>
        </div>
        <div className='description padding-top-l font-s font-center'>
          {t('description2')}
        </div>
        <ul className='logos-list padding-top-l'>
          {generateLogos(randomLogos)}
        </ul>
      </div>
    </div>
  )
}

export default ProposalsTouchPoint
