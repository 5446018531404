import { useCallback, useEffect, useState } from 'react'
import { BuyersProfileUser, getBuyersProfileUser, updateBuyersProfileUser } from '../services/BuyersProfileService'

export enum BuyersProfileDataState {
  DISABLED,
  LOADING,
  VALID,
  INVALID,
  LOADING_FAILED
}

export type BuyersProfileDataType = {
  profile: BuyersProfileUser
  state: BuyersProfileDataState
  updateProfile: (profile: BuyersProfileUser) => Promise<void>
}

const isNotEmpty = (val?: string) => val !== undefined && val.trim().length > 0

export const isBuyersProfileValid = (profile: BuyersProfileUser) =>
  isNotEmpty(profile.firstName) && isNotEmpty(profile.lastName) && isNotEmpty(profile.phoneNumber)

export const useBuyersProfileData = (enabled = true): BuyersProfileDataType => {

  const [ profile, setProfile ] = useState<BuyersProfileUser>({})
  const [ state, setState ] = useState<BuyersProfileDataState>(BuyersProfileDataState.DISABLED)

  const setProfileAndState = useCallback((newProfile: BuyersProfileUser) => {
    setProfile(newProfile)
    setState(isBuyersProfileValid(newProfile) ? BuyersProfileDataState.VALID : BuyersProfileDataState.INVALID)
  }, [ setProfile ])

  const updateProfile = useCallback((p: BuyersProfileUser) =>
    updateBuyersProfileUser(p).then(setProfileAndState),
    [ setProfileAndState ])

  useEffect(() => {
    if (enabled && state === BuyersProfileDataState.DISABLED) {
      setState(BuyersProfileDataState.LOADING)

      getBuyersProfileUser()
        .then(setProfileAndState)
        .catch(error => {
          setState(BuyersProfileDataState.LOADING_FAILED)
          console.warn(`Could not fetch profile data: ${error?.message}`)
        })
    }
  }, [ enabled, state, setProfileAndState ])

  return {
    profile,
    state,
    updateProfile
  }
}
