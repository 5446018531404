import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UserFlowDialogPage from './userFlow/UserFlowDialogPage'
import Success from './userFlow/pages/success/Success'
import { withPageTrackingReporting } from './hoc/withPageTrackingReporting'
import NoOffer from './userFlow/pages/noOffer/NoOffer'
import { CONTEXT_PATH } from '../../config/contextPaths'

export const SUCCESS_PAGE = 'erfolg'
export const NO_OFFER = 'monatliche-rate'
export const NO_OFFER_PAGE = `${CONTEXT_PATH}/finanzierungsberatung/` + NO_OFFER

const TRACKING_SUCCESS_PAGE = 'mortgageofficer.success'

const MortgageOfficer = () =>
  <Routes>
    <Route path={`/${SUCCESS_PAGE}`} element={<>{withPageTrackingReporting(Success, TRACKING_SUCCESS_PAGE)}</>}/>
    <Route path={`/${NO_OFFER}`} element={<NoOffer/>}/>
    <Route path={`/:page`} element={<UserFlowDialogPage/>}/>
  </Routes>

export default MortgageOfficer
