import React, { ReactChild } from 'react'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import { Checkbox } from 'is24-corecss'
import { PercentageInput } from '../../../common/input/numericInput'

type TableRowType = {
  label: ReactChild,
  percent: number,
  value: number,
  bold: boolean,
  checkbox?: boolean
  inputPercentValue?: boolean
  sanitizePercentValue?: (val: number) => number
  onRenovationCostPercentageChange?: (val: number) => void
  onBrokerCommissionChange?: (val: boolean) => void
  includeBrokerCommission?: boolean
}

export const TableRow: React.FC<TableRowType> = ({
  label,
  percent,
  value,
  bold,
  checkbox,
  inputPercentValue = false,
  sanitizePercentValue,
  onRenovationCostPercentageChange,
  onBrokerCommissionChange,
  includeBrokerCommission
}) => {

  const updateRenovationCostPercentage = (val: number) => {
    onRenovationCostPercentageChange?.(val)
  }

  const updateBrokerCommission = (val: boolean) => {
    onBrokerCommissionChange?.(val)
  }

  const getLabelClasses = () =>
    bold ? 'font-bold font-m palm-font-s ' : 'font-s padding-left-l palm-font-xs palm-padding-left-m '


  const getValuesClasses = () =>
    bold ? 'font-m palm-font-s ' : 'font-s palm-font-xs '


  const greyFont = () =>
    (checkbox && !includeBrokerCommission) ? 'font-gray ' : ''

  return (
    <div role='status' aria-label='row'>
      <div className=' grid grid-flex row border-bottom'>
        <span className={greyFont() + getLabelClasses() + 'grid-item eight-twelfth lap-six-twelfth palm-five-tenth'}>
          {checkbox ?
            <span className='inline-block'>
              <Checkbox
                id='checkbox-broker'
                label={label}
                checked={includeBrokerCommission && percent != 0}
                onChange={() => updateBrokerCommission(!includeBrokerCommission)}
              />
            </span>
            :
            <span>{label}</span>
          }
        </span>
        <span className={greyFont() + getValuesClasses() + 'grid-item two-twelfth lap-three-twelfth palm-two-tenth'}>
          {inputPercentValue ?
            <PercentageInput
              className={getValuesClasses()}
              id='percentage-input'
              value={percent}
              onChange={updateRenovationCostPercentage}
              sanitizeValue={sanitizePercentValue}
            />
            :
            <span className='float-right'>
              {formatNumber(percent, 2) + ' %'}
            </span>
          }
        </span>
        <span className={greyFont() + getValuesClasses() + 'grid-item two-twelfth lap-three-twelfth palm-three-tenth'}>
          <span className='float-right'>
            {toCurrency(value)}
          </span>
        </span>
      </div>
    </div>
  )
}
