import React from 'react'
import { useTranslation } from 'react-i18next'
import { BorrowerExpensesAO } from '../../../../../api/api/types/BorrowerExpensesAO.ds'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { formatNumber } from '../../../../../services/NumberUtils'
import ExpenseForm, { ExpenseFields, getExpensesTotal } from '../../../expenseForm/ExpenseForm'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { checkIfValid } from '../../../expenseForm/ExpensesInputRow'


export type ExpenseProps = Pick<OneDynamicFlowState, 'expense' | 'numberOfBorrowers' | 'numberOfChildren'> & {
  onChange: (expense: ExpenseFields) => void
} & FooterProps & CloseButtonProps

const MAX_VALUE = 100_000

export const isValidExpense = (expense: BorrowerExpensesAO = {}) => {
  for ( const key in expense ) {
    if (key !== 'numberOfVehicles' && key !== 'total'
      && !checkIfValid(expense[ key as keyof BorrowerExpensesAO ], MAX_VALUE)) {
      return false
    }
  }
  const total = getExpensesTotal([ expense ])
  return total > 0
}


export const Expense: React.FC<ExpenseProps> =
  ({
     expense = {},
     numberOfBorrowers = 1,
     onChange, onBack,
     onNext,
     onClose,
     progress,
   }) => {
    const { t } = useTranslation('odf')

    const total = getExpensesTotal([ expense ])

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.expenses.title', { count: numberOfBorrowers })}
        description={t('userFunnel.expenses.description')}
        arrow='short-arrow-center'
        disabled={!isValidExpense(expense)}
        touched={total > 0}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <div style={{ 'maxWidth': '428px' }}
             className='grid-item one-whole padding-vertical-m palm-margin-horizontal-l'>
          <ExpenseForm
            primaryExpenses={expense}
            showVehiclesExpenses={false}
            numberOfBorrowers={1}
            primaryOnChange={onChange}
            showLabelTooltip={false}
            maxValue={MAX_VALUE}
          />
          <div className='grid-flex grid-justify-space-between padding-top-xl padding-horizontal-s'>
            <strong>{t('userFunnel.expenses.total')}</strong> <br/>
            <span role='status' aria-label='expenses-sum'>
                {formatNumber(total)}&nbsp;€
              </span>
          </div>
        </div>
      </OneDynamicFlowQuestion>
    )
  }
