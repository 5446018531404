import React from 'react'
import { toCurrency } from '../../../../services/NumberUtils'
import './MaxCostsSelector.less'
import { TableRow } from './TableRow'
import { AdditionalCostAO } from '../../../../api/api/types/UserAffordabilityResultAO'
import { AdditionalCosts } from './AdditionalCosts'
import { useTranslation } from 'react-i18next'

type MaxBuyingCostType = {
  additionalCosts: AdditionalCostAO
  ownCapital: number
  maxLoanAmount: number
  maxPurchasePrice: number
  ownFundPurchasePriceRatio: number
  onRenovationCostPercentageChange: (val: number) => void
  onBrokerCommissionChange: (val: boolean) => void
  includeBrokerCommission: boolean
  maxLoanAmountMode: boolean
}

export const MaxBuyingCost: React.FC<MaxBuyingCostType> = ({
  additionalCosts,
  ownCapital,
  maxLoanAmount,
  maxPurchasePrice,
  ownFundPurchasePriceRatio,
  onRenovationCostPercentageChange,
  onBrokerCommissionChange,
  includeBrokerCommission,
  maxLoanAmountMode
}) => {

  const { t } = useTranslation('odf')

  const title = t(maxLoanAmountMode ? 'resultPage.maxCostsSelector.maxBuyingCostsShort' : 'resultPage.maxCostsSelector.maxLoanAmountShort')

  return (
    <div className='max-buying-cost'>
      <div className='loan-content'>
        <div className='rows padding-top-xl'>
          <div className='row font-l palm-font-m font-bold border-bottom-teal'>
            <span role='cell' className='float-left'>{title}</span>
            <span className='float-right'>{toCurrency(maxLoanAmountMode ? maxPurchasePrice : maxLoanAmount)}</span>
          </div>
          <TableRow
            label={(maxLoanAmountMode ? '- ' : '+ ') + t('resultPage.maxCostsSelector.ownCapital')}
            percent={ownFundPurchasePriceRatio}
            value={ownCapital ?? 0}
            bold={true}
          />
          <TableRow
            label={(maxLoanAmountMode ? '+ ' : '- ') + t('resultPage.maxCostsSelector.additionalCosts')}
            percent={additionalCosts.totalPercent ?? 0}
            value={additionalCosts.total ?? 0}
            bold={true}
          />
          <AdditionalCosts
            additionalCosts={additionalCosts}
            onRenovationCostPercentageChange={onRenovationCostPercentageChange}
            onBrokerCommissionChange={onBrokerCommissionChange}
            includeBrokerCommission={includeBrokerCommission}
          />
          <div role='cell' className='font-l palm-font-m align-right padding-top-l'>
            {t(maxLoanAmountMode ? 'resultPage.maxCostsSelector.maxLoanAmount' : 'resultPage.maxCostsSelector.maxBuyingCosts')}
          </div>
          <div className='align-right padding-top-s'>
            <div className='font-xl palm-font-l font-bold tealHighlighting'>
              {toCurrency(maxLoanAmountMode ? maxLoanAmount : maxPurchasePrice)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
