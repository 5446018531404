import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FINANCING_RESULT_PAGE,
  FINANCING_USER_FLOW,
  MORTGAGE_PROFILE,
  RESTART_FLOW_PATH,
} from '../../../config/contextPaths'
import { useFlowNavigation } from '../../common/oneDynamicFlow/hook/useFlowNavigation'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import FlowContainer, { PageType } from '../../common/oneDynamicFlow/wizard/FlowContainer'
import {
  isValidLocationWithPostcode,
  Location, LocationQueryType
} from '../../common/oneDynamicFlow/pages/location/Location'
import { loadFinancingFromStorage } from '../../../redux/actions/Financing'
import { isValidPropertyCost, PropertyCost } from '../propertyCost/PropertyCost'
import { logInCheck } from '../../../redux/actions/Login'
import { isDefined } from '../../../utils/utils'
import { ObjectUsage } from '../../common/oneDynamicFlow/pages/objectUsage/ObjectUsage'
import { EmploymentTypePage } from '../../common/oneDynamicFlow/pages/employmentTypePage/EmploymentTypePage'
import { Income, isValidIncome } from '../../common/oneDynamicFlow/pages/income/Income'
import { OwnCapital } from '../../common/oneDynamicFlow/pages/ownCapital/OwnCapital'
import { AgeGroup } from '../../common/oneDynamicFlow/pages/ageGroup/AgeGroup'
import { extractFlowDataFrom } from '../services/FinancingService'
import { useExposeFromQueryParam } from '../hook/useExposeFromQueryParam'
import useResetLocation from '../../common/oneDynamicFlow/pages/location/hook/useResetLocation'
import {isEquityCapitalValid} from "../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils";
import { ApplicationState } from '../../../redux/store'
import { LogInState } from '../../../redux/reducers/Login'

const PagePaths = {
  location: 'finanzierungsort',
  propertyCost: 'objekt-kosten',
  ownUse: 'benutzungsart',
  employmentType: 'beschaeftigungsart',
  income: 'einnahmen',
  equityCapital: 'eigenkapital',
  ageGroup: 'altersgruppe',
}

const FinancingFlowContainer: React.FC = () => {
  const dispatch = useDispatch()
  const [ dataLoadedFromStorage, setDataLoadedFromStorage ] = useState(false)
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)
  const profileUrl = `${MORTGAGE_PROFILE}/konditionen`
  const resultPageUrl = loggedIn ? profileUrl : FINANCING_RESULT_PAGE
  const { getCurrentProgress, goToNextPage, goToPreviousPage, goToResultPage } = useFlowNavigation(FINANCING_USER_FLOW, resultPageUrl, Object.values(PagePaths))
  const { done: dataInitialized} = useExposeFromQueryParam(dataLoadedFromStorage)

  useEffect(() => {
    dispatch(loadFinancingFromStorage())
    dispatch(logInCheck())
    setDataLoadedFromStorage(true)
  }, [ dispatch ])

  const onChangePropertyCost = useCallback((propertyCost: number) => setOneDynamicFlow({ propertyCost }), [ setOneDynamicFlow ])
  const onChangeBrokerCommission = useCallback((brokerCommission: number) => setOneDynamicFlow({ brokerCommission }), [ setOneDynamicFlow ])
  const onChangeCommissionPercentage = useCallback((brokerCommissionPercentage: number) => setOneDynamicFlow({ brokerCommissionPercentage }), [ setOneDynamicFlow ])

  const commonProps = useMemo(() => ({
    progress: getCurrentProgress,
    onBack: goToPreviousPage,
    onNext: goToNextPage
  }), [getCurrentProgress, goToNextPage, goToPreviousPage])

  useResetLocation(LocationQueryType.postcode, (location) => {
    setOneDynamicFlow({ location, exposeId: undefined, exposeFromShortlist: undefined })
  }, oneDynamicFlow.location)

  const pages: PageType[] = useMemo(() => [
    {
      path: PagePaths.location,
      canProceedToNextPage: isValidLocationWithPostcode( oneDynamicFlow.location),
      pageViewTracking: 'financingfunnel.region',
      component:
        <Location
          {...commonProps}
          location={oneDynamicFlow.location}
          exposeId={oneDynamicFlow.exposeId}
          exposeFromShortlist={oneDynamicFlow.exposeFromShortlist}
          queryType={LocationQueryType.postcode}
          onLocationChange={(location) => {
            setOneDynamicFlow({ location })
          }}
          onExposeChange={(expose, exposeFromShortlist) => {
            setOneDynamicFlow(extractFlowDataFrom(expose, exposeFromShortlist))
          }}
          onBack={undefined}
        />
    },
    {
      path: PagePaths.propertyCost,
      canProceedToNextPage: isValidPropertyCost(oneDynamicFlow.propertyCost),
      pageViewTracking: 'financingfunnel.spending',
      component:
        <PropertyCost
          {...commonProps}
          location={oneDynamicFlow.location}
          propertyCost={oneDynamicFlow.propertyCost}
          brokerCommission={oneDynamicFlow.brokerCommission}
          brokerCommissionPercentage={oneDynamicFlow.brokerCommissionPercentage}
          onChangePropertyCost={onChangePropertyCost}
          onChangeBrokerCommission={onChangeBrokerCommission}
          onChangeCommissionPercentage={onChangeCommissionPercentage}
        />
    },
    {
      path: PagePaths.ownUse,
      canProceedToNextPage: isDefined(oneDynamicFlow.ownUse),
      pageViewTracking: 'financingfunnel.usetype',
      component:
        <ObjectUsage {...commonProps}
          ownUse={oneDynamicFlow.ownUse}
          onClick={(ownUse) => {
            setOneDynamicFlow({ ownUse })
            goToNextPage()
          }}
        />
    },
    {
      path: PagePaths.employmentType,
      canProceedToNextPage: isDefined(oneDynamicFlow.employmentType),
      pageViewTracking: 'financingfunnel.employment',
      component:
        <EmploymentTypePage {...commonProps}
                            employmentType={oneDynamicFlow.employmentType}
                            onClick={(employmentType) => {
                              setOneDynamicFlow({ employmentType })
                              goToNextPage()
                            }}
        />
    },
    {
      path: PagePaths.income,
      canProceedToNextPage: isValidIncome(oneDynamicFlow.income),
      pageViewTracking: 'financingfunnel.income',
      component:
        <Income { ...commonProps }
                income={oneDynamicFlow.income}
                numberOfChildren={oneDynamicFlow.numberOfChildren}
                onChange={(income) => {
                  setOneDynamicFlow({ income })
                }}
        />
    },
    {
      path: PagePaths.equityCapital,
      canProceedToNextPage: isEquityCapitalValid(oneDynamicFlow.equityCapital),
      pageViewTracking: 'financingfunnel.ownfunds',
      component:
        <OwnCapital
          { ...commonProps }
          equityCapital={oneDynamicFlow.equityCapital}
          onChange={(equityCapital) => {
            setOneDynamicFlow({ equityCapital })
          }}
        />
    },
    {
      path: PagePaths.ageGroup,
      canProceedToNextPage: isDefined(oneDynamicFlow.ageGroupType),
      pageViewTracking: 'financingfunnel.age',
      component:
        <AgeGroup
          {...commonProps}
          ageGroupType={oneDynamicFlow.ageGroupType}
          onClick={(ageGroupType) => {
            setOneDynamicFlow({ ageGroupType })
            goToResultPage()
          }}
          onNext={goToResultPage}
        />
    },

  ], [ oneDynamicFlow, setOneDynamicFlow, goToResultPage, commonProps, goToNextPage, onChangePropertyCost, onChangeBrokerCommission, onChangeCommissionPercentage ])

  return (
    <FlowContainer pages={pages} flowUrl={FINANCING_USER_FLOW} resultPageUrl={FINANCING_RESULT_PAGE} restartFlowPath={RESTART_FLOW_PATH} dataInitialized={dataInitialized}/>
  )
}

export default FinancingFlowContainer
