import React, { FC } from 'react'

import './dialogPage.less'

type DialogPageType = {
  title: string,
  subTitle?: string,
  avatarUrl?: string,
  dataTestId?: string,
  children: React.ReactNode,
}

export const DialogPage: FC<DialogPageType> = ({
  title,
  subTitle,
  avatarUrl,
  dataTestId,
  children
}) =>
  <div data-testid={dataTestId}
    className="DialogPage horizontal-center palm-margin-top-s lap-margin-top-xxl palm-margin-left-xxl palm-margin-right-xxl">
    <div className="align-center padding-top-s">
        {avatarUrl &&
            <div className="img-wrapper vertical-center-container">
                <img src={avatarUrl} aria-hidden alt="" className="dialogImg vertical-center"/>
            </div>
        }
      <div className="font-xxl desk-padding-top-m lap-padding-top-m lap-margin-bottom desk-margin-bottom title">{title}</div>
      {subTitle && <div className="subtitle desk-margin-bottom lap-margin-bottom">{subTitle}</div>}
    </div>
    <div className="horizontal-center palm-padding-top-m">
      {children}
    </div>
  </div>
