import { API_PROVIDER_UNSUBSCRIBE_URL } from '../contextPaths'

export const postProviderUnsubscribe = (id: string, emailType:string, token:string): Promise<Response> => {
  return fetch(new Request(`${API_PROVIDER_UNSUBSCRIBE_URL}/${id}?emailtype=${emailType}&token=${token}`, {
    method: 'post'
  }))
}



