import React from 'react'
import './PreApprovalTouchPoint.less'
import { Trans, useTranslation } from 'react-i18next'
import certificate from '../../../assets/images/pre-approval/finanzierungszertifikat.png'
import { MORTGAGE_PROFILE, PRE_APPROVAL_USER_FLOW } from '../../../config/contextPaths'
import { CERTIFICATE_MODE_URL_PARAM } from '../hook/usePreApprovalResultPageUrl'

const PreApprovalTouchPoint: React.FC = () => {

  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.touchPoint' })

  return (
    <div className='pre-approval-touch-point padding-bottom-xl' data-testid='pre-approval-touch-point'>
      <div className='corner'>
        <div className='font-h5 padding-left-l padding-top-l'>
          {t('new')}
        </div>
      </div>
      <div className='title font-h4 font-center z-top'>
        <Trans t={t} i18nKey='title'>
          <span className='highlight'/>
        </Trans>
      </div>
      <div className='font-m font-center padding-top-m z-top'>
        {t('description')}
      </div>
      <div className='margin-left-xxl margin-right-xxl'>
        <div className='grid grid-flex padding-top-xl padding-bottom-xxl'>
          <div className='grid-item desk-one-half one-whole z-top'>
            <div className='certificate'>
              <img className='border-top' src={certificate} alt={t('pictureAltText')} width='100%' height='100%'/>
            </div>
          </div>
          <div className='grid-item desk-one-half one-whole z-top'>
            <ul className='padding-top-xxl list-check font-m'>
              <li>{t('point1')}</li>
              <li>{t('point2')}</li>
              <li>{t('point3')}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='buttonGroup grid-item desk-padding-bottom-none'>
        <a
          className='button-tertiary palm-one-whole lap-one-whole palm-margin-top-l lap-margin-top-l palm-margin-horizontal-l lap-margin-horizontal-l z-top'
          data-testid='profileButton'
          href={MORTGAGE_PROFILE}>
          {t('profileButton')}
        </a>
        <a
          className='button-primary palm-one-whole lap-one-whole palm-order-one-up lap-order-one-up desk-margin-left palm-margin-horizontal-l lap-margin-horizontal-l z-top'
          data-testid='funnelButton'
          href={`${PRE_APPROVAL_USER_FLOW}?${CERTIFICATE_MODE_URL_PARAM}`}>
          {t('funnelButton')}
        </a>
      </div>
      <div className='icon tick'/>
      <div className='icon equals'/>
    </div>
  )
}

export default PreApprovalTouchPoint
