import { MortgageOfficerDialogPage } from './userFlow/pages/mortgageOfficerDialog/MortgageOfficerDialog';
import { IncomeRange, IncomeRangeToMaxRange, IncomeRangeType } from '../../api/api/types/IncomeRange.ds';
import { NO_OFFER_PAGE } from './MortgageOfficer';
import { generatePath } from 'react-router-dom';
import { MORTGAGE_OFFICER_USER_FLOW } from '../../config/contextPaths';
import { NavigateFunction } from 'react-router/dist/lib/hooks'

const getUserFlowPath = (page: MortgageOfficerDialogPage | 'erfolg'): string =>
  generatePath(MORTGAGE_OFFICER_USER_FLOW, { page });

export const goToFlowPage = (page: MortgageOfficerDialogPage, navigate: NavigateFunction) => {
  navigate({
    pathname: getUserFlowPath(page),
    search: window.location.search
  })
}
const offerExists = (netIncomeRange: IncomeRangeType, loanAmount: number) => {
  if (netIncomeRange !== IncomeRange.OVER_5000) {
    if (IncomeRangeToMaxRange[ netIncomeRange ] * 130 < loanAmount) {
      return false
    }
  }
  return true
}
export const navigateOnIncomePageChange = (page: MortgageOfficerDialogPage, netIncomeRange: IncomeRangeType, loanAmount: number, netIncomeFilterRemoved: boolean, navigate: NavigateFunction) => {
  if (offerExists(netIncomeRange, loanAmount) || netIncomeFilterRemoved) {
    goToFlowPage(page, navigate)
  } else {
    navigate({
      pathname: NO_OFFER_PAGE,
      search: window.location.search
    })
  }
}
