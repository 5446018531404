import certificate from '../../../../assets/images/pre-approval/finanzierungszertifikat.png'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './CertificateExample.less'

const CertificateExample: React.FC = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.certificateExample' })
  return (
    <div className='certificate-example' data-testid='certificate'>
      <div className='image-wrapper border'>
        <img src={certificate} alt={t('pictureAltText')} width='100%'/>
        <div className='ribbon'>
          <div className='text'>{t('example')}</div>
        </div>
      </div>
    </div>
  )
}

export default CertificateExample
