import homeIcon from '../../../../../../assets/images/home-icon.svg'
import { Region } from '../../../../input/regionSelection/RegionSelection'
import { LatLng } from 'use-places-autocomplete'

export const createIcon = () => ({
    labelOrigin: { x: 14, y: -40 },
    url: homeIcon,
    scaledSize: { height: 60, width: 49 }
  } as google.maps.Icon
);

export const createLocation = (region: Region) => {
  return {
    locationLabel: region.locationLabel,
    postalCode: region.postalCode,
    geoCode: region.geoCode,
  }
}

export const validateRegion = (region: Region) => {
  return (region?.geoCode || region?.postalCode) !== '';
};

export const createPositionWithOffset = (position: LatLng) => {
  const earthRadius = 6_378_137
  const offsetDn = 2_800
  const offsetDe = -200
  const dLat = offsetDn / earthRadius
  const dLng = offsetDe / (earthRadius * Math.cos(Math.PI * position.lat / 180))
  const latO = position.lat + dLat * 180 / Math.PI
  const lngO = position.lng + dLng * 180 / Math.PI
  return { lat: latO, lng: lngO }
}
