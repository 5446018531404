import React from 'react'
import { FinancingFeasibility, Proposal } from '../../../model/PreApprovalResults'
import './ProposalTileDetails.less'
import ProbabilityGauge from '../../../../../common/oneDynamicFlow/probabilityGauge/ProbabilityGauge'
import { FinancingProbability } from '../../../../../../api/preApproval/FinancingProbability'
import FinancingProductsTable from './financingProductsTable/FinancingProductsTable'
import HorizontalScrollContainerWithShadow from '../../../../../common/scrollContainerWithShadow/HorizontalScrollContainerWithShadow'

export type ProposalTileDetailsProps = {
  proposal: Proposal
}

export const mapFeasibilityToProbability = (feasibility: FinancingFeasibility): FinancingProbability => {
  const mapping: Record<FinancingFeasibility, FinancingProbability> = {
    [ FinancingFeasibility.low ]: FinancingProbability.low,
    [ FinancingFeasibility.medium ]: FinancingProbability.medium,
    [ FinancingFeasibility.high ]: FinancingProbability.high
  }
  return mapping[ feasibility ]
}

const ProposalTileDetails: React.FC<ProposalTileDetailsProps> = ({ proposal }) =>
  <div className='ProposalTileDetails'>
    <div className='Container border-top'>
      <HorizontalScrollContainerWithShadow>
        <FinancingProductsTable financingProducts={proposal.financingProducts}/>
      </HorizontalScrollContainerWithShadow>
      <ProbabilityGauge probability={mapFeasibilityToProbability(proposal.feasibility)}/>
    </div>
  </div>

export default ProposalTileDetails
