import React, { useState } from 'react'
import { Button, Font } from 'is24-corecss'
import { useTranslation, Trans } from 'react-i18next'
import AdditionalInfoModal from './AdditionalInfoModal'

export interface ContactRequestDisclaimerProps {
  onOpen: () => void;
  onClose: () => void;
}

const ContactRequestDisclaimer: React.FC<ContactRequestDisclaimerProps> = ({ onOpen, onClose }) => {
  const [ isVisible, setVisible ] = useState(false)
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.disclaimer.contactRequest' })

  const handleClose = () => {
    setVisible(false)
    onClose()
  }

  const handleAdditionalInfoClick = () => {
    setVisible(true)
    onOpen()
  }

  return (
    <>
      <Font hint={true}>
        <Trans t={t} i18nKey='text'>
          <Button className='padding-none' textStyle={true} onClick={handleAdditionalInfoClick}>link</Button>
        </Trans>
      </Font>
      <AdditionalInfoModal isVisible={isVisible} onClose={handleClose} />
    </>
  )
}

export default ContactRequestDisclaimer
