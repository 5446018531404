import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import './CloseButton.less'

export type CloseButtonProps = {
  onClose?: () => void,
  disabled?: boolean,
  translationKey?: string,
  darkMode?: boolean
}

const CloseButton: React.FC<CloseButtonProps> = ({onClose, disabled, translationKey, darkMode}) => {
  const { t } = useTranslation('odf');
  return (
    <div className={`close-button-container ${darkMode ? 'DarkMode' : ''}`}>
      <Button appearance="secondary" textStyle onClick={onClose} disabled={disabled} data-testid="close-button">
        <span className="is24-icon-closing close ButtonContent"/>
        <span className="margin-left-s font-bold lap-hide palm-hide ButtonContent">
          {t(translationKey ?? 'userFunnel.common.closeButton')}
        </span>
      </Button>
    </div>
  )
}

export default CloseButton
