import React, { ReactChild, useMemo, useState } from 'react'
import ProposalTile from './ProposalTile'
import './ProposalTiles.less'
import { Button, StatusMessage } from 'is24-corecss'
import { FinancingProduct, Proposal } from '../../model/PreApprovalResults'
import { reportProposalRequestEvent } from '../../../services/PreApprovalTrackingService'
import ProposalTilesHeader from './ProposalTilesHeader'
import { useTranslation } from 'react-i18next'
import Dropdown from '../../../../common/dropdown/Dropdown'

export type ProposalTilesProps = {
  proposals: Proposal[]
  onProposalSelected: (p: Proposal) => void
  selectedProposal: Proposal | undefined
  selectionLocked: boolean
  customHeader?: ReactChild
}

export enum FilteringValue {
  ALL = 'filterAll',
  FILTER_10_YEARS = 'filter10Years',
  FILTER_15_YEARS = 'filter15Years',
  FILTER_20_YEARS = 'filter20Years',
  FILTER_30_YEARS = 'filter30Years',
}

export enum SortingMethod {
  MONTHLY_INSTALLMENT_ASC = 'monthlyInstallmentAsc',
  INTEREST_RATE_ASC = 'interestRateAsc',
  FIXED_INTEREST_RATE_IN_YEARS_ASC = 'fixedInterestRateInYearsAsc',
}

const DEFAULT_NUMBER_PROPOSALS = 3

export const findMaxFixedInterestRateInYears = (financingProducts: FinancingProduct[]) => {
  const fixedRateInYears = financingProducts.map(f => f.fixedInterestRateInYears)
  return Math.max(...fixedRateInYears)
}

export const getFilterOptions = (proposals: Proposal[]): string[] => {
  const numberOfYears = proposals.map(p => findMaxFixedInterestRateInYears(p.financingProducts))
  return Object.values(FilteringValue).filter(filterValue => {
    switch (filterValue) {
      case FilteringValue.ALL:
        return true
      case FilteringValue.FILTER_10_YEARS:
        return numberOfYears.some(n => n === 10)
      case FilteringValue.FILTER_15_YEARS:
        return numberOfYears.some(n => n === 15)
      case FilteringValue.FILTER_20_YEARS:
        return numberOfYears.some(n => n === 20)
      case FilteringValue.FILTER_30_YEARS:
        return numberOfYears.some(n => n >= 30)
      default:
        return false
    }
  })
}

export const filterProposals = (filterValue: string, proposals: Proposal[]): Proposal[] =>
  proposals.filter(p => {
    const numberOfYears = findMaxFixedInterestRateInYears(p.financingProducts)
    switch (filterValue) {
      case FilteringValue.ALL:
        return true
      case FilteringValue.FILTER_10_YEARS:
        return numberOfYears === 10
      case FilteringValue.FILTER_15_YEARS:
        return numberOfYears === 15
      case FilteringValue.FILTER_20_YEARS:
        return numberOfYears === 20
      case FilteringValue.FILTER_30_YEARS:
        return numberOfYears >= 30
      default:
        return false
    }
  })

export const sortProposals = (method: string, proposals: Proposal[]): Proposal[] => {
  switch (method) {
    case SortingMethod.MONTHLY_INSTALLMENT_ASC:
      return proposals.sort((a, b) => a.monthlyInstallment - b.monthlyInstallment)
    case SortingMethod.INTEREST_RATE_ASC:
      return proposals.sort((a, b) => a.interestRate - b.interestRate)
    case SortingMethod.FIXED_INTEREST_RATE_IN_YEARS_ASC:
      return proposals.sort((a, b) => getLowerLimitRange(a.fixedInterestRateInYears) - getLowerLimitRange(b.fixedInterestRateInYears))
    default:
      return proposals
  }
}

export const getLowerLimitRange = (fixedInterestRateInYears: string): number => {
  if (fixedInterestRateInYears.includes('-')) {
    const range = fixedInterestRateInYears.split('-')
    const upperLimit = range[ range.length - 2 ].trim()
    return parseInt(upperLimit)
  }
  return parseInt(fixedInterestRateInYears)
}

const ProposalTiles: React.FC<ProposalTilesProps> = (
  {
    proposals,
    onProposalSelected,
    selectedProposal,
    selectionLocked,
    customHeader
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals' })
  const [ showAllProposals, setShowAllProposals ] = useState(false)
  const [ filteringValue, setFilteringValue ] = useState<string>(FilteringValue.ALL)
  const [ sortingMethod, setSortingMethod ] = useState<string>(SortingMethod.MONTHLY_INSTALLMENT_ASC)

  const handleProposalSelected = (index: number, proposal: Proposal) => {
    onProposalSelected(proposal)
    reportProposalRequestEvent(index + 1)
  }

  const filterOptions = useMemo(() => getFilterOptions(proposals), [ proposals ])

  const filteredProposals = useMemo(() => filterProposals(filteringValue, proposals), [ filteringValue, proposals ])

  const visibleProposals = useMemo(() =>
   showAllProposals
      ? sortProposals(sortingMethod, filteredProposals)
      : sortProposals(sortingMethod, filteredProposals).slice(0, DEFAULT_NUMBER_PROPOSALS),
   [ filteredProposals, showAllProposals, sortingMethod ])

  return (
    <div className='ProposalTiles'>
      <div className='HeaderContainer'>
        <div className='Header'>
          {customHeader ?? <ProposalTilesHeader/>}
        </div>
        <div className='DropdownContainer filtering'>
          <div className='DropdownLabel'>{t('filteringDropdownLabel')}</div>
          <Dropdown<string>
            id='proposalsFilteringDropdown'
            label=''
            value={filteringValue}
            listItems={filterOptions}
            labelFormatter={t}
            onChange={setFilteringValue}
          />
        </div>
        <div className='DropdownContainer'>
          <div className='DropdownLabel'>{t('sortingDropdownLabel')}</div>
          <Dropdown<string>
            id='proposalsSortingDropdown'
            label=''
            value={sortingMethod}
            listItems={Object.values(SortingMethod)}
            labelFormatter={t}
            onChange={setSortingMethod}
          />
        </div>
      </div>
      <div className='ProposalList'>
        {visibleProposals.map((proposal, index) =>
          <ProposalTile key={index}
                        proposal={proposal}
                        onProposalSelected={() => handleProposalSelected(index, proposal)}
                        showSelection={proposal === selectedProposal}
                        selectionLocked={selectionLocked}/>)}
      </div>
      <div className='margin-top center'>
        {filteredProposals.length > DEFAULT_NUMBER_PROPOSALS && !showAllProposals &&
          <Button textStyle onClick={() => setShowAllProposals(true)}>
            {t('showAllProposals', { numberOfProposals: filteredProposals.length - DEFAULT_NUMBER_PROPOSALS })}
          </Button>}
      </div>
      {/*TODO add handling for "no results" state (BAU-8558) */}
      {filteredProposals.length === 0 && <StatusMessage statusType='info' headline='TODO No results!'/>}
    </div>
  )
}

export default ProposalTiles
