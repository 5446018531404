import React, { useEffect, useState } from 'react'
import { useOneDynamicFlow } from '../../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { isFilledEnoughToShowResults } from '../../../../financing/hook/useOneDynamicFlowDataCheck'
import FinancingCalculatorTeaser from './teaser/FinancingCalculatorTeaser'
import FinancingCalculatorResultsWrapper from './results/FinancingCalculatorResultsWrapper'

const FinancingCalculatorSection: React.FC = () => {
  const [ canShowResults, setCanShowResults ] = useState(false)
  const { oneDynamicFlow } = useOneDynamicFlow()

  useEffect(() => {
    setCanShowResults(oneDynamicFlow && isFilledEnoughToShowResults(oneDynamicFlow))
  }, [ oneDynamicFlow ])

  return (
    canShowResults ? <FinancingCalculatorResultsWrapper/> : <FinancingCalculatorTeaser/>
  )
}

export default FinancingCalculatorSection
