import React, { useCallback, useState } from 'react'
import { ModalBackdrop, ModalContainer } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../redux/reducers/OneDynamicFlow'
import { CertificateData, downloadCertificate } from '../../services/PreApprovalService'
import CertificateModalResultState from './resultState/CertificateModalResultState'
import CertificateModalInputState from './inputState/CertificateModalInputState'
import TrackingService from '../../../../services/TrackingService/TrackingService'
import './CertificateModal.less'

export interface CertificateModalProps {
  oneDynamicFlow: OneDynamicFlowState;
  onClose: () => void;
}

const CertificateModal: React.FC<CertificateModalProps> = ({ oneDynamicFlow, onClose }) => {

  const [ isModalResultState, setModalResultState ] = useState(false)
  const [ isLoading, setLoading ] = useState(true)
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.certificateModal' })

  const handleDownloadCertificate = (fullName: string) => {
    const certificateData: CertificateData = {
      name: fullName,
      birthdate: oneDynamicFlow.dateOfBirth || '',
      purchasePrice: oneDynamicFlow.propertyCost || 0,
      location: oneDynamicFlow.location?.locationLabel || ''
    };

    downloadCertificate(certificateData).then(() => setLoading(false))
    setModalResultState(true)

    TrackingService.reportEvent(
      'preapprovalfunnel.resultpage.positive',
      'preapproval',
      'certificate_download',
      'finance',
      {
        event_name: 'certificate_download',
        event_product: 'finance',
        event_parameter_1: 'preapproval'
      }
    )
  }
  const isClosingBlocked = isModalResultState && isLoading
  const onCloseHandler = useCallback(() => !isClosingBlocked && onClose(), [ isClosingBlocked, onClose ])

  return (<>
      <div className='certificate-modal'>
        <ModalBackdrop visible={true} onClose={onCloseHandler}>
          <ModalContainer
            width={400}
            visible={true}
            modalTitle={isModalResultState ? '' : t('title')}
            onClose={onCloseHandler}
            ariaLabelCloseButton='close'
            containerClassName={`certificate-modal-container ${isClosingBlocked ? 'HideCloseButton' : ''}`}
          >
            {!isModalResultState ?
              <CertificateModalInputState handleDownloadCertificate={handleDownloadCertificate}/>
              :
              <CertificateModalResultState isLoading={isLoading} onCloseHandler={onClose}/>
            }
          </ModalContainer>
        </ModalBackdrop>
      </div>
    </>
  )
}

export default CertificateModal
