import React from 'react'
import { Stepper } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'

const MIN_VALUE = 0
const MAX_VALUE = 9

type Props = Pick<OneDynamicFlowState, 'numberOfChildren'> & {
  onChange: (numberOfChildren: number) => void
}

export const NumberOfChildren: React.FC<Props & FooterProps & CloseButtonProps> =
  ({
     numberOfChildren = 0,
     onChange,
     onBack,
     onNext,
     onClose,
     progress
   }) => {
    const { t } = useTranslation('odf')

    const handleOnNext = () => {
      onChange(numberOfChildren)
      onNext?.()
    }

    const normalizeValue = (numOfChildren: number): number => {
      if (numOfChildren > MAX_VALUE) {
        return MAX_VALUE
      }
      if (numOfChildren < MIN_VALUE) {
        return MIN_VALUE
      }
      return Math.floor(numOfChildren)
    }

    const handleOnChange = (event: any): void => {
      const numOfChildren = normalizeValue(Number(event.target.value))
      onChange(numOfChildren)
    }

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.numberOfChildren.title')}
        description={t('userFunnel.numberOfChildren.description')}
        arrow='long-arrow-center'
        disabled={false}
        onBack={onBack}
        onNext={handleOnNext}
        progress={progress}
        onClose={onClose}
      >
        <Stepper
          id='numberOfChildren'
          placeholder=''
          label=''
          defaultValue={numberOfChildren.toString()}
          min={MIN_VALUE}
          max={MAX_VALUE}
          onChange={handleOnChange}
          onPaste={handleOnChange}
        />
      </OneDynamicFlowQuestion>
    )
  }


