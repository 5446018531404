import React from 'react'
import { StepDescription } from '../../stepDescription/StepDescription'
import { TabId } from '../ProfileTabs'
import { Expose } from '../../../../api/api/Expose'
import SelectedObjectSection from '../../common/selectedObjectSection/SelectedObjectSection'
import ProposalsSection from './proposalsSection/ProposalsSection'
import { PreApprovalResultState } from '../../../preApproval/hook/usePreApprovalResults'

export type OffersTabProps = {
  selectedObject?: Expose
  selectNewObject: () => void
  preApprovalResults: PreApprovalResultState,
}

const OffersTab: React.FC<OffersTabProps> = ({ selectedObject, selectNewObject, preApprovalResults }) =>
  <div>
    <StepDescription className='padding-vertical-l lap-hide desk-hide'
                     stepIndex={2}
                     stepId={TabId.OFFERS_TAB}/>
    {selectedObject && <SelectedObjectSection selectedObject={selectedObject} selectNewObject={selectNewObject}/>}
    <ProposalsSection preApprovalResults={preApprovalResults}/>
  </div>

export default OffersTab
