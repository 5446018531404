import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowQuestion } from '../../common/oneDynamicFlow/OneDynamicFlowQuestion'
import { FooterProps } from '../../common/oneDynamicFlow/wizard/common/footer/Footer'
import { CloseButtonProps } from '../../common/oneDynamicFlow/wizard/common/closeButton/CloseButton'
import { NumberInput } from '../../common/input/numericInput'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import './PropertyCost.less'
import { Checkbox } from 'is24-corecss'
import { toCurrency } from '../../../services/NumberUtils'
import { useAdditionalCostDefaults } from '../hook/useAdditionalCostDefaults'

export type PropertyCostProps =
  Pick<OneDynamicFlowState, 'propertyCost' | 'brokerCommission' | 'brokerCommissionPercentage' | 'location'> & {
  onChangePropertyCost: (propertyCost: number) => void
  onChangeBrokerCommission: (brokerCommission: number) => void
  onChangeCommissionPercentage: (brokerCommissionPercentage: number) => void
} & FooterProps & CloseButtonProps

const MIN_VALUE = 100_000
const MAX_VALUE = 10_000_000

export const isValidPropertyCost = (value: number | undefined) => {
  return !!value && value >= MIN_VALUE && value < MAX_VALUE
}

export const PropertyCost: React.FC<PropertyCostProps> = (
  {
    propertyCost,
    brokerCommission,
    brokerCommissionPercentage,
    location,
    onChangePropertyCost,
    onChangeBrokerCommission,
    onChangeCommissionPercentage,
    onBack,
    onNext,
    progress,
    onClose
  }) => {

  const { t } = useTranslation('odf')
  const [ touched, setTouched ] = useState(!!propertyCost)
  const [ propertyCostValue, setPropertyCostValue ] = useState(propertyCost)
  const [ brokerCommissionIncluded, setBrokerCommissionIncluded ] = useState(!!brokerCommission)

  const postalCode = !brokerCommissionPercentage ? location?.postalCode : undefined
  const geoCode = !brokerCommissionPercentage ? location?.geoCode : undefined
  const additionalCostDefaults = useAdditionalCostDefaults(postalCode, geoCode)

  useEffect(() => {
    !brokerCommissionPercentage && onChangeCommissionPercentage(additionalCostDefaults?.brokerCommissionPercentage ?? 0)
  }, [ additionalCostDefaults, onChangeCommissionPercentage, brokerCommissionPercentage ])

  const updateBrokerCommission = useCallback(() => {
    if (brokerCommissionIncluded) {
      const newBrokerCommission = calculateBrokerCommission(propertyCost, (brokerCommissionPercentage ?? 0))
      onChangeBrokerCommission(newBrokerCommission)
      setPropertyCostValue((propertyCost ?? 0) + (newBrokerCommission ?? 0));
      onChangePropertyCost(propertyCost ?? 0)
    } else {
      onChangeBrokerCommission(0)
      setPropertyCostValue((propertyCost ?? 0));
    }
  }, [ brokerCommissionIncluded, onChangeBrokerCommission, onChangePropertyCost, propertyCost, brokerCommissionPercentage ])

  useEffect(() => {
    updateBrokerCommission()
  }, [ brokerCommissionIncluded, updateBrokerCommission ])

  const calculateBrokerCommission = (propertyCost: number | undefined, brokerCommissionPercentage: number) => {
    return propertyCost ? propertyCost * brokerCommissionPercentage / 100 : 0
  }

  const brokerCommissionLabel = t('financingFunnel.propertyCost.brokerCommissionMessage', {
    brokerCommissionPercentage, brokerCommission: toCurrency(brokerCommission ?? 0)
  })


  const getErrorMessage = () => {
    if (propertyCost && propertyCost < MIN_VALUE) {
      return t('financingFunnel.propertyCost.errorMessageMin')
    } else if (propertyCost && propertyCost >= MAX_VALUE) {
      return t('financingFunnel.propertyCost.errorMessageMax')
    } else return ''
  }

  return (
    <OneDynamicFlowQuestion
      title={t('financingFunnel.propertyCost.title')}
      description={t('financingFunnel.propertyCost.description')}
      arrow='long-arrow-bottom-middle-left'
      disabled={!isValidPropertyCost(propertyCost)}
      touched={touched && (propertyCost ?? 0) > 0}
      onBack={onBack}
      onNext={onNext}
      progress={progress}
      onClose={onClose}
    >
      <div className='property-cost-container'>
        <NumberInput
          id={'property-cost-number-input'}
          placeholder=''
          valid={!touched || (touched && isValidPropertyCost(propertyCost))}
          errorMessage={getErrorMessage()}
          value={propertyCostValue}
          onChange={(value) => {
            onChangePropertyCost(value)
            setBrokerCommissionIncluded(false)
          }}
          unitRight='eur'
          className='property-cost-number-input'
          onBlur={() => setTouched(true)}
          ariaLabel={'property-cost'}
        />
        <div>
          <Checkbox
            id='checkbox-broker-commission'
            label={brokerCommissionLabel}
            checked={brokerCommissionIncluded}
            onChange={() => setBrokerCommissionIncluded(!brokerCommissionIncluded)}
          />
        </div>
      </div>
    </OneDynamicFlowQuestion>
  )
}

