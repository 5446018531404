import React, { useEffect, useMemo, useState } from 'react'
import { PageViewTracker } from '../../PageViewTracker'
import { FooterProps } from './common/footer/Footer'
import { CloseButtonProps } from './common/closeButton/CloseButton'
import { useFlowNavigation } from '../hook/useFlowNavigation'

export type PageType = {
  path: string,
  pageViewTracking: string
  canProceedToNextPage: boolean,
  shouldBeSkipped?: boolean,
  component: React.ReactElement<FooterProps & CloseButtonProps & { numberOfBorrowers: number | undefined }>
}

type FlowContainerProps = {
  pages: PageType[]
  flowUrl: string
  resultPageUrl: string
  restartFlowPath: string
  dataInitialized: boolean
}

const FlowContainer: React.FC<FlowContainerProps> = ({ pages, flowUrl, resultPageUrl, restartFlowPath, dataInitialized }) => {
  const { currentPage, goToPageReplace, goToResultPage } = useFlowNavigation(flowUrl, resultPageUrl)

  const pageToRender = useMemo(() => dataInitialized && pages.find(p => p.path === currentPage), [ currentPage, pages, dataInitialized ])

  const [ redirectDone, setRedirectDone ] = useState(false)

  useEffect(() => {
    if (dataInitialized && !redirectDone) {
      setRedirectDone(true);
      if (currentPage === restartFlowPath) {
        goToPageReplace(pages[0].path)
        return
      }
      if (!currentPage && pages.every(p => (p.canProceedToNextPage || p.shouldBeSkipped))) {
        goToResultPage()
        return
      }
      const nextValidPage = pages.find(p => (!p.canProceedToNextPage && !p.shouldBeSkipped) || p.path === currentPage) || pages[0]
      pageToRender !== nextValidPage && goToPageReplace(nextValidPage.path)
    }
  }, [ dataInitialized, goToPageReplace, pages, currentPage, pageToRender, redirectDone, goToResultPage, restartFlowPath ]);

  return (
    <>
      {pageToRender &&
        <PageViewTracker pageTitle={pageToRender.pageViewTracking}>
          {pageToRender.component}
        </PageViewTracker>
      }
    </>
  )
}

export default FlowContainer
