import React, { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { postProviderUnsubscribe } from '../../api/api/Provider'
import ProviderUnsubscribe from './ProviderUnsubscribe'
import { queryParams } from '../../utils/utils'

type ProviderUnsubscribeParams = {
  email: string;
  emailtype: string;
  token: string;
}

const ProviderUnsubscribePage: FC = () => {
  const [ status, setStatus ] = useState<number>()
  const { id } = useParams<{ id: string }>()
  const params: ProviderUnsubscribeParams = queryParams<ProviderUnsubscribeParams>(useLocation().search)

  useEffect(() => {
    const sendRequest = async (id: string | undefined, params: ProviderUnsubscribeParams) => {
      if (!isNaN(Number(id)) && params != null && params.email != null && params.emailtype != null && params.token != null) {
        const response = await postProviderUnsubscribe(id!, params.emailtype, params.token);
        setStatus(response.status);
      } else {
        setStatus(400);
      }
    }

    sendRequest(id, params)
  }, [ id, params ])

  const { email, emailtype } = params
  return <>{status && <ProviderUnsubscribe status={status} email={decodeURIComponent(email)} emailType={emailtype}/>}
  </>
}

export default ProviderUnsubscribePage
