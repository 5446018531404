import React from 'react'
import './ProbabilityGauge.less'
import { useTranslation } from 'react-i18next'
import CustomTooltip from '../../customTooltip/CustomTooltip'
import { FinancingProbability } from '../../../../api/preApproval/FinancingProbability'
import { FinancingFeasibility } from '../../../preApproval/resultPage/model/PreApprovalResults'

const ProbabilityGauge: React.FC<{ probability: FinancingProbability | FinancingFeasibility }> = ({ probability }) => {
  const { t } = useTranslation('odf')
  return (
    <div className='probabilityGauge' data-testid='probabilityGauge'>
      <div className='columnContainer'>
        {Object.values(FinancingProbability).map((p, index) => (
          <div key={p} className={`barContainer ${index === 0 ? 'margin-left-none' : ''}`}>
            {probability === p && <>
              <div className='font-bold'>{t(`financingFunnel.resultPage.probabilityGauge.${p}`)}</div>
              <div>{t('financingFunnel.resultPage.probabilityGauge.probability')}</div>
              <div className='pointerLine'/>
            </>}
          </div>
        ))}
      </div>
      <div className='columnContainer'>
        {Object.values(FinancingProbability).map((p, index) => (
          <div key={p} className={`barContainer ${index === 0 ? 'margin-left-none' : ''}`}>
            <div className={`colorBar ${p}`}/>
          </div>
        ))}
      </div>
      <div className='label-with-tooltip'>
        <span className='tooltip'>
          <CustomTooltip
            text={t('financingFunnel.resultPage.probabilityGauge.tooltipMessage')}
            bodyPosition='top'
            arrowPosition='left'
            minWidth={328}
          />
        </span>
        <span className='label'>{t('financingFunnel.resultPage.probabilityGauge.hint')}</span>
      </div>
    </div>
  )
}

export default ProbabilityGauge
