import React, { useMemo } from 'react'
import {
  MORTGAGE_PROFILE,
  MORTGAGE_PROFILE_CONDITIONS_TAB_PATH,
  MORTGAGE_PROFILE_FEASIBILITY_TAB_PATH, MORTGAGE_PROFILE_OFFERS_TAB_PATH
} from '../../../config/contextPaths'
import TabNavigation, { TabDefinition } from '../../common/tabNavigation/TabNavigation'
import { useTranslation } from 'react-i18next'
import FeasibilityTab from './feasibility/FeasibilityTab'
import ConditionsTab from './conditions/ConditionsTab'
import OffersTab from './offers/OffersTab'
import { Expose } from '../../../api/api/Expose'
import { PreApprovalResultState } from '../../preApproval/hook/usePreApprovalResults'

export enum TabId {
  FEASIBILITY_TAB = 'FEASIBILITY_TAB',
  CONDITIONS_TAB = 'CONDITIONS_TAB',
  OFFERS_TAB = 'OFFERS_TAB',
}

export type ProfileTabsProps = {
  onTabSelected: (tabId: TabId, tabIndex: number) => void
  selectedObject?: Expose
  selectNewObject: () => void,
  preApprovalResults: PreApprovalResultState,
}

const ProfileTabs: React.FC<ProfileTabsProps> = ({ onTabSelected, selectedObject, selectNewObject, preApprovalResults }) => {
  const { t } = useTranslation('profile')
  const tabs: TabDefinition<TabId>[] = useMemo(() => [
    {
      id: TabId.FEASIBILITY_TAB,
      path: MORTGAGE_PROFILE_FEASIBILITY_TAB_PATH,
      title: t('feasibilityTab.title'),
      renderAlsoWhenInactive: true,
      component: <FeasibilityTab selectedObject={selectedObject}
                                 selectNewObject={selectNewObject}
                                 preApprovalResults={preApprovalResults}/>,
    },
    {
      id: TabId.CONDITIONS_TAB,
      path: MORTGAGE_PROFILE_CONDITIONS_TAB_PATH,
      title: t('conditionsTab.title'),
      renderAlsoWhenInactive: true,
      component: <ConditionsTab selectedObject={selectedObject} selectNewObject={selectNewObject}/>,
    },
    {
      id: TabId.OFFERS_TAB,
      path: MORTGAGE_PROFILE_OFFERS_TAB_PATH,
      title: t('offersTab.title'),
      renderAlsoWhenInactive: true,
      component: <OffersTab selectedObject={selectedObject}
                            selectNewObject={selectNewObject}
                            preApprovalResults={preApprovalResults}/>,
    },
  ], [ t, selectedObject, selectNewObject, preApprovalResults ])

  return <TabNavigation<TabId> tabs={tabs} baseUrl={MORTGAGE_PROFILE} onTabSelected={onTabSelected}/>
}

export default ProfileTabs
