import React from 'react'
import { useTranslation } from 'react-i18next'
import './DescriptionPanel.less'
import descriptionImg from '../../../../../../assets/images/pre-approval/contact-request-modal/description.png'
import descriptionImg2x from '../../../../../../assets/images/pre-approval/contact-request-modal/description-2x.png'
import descriptionImg3x from '../../../../../../assets/images/pre-approval/contact-request-modal/description-3x.png'
import feature1 from '../../../../../../assets/images/pre-approval/contact-request-modal/feature1.svg'
import feature2 from '../../../../../../assets/images/pre-approval/contact-request-modal/feature2.svg'
import feature3 from '../../../../../../assets/images/pre-approval/contact-request-modal/feature3.svg'
import feature1Mobile from '../../../../../../assets/images/pre-approval/contact-request-modal/feature1-mobile.svg'
import feature2Mobile from '../../../../../../assets/images/pre-approval/contact-request-modal/feature2-mobile.svg'
import feature3Mobile from '../../../../../../assets/images/pre-approval/contact-request-modal/feature3-mobile.svg'

type DescriptionFeatureType = {
  title: string
  description: string
  image: string
  imageMobile: string
}

const DescriptionFeature: React.FC<DescriptionFeatureType> = ({ title, description, image, imageMobile }) =>
  <div className='feature'>
    <img src={image} alt='description' className='feature-icon palm-hide lap-hide'/>
    <img src={imageMobile} alt='description' className='feature-icon desk-hide'/>
    <div className='feature-title'>{title}</div>
    <div className='feature-description'>{description}</div>
  </div>

export const DescriptionPanel: React.FC = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.contactRequestModal' })

  return (
    <div className='description-panel padding-horizontal-l'>
      <div className='palm-hide lap-hide padding-top-xl'>
        <img src={descriptionImg} srcSet={`${descriptionImg2x} 2x, ${descriptionImg3x} 3x`} alt='description'/>
      </div>
      <div className='features'>
        <DescriptionFeature
          title={t('description.feature.1.title')}
          description={t('description.feature.1.description')}
          image={feature1}
          imageMobile={feature1Mobile}
        />
        <DescriptionFeature
          title={t('description.feature.2.title')}
          description={t('description.feature.2.description')}
          image={feature2}
          imageMobile={feature2Mobile}
        />
        <DescriptionFeature
          title={t('description.feature.3.title')}
          description={t('description.feature.3.description')}
          image={feature3}
          imageMobile={feature3Mobile}
        />
      </div>
    </div>
  )
}
