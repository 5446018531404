import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RepaymentType } from '../model/FinancingResults'
import { NumberInput } from '../../../common/input/numericInput'
import Dropdown from '../../../common/dropdown/Dropdown'
import './Settings.less'
import { Chip } from './Chip'

export type SpecialRepaymentProps = {
  loanAmount: number
  repaymentAmount: number
  onRepaymentAmountChange: (repaymentAmount: number) => void
  repaymentType: RepaymentType
  repaymentYearOrMonth: number
  onRepaymentTypeChange: (repaymentType: RepaymentType, repaymentYearOrMonth: number) => void
}

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const YEARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const SpecialRepayment: React.FC<SpecialRepaymentProps> = ({
  loanAmount,
  repaymentAmount,
  onRepaymentAmountChange,
  repaymentType,
  repaymentYearOrMonth,
  onRepaymentTypeChange
}) => {
  const { t } = useTranslation('odf')
  const specialRepaymentTranslationPrefix = "financingFunnel.resultPage.loanOverviewSection.settings.specialRepayment"
  const [ inputAmount, setInputAmount ] = useState(repaymentAmount)
  const maxAmount = loanAmount / 10;
  const validInput = inputAmount >= 0 && inputAmount <= maxAmount

  useEffect(() => {
    setInputAmount(repaymentAmount)
  }, [repaymentAmount])

  const onInputBlur = () => {
    if (validInput) {
      onRepaymentAmountChange(inputAmount)
    }
  }

  return (
    <div>
      <div className='grid grid-flex'>
        <div className='grid-item two-fifth'>
        <NumberInput
          id='repayment-amount-input'
          label={t(`${specialRepaymentTranslationPrefix}.specialRepayment`)}
          value={inputAmount}
          onChange={setInputAmount}
          onBlur={onInputBlur}
          valid={validInput}
        />
        </div>
        <div className='grid-item three-fifth'>
        {repaymentType === RepaymentType.YEARLY ?
          <Dropdown<number>
            id='yearlyRepaymentDropdown'
            label='&nbsp;'
            value={repaymentYearOrMonth}
            listItems={MONTHS}
            labelFormatter={(month: number) => t(`commons.monthsFull.${month - 1}`)}
            onChange={(val: string) => onRepaymentTypeChange(RepaymentType.YEARLY, Number(val))}
          /> :
          <Dropdown<number>
            id='onceRepaymentDropdown'
            label='&nbsp;'
            value={repaymentYearOrMonth}
            listItems={YEARS}
            labelFormatter={(year: number) => t(`${specialRepaymentTranslationPrefix}.onceRepaymentDropdownOption`, { year })}
            onChange={(val: string) => onRepaymentTypeChange(RepaymentType.ONCE, Number(val))}
          />}
        </div>
      </div>
      {!validInput && <div className='font-error padding-top-xs'>{t(`${specialRepaymentTranslationPrefix}.invalidRepaymentErrorMessage`)}</div>}
      <div className='grid grid-flex grid-justify-end gutter-s padding-top-m'>
        <div className='grid-item'>
          <Chip active={repaymentType === RepaymentType.YEARLY}
                onClick={() => onRepaymentTypeChange(RepaymentType.YEARLY, 1)}
                label={t(`${specialRepaymentTranslationPrefix}.yearly`)}/>
        </div>
        <div className='grid-item'>
          <Chip active={repaymentType === RepaymentType.ONCE}
                onClick={() => onRepaymentTypeChange(RepaymentType.ONCE, 1)}
                label={t(`${specialRepaymentTranslationPrefix}.once`)}/>
        </div>
      </div>
    </div>
  )
}

export default SpecialRepayment
