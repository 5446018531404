import React from 'react'
import { useTranslation } from 'react-i18next'
import { toCurrency } from '../../../../services/NumberUtils'
import DataTiles from '../../../common/oneDynamicFlow/dataTiles/DataTiles'
import DataTile from '../../../common/oneDynamicFlow/dataTiles/DataTile'
import useFormattedYearsAndMonths from './useFormattedYearsAndMonths'

type LoanDevelopmentSummaryProps = {
  netLoan: number
  totalTimeMonths: number
  remainingDebt: number
}

const LoanDevelopmentSummary: React.FC<LoanDevelopmentSummaryProps> = ({netLoan, totalTimeMonths, remainingDebt}) => {
  const { t } = useTranslation('odf', { keyPrefix: 'financingFunnel.resultPage.loanDevelopmentSection.summary'})
  const formattedYearsAndMonths = useFormattedYearsAndMonths(totalTimeMonths)

  return (
    <div>
      <DataTiles>
        <DataTile value={toCurrency(netLoan)} label={t('netLoan')}/>
        <DataTile value={formattedYearsAndMonths} label={t('totalTime')}/>
        <DataTile value={toCurrency(remainingDebt)} label={t('remainingDebt')}/>
      </DataTiles>
      <div className='margin-top-xl'>
        <p>{t('assumptions')}</p>
      </div>
    </div>
  )
}

export default LoanDevelopmentSummary
