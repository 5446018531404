import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'
import { isUndefined } from '../../../../../utils/utils'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { ButtonGroup } from '../../wizard/common/buttonGroup/ButtonGroup'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'
import { EmploymentType } from '../../../../../api/api/types/Employment'

type Props = Pick<OneDynamicFlowState, 'employmentType'> & {
  onClick: (employmentType: EmploymentType) => void
}

export const EmploymentTypePage: React.FC<Props & FooterProps & CloseButtonProps> =
  ({
     employmentType,
     onClick,
     onBack,
     onNext,
     onClose,
     progress
   }) => {
    const { t } = useTranslation('odf')
    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.employmentType.title')}
        description={t('userFunnel.employmentType.description')}
        arrow='short-arrow-bottom-left'
        disabled={isUndefined(employmentType)}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <ButtonGroup<EmploymentType>
          value={employmentType}
          onClick={onClick}
          values={[ 'EMPLOYED', 'WORKER', 'CIVIL_SERVANT', 'FREELANCER', 'PENSIONER', 'SELFEMPLOYED', 'UNEMPLOYED', 'HOUSEWIFE' ]}
          translationRoute={'odf:userFunnel.employmentType'}
        />
      </OneDynamicFlowQuestion>
    )
  }


