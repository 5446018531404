import { ProgressBar } from 'is24-corecss'
import React from 'react'
import { NextButton } from '../../Buttons'
import BackButton from '../backButton/BackButton'

import './Footer.less'

export type FooterProps = {
  progress?: number | (()=>number),
  disabledForward?: boolean,
  disabledBack?: boolean,
  onBack?: () => void,
  onNext?: () => void
}
const Footer: React.FC<FooterProps> = ({ progress, disabledForward, disabledBack, onBack, onNext }) => (
  <div className='footer-container'>
    <ProgressBar progress={typeof progress === 'function' ? progress() : progress}/>
    <div className='footer-button-container margin-horizontal-l margin-vertical-xl'>
      {onBack ? <BackButton onClick={onBack} disabled={disabledBack}/> : <div/>}
      {onNext ? <NextButton onClick={onNext} disabled={disabledForward}/> : <div/>}
    </div>
  </div>
)

export default Footer

