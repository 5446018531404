import React from 'react'
import { StatusMessage } from 'is24-corecss'
import { useTranslation } from 'react-i18next'

export const TechnicalError: React.FC = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.proposals.technicalError' })

  return (
    <StatusMessage statusType='error'
                   headline={<div className='font-h5'>{t('title')}</div>}
                   message={<div>
                     <div>{t('message')}</div>
                     <button className='link-text padding-top-s padding-none'
                             onClick={() => window.location.reload()}>{t('pageRefresh')}
                     </button>
                   </div>}
    />
  )
}

export default TechnicalError
