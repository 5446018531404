import { StepWrapper } from './StepWrapper'
import React from 'react'
import { Loading } from '../../common/snippets/Loading'

export const LoadingPage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <StepWrapper title={text}>
      <div className='align-center padding-vertical-l'>
        <Loading/>
      </div>
    </StepWrapper>
  )
}
