import React from 'react'
import { useTranslation } from 'react-i18next'
import RestartButton from './RestartButton'
import { Button } from 'is24-corecss'
import './ActionButtons.less'

export type ActionButtonsType = {
  onClick?: () => void
}

const ActionButtons: React.FC<ActionButtonsType> = ({ onClick }) => {
  const { t } = useTranslation('odf')

  return (
    <div className='ActionButtons grid grid-flex padding-vertical-xxl'>
      <RestartButton/>
      <Button
        className='palm-one-whole desk-margin-left-l lap-margin-left-l palm-order-one-up palm-margin-bottom-l'
        onClick={onClick}
        appearance='secondary'>
        {t('resultPage.actionButtons.goToProfile')}
      </Button>
    </div>
  )
}

export default ActionButtons
