import React, { useCallback, useEffect } from 'react'
import { Is24Template } from '../../common/snippets/Is24Template'
import { logInCheck } from '../../../redux/actions/Login'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LogInState } from '../../../redux/reducers/Login'
import { useAffordabilityResults } from '../hook/useAffordabilityResults'
import AffordabilityResultPage from './AffordabilityResultPage'
import { withPageTrackingReporting } from '../../mortgageOfficer/hoc/withPageTrackingReporting'
import LoadingPage from '../../common/loadingPage/LoadingPage'
import useAffordabilityFlowDataCheck from '../hook/useAffordabilityFlowDataCheck'
import { AFFORDABILITY_USER_FLOW_RESTART, MORTGAGE_PROFILE } from '../../../config/contextPaths'
import { redirectToSsoToOpenProfile } from '../services/AffordabilityResultService'
import { onAffordabilityInit } from '../../../redux/thunks/Affordability'
import { AffordabilityState } from '../../../redux/reducers/Affordability'
import { useNavigate } from 'react-router-dom'

const AffordabilityResultWrapper: React.FC = () => {
  const { initialized } = useSelector<ApplicationState, AffordabilityState>(state => state.affordability)
  useAffordabilityFlowDataCheck(initialized)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logInCheck())
  }, [ dispatch ])

  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)

  useEffect(() => {
    if (loggedIn !== undefined) {
      dispatch(onAffordabilityInit())
    }
  }, [ dispatch, loggedIn ])

  const navigate = useNavigate()
  const onCalculationError = useCallback(() => navigate(AFFORDABILITY_USER_FLOW_RESTART), [ navigate ])

  const {
    result, onAmortizationRateChange, onInterestRateChange, onMonthlyRateChange, onOwnCapitalChange,
    onRenovationCostPercentageChange, onBrokerCommissionChange
  } = useAffordabilityResults(onCalculationError)

  const goToProfilePage = useCallback(() => {
    if (loggedIn) {
      navigate(MORTGAGE_PROFILE)
    } else {
      redirectToSsoToOpenProfile()
    }
  }, [ loggedIn, navigate ])

  return (
    loggedIn == undefined || result == undefined ? <LoadingPage/> :
      <Is24Template
        headerType={loggedIn ? 'default' : 'light'}
        backToOverview={true}>
        <AffordabilityResultPage
          result={result}
          onOpenProfilePageButtonClick={goToProfilePage}
          onAmortizationRateChange={onAmortizationRateChange}
          onInterestRateChange={onInterestRateChange}
          onMonthlyRateChange={onMonthlyRateChange}
          onOwnCapitalChange={onOwnCapitalChange}
          onRenovationCostPercentageChange={onRenovationCostPercentageChange}
          onBrokerCommissionChange={onBrokerCommissionChange}
        />
      </Is24Template>
  )
}

export default withPageTrackingReporting(AffordabilityResultWrapper, 'affordability_funnel_resultpage')
