import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  USER_FLOW_PATH, USER_FLOW_RESULT_PAGE_PATH
} from '../../config/contextPaths'
import FinancingFlowContainer from './userFlow/FinancingFlowContainer'
import FinancingResultWrapper from './resultPage/FinancingResultWrapper'

const FinancingRoutes: React.FC = () =>
  <Routes>
    <Route path={USER_FLOW_PATH + '/:page?'} element={<FinancingFlowContainer/>}/>
    <Route path={USER_FLOW_RESULT_PAGE_PATH} element={<FinancingResultWrapper/>}/>
  </Routes>

export default FinancingRoutes;
