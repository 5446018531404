import React from 'react'
import defaultAvatar from '../../../../assets/images/avatar.png'
import { Heading4 } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'
import { SubtitleParams } from './GuideSection'

export type GuideSectionPositiveProps = {
  subtitleParams: SubtitleParams
}

const AVATAR_WIDTH = 80

const GuideSectionPositive: React.FC<GuideSectionPositiveProps> = (
  {
    subtitleParams
  }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.guideSection.positive' })

  return (
    <div className='positive-container'>
      <div className='avatar-section'>
        <div className='grid-flex avatar-and-subtitle'>
          <div className='avatar padding-right-l palm-padding-right-m'>
            <img src={defaultAvatar} width={AVATAR_WIDTH} alt='avatar'/>
          </div>
          <div className='subtitle'>
            <Heading4>
              <Trans t={t} i18nKey='subtitle' values={subtitleParams}>
                <span className='highlight positive'/>
              </Trans>
            </Heading4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuideSectionPositive
