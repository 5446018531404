export const AgeGroup = {
  UNDER_45: 'UNDER_45',
  BETWEEN_45_AND_54: 'BETWEEN_45_AND_54',
  BETWEEN_55_AND_66: 'BETWEEN_55_AND_66',
  OVER_66: 'OVER_66'
} as const

export type AgeGroupType = keyof typeof AgeGroup

export const AgeGroupValues = Object.values(AgeGroup)
