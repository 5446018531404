import { Helmet } from 'react-helmet-async'
import React from 'react'

const AffordabilityMetaAttributes = () => (
  <Helmet>
    <title>Wie viel Haus kann ich mir leisten? Budgetrechner | Immobilienscout24</title>
    <meta name='description'
          content='Wie viel Haus kann ich mir leisten? ➜ Um die Kosten f&uuml;r den Hauskauf und das anschlie&szlig;ende Hauskauf-Darlehen realistisch zu planen, m&uuml;ssen Sie Ihre monatliche Belastbarkeit kalkulieren. Mit dem Immobilienscout24 Budgetrechner ✓ finden Sie praktische Hilfe ✓ und wichtige Tipps ✓ zur Immobilienfinanzierung'/>
    <meta name="robots" content="index, follow"/>
    <link rel='canonical'
          href='https://www.immobilienscout24.de/baufinanzierung/wie-viel-haus-kann-ich-mir-leisten/'/>
  </Helmet>
)

export default AffordabilityMetaAttributes
