import React from 'react'
import { Loader } from 'is24-corecss'

export type LoadingPageProps = {
  message?: string
}

const LoadingPage: React.FC<LoadingPageProps> = ({ message }) =>
  <div>
    <div role={'progressbar'} className='padding-top-xl horizontal-center' style={{ width: '48px' }}>
      <Loader/>
    </div>
    {message && <div className='font-center padding-top-m'>{message}</div>}
  </div>

export default LoadingPage
