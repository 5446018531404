import React from 'react'
import { useTranslation } from 'react-i18next'
import benefit1 from '../../../assets/images/lead-engine/benefit-bar/benefit1.svg'
import benefit2 from '../../../assets/images/lead-engine/benefit-bar/benefit2.svg'
import benefit3 from '../../../assets/images/lead-engine/benefit-bar/benefit3.svg'
import './BenefitBar.less'

export const BenefitBar = () => {
  const { t } = useTranslation('leadEngine')

  return (
    <div className='benefit-bar'>
      <div className='benefit-bar-content font-xs'>
        <div className='align-center palm-padding-bottom-m desk-order-two-down'>
          <img alt='Benefit 1' src={benefit1}/><span>{t('benefitBar.benefit1')}</span>
        </div>
        <div className='align-center palm-padding-bottom-m'>
          <img alt='Benefit 2' src={benefit2}/><span>{t('benefitBar.benefit2')}</span>
        </div>
        <div className='align-center'>
          <img alt='Benefit 3' src={benefit3}/><span>{t('benefitBar.benefit3')}</span>
        </div>
      </div>
    </div>
  )
}
