import { useEffect, useState } from 'react'
import { getBuyersProfileUser } from '../../../services/BuyersProfileService'

const useUserFirstName = (): string | undefined => {
  const [ name, setName ] = useState<string | undefined>(undefined)

  useEffect(() => {
    getBuyersProfileUser().then(user => setName(user?.firstName?.trim()))
      .catch(() => undefined)
  }, [ setName ])

  return name
}

export default useUserFirstName
