import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { formatNumber, toCurrency } from '../../../../services/NumberUtils'
import './HouseholdBudget.less'
import { Button } from 'is24-corecss'
import { BorrowerExpensesAO } from '../../../../api/api/types/BorrowerExpensesAO.ds'
import { BorrowerIncomeAO } from '../../../../api/api/types/BorrowerIncomeAO.ds'

type HouseholdBudgetType = {
  monthlyRate: number,
  pricePerSquareMeter: number,
  savedMonthlyRent: number,
  squareMeters: number
  firstBorrowerIncome: BorrowerIncomeAO,
  firstBorrowerSpending: BorrowerExpensesAO,
  forcedRollDown: boolean
}

export const HouseholdBudget: React.FC<HouseholdBudgetType> = ({ monthlyRate, pricePerSquareMeter,
                                                                 savedMonthlyRent, squareMeters,
                                                                 firstBorrowerIncome, firstBorrowerSpending, forcedRollDown }) => {
  const [ rolled, setRolled ] = useState(forcedRollDown);
  useEffect(() => setRolled(forcedRollDown), [forcedRollDown])
  const { t } = useTranslation('odf')
  const incomeKeywords = ["salary", "alimony", "pension", "rental", "childBenefits", "other"]
  const expensesKeywords = ["rent", "buildingSaving", "saving", "alimony", "healthInsurance", "pensionInsurance", "creditRate", "other"]

  const displaySubset = (incomeKeywords: string[], subset: BorrowerExpensesAO | BorrowerIncomeAO, subsetName: string) => {
    return incomeKeywords.map((key: string) =>
      (subset[key as keyof (BorrowerExpensesAO | BorrowerIncomeAO)] !== undefined && subset[key as keyof (BorrowerExpensesAO | BorrowerIncomeAO)] !== 0) ?
        <div key={subsetName + '-' + key} className='row font-s'>
          <span className='float-left'>{t('resultPage.householdBudget.' + subsetName + "." + key)}</span>
          <span className='float-right'>{toCurrency(subset[key as keyof (BorrowerExpensesAO | BorrowerIncomeAO)] ?? 0)}</span>
        </div> : null
    )
  }

  const totalIncome = firstBorrowerIncome.total ?? 0;
  const totalExpenses = firstBorrowerSpending.total ?? 0;

  return (
    <div className='household-container padding-bottom-xxl'>
      {rolled ?
        <div>
          <div className='font-l padding-top-xl padding-bottom-xl'>
            {t('resultPage.householdBudget.title')}
          </div>
          <div className='grid grid-flex gutter-xxl'>
            <div className='grid-item desk-one-half one-whole'>
              <div className='padding-bottom-l font-l font-bold'>
                {t('resultPage.householdBudget.subTitleBefore')}
              </div>
              <div className='rows padding-bottom-xl'>
                <div className="border-bottom">
                  {displaySubset(incomeKeywords, firstBorrowerIncome, "incomes")}
                </div>
                <div className='row font-s font-bold border-bottom'>
                  <span className='float-left'>{t('resultPage.householdBudget.totalIncome')}</span>
                  <span className='float-right textColorPositive'>{toCurrency(totalIncome)}</span>
                </div>
                <div className="padding-top-l border-bottom">
                  {displaySubset(expensesKeywords, firstBorrowerSpending, "expenses")}
                </div>
                <div className='row font-s font-bold border-bottom'>
                  <span className='float-left'>{t('resultPage.householdBudget.totalExpenses')}</span>
                  <span className='float-right textColorNegative'>{toCurrency(totalExpenses === 0 ? 0 : - totalExpenses)}</span>
                </div>
                <div className='row font-s font-bold'>
                  <span className='float-left'>{t('resultPage.householdBudget.totalIncome')}</span>
                  <span className={'float-right ' + ((totalIncome - totalExpenses) > 0 ? 'textColorPositive' : 'textColorNegative')}>
                    {toCurrency(totalIncome - totalExpenses)}
                  </span>
                </div>
              </div>
            </div>
          <div className='grid-item desk-one-half one-whole'>
            <div className='padding-bottom-l font-l font-bold'>
              {t('resultPage.householdBudget.subTitleAfter')}
            </div>
            <div className='rows'>
              <div className='row font-s'>
                <span className='float-left'>{t('resultPage.householdBudget.totalIncome')}</span>
                <span className='float-right'>{toCurrency(totalIncome)}</span>
              </div>
              <div className='row font-s'>
                <span className='float-left'>{t('resultPage.householdBudget.totalExpenses')}</span>
                <span className='float-right'>{toCurrency(totalExpenses === 0 ? 0 : - totalExpenses)}</span>
              </div>
              <div className='row font-s'>
                <span className='float-left'>{t('resultPage.householdBudget.savedMonthlyRent')}</span>
                <span className='float-right'>{toCurrency(savedMonthlyRent)}</span>
              </div>
              <div className='row font-s'>
                <span className='float-left'>
                  <Trans t={t} i18nKey='resultPage.householdBudget.charge'>
                    {formatNumber(pricePerSquareMeter,2)}
                    {squareMeters.toString()}
                  </Trans>
                 </span>
                <span className='float-right'>{toCurrency(pricePerSquareMeter === 0 || squareMeters === 0 ? 0 : - (pricePerSquareMeter * squareMeters))}</span>
              </div>
              <div className='row font-s border-bottom'>
                <span className='float-left'>{t('resultPage.householdBudget.monthlyRate')}</span>
                <span className='float-right'>{toCurrency(monthlyRate === 0 ? 0 : - monthlyRate)}</span>
              </div>
            </div>
            <div className='font-l align-right'>
              {t('resultPage.householdBudget.availableFunds')}
            </div>
            <div className='align-right'>
              <div className='font-xl font-bold tealHighlighting'>
                {toCurrency(totalIncome - totalExpenses + savedMonthlyRent - (pricePerSquareMeter * squareMeters) - monthlyRate)}
              </div>
            </div>
          </div>
        </div>
      </div> : null }
      <Button className='float-right' textStyle={true} onClick={() => setRolled(!rolled)}>
        <span
          className='font-s'>{rolled ?
          t('resultPage.householdBudget.lessDetails') :
          t('resultPage.householdBudget.moreDetails')} </span>
      </Button>
    </div>
  )
}
