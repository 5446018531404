import React from 'react';
import { ShortlistEntryAO } from '../../../../../../../api/api/types/ShortlistEntryAO'

export const DEFAULT_IMAGE = 'https://pictures.immobilienscout24.de/dims3/S3/https://s3-eu-west-1.amazonaws.com/is24-search-static/graphicformissingimage.png'

const widthPlaceholder = '%WIDTH%'
const heightPlaceholder = '%HEIGHT%'
const imageWidth = 617
const imageHeight = 480

const getImageUrl = (shortlistEntry: ShortlistEntryAO) =>
  (shortlistEntry.imageUrlForWeb || shortlistEntry.imageUrl || DEFAULT_IMAGE)
    .replace(widthPlaceholder, String(imageWidth))
    .replace(heightPlaceholder, String(imageHeight))

const ShortlistImage: React.FC<{ shortlistEntry: ShortlistEntryAO }> = ({ shortlistEntry }) => (
  <div className='content image-container'>
    <div className='aspect-ratio'/>
    <a href={`/expose/${shortlistEntry.exposeId}`} target='_self'>
      <img src={getImageUrl(shortlistEntry)} alt='Bild'
           onError={() => shortlistEntry.imageUrlForWeb = DEFAULT_IMAGE}/>
    </a>
  </div>
)

export default ShortlistImage
