import React, { useEffect, useState } from 'react'
import { Slider } from 'is24-corecss'
import './GenericSliderWithInput.less'
import { toCurrency } from '../../../../services/NumberUtils'
import { NumberInput } from '../../../common/input/numericInput'

export const limitValue = (value: number, min: number, max: number, finalMaxRange?: number): number => {
  const effectiveMax = finalMaxRange ?? max
  return Math.max(Math.min(value, effectiveMax), min)
}

type GenericSliderWithInputType = {
  topLabel: string,
  id: string,
  min: number,
  max: number,
  value: number,
  onChange?: (val: number) => void,
  onRelease: (val: number) => void,
  finalMaxRange?: number
}

export const GenericSliderWithInput: React.FC<GenericSliderWithInputType> = ({
  topLabel,
  id,
  min,
  max,
  value,
  onChange,
  onRelease,
  finalMaxRange,
}) => {
  const [ inputValue, setInputValue ] = useState(value);
  const [ sliderValue, setSliderValue ] = useState(value)

  const onNumberInputBlur = () => {
    const newValue = limitValue(inputValue, min, max, finalMaxRange)
    setInputValue(newValue)
    setSliderValue(newValue)
    onChange?.(newValue)
    onRelease(newValue)
  }

  const onSliderChange = (val: number) => {
    setSliderValue(val)
    onChange?.(val)
  }

  const onSliderRelease = (val: number) => {
    setInputValue(val)
    onRelease(val)
  }

  useEffect(() => {
    setInputValue(value)
    setSliderValue(value)
  }, [ value ])

  return (
    <div className='GenericSliderWithInput'>
      <div className='grid-flex grid-justify-space-between'>
        <div className='font-m padding-top-s'>
          {topLabel}
        </div>
        <div className='number-input'>
          <NumberInput
            id={`${id}-number-input`}
            value={inputValue}
            onChange={setInputValue}
            onBlur={onNumberInputBlur}
          />
        </div>
      </div>
      <div className='padding-top-s'>
        <Slider
          id={`${id}-slider`}
          title={`${id}-slider`}
          maxValue={sliderValue}
          min={min}
          max={max}
          step={1}
          tooltip={toCurrency}
          onChange={onSliderChange}
          onRelease={onSliderRelease}
        />
      </div>
    </div>
  )
}
