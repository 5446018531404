import { Dispatch } from 'redux'
import { AffordabilityInitAction } from '../actions/Affordability'
import {
  FinancingLoadAction,
  FinancingLocalStorageLoadAction,
  loadFinancing,
  loadFinancingFromStorage
} from '../actions/Financing'
import {
  OneDynamicFlowRedirectAndExitAction,
  OneDynamicFlowRedirectToProfilePageOnResultPage,
  oneDynamicFlowRedirectAndExit,
  oneDynamicFlowRedirectToProfilePageOnResultPage,
} from '../actions/OneDynamicFlow'

export const onAffordabilityInit = () =>
  (dispatch: Dispatch<FinancingLoadAction | AffordabilityInitAction>) => {
    dispatch(loadFinancing())
  }

export const onCloseButtonClicked = () =>
  (dispatch: Dispatch<FinancingLocalStorageLoadAction | OneDynamicFlowRedirectAndExitAction>) => {
    dispatch(loadFinancingFromStorage())
    dispatch(oneDynamicFlowRedirectAndExit())
  }

export const onOpenProfileButtonClick = () =>
  (dispatch: Dispatch<FinancingLocalStorageLoadAction | OneDynamicFlowRedirectToProfilePageOnResultPage>) => {
    dispatch(loadFinancingFromStorage())
    dispatch(oneDynamicFlowRedirectToProfilePageOnResultPage())
  }
