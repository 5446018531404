import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

export type FlowNavigation = {
  currentPage: string | undefined
  getCurrentProgress: () => number
  goToNextPage: () => void
  goToPreviousPage: () => void
  goToResultPage: () => void
  goToPageReplace: (id: string) => void
  goToFlowPage: (id: string) => void
}

export const useFlowNavigation = (flowUrl: string, resultPageUrl: string, pagePaths: string[] = []): FlowNavigation => {
  const location = useLocation()
  const navigate = useNavigate();
  const { page } = useParams<{ page: string }>()
  const currentPageIndex = page ? pagePaths.indexOf(page) : -1

  const getCurrentProgress = useCallback(() => {
    return (currentPageIndex + 1) / pagePaths.length * 100
  }, [currentPageIndex, pagePaths])

  const goToFlowPage = useCallback((id: string | undefined) => {
    id && navigate({ pathname: flowUrl + '/' + id, search: location.search })
  }, [ navigate, flowUrl, location.search ])

  const goToPageReplace = useCallback((id: string) => {
    navigate({ pathname: flowUrl + '/' + id, search: location.search }, { replace: true })
  }, [ navigate, flowUrl, location.search ])

  const goToNextPage = useCallback(() => {
    goToFlowPage(pagePaths[currentPageIndex + 1])
  }, [currentPageIndex, goToFlowPage, pagePaths])

  const goToPreviousPage = useCallback(() => {
    goToFlowPage(pagePaths[currentPageIndex - 1])
  }, [currentPageIndex, goToFlowPage, pagePaths])

  const goToResultPage = useCallback(() => {
    navigate({ pathname: resultPageUrl, search: location.search })
  }, [ navigate, resultPageUrl, location.search ])

  return {
    currentPage: page,
    getCurrentProgress,
    goToNextPage,
    goToPreviousPage,
    goToResultPage,
    goToPageReplace,
    goToFlowPage
  }
}
