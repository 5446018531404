import React, { ChangeEvent, createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/berufsgruppe.jpg'
import { DialogPage } from '../dialogPage/DialogPage'
import { Button, SelectField } from 'is24-corecss'
import { Employment } from '../../../../../api/api/types/Employment'
import { isRefValid } from '../../../../common/input/refs'
import ScrollToTop from '../../../../common/scrollToTopOn/ScrollToTop'

type Props = {
  employment?: string,
  onChange: (netLoan: any) => void,
  onClick: () => void
}

export const EmploymentDialog = ({ employment, onChange, onClick }: Props) => {
  const { t } = useTranslation('userFlow')
  const [ showErrors, setShowErrors ] = useState<boolean>(false)
  const employmentRef = createRef<HTMLSelectElement>()

  // is24-corecss select component is broken. If the message is defined, it'll always display
  // no matter the isErroneous flag
  const errorMessage = showErrors && !isRefValid(employmentRef) ? `${t('employment.employmentErrorMessage')}` : ''

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    onChange(e.target.value)
  }

  const handleClick = () => {
    setShowErrors(true)
    if (isRefValid(employmentRef)) {
      onClick()
    }
  }

  const options = Object.keys(Employment)
    .map(type => <option key={type} value={type}>{t(`common:finance.values.${type}`)}</option>)

  return (
    <>
      <ScrollToTop/>
      <DialogPage avatarUrl={`${img}`} title={t('employment.title')}>
        <div className="align-center grid grid-flex margin-top-xl dialog-single-input-wrapper">
          <div className="grid-item one-whole dialog-input">
            <SelectField
              id="employment"
              label=""
              onChange={handleChange}
              ref={employmentRef}
              defaultValue={employment}
              isErroneous={showErrors && !isRefValid(employmentRef)}
              errorMessage={errorMessage}
              required
            >
              <option value="">{t('employment.employmentPlaceholder')}</option>
              {options}
            </SelectField>
          </div>

          <Button type="button" appearance="primary" size="medium" className="one-whole palm-one-whole nextBtn" onClick={handleClick}>
            {t('employment.button')}
          </Button>
        </div>
      </DialogPage>
    </>
  )
}
