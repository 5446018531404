import React from 'react'
import { Font } from 'is24-corecss'

interface ErrorMessageProps {
  id?: string
  children: React.ReactNode
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ id = 'error-message', children }) => {
  return (
    <Font id={id} color='error' className='margin-top-m align-center font-error' style={{ whiteSpace: 'pre-wrap' }}>
      {children}
    </Font>
  )
}

export default ErrorMessage
