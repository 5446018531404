import React from 'react'
import './BreadcrumbNavigation.less'

export type BreadcrumbProps = {
  items: BreadcrumbItem[]
}

export type BreadcrumbItem = {
  text: string
  href?: string
}

const BreadcrumbNavigation: React.FC<BreadcrumbProps> = ({ items }) => {

  const ICON_SEPARATOR = 'is24-icon-chevron-right'

  return (
    <div className='breadcrumb-navigation'>
      {items.map((element, index) => {
        return (
          <span className='breadcrumb-item' data-testid='breadcrumb-item' key={index}>
              <a href={element.href} className={!element.href ? 'inactive-link' : ''}>{element.text}</a>
            {index != items.length - 1 && <i className={ICON_SEPARATOR}/>}
          </span>)
      })}
    </div>
  )
}

export default BreadcrumbNavigation
