import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import img from '../../../../../assets/images/kaufpreis.jpg'
import { NumberInput } from '../../../../common/input/numericInput'
import { DialogPage } from '../dialogPage/DialogPage'
import { NetLoanType } from '../../../state/reducer'
import { formatNumber } from '../../../../../services/NumberUtils'
import LabelWithTooltip from '../../../common/labelWithTooltip/LabelWithTooltip'
import ErrorMessage from './ErrorMessage'

type Props = {
  netLoan?: Partial<NetLoanType>,
  onChange: (netLoan: any) => void
  onClick: () => void
}

export const MIN_LOAN_AMOUNT = 150_000
const MIN_OWN_FUNDS_PERCENTAGE = 10

export const NetLoan = ({ netLoan = {}, onChange, onClick }: Props) => {
  const { t } = useTranslation('userFlow')
  const [ showErrors, setShowErrors ] = useState<boolean>(false)
  const { purchasePrice = 0, ownFunds = 0 } = netLoan
  const loanAmount = Math.max(purchasePrice - ownFunds, 0)
  const isAboveMinLoanAmount = loanAmount >= MIN_LOAN_AMOUNT
  const isAboveMinOwnFunds = ownFunds / purchasePrice * 100 >= MIN_OWN_FUNDS_PERCENTAGE

  const isLoanAmountValid = (): boolean => showErrors && loanAmount > 0
  const isPurchasePriceValid = (): boolean => !showErrors || isLoanAmountValid()
  const isOwnFundsValid = (): boolean => !showErrors || (isLoanAmountValid() && isAboveMinOwnFunds)

  const handleClick = () => {
    setShowErrors(true)
    if (isAboveMinLoanAmount && isAboveMinOwnFunds) {
      onClick()
    }
  }

  const errorMessage = useMemo((): string => {
    let message = '';

    if (!isAboveMinLoanAmount && !isAboveMinOwnFunds) {
      message = t('netLoan.ownFundsAndLoanAmountTooLowErrorMessage', {
        minLoanAmount: formatNumber(MIN_LOAN_AMOUNT),
        minOwnFundsPercentage: MIN_OWN_FUNDS_PERCENTAGE
      })
    } else if (!isAboveMinLoanAmount) {
      message = t('netLoan.loanAmountTooLowErrorMessage', { minLoanAmount: formatNumber(MIN_LOAN_AMOUNT) })
    } else if (!isAboveMinOwnFunds) {
      message = t('netLoan.ownFundsTooLowErrorMessage', { minOwnFundsPercentage: MIN_OWN_FUNDS_PERCENTAGE })
    }

    return message
  }, [isAboveMinLoanAmount, isAboveMinOwnFunds, t]);

  return (
    <DialogPage avatarUrl={img} title={t('netLoan.title')} subTitle={t('netLoan.subtitle')}>
      <div className="align-center grid grid-flex margin-top-xl dialog-single-input-wrapper">
        <div className="grid-item one-whole dialog-input">
          <NumberInput
            id='purchasePrice'
            label={t('netLoan.purchasePrice')}
            value={purchasePrice}
            onChange={(value: number) => onChange({ ...netLoan, purchasePrice: value })}
            valid={isPurchasePriceValid()}
            errorMessage={t('netLoan.purchasePriceErrorMessage')}
          />
        </div>
        <div className="grid-item one-whole dialog-input">
          <NumberInput
            id='ownFunds'
            label={
              <LabelWithTooltip
                label={t('netLoan.ownFunds')}
                tooltipTitle={t('netLoan.ownFunds')}
                tooltipText={t('netLoan.ownFundsTooltip', { minOwnFundsPercentage: MIN_OWN_FUNDS_PERCENTAGE })}
              />
            }
            value={ownFunds}
            onChange={(value: number) => onChange({ ...netLoan, ownFunds: value })}
            valid={isOwnFundsValid()}
            errorMessage={t('netLoan.ownFundsErrorMessage')}
          />
        </div>
        <span id="loanAmount"
              className={`font-m font-bold align-left one-whole ${(showErrors && loanAmount <= 0) ? 'font-error' : 'font-normal'}`}>
          {formatNumber(loanAmount)} &euro;
        </span>
        <span className="align-left one-whole">{t('netLoan.loanAmount')}</span>
        <Button
          appearance="primary"
          size="medium"
          className="one-whole palm-one-whole margin-top-xl"
          onClick={handleClick}
        >
          {t('netLoan.button')}
        </Button>
      </div>
      { showErrors && <ErrorMessage>{errorMessage}</ErrorMessage> }
      <p className="margin-top-xl align-center palm-padding-bottom-l">{t('netLoan.description')}</p>
    </DialogPage>
  )
}
