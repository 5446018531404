import React from 'react'
import defaultAvatar from '../../../../assets/images/avatar.png'
import { Heading4 } from 'is24-corecss'
import { Trans, useTranslation } from 'react-i18next'
import { SubtitleParams } from './GuideSection'

export type GuideSectionNegativeProps = {
  subtitleParams: SubtitleParams
}

const AVATAR_WIDTH = 80

const GuideSectionNegative: React.FC<GuideSectionNegativeProps> = ({ subtitleParams }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.guideSection.negative' })

  return (
    <div>
      <div className='grid-flex avatar-and-subtitle'>
        <div className='avatar padding-right-l desk-padding-right-l'>
          <img src={defaultAvatar} width={AVATAR_WIDTH} alt='avatar'/>
        </div>
        <div className='subtitle'>
          <Heading4>
            <Trans t={t} i18nKey='subtitle' values={subtitleParams}>
            </Trans>
          </Heading4>
        </div>
      </div>
      <div>
        {t('description')}
      </div>
    </div>
  )
}

export default GuideSectionNegative
