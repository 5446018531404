import React, { ReactNode, useEffect } from 'react'
import TrackingService from '../../services/TrackingService/TrackingService'

export const PageViewTracker: React.FC<{ pageTitle: string, children?: ReactNode }> = ({ pageTitle, children }) => {
  useEffect(() => {
    TrackingService.reportPageView(pageTitle)
  }, [ pageTitle ])

  return <>{children}</>
}
