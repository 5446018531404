import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import './ActionButtons.less'
import { AFFORDABILITY_USER_FLOW } from '../../../../config/contextPaths'
import { useNavigate } from 'react-router-dom'

export type ActionButtonsNegativeProps = {
  restartFlowHandler: () => void
}

const ActionButtonsNegative: React.FC<ActionButtonsNegativeProps> = ({ restartFlowHandler }) => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.actionButtons' })
  const navigate = useNavigate()

  const AFFORDABILITY_USER_FLOW_WITH_TRACKING = AFFORDABILITY_USER_FLOW +
    '?cmp_id=10-051838&cmp_name=finance_affordability_calc&cmp_position=preapproval_profile&cmp_creative=back_to_affordability'

  const toAffordabilityHandler = () => navigate(AFFORDABILITY_USER_FLOW_WITH_TRACKING)

  return (
    <>
      <Button className='palm-one-whole lap-one-whole palm-margin-top-l lap-margin-top-l'
              onClick={restartFlowHandler}>
        {t('restartFlow')}
      </Button>
      <Button className='palm-one-whole lap-one-whole palm-order-one-up lap-order-one-up desk-margin-left '
              onClick={toAffordabilityHandler}
              appearance='primary'>
        {t('negative.toAffordability')}
      </Button>
    </>
  )
}

export default ActionButtonsNegative
