import React from 'react'
import { useTranslation } from 'react-i18next'
import { ShortlistEntryAO } from '../../../../api/api/types/ShortlistEntryAO'
import './SelectedObjectSection.less'
import ShortlistTile from '../../tabs/feasibility/shortlistSection/shortlistTile/ShortlistTile'
import { Button } from 'is24-corecss'
import Section from '../section/Section'
import { useOneDynamicFlow } from '../../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { useAdditionalCostDefaults } from '../../../financing/hook/useAdditionalCostDefaults'
import { useLoanCalculations } from '../../../preApproval/hook/useLoanCalculations'
import { toCurrency } from '../../../../services/NumberUtils'
import { Expose, ExposeAddress } from '../../../../api/api/Expose'

const mapExposeAddress = (address: ExposeAddress) => `${address.quarter} ${address.postcode} ${address.city}`

export const mapFromExpose = (expose: Expose): ShortlistEntryAO => ({
    exposeId: expose.id,
    title: expose.title,
    address: mapExposeAddress(expose.address),
    imageUrl: expose.picture,
    imageUrlForWeb: expose.picture,
    purchasePrice: expose.purchasePrice || 0,
    numberOfRooms: expose.numberOfRooms || 0,
    squareMeters: expose.livingArea,
    realEstateType: expose.type,
    publicationState: 'active'
  }
)

export type SelectedObjectSectionProps = {
  selectedObject: Expose,
  selectNewObject: () => void
}

const SelectedObjectSection: React.FC<SelectedObjectSectionProps> = ({ selectedObject, selectNewObject }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'common.shortlistOneObjectSection' })
  const { oneDynamicFlow } = useOneDynamicFlow()
  const additionalCost = useAdditionalCostDefaults(oneDynamicFlow.location?.postalCode, oneDynamicFlow.location?.geoCode)
  const loanCalculations = useLoanCalculations(oneDynamicFlow, additionalCost)

  return (
    <Section title={t('title')} borderBottom={false}>
      <div className='absolute-reference margin-bottom-l align-right'>
        <span className='select-new-object'>
          <Button textStyle onClick={selectNewObject}>{t('selectNewObject')}</Button>
        </span>
      </div>
      <div className='SelectedObjectSection'>
        <div className='shortlist-preview'>
          <ShortlistTile shortlistEntry={mapFromExpose(selectedObject)} previewMode/>
        </div>
        <div className='object-costs padding-left-l palm-padding-left-none'>
          <div className='object-cost-title'>{t('objectCosts.title')}</div>
          <div className='object-cost-attribute'>
            <span>{t('objectCosts.purchasePrice')}</span>
            <span data-testid='purchasePrice'>{toCurrency(oneDynamicFlow.propertyCost ?? 0)}</span>
          </div>
          <div className='object-cost-attribute'>
            <span>{t('objectCosts.ownCapital')}</span>
            <span data-testid='ownCapital'>{toCurrency(loanCalculations.totalOwnCapital ? loanCalculations.totalOwnCapital * -1 : 0)}</span>
          </div>
          <div className='object-cost-attribute'>
            <span>{t('objectCosts.additionalCost')}</span>
            <span data-testid='additionalCost'>{toCurrency(loanCalculations.additionalCosts)}</span>
          </div>
          <div className='object-cost-attribute'>
            <span>{t('objectCosts.totalCost')}</span>
            <span data-testid='totalCost'>{toCurrency(loanCalculations.totalCost)}</span>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SelectedObjectSection
