import React from 'react'
import './CallculationSummary.less'

export type RowProps = {
  firstColumn?: string,
  secondColumn?: string,
  subRow?: boolean
}

export const Row: React.FC<RowProps> = ({ firstColumn, secondColumn, subRow }) => {

  const rowClasses = 'font-h5 palm-font-s font-bold'
  const subRowClasses = 'sub'

  return (
    <div className={`${subRow ? subRowClasses : rowClasses} row border-bottom`}>
      <p>{firstColumn}</p>
      <p className='right'>{secondColumn}</p>
    </div>)
}
