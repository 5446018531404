import React from 'react'
import './AffordabilityResults.less'
import { Trans, useTranslation } from 'react-i18next'
import { toCurrency } from '../../../../../../services/NumberUtils'
import Section from '../../../../common/section/Section'
import TotalCostDonutChart from '../../../../../affordability/resultPage/totalCostDonutChart/TotalCostDonutChart'
import { AFFORDABILITY_USER_FLOW_RESTART, RESULTS_AFFORDABILITY, } from '../../../../../../config/contextPaths'
import { AffordabilityResult } from '../../../../../affordability/services/AffordabilityResultService'

export type AffordabilityResultsProps = {
  result?: AffordabilityResult
}

const AffordabilityResults: React.FC<AffordabilityResultsProps> = ({ result }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.affordabilitySection.results' })
  const sectionTitle =
    <Trans t={t} i18nKey='title' values={{ totalCost: toCurrency(result?.totalCost || 0) }}><span
      className='Highlight'/>
    </Trans>

  return (
    <Section title={sectionTitle} className='AffordabilityResultsSection'>
      <a className='AffordabilityResultsButton' href={RESULTS_AFFORDABILITY}>
        {t('goToAffordabilityResultPage')}
      </a>
      <div className='AffordabilityResults'>
        <div className='DescriptionContainer'>
          <div>{t('description1')}</div>
          <div className='padding-top-xl'>{t('description2')}</div>
        </div>
        <div className='DonutContainer'>
          <TotalCostDonutChart
            maxLoan={result?.maxLoanAmount || 0}
            ownCapital={result?.totalOwnFunds || 0}
            totalCost={result?.totalCost || 0}
            profileStyling
          />
        </div>
        <div className='ButtonContainer'>
          <a href={AFFORDABILITY_USER_FLOW_RESTART} className='button' role='button'>
            {t('goToFlowButton')}
          </a>
        </div>
      </div>
    </Section>
  )
}

export default AffordabilityResults
