import React from 'react'
import { useTranslation } from 'react-i18next'
import './HeaderSection.less'
import ClaraAvatar from '../../common/clara/ClaraAvatar'
import { StepDescription } from '../stepDescription/StepDescription'
import { TabId } from '../tabs/ProfileTabs'

export type HeaderSectionType = {
  firstName?: string
  stepIndex: number
  stepId: TabId
}

export const HeaderSection: React.FC<HeaderSectionType> = ({ firstName, stepIndex, stepId }) => {

  const { t } = useTranslation('profile', { keyPrefix: 'headerSection' })

  const name = firstName && firstName.trim().length > 0 ? ' ' + firstName : ''

  return (
    <div className='HeaderSection grid grid-flex grid-fill-rows flex-nowrap'>
      <div className='avatar grid-item grid-item-fixed-width'>
        <ClaraAvatar/>
      </div>
      <div className='section-text grid-item'>
        <div>
          <span className='palm-font-bold'>
            {t('title.1')}{name},
          </span>
          <span className='lap-hide desk-hide'><br/></span> {t('title.2')}
        </div>
        <StepDescription stepIndex={stepIndex} stepId={stepId} className='palm-hide'/>
      </div>
    </div>
  )
}
