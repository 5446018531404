import React, { useEffect } from 'react'
import { Is24Template } from '../common/snippets/Is24Template'
import { logInCheck } from '../../redux/actions/Login'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/store'
import { LogInState } from '../../redux/reducers/Login'
import LoadingPage from '../common/loadingPage/LoadingPage'
import { redirectToSsoForLogin } from './services/ProfileService'
import ProfilePage from './ProfilePage'
import { loadFinancingFromStorage } from '../../redux/actions/Financing'

const ProfilePageWrapper: React.FC = () => {
  const dispatch = useDispatch()
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)

  useEffect(() => {
    dispatch(loadFinancingFromStorage())
    dispatch(logInCheck())
  }, [ dispatch ])

  useEffect(() => {
    if (loggedIn === false) {
      redirectToSsoForLogin()
    }
  }, [ loggedIn ])

  return (
    !loggedIn ? <LoadingPage/> :
      <Is24Template backToOverview headerType='default' limitWidth>
        <ProfilePage/>
      </Is24Template>
  )
}

export default ProfilePageWrapper

