import { MORTGAGE_PROFILE, RESULTS_AFFORDABILITY } from '../../../config/contextPaths'
import { getStoredEntryPointUrl } from '../services/EntryPointService'

const containsProfileUrl = (url?: string): boolean => url ? url.indexOf(MORTGAGE_PROFILE) > -1 : false

const useAffordabilityResultPageUrl = (): string => {
  const storedEntryPointUrl = getStoredEntryPointUrl()
  return containsProfileUrl(storedEntryPointUrl) ? MORTGAGE_PROFILE : RESULTS_AFFORDABILITY
}

export default useAffordabilityResultPageUrl
