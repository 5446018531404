import React from 'react'
import { ShortlistEntryAO } from '../../../../../../api/api/types/ShortlistEntryAO'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'
import ShortlistTiles from '../shortlistTiles/ShortlistTiles'

export type ShortlistProps = {
  totalCount: number
  visibleEntries: ShortlistEntryAO[]
  showAll: boolean,
  setShowAll: (value: boolean) => void
}

const Shortlist: React.FC<ShortlistProps> = ({
  totalCount,
  visibleEntries,
  showAll,
  setShowAll
}) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.shortlistSection.shortlist' })
  const withShowAllLink = !showAll && totalCount > visibleEntries.length

  return (
    <div className='ProfileShortlist'>
      <div className='margin-vertical'>{t('totalRows', { count: totalCount})}</div>
      <ShortlistTiles shortlistEntries={visibleEntries}/>
      {withShowAllLink &&
        <div className='align-right'>
          <Button textStyle onClick={() => setShowAll(true)}>{t('showAll')}</Button>
        </div>
      }
    </div>
  )
}

export default Shortlist
