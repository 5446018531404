import { Dispatch } from 'redux'

import {
  gdprObjectionSendFailure,
  gdprObjectionSendRequest,
  gdprObjectionSendSuccess
} from './actions'
import { sendGdprContactRequestObjection } from '../../../api/api/Gdpr'


export const sendGdprContactRequestObjectionThunk = (contactRequestId: number, token: string)  => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(gdprObjectionSendRequest())
    await sendGdprContactRequestObjection(contactRequestId, token)
    dispatch(gdprObjectionSendSuccess())
  } catch(error) {
    if (error instanceof Error) dispatch(gdprObjectionSendFailure(error.toString()))
  }
}
