import React from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/reason.jpg'
import { DialogPage } from '../dialogPage/DialogPage'
import {
  FinancingType as TypeOfFinancingType,
  FinancingTypeValue
} from '../../../../../api/api/types/FinancingType'
import { Chips } from '../../../../common/chips/Chips'
import { useFeature } from '@optimizely/react-sdk';
import { Feature } from '../../../../../config/optimizely';

interface Props {
  financingType?: TypeOfFinancingType,
  onChange: (financingType: TypeOfFinancingType) => void
}

export const FinancingType = ({ financingType, onChange }: Props) => {
  const { t } = useTranslation('userFlow')

  const [ followUpFinancingEnabled ] = useFeature(Feature.FOLLOWUP_FINANCING_MA_FLOW)

  const chips = followUpFinancingEnabled ? [
    {
      key: FinancingTypeValue.PurchaseFinancing,
      label: t('financingType.chips.buy')
    },
    {
      key: FinancingTypeValue.BuildingFinancing,
      label: t('financingType.chips.build')
    },
    {
      key: FinancingTypeValue.FollowupFinancing,
      label: t('financingType.chips.followup')
    },
  ] : [
    {
      key: FinancingTypeValue.BuildingFinancing,
      label: t('financingType.chips.build')
    },
    {
      key: FinancingTypeValue.PurchaseFinancing,
      label: t('financingType.chips.buy')
    },
  ]

  return (
    <DialogPage avatarUrl={`${img}`} title={t('financingType.title')}>
      <div className="grid grid-flex grid-justify-center align-center" style={{ whiteSpace: 'nowrap' }}>
        <Chips<TypeOfFinancingType> chips={chips} value={financingType} onClick={onChange} />
      </div>
    </DialogPage>
  )
}
