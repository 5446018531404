import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadFinancingFromStorage } from '../../../redux/actions/Financing'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { PRE_APPROVAL_USER_FLOW } from '../../../config/contextPaths'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'
import { shouldShowEmployedSinceQuestionFor } from '../services/PreApprovalService'
import { isDefined } from '../../../utils/utils'
import { BuyersProfileDataState, useBuyersProfileData } from '../../../hooks/useBuyersProfileData'
import { useFeature } from '@optimizely/react-sdk'
import { Feature } from '../../../config/optimizely'
import { useNavigate } from 'react-router-dom'

const isEmpty = (oneDynamicFlow: Partial<OneDynamicFlowState>) =>
  !oneDynamicFlow || Object.keys(oneDynamicFlow).length === 0

export const isFilledEnoughToShowResults = (oneDynamicFlow: Partial<OneDynamicFlowState>) =>
  !!oneDynamicFlow?.location?.postalCode &&
  isDefined(oneDynamicFlow.exposeId) &&
  !!oneDynamicFlow?.propertyCost &&
  getIncomeTotal([ oneDynamicFlow?.income ?? {} ]) > 0 &&
  !!oneDynamicFlow?.dateOfBirth &&
  !!oneDynamicFlow?.employmentType &&
  (!!oneDynamicFlow.employedSince || !shouldShowEmployedSinceQuestionFor(oneDynamicFlow.employmentType)) &&
  isDefined(oneDynamicFlow?.otherRemainingDebts)

const usePreApprovalFlowDataCheck = () => {

  const { oneDynamicFlow } = useOneDynamicFlow()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ showContactInFlow, /*unused*/, /*optimizelyReady, optimizelyTimedOut*/ ] = useFeature(Feature.BAUFI_READY_SHOW_CONTACT_IN_FLOW)
  const [ odfValidationFinished, setOdfValidationFinished ] = useState(false)
  const { state: buyersProfileState } = useBuyersProfileData(showContactInFlow && odfValidationFinished)

  useEffect(() => {
    if (isEmpty(oneDynamicFlow)) {
      dispatch(loadFinancingFromStorage())
    } else if (!isFilledEnoughToShowResults(oneDynamicFlow)) {
      navigate(PRE_APPROVAL_USER_FLOW, { replace: true })
    } else {
      setOdfValidationFinished(true)
    }
  }, [ dispatch, oneDynamicFlow, navigate ])

  useEffect(() => {
    if (buyersProfileState === BuyersProfileDataState.INVALID ||
      buyersProfileState === BuyersProfileDataState.LOADING_FAILED) {

      navigate(PRE_APPROVAL_USER_FLOW, { replace: true })
    }
  }, [ buyersProfileState, navigate ])
}

export default usePreApprovalFlowDataCheck
