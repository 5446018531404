import React from 'react';

const ShortlistAttribute: React.FC<{ label: string, value: string, border?: boolean }> = ({ label, value, border }) => (
  <div className={`grid padding-vertical-s shortlist-preview-attribute-padding ${border ? 'border-bottom' : ''}`}>
    <div className='grid-item five-tenths font-ellipsis'>{label}</div>
    <div className='grid-item five-tenths font-ellipsis align-right'>{value}</div>
  </div>
)

export default ShortlistAttribute
