import React from 'react';
import { ShortlistEntryAO } from '../../../../../../../api/api/types/ShortlistEntryAO'
import ShortlistAttribute from './ShortlistAttribute'
import { toCurrency } from '../../../../../../../services/NumberUtils'
import { useTranslation } from 'react-i18next'

const noDataLabel = '-'

const ShortlistAttributes: React.FC<{ shortlistEntry: ShortlistEntryAO }> = ({ shortlistEntry }) => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.shortlistSection.shortlistTile' })

  const purchasePrice = shortlistEntry.purchasePrice ? toCurrency(shortlistEntry.purchasePrice) : noDataLabel
  const numberOfRooms = shortlistEntry.numberOfRooms ?`${shortlistEntry.numberOfRooms} ${t('numberOfRoomsUnit')}` : noDataLabel
  const squareMeters = shortlistEntry.squareMeters ? `${shortlistEntry.squareMeters} ${t('squareMetersUnit')}` : noDataLabel

  return <>
    <ShortlistAttribute label={t('purchasePrice')} value={purchasePrice} border/>
    <ShortlistAttribute label={t('numberOfRooms')} value={numberOfRooms} border/>
    <ShortlistAttribute label={t('squareMeters')} value={squareMeters}/>
  </>
}

export default ShortlistAttributes
