import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  USER_FLOW_PATH, USER_FLOW_RESULT_PAGE_PATH
} from '../../config/contextPaths'
import PreApprovalFlowContainer from './userFlow/PreApprovalFlowContainer'
import PreApprovalResultWrapper from './resultPage/PreApprovalResultWrapper'

const PreApprovalRoutes: React.FC = () =>
  <Routes>
    <Route path={USER_FLOW_PATH + '/:page?'} element={<PreApprovalFlowContainer/>}/>
    <Route path={USER_FLOW_RESULT_PAGE_PATH} element={<PreApprovalResultWrapper/>}/>
  </Routes>

export default PreApprovalRoutes;
