import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FinancingStartType,
  FollowupFinancingStart,
  InitialFinancingStart,
} from '../../../api/api/types/FinancingStart'
import { Chips } from '../../common/chips/Chips'
import { FinancingType as TypeOfFinancingType, FinancingTypeValue } from '../../../api/api/types/FinancingType';

interface Props {
  value?: FinancingStartType,
  financingType?: TypeOfFinancingType,
  onSelect: (financingStart: FinancingStartType) => void
}

export const FinancingStartSelect = ({ value, onSelect, financingType }: Props) => {
  const { t } = useTranslation('userFlow')

  const chips = financingType === FinancingTypeValue.FollowupFinancing ? [
    {
      key: FollowupFinancingStart.IN_LESS_THAN_ONE_YEAR,
      label: t('financingStart.chips.IN_LESS_THAN_ONE_YEAR')
    },
    {
      key: FollowupFinancingStart.IN_ONE_YEAR,
      label: t('financingStart.chips.IN_ONE_YEAR')
    },
    {
      key: FollowupFinancingStart.IN_TWO_YEARS,
      label: t('financingStart.chips.IN_TWO_YEARS')
    },
    {
      key: FollowupFinancingStart.IN_THREE_YEARS,
      label: t('financingStart.chips.IN_THREE_YEARS')
    },
    {
      key: FollowupFinancingStart.IN_FOUR_YEARS,
      label: t('financingStart.chips.IN_FOUR_YEARS')
    },
    {
      key: FollowupFinancingStart.IN_FIVE_YEARS,
      label: t('financingStart.chips.IN_FIVE_YEARS')
    }
  ] : [
    {
      key: InitialFinancingStart.IN_LESS_THAN_THREE_MONTH,
      label: t('financingStart.chips.IN_LESS_THAN_THREE_MONTH')
    },
    {
      key: InitialFinancingStart.IN_BETWEEN_THREE_AND_SIX_MONTHS,
      label: t('financingStart.chips.IN_BETWEEN_THREE_AND_SIX_MONTHS')
    },
    {
      key: InitialFinancingStart.IN_MORE_THAN_SIX_MONTH,
      label: t('financingStart.chips.IN_MORE_THAN_SIX_MONTH')
    }
  ]

  return <Chips<FinancingStartType>
    chips={chips}
    value={value}
    onClick={onSelect}
  />
}
