import { postFinancingRequest, REQUEST_SOURCE } from '../../../api/api/ContactRequest'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { createContactRequest } from './ContactRequestMapper'
import { AdditionalCosts, Proposal } from '../resultPage/model/PreApprovalResults'
import { Expose, fetchExposeEntity } from '../../../api/api/Expose'

export interface ContactInformation {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const getExpose = (exposeId: number): Promise<Expose | undefined> => fetchExposeEntity(exposeId)
  .catch(error => {
    console.error('Error while fetching expose data:', error)
    return Promise.resolve(undefined)
  })

export const sendContactRequest = async (
  oneDynamicFlow: OneDynamicFlowState,
  contactInformation: ContactInformation,
  proposal: Proposal,
  additionalCosts: AdditionalCosts
): Promise<{ id: number }> => {
  const expose = oneDynamicFlow.exposeId ? await getExpose(oneDynamicFlow.exposeId) : undefined
  const contactRequest = createContactRequest(oneDynamicFlow, contactInformation, proposal, additionalCosts, expose)
  try {
    return await postFinancingRequest(contactRequest, REQUEST_SOURCE.PREAPPROVAL)
  } catch (error: unknown) {
    throw new Error('Failed submitting financing request', { cause: error })
  }
}
