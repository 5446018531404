import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'is24-corecss'

import './BackButton.less'

type Props = {
  goToPrevious: () => void
}

const BackButton = ({ goToPrevious }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="MortgageOfficerDialogBackButton">
      <Button appearance="secondary" textStyle onClick={goToPrevious}>
        <span className="is24-icon-chevron-left arrow" />
        <span className="margin-left-s palm-hide">
          {t('common:button.back')}
        </span>
      </Button>
    </div>
  )
}

export default BackButton
