import React from 'react'
import { useTranslation } from 'react-i18next'
import { AFFORDABILITY_USER_FLOW_RESTART } from '../../../../config/contextPaths'

const RestartButton: React.FC = () => {
  const { t } = useTranslation('odf')

  return (
    <a
      className='button-inverted palm-one-whole'
      href={AFFORDABILITY_USER_FLOW_RESTART}>
      {t('resultPage.actionButtons.restartFlow')}
    </a>
  )
}

export default RestartButton
