import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneDynamicFlowState } from '../../../../../redux/reducers/OneDynamicFlow'

import { NumberInput } from '../../../input/numericInput'
import { OneDynamicFlowQuestion } from '../../OneDynamicFlowQuestion'
import { FooterProps } from '../../wizard/common/footer/Footer'
import { CloseButtonProps } from '../../wizard/common/closeButton/CloseButton'


export type SizeProps = Pick<OneDynamicFlowState, 'numberOfBorrowers' | 'floorArea'> & {
  onChange: (floorArea: number) => void
} & FooterProps & CloseButtonProps

export const isValidFloorArea = (floorArea: number | undefined) =>
  !!floorArea && floorArea >= 20 && floorArea <= 300


export const Size: React.FC<SizeProps> =
  ({
     floorArea,
     numberOfBorrowers = 1,
     onChange,
     onBack,
     onNext,
     onClose,
     progress
   }) => {
    const { t } = useTranslation('odf')
    const [ touched, setTouched ] = useState(!!floorArea)

    return (
      <OneDynamicFlowQuestion
        title={t('userFunnel.objectSize.title', { count: numberOfBorrowers })}
        description={t('userFunnel.objectSize.description', { count: numberOfBorrowers })}
        arrow='long-arrow-bottom-middle-left'
        disabled={!isValidFloorArea(floorArea)}
        touched={touched && (floorArea ?? 0) > 0}
        onBack={onBack}
        onNext={onNext}
        progress={progress}
        onClose={onClose}
      >
        <NumberInput
          id='floorArea'
          placeholder=''
          label=''
          valid={!touched || (touched && isValidFloorArea(floorArea))}
          errorMessage={t('userFunnel.objectSize.errorMessage')}
          value={floorArea}
          onChange={onChange}
          onBlur={() => setTouched(true)}
          unitRight='sqm'
          className='align-left'
          ariaLabel={t('userFunnel.objectSize.propertySize')}
        />
      </OneDynamicFlowQuestion>
    )
  }


