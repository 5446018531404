import React from 'react'
import { useTranslation } from 'react-i18next'
import { IncomeRangeType, IncomeRangeValues } from '../../../../../api/api/types/IncomeRange.ds'
import img from '../../../../../assets/images/illu-save-money.png'
import { DialogPage } from '../dialogPage/DialogPage'
import { Chips } from '../../../../common/chips/Chips'

type Props = {
  netIncomeRange?: IncomeRangeType;
  onClick: (netIncomeRange: IncomeRangeType) => void;
};

export const IncomeDialog = ({ netIncomeRange, onClick }: Props) => {

  const { t } = useTranslation('userFlow');

  const chips = IncomeRangeValues.map(value => {
    return {
      key: value,
      label: t(`common:finance.values.${value}`)
    }
  })

  return (
    <DialogPage avatarUrl={`${img}`} title={t('incomeRange.title')}>
      <div
        className="grid grid-flex grid-justify-center align-center palm-padding-bottom-l"
        style={{ whiteSpace: 'nowrap' }}>
        <Chips<IncomeRangeType> chips={chips} value={netIncomeRange} onClick={onClick}/>
      </div>
    </DialogPage>
  )
}
