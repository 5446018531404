import { useState } from 'react'
import {
  FinancingResult,
  FinancingResultError,
  userInputDefaults
} from '../resultPage/model/FinancingResults'
import { useFinancingProbability } from './useFinancingProbability'
import { useLoanAmount } from './useLoanAmount'
import { useAmortizationCalculation } from './useAmortizationCalculation'
import { FinancingProbability } from '../../../api/preApproval/FinancingProbability'

export type FinancingResultState = {
  loanAmount: number
  result?: FinancingResult
  probability?: FinancingProbability
  error?: FinancingResultError
}

export const useFinancingResults = (userInput = userInputDefaults): FinancingResultState => {
  const [ error, setError ] = useState<FinancingResultError | undefined>(undefined)
  const loanAmount = useLoanAmount(error, setError)
  const probability = useFinancingProbability(loanAmount, setError)
  const result = useAmortizationCalculation(userInput, loanAmount, setError)

  return {
    loanAmount,
    result,
    probability,
    error
  }
}
