import React from 'react'
import { useTranslation } from 'react-i18next'
import { AFFORDABILITY_USER_FLOW } from '../../../../../../config/contextPaths'
import affordabilityCalcImage from '../../../../../../assets/images/profile/affordability-calculator.png'
import './AffordabilityTeaser.less'
import Section from '../../../../common/section/Section'

const AffordabilityTeaser: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'feasibilityTab.affordabilitySection.teaser' })
  return (
    <Section title={t('title')}>
      <div className='AffordabilityTeaser'>
        <img className='AffordabilityCalcImage palm-hide' src={affordabilityCalcImage} alt='screenshot'/>
        <div className='TeaserContent'>
          <p className='TeaserText'>{t('text')}</p>
          <img className='AffordabilityCalcImage desk-hide lap-hide' src={affordabilityCalcImage} alt='screenshot'/>
          <a className='TeaserButton button palm-one-whole' role='button'
             href={AFFORDABILITY_USER_FLOW}>{t('goToFlowButton')}</a>
        </div>
      </div>
    </Section>
  )
}

export default AffordabilityTeaser
