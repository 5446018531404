import React from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/magnifying-glass.jpg'
import { DialogPage } from '../dialogPage/DialogPage'
import { FinancingProjectStatePageSelect } from '../../../common/FinancingProjectStatePageSelect'
import { BuildingProjectStateType, PurchaseProjectStateType } from '../../../../../api/api/types/ProjectState'
import { FinancingType } from '../../../../../api/api/types/FinancingType'

interface Props {
  initialValue?: PurchaseProjectStateType | BuildingProjectStateType
  financingType?: FinancingType
  onChange: (financingStart: PurchaseProjectStateType | BuildingProjectStateType) => void
}

export const FinancingProjectStatePage = ({ initialValue, financingType, onChange }: Props) => {
  const { t } = useTranslation('userFlow')

  return (
    <DialogPage
      avatarUrl={`${img}`}
      title={t('financingProjectState.title')}
      subTitle={t('financingProjectState.subTitle')}
    >
      <div className="grid grid-flex grid-justify-center align-center palm-padding-bottom-l" style={{ whiteSpace: 'nowrap' }}>
        <FinancingProjectStatePageSelect financingType={financingType} value={initialValue} onSelect={onChange}/>
      </div>
    </DialogPage>
  )
}
