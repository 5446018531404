import React from 'react'
import { Heading2 } from 'is24-corecss'
import { useTranslation } from 'react-i18next'

export const Headline: React.FC = () => {
  const { t } = useTranslation('profile');

  return (
    <Heading2>{t('headline')}</Heading2>
  )
}

export default Headline
