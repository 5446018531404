import React from 'react'
import ProviderUnsubscribeSuccess from './ProviderUnsubscribeSuccess'
import ProviderUnsubscribeError from './ProviderUnsubscribeError'

type MortgageProviderUnsubscribeType = {
  status: number;
  email: string;
  emailType: string;
}

const SUCCESS = 200;

const ProviderUnsubscribe: React.FC<MortgageProviderUnsubscribeType> = ({ status, email, emailType }) => {
  return (
    <div className='background-wrapper content-wrapper margin-vertical-l'>
      {status === SUCCESS ? <ProviderUnsubscribeSuccess email={email} emailType={emailType}/> :
        <ProviderUnsubscribeError/>}
    </div>
  )
}

export default ProviderUnsubscribe
