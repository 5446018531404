import React from 'react'
import { Heading1, Heading3 } from 'is24-corecss'
import defaultAvatar from '../../../../assets/images/avatar.png'
import './GuideSection.less'
import { useTranslation } from 'react-i18next'
import ProbabilityGauge from '../../../common/oneDynamicFlow/probabilityGauge/ProbabilityGauge'
import { FinancingProbability } from '../../../../api/preApproval/FinancingProbability'

const GuideSection: React.FC<{ probability: FinancingProbability}> = ({ probability }) => {
  const { t } = useTranslation('odf')

  return (
    <div className='financing-guide-section lap-padding-bottom-l palm-padding-bottom-xxl grid'>
      <div className='grid-item one-whole padding-bottom'>
        <Heading1>{t('financingFunnel.resultPage.title')}</Heading1>
      </div>
      <div className='grid-item one-whole desk-one-half'>
        <div className='grid-item one-whole grid-flex avatarAndProbability'>
          <div className='grid-item avatar padding-right-l desk-padding-right-xl float-left'>
            <img src={defaultAvatar} width={80} alt="avatar"/>
          </div>
          <div className='grid-item probabilityHeading'>
            <Heading3>{t('financingFunnel.resultPage.probabilityRating')}
              <span className={`font-bold highlight ${probability}`}>{t(`financingFunnel.resultPage.probability.${probability}`)}</span>
            </Heading3>
          </div>
        </div>
        <div className='grid-item one-whole margin-top palm-margin-top-xl'>
          <p>{t('financingFunnel.resultPage.probabilityTips')}</p>
        </div>
      </div>
      <div className='grid-item one-whole desk-one-half margin-top-l desk-padding-left-xl'>
        <ProbabilityGauge probability={probability}/>
      </div>
    </div>
  )
}

export default GuideSection
