import React, { useEffect } from 'react'
import { Is24Template } from '../../common/snippets/Is24Template'
import { logInCheck } from '../../../redux/actions/Login'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../redux/store'
import { LogInState } from '../../../redux/reducers/Login'
import LoadingPage from '../../common/loadingPage/LoadingPage'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import FinancingErrorPage from '../../financing/resultPage/errorPage/FinancingErrorPage'
import PreApprovalResultPage from './PreApprovalResultPage'
import { PRE_APPROVAL_USER_FLOW } from '../../../config/contextPaths'
import { redirectToSsoForLogin } from '../services/PreApprovalService'
import TrackingService from '../../../services/TrackingService/TrackingService'
import { usePreApprovalLoadingPageMessage } from '../hook/usePreApprovalLoadingPageMessage'
import useResetDataDependentOnCurrentEmploymentType from '../hook/useResetDataDependentOnCurrentEmploymentType'
import { useContactRequestBlockedReset } from '../hook/useContactRequestBlockedReset'
import usePreApprovalFlowDataCheck from '../hook/usePreApprovalFlowDataCheck'
import { usePreApprovalResults } from '../hook/usePreApprovalResults'
import { FinancingFeasibility } from './model/PreApprovalResults'

const PreApprovalResultWrapper: React.FC = () => {
  usePreApprovalFlowDataCheck()
  const dispatch = useDispatch()
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)
  const {
    loanAmount,
    overallFeasibility,
    proposals,
    additionalCosts,
    optimizationMessages,
    error
  } = usePreApprovalResults()
  const { oneDynamicFlow } = useOneDynamicFlow()
  const loadingPageMessage = usePreApprovalLoadingPageMessage()
  useResetDataDependentOnCurrentEmploymentType()
  useContactRequestBlockedReset()

  useEffect(() => {
    if (overallFeasibility) {
      TrackingService.reportPageView(`preapprovalfunnel.resultpage.${getProbabilityResultState(overallFeasibility)}`)
    }
  }, [ overallFeasibility ]);

  useEffect(() => {
    dispatch(logInCheck())
  }, [ dispatch ])

  useEffect(() => {
    if (loggedIn === false) {
      redirectToSsoForLogin()
    }
  }, [ loggedIn ])

  return (
    !loggedIn ? <LoadingPage message={loadingPageMessage}/> :
      <Is24Template headerType={loggedIn ? 'default' : 'light'} backToOverview={true}>
        {(!overallFeasibility && !error) && <LoadingPage message={loadingPageMessage}/>}
        {overallFeasibility && !error &&
          <PreApprovalResultPage
            overallFeasibility={overallFeasibility}
            oneDynamicFlow={oneDynamicFlow}
            proposals={proposals}
            additionalCosts={additionalCosts}
            optimizationMessages={optimizationMessages}
            error={error}/>}
        {error && <FinancingErrorPage error={error} oneDynamicFlow={oneDynamicFlow} loanAmount={loanAmount}
                                      flowUrl={PRE_APPROVAL_USER_FLOW}/>}
      </Is24Template>
  )
}

export const getProbabilityResultState = (probability?: FinancingFeasibility) => {
  return probability === FinancingFeasibility.high || probability === FinancingFeasibility.medium ? 'positive' : 'negative'
}

export default PreApprovalResultWrapper
