import { Heading1 } from 'is24-corecss'
import React from 'react'
import defaultAvatar from '../../../../../../assets/images/avatar.png'
import BackgroundWithLogo from '../backgroundWithLogo/BackgroundWithLogo'
import './Question.less'

type ArrowType =
  'long-arrow-bottom-left'
  | 'long-arrow-center'
  | 'long-arrow-bottom-middle-left'
  | 'short-arrow-bottom-right'
  | 'short-arrow-bottom-left'
  | 'long-arrow-bottom-right'
  | 'long-arrow-top-right'
  | 'short-arrow-center'

export type QuestionProps = {
  title: string,
  description: string,
  arrow: ArrowType,
  additionalClassName?: string,
}

const Question = ({ title, description, arrow, additionalClassName = '' }: QuestionProps) => (
  <BackgroundWithLogo>
    <div className={`question-container palm-padding-l desk-padding-m align-center ${additionalClassName}`}>
      <div className='text-container arrow-container'>
        <div className='avatar-container align-left'>
          <img src={defaultAvatar} className='avatar-img' alt='avatar'/>
          <div className='padding-left-l palm-padding-none'>
            <Heading1 className='font-white margin-bottom-l' weight='bold'>
              {title}
            </Heading1>
            <div className='description-container'>
              <p className='font-white custom-description-font'>
                {description}
              </p>
            </div>
          </div>
        </div>
        <span className={`arrow ${arrow}`}/>
      </div>
    </div>
  </BackgroundWithLogo>
)

export default Question



