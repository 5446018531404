import React from 'react'
import './DataTiles.less'

type DataTileProps = {
  value: string
  label: string
  highlight?: boolean
  valueClass?: string
  labelClass?: string
}

const DataTile: React.FC<DataTileProps> = ({value, label, highlight= false, valueClass = '', labelClass = ''}) => (
  <div className='DataTile palm-one-whole'>
    <div className={`DataTileValue font-xxl font-nowrap ${highlight ? 'highlight' : ''} ${valueClass}`}>{value}</div>
    <div className={labelClass}>{label}</div>
  </div>
)

export default DataTile
