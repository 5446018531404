import { BorrowerEquityCapital } from '../../../../../api/api/types/BorrowerEquityCapital';
import { isDefined, isUndefined } from '../../../../../utils/utils';

export type EquityCapitalField = keyof BorrowerEquityCapital

export const ownCapitalFields: EquityCapitalField[] = [ 'bankSavingsBalance', 'securities', 'buildingSavings', 'lifeInsuranceValue', 'otherAssets' ]

export const MAX_VALUE = 10_000_000

export const isFieldValid = (value = 0) =>
  value >= 0 && value <= MAX_VALUE

export const isEquityCapitalValid = (equityCapital: BorrowerEquityCapital | undefined): boolean =>
  isUndefined(equityCapital) ||
  (isDefined(equityCapital)
    && ownCapitalFields.every(field => isFieldValid(equityCapital[ field ]))
    && getTotal(equityCapital) <= MAX_VALUE)


export const getTotal = (equityCapital: BorrowerEquityCapital | undefined) =>
  equityCapital && ownCapitalFields.map(k => equityCapital[ k ])
    .reduce((a = 0, b = 0) => a + b) || 0
