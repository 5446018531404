import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chips } from '../../common/chips/Chips'
import {
  BuildingProjectState,
  BuildingProjectStateType,
  PurchaseProjectState,
  PurchaseProjectStateType
} from '../../../api/api/types/ProjectState'
import { FinancingType, FinancingTypeValue } from '../../../api/api/types/FinancingType'

interface PropsTypes {
  financingType?: FinancingType,
  value?: PurchaseProjectStateType | BuildingProjectStateType,
  onSelect: (financingThirdPage: PurchaseProjectStateType | BuildingProjectStateType) => void
}

export const FinancingProjectStatePageSelect = ({ financingType = FinancingTypeValue.PurchaseFinancing, value, onSelect }: PropsTypes) => {
  const { t } = useTranslation('userFlow')

  const chips = {
    PurchaseFinancing: [
      {
        key: PurchaseProjectState.ON_OBJECT_SEARCH,
        label: t('financingProjectState.chips.ON_OBJECT_SEARCH')
      },
      {
        key: PurchaseProjectState.OBJECT_FOUND,
        label: t('financingProjectState.chips.OBJECT_FOUND')
      },
      {
        key: PurchaseProjectState.PURCHASING_NEGOTIATIONS,
        label: t('financingProjectState.chips.PURCHASING_NEGOTIATIONS')
      },
      {
        key: PurchaseProjectState.NOTARY_APPOINTMENT_PLANNED,
        label: t('financingProjectState.chips.NOTARY_APPOINTMENT_PLANNED')
      }
    ],
    BuildingFinancing: [
      {
        key: BuildingProjectState.ON_BUILDER_SEARCH,
        label: t('financingProjectState.chips.ON_OBJECT_SEARCH')
      },
      {
        key: BuildingProjectState.PROPERTY_FOUND,
        label: t('financingProjectState.chips.OBJECT_FOUND')
      },
      {
        key: BuildingProjectState.NOTARY_APPOINTMENT_PLANNED,
        label: t('financingProjectState.chips.NOTARY_APPOINTMENT_PLANNED')
      }
    ]
  } as any

  return <Chips<PurchaseProjectStateType | BuildingProjectStateType> chips={chips[financingType]} value={value} onClick={onSelect} />
}
