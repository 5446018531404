import { AdditionalCostAO } from '../../../api/api/types/UserAffordabilityResultAO'
import { AffordabilityResult } from './AffordabilityResultService'
import {
  AdditionalCosts,
  AffordabilityCalculationRequest,
  AffordabilityCalculationResponse
} from '../../../api/api/types/UserAffordabilityCalculation'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { FinancingUseTypes } from '../../../api/api/types/FinancingUseType.ds'
import { BorrowerIncomeAO } from '../../../api/api/types/BorrowerIncomeAO.ds'
import { BorrowerExpensesAO } from '../../../api/api/types/BorrowerExpensesAO.ds'

const emptyAffordabilityResult: AffordabilityResult = {
  totalCost: 0,
  currentMonthlyBudget: 0,
  futureMonthlyBudget: 0,
  minMonthlyRate: 0,
  maxMonthlyRate: 0,
  monthlyRate: 0,
  pricePerSquareMeter: 0,
  savedMonthlyRent: 0,
  squareMeters: 0,
  firstBorrowerIncome: {},
  firstBorrowerSpending: {},
  totalOwnFunds: 0,
  additionalCosts: {},
  maxPurchasePrice: 0,
  maxLoanAmount: 0,
  ownFundPurchasePriceRatio: 0,
  includeBrokerCommission: true,
  fixedNominalInterestRate: '',
  financingUseType: FinancingUseTypes.UNKNOWN,
  geoCode: '',
  searchLabel: ''
}

const getNumberOrZero = (value: number | undefined): number => value ?? 0

const getTotalOwnFunds = (oneDynamicFlow: OneDynamicFlowState): number => {
  const { equityCapital } = oneDynamicFlow;
  if (!equityCapital) return 0;

  return getNumberOrZero(equityCapital.bankSavingsBalance)
    + getNumberOrZero(equityCapital.buildingSavings)
    + getNumberOrZero(equityCapital.lifeInsuranceValue)
    + getNumberOrZero(equityCapital.otherAssets)
    + getNumberOrZero(equityCapital.securities)
}

const getTotalIncome = (income: BorrowerIncomeAO): number => {
  return getNumberOrZero(income.alimony)
    + getNumberOrZero(income.childBenefits)
    + getNumberOrZero(income.other)
    + getNumberOrZero(income.pension)
    + getNumberOrZero(income.rental)
    + getNumberOrZero(income.salary)
}

const getTotalSpending = (expenses: BorrowerExpensesAO): number => {
  return getNumberOrZero(expenses.livingCost)
    + getNumberOrZero(expenses.rent)
    + getNumberOrZero(expenses.buildingSaving)
    + getNumberOrZero(expenses.saving)
    + getNumberOrZero(expenses.alimony)
    + getNumberOrZero(expenses.healthInsurance)
    + getNumberOrZero(expenses.pensionInsurance)
    + getNumberOrZero(expenses.numberOfVehicles)
    + getNumberOrZero(expenses.creditRate)
    + getNumberOrZero(expenses.other)
}

const getBorrowerIncome = (oneDynamicFlow: OneDynamicFlowState): BorrowerIncomeAO => {
  const { income } = oneDynamicFlow
  if (!income) return {}

  const total = getNumberOrZero(income.alimony)
    + getNumberOrZero(income.childBenefits)
    + getNumberOrZero(income.other)
    + getNumberOrZero(income.pension)
    + getNumberOrZero(income.rental)
    + getNumberOrZero(income.salary)

  return { ...income, total }
}

const getBorrowerExpenses = (oneDynamicFlow: OneDynamicFlowState): BorrowerExpensesAO => {
  const { expense } = oneDynamicFlow
  if (!expense) return {}

  const total = getNumberOrZero(expense.alimony)
    + getNumberOrZero(expense.buildingSaving)
    + getNumberOrZero(expense.creditRate)
    + getNumberOrZero(expense.healthInsurance)
    + getNumberOrZero(expense.livingCost)
    + getNumberOrZero(expense.other)
    + getNumberOrZero(expense.pensionInsurance)
    + getNumberOrZero(expense.rent)
    + getNumberOrZero(expense.saving)

  return { ...expense, total }
}

export const createInitialResult = (oneDynamicFlowState: OneDynamicFlowState): AffordabilityResult => ({
  ...emptyAffordabilityResult,
  squareMeters: oneDynamicFlowState.floorArea ?? 0,
  firstBorrowerIncome: getBorrowerIncome(oneDynamicFlowState),
  firstBorrowerSpending: getBorrowerExpenses(oneDynamicFlowState),
  totalOwnFunds: getTotalOwnFunds(oneDynamicFlowState),
  geoCode: oneDynamicFlowState.location?.geoCode ?? '',
  searchLabel: oneDynamicFlowState.location?.locationLabel ?? '',
  financingUseType: oneDynamicFlowState.financingUseType ?? FinancingUseTypes.OWNER_OCCUPATION,
  amortizationRate: oneDynamicFlowState.amortizationRate,
  interestRate: oneDynamicFlowState.interestRate,
  monthlyRate: oneDynamicFlowState.monthlyRate ?? 0,
  maxMonthlyRate: oneDynamicFlowState.monthlyRate ?? 0,
  includeBrokerCommission: oneDynamicFlowState.includeBrokerCommission ?? true
})

export const mapToAffordabilityCalculationRequest = (result: AffordabilityResult): AffordabilityCalculationRequest => ({
  amortizationRate: result.amortizationRate,
  interestRate: result.interestRate,
  monthlyRate: result.monthlyRate,
  renovationCostsPercentage: result.additionalCosts.renovationCostPercentage,
  includeBrokerCommission: result.includeBrokerCommission,
  totalOwnFunds: result.totalOwnFunds,
  totalIncome: getTotalIncome(result.firstBorrowerIncome),
  totalCostOfLiving: result.firstBorrowerSpending.livingCost || 0,
  geoCode: result.geoCode,
  financingUseType: result.financingUseType,
  monthlyRent: result.firstBorrowerSpending.rent || 0,
  squareMeters: result.squareMeters,
  totalSpending: getTotalSpending(result.firstBorrowerSpending)
})

const mapAdditionalCosts = (additionalCosts: AdditionalCosts): AdditionalCostAO => ({
  brokerCommission: additionalCosts.brokerCommission,
  brokerCommissionPercentage: additionalCosts.brokerCommissionPercentage,
  entryLandRegister: additionalCosts.entryLandRegister,
  entryLandRegisterPercentage: additionalCosts.entryLandRegisterPercentage,
  landTransfer: additionalCosts.landTransfer,
  landTransferPercentage: additionalCosts.landTransferPercentage,
  notaryCosts: additionalCosts.notaryCosts,
  notaryCostsPercentage: additionalCosts.notaryCostsPercentage,
  renovationCost: additionalCosts.renovationCosts,
  renovationCostPercentage: additionalCosts.renovationCostsPercentage,
  total: additionalCosts.total,
  totalPercent: additionalCosts.totalPercentage
})

export const mapFromCalculationResponse = (
  response: AffordabilityCalculationResponse,
  result: AffordabilityResult
): AffordabilityResult => ({
  ...result,
  totalCost: response.totalCosts,
  currentMonthlyBudget: response.currentMonthlyBudget,
  futureMonthlyBudget: response.futureMonthlyBudget,
  minMonthlyRate: response.minMonthlyRate,
  maxMonthlyRate: response.maxMonthlyRate,
  monthlyRate: response.monthlyRate,
  pricePerSquareMeter: response.pricePerSquareMeter,
  savedMonthlyRent: response.savedMonthlyRent,
  amortizationRate: response.amortizationRate,
  interestRate: response.interestRate,
  additionalCosts: mapAdditionalCosts(response.additionalCosts),
  maxPurchasePrice: response.maxPurchasePrice,
  maxLoanAmount: response.maxLoanAmount,
  ownFundPurchasePriceRatio: response.ownFundPurchasePriceRatio,
  error: undefined,
  warning: response.warning
})
