import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, ModalContent, TextInput } from 'is24-corecss'
import { useTranslation } from 'react-i18next'
import { getBuyersProfileUser, getFullName } from '../../../../../services/BuyersProfileService'
import './CertificateModalInputState.less'

const hasSpaces = (text: string): boolean => text.split(' ').length > 1;
export const isValidFullName = (fullName?: string) => !!fullName && hasSpaces(fullName);

export type CertificateModalInputStateProps = {
  handleDownloadCertificate: (fullName: string) => void
}

const CertificateModalInputState: React.FC<CertificateModalInputStateProps> = ({ handleDownloadCertificate }) => {
  const [ fullName, setFullName ] = useState('')
  const [ fullNameTouched, setFullNameTouched ] = useState(false)
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.resultPage.certificateModal' })

  useEffect(() => {
    getBuyersProfileUser()
      .then(user => setFullName(prevState => prevState || getFullName(user)))
      .catch(error => console.warn(`Could not fetch user's full name: ${error?.message}`))
  }, [ setFullName ])

  return (<>
    <ModalContent>
      <div className='certificate-modal-input'>
        <div>
          <div className='padding-bottom-m'>{t('description')}</div>
        </div>
        <div>
          <div className='padding-bottom-m'>
            <TextInput
              id='fullName'
              value={fullName}
              placeholder={t('fullNamePlaceholder')}
              label={t('fullName').toString()}
              errorMessage={t('fullNameErrorMessage')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFullNameTouched(true)
                setFullName(e.target.value)
              }
              }
              valid={!fullNameTouched || isValidFullName(fullName)}
            />
          </div>
        </div>
        <div className='button-container padding-top-m'>
          <Button
            className='one-whole'
            data-testid='download-certificate-button'
            onClick={() => handleDownloadCertificate(fullName)}
            appearance='primary'
            disabled={!isValidFullName(fullName)}
          >
            {t('downloadCertificate')}
          </Button>
        </div>
      </div>
    </ModalContent>
  </>)


}
export default CertificateModalInputState
