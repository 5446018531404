import React from 'react'
import { useTranslation } from 'react-i18next'

import './BackButton.less'

const BackButton: React.FC<{ onClick: () => void, disabled?: boolean }> = ({ onClick, disabled }) => {
  const { t } = useTranslation('odf')

  return (
    <div role='button' className={`affordability-back-button-container ${disabled ? 'back-button-disabled' : 'cursor-pointer'}`}
         onClick={() => !disabled && onClick()} tabIndex={0} >
      <span className='is24-icon-chevron-left arrow'/>
      <span className='margin-left-s font-bold'>
        {t('userFunnel.common.backButton')}
      </span>
    </div>
  )
}

export default BackButton
