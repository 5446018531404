import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { LEAD_ENGINE_ROAD_PAGE } from '../../../config/contextPaths'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils'

const useLeadEngineFlowUrlWithParams = (): string => {
  const { oneDynamicFlow } = useOneDynamicFlow()
  const urlBase = `${LEAD_ENGINE_ROAD_PAGE}/finanzierungsstart?`
  const searchParams = new URLSearchParams({
      openForm: 'true',
      financingType: 'PurchaseFinancing',
  })
  oneDynamicFlow?.location?.postalCode && searchParams.append('postalCode', oneDynamicFlow.location?.postalCode)
  oneDynamicFlow?.location?.geoCode && searchParams.append('geoCode', oneDynamicFlow.location?.geoCode)
  oneDynamicFlow?.propertyCost && searchParams.append('purchasePrice', oneDynamicFlow.propertyCost.toString())
  oneDynamicFlow?.exposeId && searchParams.append('exposeid', oneDynamicFlow.exposeId.toString())
  const totalEquityCapital = getTotal(oneDynamicFlow?.equityCapital)
  totalEquityCapital && searchParams.append('ownFunds', totalEquityCapital.toString())

  return urlBase + searchParams
}

export default useLeadEngineFlowUrlWithParams
