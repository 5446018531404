import React from 'react'
import { useTranslation } from 'react-i18next'
import { LEAD_ENGINE_ROAD_PAGE } from '../../../../config/contextPaths'

const LEAD_ENGINE_START_PAGE = `${LEAD_ENGINE_ROAD_PAGE}/finanzierungsstart`

const appendTrackingParams = (params: URLSearchParams) => {
  params.append('cmp_id','10-051791')
  params.append('cmp_name', 'finance_leadengine')
  params.append('cmp_position', 'finance_calc_page')
  params.append('cmp_creative', 'cta_finance_calc')
}

const getUrl = (
  additionalCosts: number,
  ownFunds: number,
  remainingDebt: number,
  geoCode?: string,
  purchasePrice?: number,
) => {
  const params = new URLSearchParams()

  appendTrackingParams(params)

  params.append('openForm', 'true')
  geoCode && params.append('geocode', geoCode)
  purchasePrice && params.append('purchaseprice', purchasePrice.toString())
  params.append('additionalcosts', additionalCosts.toString())
  params.append('ownfunds', ownFunds.toString())
  params.append('financingtype', 'PROPERTY_PURCHASE')
  params.append('remainingdebt', remainingDebt.toString())

  return `${LEAD_ENGINE_START_PAGE}?${params.toString()}`
}

export type LeadEngineButtonType = {
  geoCode?: string
  purchasePrice?: number
  additionalCosts: number
  ownFunds: number
  remainingDebt: number
}

export const LeadEngineButton: React.FC<LeadEngineButtonType> = ({
  geoCode,
  purchasePrice,
  additionalCosts,
  ownFunds,
  remainingDebt
}) => {
  const { t } = useTranslation('odf')
  const url = getUrl(
    additionalCosts,
    ownFunds,
    remainingDebt,
    geoCode,
    purchasePrice,
  )

  return (
    <div>
      <a className='button-primary palm-one-whole' href={url}>
        {t('financingFunnel.resultPage.loanDevelopmentSection.profileButtonWrapper.leadEngineButton')}
      </a>
    </div>
  )
}
