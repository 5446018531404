import React from 'react'
import ProposalsTouchPoint from '../../../../../preApproval/proposalsTouchpoint/ProposalsTouchPoint'
import Section from '../../../../common/section/Section'
import { useTranslation } from 'react-i18next'

const ProposalsTeaser: React.FC = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'offersTab.proposalSection.teaser' })

  return (
    <Section title={t('title')}>
      <ProposalsTouchPoint withBadge={false}/>
    </Section>
  )
}

export default ProposalsTeaser
