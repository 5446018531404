import React from 'react'
import { useTranslation } from 'react-i18next'

import img from '../../../../../assets/images/thumb.jpg'
import FinancingCard from './card/FinancingCard'
import ScrollToTop from '../../../../common/scrollToTopOn/ScrollToTop'
import { withHtmlMeta } from '../../../withHtmlMeta'

const Success = () => {
  const { t } = useTranslation('userFlow')

  const ctaUrl = 'https://www.immobilienscout24.de/wissen/kaufen?cmp_id=10-051453&cmp_name=buy_hub_lp&cmp_position=finance_mortgage_officer_lp&cmp_creative=confirmation_screen'

  const cards = [
    {
      title: t('success.steps.one.title'),
      content: t('success.steps.one.content')
    },
    {
      title: t('success.steps.two.title'),
      content: t('success.steps.two.content')
    },
    {
      title: t('success.steps.three.title'),
      content: t('success.steps.three.content')
    }
  ]

  return (
    <>
      <ScrollToTop/>
      <div
        className="grid grid-flex horizontal-center grid-justify-space-around margin-vertical-xxl padding-horizontal-m">
        <div className="grid-item align-center margin-bottom-xxl" style={{ maxWidth: '60rem' }}>
          <img width={120} src={img} alt="" aria-hidden/>
          <h1 className="font-xxl font-normal margin-bottom">{t('success.title')}</h1>
          <div className="margin-bottom font-l">{t('success.subtitle')}</div>
        </div>
        <div className="grid grid-flex gutter-horizontal-xl">
          {cards.map((card, index) =>
            <div key={index} className="grid-item one-whole desk-one-third margin-top-l">
              <FinancingCard stepNumber={index + 1} {...card} />
            </div>
          )}
        </div>
        <div
          className="grid-item margin-top-xxl palm-one-whole lap-one-half desk-one-third padding-bottom-l align-center">
          <div className="font-l padding-top-m">{t('success.explanation.title')}</div>

          <div className="subtitle">{t('success.explanation.subtitle')}</div>

          <a href={ctaUrl} className="one-whole button-primary margin-top-l palm-one-whole">
            {t('success.explanation.button')}
          </a>
        </div>
      </div>
    </>
  )
}

export default withHtmlMeta(Success)
