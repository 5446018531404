import { BorrowerIncomeAO } from '../../../../api/api/types/BorrowerIncomeAO.ds'
import { BorrowerExpensesAO } from '../../../../api/api/types/BorrowerExpensesAO.ds'
import { getLivingCosts } from '../../../../api/api/Financing'

export const shouldPrefillChildBenefit = (borrowerIncome: BorrowerIncomeAO | undefined, numberOfChildren: number | undefined): boolean => {
  return !!(numberOfChildren && ((borrowerIncome && borrowerIncome.childBenefits == undefined) || !borrowerIncome));
}

export const calculateChildBenefit = (numberOfChildren: number) => {
    return numberOfChildren * 250;
}

export const shouldPrefillLivingExpenses = (borrowerExpenses: BorrowerExpensesAO | undefined) => {
  return (borrowerExpenses && borrowerExpenses.livingCost == undefined) || !borrowerExpenses;
}

export const calculateLivingExpenses = (numberOfBorrowers: number | undefined, numberOfChildren: number | undefined, salary: number | undefined): Promise<number | undefined> => {
  const householdSize = (numberOfBorrowers ?? 0) + (numberOfChildren ?? 0)
  return getLivingCosts(householdSize, salary ?? 0)
    .then(result => result.livingCosts)
    .catch(error => {
      console.warn(error)
      return Promise.resolve(undefined)
    })
}

