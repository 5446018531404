import { FixedNominalInterestRate, FixedNominalInterestRateType } from '../../../api/api/types/FixedNominalInterestRate'
import { OneDynamicFlowState } from '../../../redux/reducers/OneDynamicFlow'
import { ContactRequestAO } from '../../../api/api/types/ContactRequestAO.ds'
import { FinancingUseTypes } from '../../../api/api/types/FinancingUseType.ds'
import { FinancingTypeValue } from '../../../api/api/types/FinancingType'
import { PurchaseProjectState } from '../../../api/api/types/ProjectState'
import { ContactChannel } from '../../../api/api/types/ContactChannel.ds'
import { PreApprovedBy } from '../../../api/api/types/PreApprovedBy'
import { ContactInformation } from './ContactRequestService'
import { AdditionalCosts, Proposal } from '../resultPage/model/PreApprovalResults'
import { WorkContractType, WorkContractTypeValue } from '../../../api/api/types/WorkContractTypeValue'
import { Expose } from '../../../api/api/Expose'
import { ObjectTypeType } from '../../../api/api/types/ObjectType.ds'
import { FinancingObjectAO } from '../../../api/api/types/FinancingObjectAO.ds'
import { getTotal } from '../../common/oneDynamicFlow/pages/ownCapital/ownCapital.utils';
import { getIncomeTotal } from '../../common/incomeForm/IncomeForm'

const MAX_SIZE_MESSAGE = 1024

const PROPERTY_TRANSLATION_MAP = {
  loanAmount: 'darlehensSumme',
  monthlyInstallment: 'gesamtRateProMonat',
  fixedInterestRateInYears: 'zinsbindungInJahrenMinMax',
  interestRate: 'sollZins',
  providerName: 'produktAnbieter',
  amortizationRate: 'tilgungssatzInProzent',
  feasibility: 'machbarkeit'
}

const propertiesToIgnore: (keyof Proposal)[] = [ 'financingProducts', 'providerName' ]

export const createMessage = (proposal: Proposal): string => {
  const localizedAttributeList = Object.getOwnPropertyNames(proposal)
    .filter(p => !propertiesToIgnore.includes(p as keyof Proposal))
    .map(p => PROPERTY_TRANSLATION_MAP[ p as keyof typeof PROPERTY_TRANSLATION_MAP ] + ': ' + proposal[ p as keyof Partial<Proposal> ])
  const providerNames = proposal.financingProducts?.map(product => product.providerName).join('+')
  const providerNameLocalizedAttribute = PROPERTY_TRANSLATION_MAP[ 'providerName' ] + ': ' + providerNames
  return [ providerNameLocalizedAttribute, ...localizedAttributeList ]
    .join(',\n')
    .substring(0, MAX_SIZE_MESSAGE)
}

export const getFixedNominalInterestRateFromRange = (fixedInterestRateInYearsRange: string): FixedNominalInterestRateType => {
  const value: number = fixedInterestRateInYearsRange.includes("-")
    ? Number(fixedInterestRateInYearsRange.split("-")[1])
    : Number(fixedInterestRateInYearsRange);

  if (value <= 5) return FixedNominalInterestRate.FIVE_YEARS;
  if (value <= 10) return FixedNominalInterestRate.TEN_YEARS;
  if (value <= 15) return FixedNominalInterestRate.FIFTEEN_YEARS;
  if (value <= 20) return FixedNominalInterestRate.TWENTY_YEARS;
  if (value <= 30) return FixedNominalInterestRate.THIRTY_YEARS;

  return FixedNominalInterestRate.FORTY_YEARS;
}

export const getAdditionalCostsTotal = (additionalCosts: AdditionalCosts): number => {
  return additionalCosts.brokerCommission + additionalCosts.notaryCosts + additionalCosts.landTransferTax;
}

const toWorkContractType = (fixedTermEmployment: boolean | undefined): WorkContractType | undefined => {
  if (typeof fixedTermEmployment === 'undefined') {
    return undefined;
  }
  return fixedTermEmployment ? WorkContractTypeValue.LIMITED : WorkContractTypeValue.PERMANENT;
}

const toFinancingObjectType = (exposeType: string): ObjectTypeType => {
  if (exposeType === 'HouseBuy') return 'HOUSE_BUY'
  if (exposeType === 'ApartmentBuy') return 'APARTMENT_BUY'
  if (exposeType === 'LivingBuySite') return 'LIVING_BUY_SITE'
  return 'HOUSE_BUY'
}

const toFinancingObject = (expose: Expose | undefined): FinancingObjectAO | undefined => {
  if (expose) {
    const placeholderWhenNoValue = 'nicht verfügbar'
    const streetPlaceholder = expose?.address?.houseNumber ? 'Strasse nicht verfügbar' : 'Strasse & Hausnr. nicht verfügbar'
    return {
      street: expose.address?.street ?? streetPlaceholder,
      streetNumber: expose.address?.houseNumber ?? '-',
      postalCode: expose.address?.postcode ?? '00000',
      city: expose.address?.city ?? placeholderWhenNoValue,
      type: toFinancingObjectType(expose.type),
      livingArea: expose?.livingArea,
      siteArea: expose?.siteArea,
      constructionYear: expose?.constructionYear,
    }
  }
  return undefined
}

export const createContactRequest = (
  oneDynamicFlow: OneDynamicFlowState,
  contactInformation: ContactInformation,
  proposal: Proposal,
  additionalCosts: AdditionalCosts,
  expose: Expose | undefined,
): ContactRequestAO => {
  return {
    useType: FinancingUseTypes.UNKNOWN,
    financingTerms: {
      postalCode: oneDynamicFlow.location?.postalCode,
      geoCode: oneDynamicFlow.location?.geoCode,
      locationName: oneDynamicFlow.location?.locationLabel,
      employment: oneDynamicFlow.employmentType,
      amortizationRate: proposal.amortizationRate,
      fixedNominalInterestRate: getFixedNominalInterestRateFromRange(proposal.fixedInterestRateInYears),
      financing: {
        type: FinancingTypeValue.PurchaseFinancing,
        financingStart: 'NA', // NA, IN_LESS_THAN_THREE_MONTH, IN_BETWEEN_THREE_AND_SIX_ MONTHS, IN_MORE_THAN_SIX_MONTH
        projectState: PurchaseProjectState.OBJECT_FOUND,
        purchasePrice: oneDynamicFlow!.propertyCost!,
        ownFunds: getTotal(oneDynamicFlow.equityCapital),
        additionalCosts: getAdditionalCostsTotal(additionalCosts)
      }
    },
    netIncome: getIncomeTotal([oneDynamicFlow.income || {}]),
    income: {
      alimony: oneDynamicFlow.income?.alimony,
      childBenefits: oneDynamicFlow.income?.childBenefits,
      other: oneDynamicFlow.income?.other,
      pension: oneDynamicFlow.income?.pension,
      rental: oneDynamicFlow.income?.rental,
      salary: oneDynamicFlow.income?.salary
    },
    financingObject: toFinancingObject(expose),
    contactChannel: ContactChannel.TELEPHONE,
    forename: contactInformation.firstName,
    surname: contactInformation.lastName,
    dateOfBirth: oneDynamicFlow.dateOfBirth,
    email: contactInformation.email,
    phoneNumber: contactInformation.phoneNumber,
    numberOfChildren: 0, // set to 0 by default to dispatch lead with extended lead data
    exposeId: oneDynamicFlow.exposeId,
    workContractType: toWorkContractType(oneDynamicFlow.fixedTermEmployment),
    preApprovedBy: PreApprovedBy.EUROPACE,
    currentCredits: oneDynamicFlow.otherRemainingDebts,
    message: createMessage(proposal)
  }
}
