import React, { MouseEventHandler } from 'react'
import { Button } from 'is24-corecss'
import { PROVIDER_COMPARISON_PAGE } from '../../config/contextPaths'

type ContactRequestObjectionComponentType = {
  onSend: MouseEventHandler<HTMLButtonElement>;
  objectionSuccess?: boolean;
  error?: boolean;
}

const ContactRequestObjectionComponent: React.FC<ContactRequestObjectionComponentType> =
  ({ onSend, objectionSuccess, error }) => {

    const handleCancel = () => {
      window.location.href = PROVIDER_COMPARISON_PAGE
    }

    return (
      <div className="background-wrapper content-wrapper margin-vertical-l">
        <h2 id='title'>Widerspruch zur Nutzung meiner Daten für die weitere Empfehlung von
          Baufinanzierungsanbietern</h2>

        {objectionSuccess && <div className="status-message status-message--short status-confirm">
          <h4>Erfolgreich</h4><p>Wir haben deinen Widerspruch erhalten.</p></div>}

        {objectionSuccess && <p className='padding-top-m'>
          Wir haben deinen Widerspruch zur weiteren Nutzung deiner Daten für Baufinanzierungsempfehlungen
          erhalten. Bitte beachte, dass wir bis zu 72h benötigen, bis dein Widerspruch in unseren Systemen
          verarbeitet wurde. Weitere Informationen zur Verarbeitung deiner personenbezogenen Daten bei
          ImmoScout24 findest du&nbsp;
          <a href='https://www.immobilienscout24.de/agb/datenschutz.html' target="_blank" rel="noreferrer">hier</a>.
        </p>}

        {error && <div className="status-message status-message--short status-error">
          <h4>Ein Fehler ist aufgetreten.</h4><p>Bitte versuchen Sie es später erneut.</p></div>}

        <p className='padding-top-m'>
          Beachte: Die Nutzung bereits übermittelter Daten unterliegen der selbständigen Verantwortung der jeweiligen
          Baufinanzierungsanbieter:innen. Diese sind somit deine direkten Ansprechpartner:innen für Widersprüche und
          sonstige Datenschutzrechte.
        </p>

        {!objectionSuccess && <div className="grid grid-flex grid-justify-space-between padding-top-xxl">
          <Button id='btn_cancel' onClick={handleCancel}>Abbrechen</Button>
          <Button id='btn_submit' appearance='primary' onClick={onSend}>Widerspruch senden</Button>
        </div>}
      </div>
    )
  }

export default ContactRequestObjectionComponent
