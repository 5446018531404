import React from 'react'
import ValueWithLabel from '../../../common/valueWithLabel/ValueWithLabel'
import { useTranslation } from 'react-i18next'
import './ProposalSkeleton.less'

const ProposalSkeleton: React.FC = () => {
  const { t } = useTranslation('odf', { keyPrefix: 'preApproval.userFunnel.loading.proposalSkeleton' })
  const placeholderWidth = 55

  return (
    <div className='proposal-container'>
      <div className='provider'>
        <ValueWithLabel label={t('provider')} placeholderWidth={placeholderWidth} labelPosition='right' />
        <ValueWithLabel label={t('provider')} placeholderWidth={placeholderWidth} />
      </div>
      <div className='monthly-installment'>
        <ValueWithLabel label={t('monthlyInstallment.mobile')} placeholderWidth={placeholderWidth} labelPosition='left' labelWeight='bold' />
        <ValueWithLabel label={t('monthlyInstallment.desktop')} placeholderWidth={placeholderWidth} labelWeight='bold' />
      </div>
      <div className='lower-section'>
        <ValueWithLabel label={t('fixedRate')} placeholderWidth={placeholderWidth} />
        <ValueWithLabel label={t('interestRate')} placeholderWidth={placeholderWidth} />
        <ValueWithLabel label={t('netLoanAmount')} placeholderWidth={placeholderWidth} />
      </div>
    </div>
  )
}

export default ProposalSkeleton
