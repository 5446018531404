import { ApexOptions } from 'apexcharts'
import { toCurrency } from '../../../../../services/NumberUtils'
import { TFunction } from 'i18next'

export const ChartOptions = (totalCost: number, t: TFunction, profile: boolean) => {

  const COLORS = {
    maxLoan: '#00D9B1',
    ownCapital: '#F2CA26',
    black: '#333333',
  }

  const LABELS = [ t('resultPage.totalCostDonutChart.ownCapital'),
    profile ? t('resultPage.totalCostDonutChart.netLoan') : t('resultPage.totalCostDonutChart.maxLoan') ];
  const FONT_FAMILY = 'Make It Sans IS24 Web';
  const DONUT_FONT_SIZE = '14';
  const LABELS_FONT_SIZE = '12';
  const DONUT_SIZE = '50%';

  enum positionEnum {
    top = 'top',
    right = 'right',
    bottom = 'bottom',
    left = 'left'
  }

  return {
    labels: LABELS,
    tooltip: {
      enabled: false
    },
    chart: {
      id: 'total-cost-chart',
      animations: {
        enabled: true,
      }
    },
    colors: [ COLORS.ownCapital, COLORS.maxLoan, ],
    legend: {
      show: true,
      fontSize: LABELS_FONT_SIZE,
      color: COLORS.black,
      fontFamily: FONT_FAMILY,
      position: positionEnum.bottom,
      formatter: (value: any, { seriesIndex, w }: any) => {
        return formatLegend(value, w, seriesIndex)
      },
      inverseOrder: true,
      onItemClick: {
        toggleDataSeries: false
      },
      markers: {
        offsetY: -7,
        offsetX: -7,
        width: 9,
        height: 9
      },
      itemMargin: {
        horizontal: 20
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: DONUT_SIZE,
          labels: {
            show: true,
            total: {
              show: true,
              fontSize: DONUT_FONT_SIZE,
              color: COLORS.black,
              fontFamily: FONT_FAMILY,
              label: t('resultPage.totalCostDonutChart.totalCost'),
              formatter: () => {
                return toCurrency(totalCost)
              },
            },
            value: {
              show: true,
              fontSize: DONUT_FONT_SIZE,
              color: COLORS.black,
              fontFamily: FONT_FAMILY,
              formatter: (val: string) => {
                return toCurrency(Number.parseInt(val))
              },
              offsetY: 2
            },
            name: {
              show: true,
              fontSize: DONUT_FONT_SIZE,
              color: COLORS.black,
              fontFamily: FONT_FAMILY,
              formatter: (val: string) => val,
              offsetY: -2
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false
      },
      style: {
        fontSize: LABELS_FONT_SIZE,
        colors: [ COLORS.black ],
        fontFamily: FONT_FAMILY,
      }
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      }
    }
  } as ApexOptions
}

export function formatLegend(value: string, w: any, seriesIndex: any) {
  return '<div>' + value + '</div>' + '<div><b>' + toCurrency(w.config.series[ seriesIndex ]) + '</b></div>'
}
