import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../../redux/store'
import { LogInState } from '../../../../../../redux/reducers/Login'
import { Is24Template } from '../../../../snippets/Is24Template'
import { BenefitBar } from '../../../../benefitBar/BenefitBar'
import './PageWithHeader.less'

export interface PageWithHeaderProps {
  withBenefitBar?: boolean
  children: React.ReactNode
}

const PageWithHeader: React.FC<PageWithHeaderProps> = ({withBenefitBar = false, children}) => {
  const { loggedIn } = useSelector<ApplicationState, LogInState>(state => state.logIn)

  return (
    <div className='page-container'>
      <div className='page-content-container'>
        <Is24Template headerType={loggedIn ? 'default' : 'light'} withFooter={false}>{children}</Is24Template>
      </div>
      {withBenefitBar &&
        <div className='benefit-bar-container page-wrapper'>
          <BenefitBar />
        </div>
      }
    </div>
  )
}

export default PageWithHeader
