import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ContactRequestObjectionPage from '../components/gdpr/ContactRequestObjectionPage'
import FinancingRequestRoad from '../components/leadengine/FinancingRequestRoad'
import { withInitialization } from '../components/leadengine/hoc/withInitialization'
import { withIs24Template } from '../components/common/snippets/withIs24Template'
import MortgageOfficer from '../components/mortgageOfficer/MortgageOfficer';
import RedistributionLandingPage from '../components/redistribution/RedistributionLandingPage'
import ProviderUnsubscribePage from '../components/unsubscribe/ProviderUnsubscribePage'
import AffordabilityRoutes from '../components/affordability/AffordabilityRoutes'

import {
  AFFORDABILITY,
  CONTACT_REQUEST_REDIST_LANDING_PAGE,
  FINANCING,
  GDPR_OBJECTION_CONTACT_REQUEST_PAGE,
  LEAD_ENGINE_ROAD_PAGE,
  MORTGAGE_PROFILE,
  MORTGAGE_OFFICER,
  PRE_APPROVAL,
  PROVIDER_UNSUBSCRIBE_PAGE
} from './contextPaths'
import FinancingRoutes from '../components/financing/FinancingRoutes'
import PreApprovalRoutes from '../components/preApproval/PreApprovalRoutes'
import ProfilePageWrapper from '../components/profile/ProfilePageWrapper'

const FinancingRequestRoadWithInitialization = withInitialization(FinancingRequestRoad)

const MortgageOfficersWithIs24Template = withIs24Template(MortgageOfficer)

/** Context path /baufinanzierung/portal-resources is only used because the app is served from a
 context path in the company-wide Cloudfront instance.
 When the whole /baufinanzierung will have been migrated to ReactJS, `portal-resources` can be
 removed and only /baufinanzierung present */
const AppRoutes: React.FC = () => (
  <Routes>
    <Route path='/baufinanzierung/portal-resources' element={<Navigate to="/" replace />} />
    <Route path='/' element={<Navigate to={MORTGAGE_PROFILE} replace />} />
    <Route path={`${LEAD_ENGINE_ROAD_PAGE}/:page`} element={<FinancingRequestRoadWithInitialization/>} />
    <Route path={GDPR_OBJECTION_CONTACT_REQUEST_PAGE} element={<ContactRequestObjectionPage/>} />
    <Route path={`${MORTGAGE_OFFICER}/*`} element={<MortgageOfficersWithIs24Template/>} />
    <Route path={CONTACT_REQUEST_REDIST_LANDING_PAGE} element={<RedistributionLandingPage/>} />
    <Route path={PROVIDER_UNSUBSCRIBE_PAGE} element={<ProviderUnsubscribePage/>} />
    <Route path={`${AFFORDABILITY}/*`} element={<AffordabilityRoutes/>} />
    <Route path={`${MORTGAGE_PROFILE}/*`} element={<ProfilePageWrapper/>} />
    <Route path={`${FINANCING}/*`} element={<FinancingRoutes/>} />
    <Route path={`${PRE_APPROVAL}/*`} element={<PreApprovalRoutes/>} />
  </Routes>
)

export default AppRoutes
