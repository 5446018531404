import { useEffect } from 'react'
import { useOneDynamicFlow } from '../../common/oneDynamicFlow/hook/useOneDynamicFlow'
import { shouldShowEmployedSinceQuestionFor } from '../services/PreApprovalService'

const useResetDataDependentOnCurrentEmploymentType = () => {
  const { oneDynamicFlow, setOneDynamicFlow } = useOneDynamicFlow()
  useEffect(() => {
    if (oneDynamicFlow.employmentType) {
      if (!shouldShowEmployedSinceQuestionFor(oneDynamicFlow.employmentType)) {
        setOneDynamicFlow({ fixedTermEmployment: undefined, employedSince: undefined })
      } else if (typeof oneDynamicFlow.fixedTermEmployment === 'undefined') {
        setOneDynamicFlow({ fixedTermEmployment: false })
      }
    }
  }, [ oneDynamicFlow.employmentType, oneDynamicFlow.fixedTermEmployment, setOneDynamicFlow])
}

export default useResetDataDependentOnCurrentEmploymentType
